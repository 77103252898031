import React from 'react'
import KineContext from 'src/context/KineContext'

interface Props {
	size?: string
    profile:{img?: string, name:string}
}

const InitialSimbol: React.FC<Props> = ({ size, profile }) => {
	
	return (
		<div className="flex flex-col">
			{profile && profile.img && profile.img != 'null' ? (
				<img
					src={profile.img}
					alt="profile"
					className={
						size === 'lg'
							? 'data__initial min-w-[30px] w-[30px] h-[30px]  '
							: 'data__initial min-w-[30px] w-[30px] h-[30px]  '
					}
					style={{
						objectFit: 'cover',
						borderRadius: '100%',
					}}
				/>
			) : (
				<p
					className={
						size === 'lg'
							? 'data__initial min-w-[30px] w-[30px] h-[30px]  '
							: 'data__initial min-w-[30px] w-[30px] h-[30px]  '
					}>
					{profile.name ? profile.name[0].toUpperCase() : ''}
				</p>
			)}
		</div>
	)
}

export default InitialSimbol
