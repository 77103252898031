import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleCaption,
	TextStyleH1,
	TextStyleH2,
	TextStyleH3,
} from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { getUserTwinFiles, insertUserFile } from 'src/service/storage/Ipfs'
import { DigitalTwinForm, FileInfo } from './components/digitalTwinForm'
import { getSocialProfileByAddres } from 'src/service/social/socialService'
import { User, UserProfile } from 'src/interface'
import InputText from 'src/components/_Dsc/InputText'
import { DsyLabel } from 'src/components/_Dsc/Button/styles'
const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Digital Twin', link: '' },
]
export const DigitalTwin: React.FC = () => {
	const { user } = React.useContext(KineContext)
	const [digitalData, setDigitalData] = useState<any>([])
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [isEdit, setIsEdit] = useState<boolean>(false)
	const [type, setType] = useState('')
	const [digitalTwin, setDigitalTwin] = useState<UserProfile>({} as UserProfile)
	const navigate = useNavigate()

	const getTwinFiles = async () => {
		setInFetch(true)
		let req = await getUserTwinFiles(user.address)
		if (req && req.data && req.data.length) {
			setDigitalData(req.data)
		}
		setInFetch(false)
	}

	const openEditForm = () => {
		setIsEdit(true)
	}

	const getCurrentTwinData = async () => {
		let req = await getSocialProfileByAddres(user.address)
		setDigitalTwin(req)
	}

	const onChangeInput = (e: string) => {
		console.log('e', e)
	}

	React.useEffect(() => {
		if (!user || !user.address) {
			navigate('')
		} else {
			getTwinFiles()
		}
	}, [user])

	React.useEffect(() => {
		if (user) {
			getCurrentTwinData()
		}
	}, [user, isEdit])

	return (
		<div className="page-padding">
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer>
					<TextStyleH1>Digital Twin</TextStyleH1>
				</CardContainer>
				<div className="flex flex-col">
					{!inFetch ? (
						<div className="p-2">
							{user && user.address ? (
								<div className="flex flex-col gap-2">
									<div className="flex items-start justify-between w-full">
										<div>
											<TextStyleBodyDefault>@{user.tag}</TextStyleBodyDefault>
											<TextStyleH2>{user.name}</TextStyleH2>
										</div>
										{!isEdit ? <Button size="small" value="Edit" icon="edit" onClick={openEditForm} /> : ''}
									</div>
									<div className="flex flex-col">
										<TextStyleBodyDefault>Bio</TextStyleBodyDefault>
										<TextStyleH3>{user.description}</TextStyleH3>
									</div>
								</div>
							) : (
								''
							)}
						</div>
					) : (
						''
					)}
				</div>
				<div className="flex flex-col bg-white rounded-[4px] p-2">
					{inFetch ? (
						<Loading />
					) : isEdit ? (
						<DigitalTwinForm
							getTwinFiles={getTwinFiles}
							setIsEdit={setIsEdit}
							currentFiles={digitalData}
							digitalTwin={digitalTwin}
						/>
					) : (
						<div className="flex flex-col gap-1">
							{digitalTwin ? (
								<div className="flex flex-col gap-2">
									<InputText
										label="Twin Name:"
										disabled
										value={digitalTwin.digital_twin_name || ''}
										onChange={onChangeInput}
									/>
									<InputText
										label="Twin Description:"
										disabled
										value={digitalTwin.digital_twin_description || ''}
										onChange={onChangeInput}
									/>
									<div className="flex items-center ">
									<input
										id="radio-image"
										type="radio"
										value="img"
										name="file-type"
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
										
										checked
									/>
									<label htmlFor="radio-image" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
										{digitalTwin.twin_base_model || 'No defined model'}
									</label>
								</div>
									<div className="flex flex-col">
										<div className="flex items-center gap-1">
											<DsyLabel className="font-[14px] text-[14px]">System prompt:</DsyLabel>
										</div>
										<div className="w-full flex flex-col items-end justify-end">
											<textarea
												disabled
												value={digitalTwin.twin_prompt || 'Write the prompt for you project'}
												maxLength={500}
												id="description"
												rows={4}
												className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"></textarea>
										</div>
									</div>
								</div>
							) : (
								''
							)}
							{digitalData && digitalData.length ? (
								<div>
									<TextStyleBodyDefaultLarge>Files:</TextStyleBodyDefaultLarge>
									<div className="flex flex-col gap-2">
										{digitalData.map((file: FileInfo, key: number) => (
											<div
												key={key}
												className="p-2 flex w-full items-center justify-between  border border-[#ededed] rounded-[4px]">
												<TextStyleBodyDefaultLarge>{file.file_name}</TextStyleBodyDefaultLarge>{' '}
												<TextStyleCaption>{file.file_size} KB</TextStyleCaption>
											</div>
										))}
									</div>
								</div>
							) : (
								<div className="flex items-center w-full flex-col gap-2">
									<TextStyleCaption>
										You have not yet added files as a source of knowledge for your digital twin
									</TextStyleCaption>
									{!isEdit ? (
										<Button size="small" value="Add files to the digital twin" icon="edit" onClick={openEditForm} />
									) : (
										''
									)}
								</div>
							)}
						</div>
					)}
				</div>
			</CardInner>
		</div>
	)
}
