import React from 'react'
import KineContext from 'src/context/KineContext'

interface Props {
	size?: string
}

const UserAvatar: React.FC<Props> = ({ size }) => {
	const { profile } = React.useContext(KineContext)
	return (
		<div className="flex flex-col">
			{profile && profile.img && profile.img != 'null' ? (
				<img
					src={profile.img}
					alt="profile"
					className={size === 'lg' ? 'min-w-[80px] w-[80px]' : 'min-w-[50px] w-[50px]'}
					style={{
						width: size === 'lg' ? '80px' : '50px',
						height: size === 'lg' ? '80px' : '50px',
						objectFit: 'cover',
						borderRadius: '100%',
					}}
				/>
			) : (
				<p
					className={
						size === 'lg' ? 'data__initial w-[50px] h-[50px] text-2xl ' : 'data__initial w-[30px] h-[30px] text-2xl '
					}>
					{profile.name ? profile.name[0].toUpperCase() : ''}
				</p>
			)}
		</div>
	)
}

export default UserAvatar
