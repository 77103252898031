import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Character } from '../community/components/Actions/components/GPT/components/Characters'
import { addCharacterInteract, getPublicCharactersBydId } from 'src/service/community/communityService'
import axios from 'axios'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleBodyDefaultMedium,
	TextStyleCaption,
	TextStyleH1,
} from 'src/components/_Dsc/typography'
import { Divider } from '@mui/material'
import { Loading } from 'src/components/loading'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Interact', link: '' },
]

export const CharacterInteract: React.FC = () => {
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [publiCharacters, setPublicCharacters] = useState<Character[]>([])
	const [selectedCharacter, setSelectedCharacter] = useState<Character>({} as Character)
	const [responses, setResponses] = useState<string[]>([])
	const [loading, setLoading] = useState(false)
	const { id } = useParams()
	const [inputText, setInputText] = useState('')

	const getAllPublicCommunityCharacters = async () => {
		setInFetch(true)
		if (id) {
			let req = await getPublicCharactersBydId(id)
			if (req && req.data) {
				setSelectedCharacter(req.data)
				setPublicCharacters(req.data)
			}
		}
		setInFetch(false)
	}

	const postChatCompletion = async () => {
		try {
			setInputText('')
			setLoading(true)
			const response = await axios.post(
				'https://open-gpt.kinera.network/api/chat/completions',
				{
					model: selectedCharacter.base_param ?? 'arena-model',
					messages: [
						{
							role: 'user',
							content: `Use system prompt:${selectedCharacter.params} based on this answer: ${inputText}`,
						},
					],
					files: [],
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)

			setResponses(prev => [...prev, `Question: ${inputText}`, `Answer: ${response.data.choices[0].message.content}`])
			addInteract(selectedCharacter.id)
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setLoading(false)
		}
	}

	const addInteract = async (id: string) => {
		 await addCharacterInteract(id)
	}

	useEffect(() => {
		getAllPublicCommunityCharacters()
	}, [])

	return (
		<div className="w-full flex flex-col h-full page-padding">
			<Breadcrumb routes={path} />
			<CardInner className='flex flex-col h-full'>
				<CardContainer className="gap-2 flex flex-col h-full">
					<div className="w-full flex flex-col h-full">
						<div className="flex items-center justify-between">
							<TextStyleH1>Interact</TextStyleH1>
						</div>
						<div className="p-4 h-full flex bg-[#ededed]">
							<div className="p-4 bg-white gap-4 flex flex-col max-w-[40%] w-[40%] overflow-hidden">
								<div className="flex flex-col items-end justify-end">
									<TextStyleCaption>Creator:</TextStyleCaption>
									<div className="flex items-center gap-1 cursor-pointer hover:bg-gray-100">
										<span className="material-symbols-outlined">person</span>
										<TextStyleBodyDefaultLarge>@{selectedCharacter.owner_name}</TextStyleBodyDefaultLarge>
									</div>
								</div>
								<Divider />
								<div className="flex flex-col gap-2">
									<div className="flex items-center gap-2">
										<span className="material-symbols-outlined">description</span>
										<TextStyleBodyDefault>{selectedCharacter.name}</TextStyleBodyDefault>
									</div>
									<div>
										<TextStyleCaption>About:</TextStyleCaption>
										<div className="overflow-y-auto">
											<TextStyleBodyDefault className='break-all'>{selectedCharacter.description}</TextStyleBodyDefault>
										</div>
									</div>
								</div>
							</div>
							<div className="w-full justify-end items-center flex flex-col">
								{responses && responses.length ? (
									<div className="overflow-y-auto p-2 w-full">
										{responses.map((response, index) => (
											<div key={index} className="flex flex-col w-full">
												{response.includes('Question:') ? (
													<div className="w-full flex items-end  justify-end ">
														<div className="w-fit p-2 bg-[#F9F9F9] rounded-[100px]">
															<TextStyleBodyDefaultMedium>
																{response.includes('Question:') ? response.split('Question: ')[1] : ''}
															</TextStyleBodyDefaultMedium>
														</div>
													</div>
												) : (
													''
												)}

												{response.includes('Answer:') ? (
													<div className="p-2 w-full items-start justify-start flex rounded-[10px]">
														<div className="flex w-full h-full items-start gap-2">
															<img
																src="https://alpha.kinera.network/static/media/kinera_.aa99fcdcf04e75b583bf.webp"
																className="w-[30px] rounded-full bg-white p-1"
															/>
															<TextStyleBodyDefaultMedium>
																{response.includes('Answer:') ? response.split('Answer: ')[1] : ''}
															</TextStyleBodyDefaultMedium>
														</div>
													</div>
												) : (
													''
												)}
											</div>
										))}
									</div>
								) : (
									''
								)}
								{loading ? <Loading /> : ''}
								<div className="bg-white p-4 w-[90%] rounded-[10px] mt-4">
									<div className="flex flex-col gap-1 items-end justify-end">
										<textarea
											maxLength={300}
											id="description"
											rows={4}
											value={inputText}
											onChange={e => setInputText(e.target.value)}
											className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
											placeholder="Start typing"></textarea>
										<Button
											value="Send"
											size="small"
											onClick={postChatCompletion}
											disabled={loading || inputText.trim() === ''}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}
