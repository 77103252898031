import React, { useRef, useState } from 'react'
import Modal from '../_Dsc/Modal'
import KineContext from 'src/context/KineContext'
import Button from '../_Dsc/Button'
import { Loading } from '../loading'
import { TextStyleBodyDefault, TextStyleBodyDefaultMedium, TextStyleCaption } from '../_Dsc/typography'
import axios from 'axios'
import Dropdown from '../_Dsc/Dropdown'
import { Tooltip } from '@mui/material'
import { useDrag } from './useDrag'
import './style.scss'
import { ProdutionTools } from '../ProdutionsTools'
import Tabs from '../_Dsc/Tabs'
import { Chats } from '../_Dsc/Chat'
import { useNavigate } from 'react-router-dom'
const options = [
	{
		label: 'Kinera',
		value: 'kineragpt',
		description: 'Best for answering questions about the platform and the objectives of the kinera project.',
	},
	{
		label: 'Arena model',
		value: 'arena-model',
		description: 'Ideal for fast, responsive real-time interactions and dynamic content.',
	},
	{
		label: 'gemma2 latest',
		value: 'gemma2:latest',
		description: 'Best for detailed conversations and contextual responses.',
	},

	{
		label: 'llama3.2 latest',
		value: 'llama3.2:latest',
		description: 'Focused on natural style and engaging language.',
	},
	{
		label: 'Qwen2.5-14B',
		description: 'Qwen-2.5-14B is a powerful and efficient language model with 14 billion parameters.',
		value: 'qwen2.5:14b',
	},
]

let categories = [
	{
		label: `GPT's`,
		value: 'gpt',
	},
	{
		label: 'Prodution Tools',
		value: 'tools',
	},
]

interface Option {
	label: string
	value: string
	description: string
}

const defaultHelp = ['What is the Kinera Network?', 'What are constellations?', 'What are rankings?']

export const GPT: React.FC = () => {
	const navigate = useNavigate()
	const { openHelp, swapCurrentHelp, user } = React.useContext(KineContext)
	const [inputText, setInputText] = useState('')
	const [selectedOption, setSelectedOptions] = useState(options[1].value)
	const [selectedOptionDetail, setSelectedOptionsDetail] = useState<Option>(options[1])
	const [responses, setResponses] = useState<string[]>([])
	const [chatId, setChatId] = useState<string[]>([])
	const [loading, setLoading] = useState(false)
	const [isHelp, setIsHelp] = useState(false)
	const [selectedOptions, setSelectedOption] = useState('gpt')
	const [produtionToolResponse, setProdutionToolResponse] = useState('')
	const [enableProdutionTool, setEnableProdutionTool] = useState(false)
	const [showOptions, setShowOptions] = useState(false)
	const draggableRef = useRef(null)

	const { position, handleMouseDown } = useDrag({
		ref: draggableRef,
	})

	const changeTab = (val: string) => {
		if (val === 'tools') {
			setEnableProdutionTool(true)
		} else {
			setEnableProdutionTool(false)
		}
		setSelectedOption(val)
	}

	const postChatCompletion = async (param?: string) => {
		setProdutionToolResponse('')
		setEnableProdutionTool(false)
		try {
			setInputText('')
			setLoading(true)
			const currentMessage = {
				role: 'user',
				content: param ? param : inputText,
			}
			const response = await axios.post(
				'https://open-gpt.kinera.network/api/chat/completions',
				{
					model: selectedOptionDetail.value,
					chat_id: chatId ? chatId : '',
					messages: [
						...responses.map((response, index) => ({
							role: index % 2 === 0 ? 'user' : 'assistant', // Alterna entre user e assistant
							content: response.includes('Question:') ? response.split('Question: ')[1] : response.split('Answer: ')[1],
						})),
						currentMessage,
					],
					files: [],
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)
			console.log('response.data', response.data)
			if (!chatId) {
				setChatId(response.data.id)
			}
			setResponses(prev => [
				...prev,
				`Question: ${param ? param : inputText}`,
				`Answer: ${response.data.choices[0].message.content}`,
			])
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setLoading(false)
		}
	}

	const handleSelectOptions = (option: string) => {
		let filter = options.filter((val: Option) => val.value === option[0])
		setSelectedOptionsDetail(filter[0])
		setSelectedOptions(option[0])
	}

	const handleSearchByHelp = (option: string) => {
		setInputText(option)
		postChatCompletion(option)
	}

	const onOpenHelp = () => {
		setIsHelp(true)
		setSelectedOptions(options[0].value)
		setSelectedOptionsDetail(options[0])
		swapCurrentHelp(true)
	}

	const closeModal = () => {
		swapCurrentHelp(false)
		setIsHelp(false)
	}

	const openModal = () => {
		swapCurrentHelp(true)
		setSelectedOptions(options[1].value)
		setSelectedOptionsDetail(options[1])
	}

	const downloadImage = async () => {
		try {
			// Faz o fetch da imagem
			const response = await fetch(`https://open-gpt.kinera.network${produtionToolResponse}`)

			// Verifica se o download foi bem-sucedido
			if (!response.ok) {
				throw new Error('Failed to fetch the image')
			}

			// Obtém os dados da imagem como um blob
			const blob = await response.blob()

			// Cria uma URL local para o blob
			const blobUrl = URL.createObjectURL(blob)

			// Cria um link temporário para forçar o download
			const link = document.createElement('a')
			link.href = blobUrl
			link.download = `generated_image_${Date.now()}.png` // Nome do arquivo
			document.body.appendChild(link) // Adiciona ao DOM
			link.click() // Simula o clique
			document.body.removeChild(link) // Remove o link do DOM
			URL.revokeObjectURL(blobUrl) // Libera a memória associada ao blob
		} catch (error) {
			console.error('Error downloading the file:', error)
		}
	}

	React.useEffect(() => {
		setResponses([])
		setSelectedOption('gpt')
		setProdutionToolResponse('')
		setEnableProdutionTool(false)
	}, [openHelp])

	return (
		<div className="relative z-[100]">
			<Modal
				title="Kinera AI Assistant"
				open={openHelp}
				onCancel={() => {
					closeModal()
				}}
				onClose={() => {
					closeModal()
				}}
				cancelLabel="Close"
				size="xl"
			>
				<div className="p-2 h-full flex bg-[#ededed] gap-2">
					<div className="p-4 h-full justify-between bg-white gap-4 flex flex-col max-w-[40%] w-[40%] ">
						<div>
							<div className="relative w-full">
								<Dropdown
									position="right"
									options={options}
									value={[selectedOption]}
									onChange={(e: any) => {
										handleSelectOptions(e)
									}}
								></Dropdown>
							</div>
							<div>
								<TextStyleBodyDefaultMedium>{selectedOptionDetail.description}</TextStyleBodyDefaultMedium>
							</div>
						</div>
						<div>{/** */}</div>
					</div>
					<div className="w-full justify-end items-center flex flex-col ">
						<div className="overflow-y-auto p-2 w-full max-h-[550px]">
							{responses.map((response, index) => (
								<div key={index} className="flex flex-col w-full">
									{response.includes('Question:') ? (
										<div className="w-full flex items-end  justify-end ">
											<div className="w-fit p-2 bg-[#F9F9F9] rounded-[100px]">
												<TextStyleBodyDefaultMedium>
													{response.includes('Question:') ? response.split('Question: ')[1] : ''}
												</TextStyleBodyDefaultMedium>
											</div>
										</div>
									) : (
										''
									)}

									{response.includes('Answer:') ? (
										<div className="p-2 w-full items-start justify-start flex rounded-[10px]">
											<div className="flex w-full h-full items-start gap-2">
												<img
													src="https://alpha.kinera.network/static/media/kinera_.aa99fcdcf04e75b583bf.webp"
													className="w-[30px] rounded-full bg-white p-1"
												/>
												<TextStyleBodyDefaultMedium>
													{response.includes('Answer:') ? response.split('Answer: ')[1] : ''}
												</TextStyleBodyDefaultMedium>
											</div>
										</div>
									) : (
										''
									)}
								</div>
							))}
						</div>

						{loading ? <Loading /> : ''}
						<div className=" w-full rounded-[10px] mt-4 flex flex-col gap-2">
							<div className="flex items-center gap-2">
								{isHelp && !responses.length && !loading
									? defaultHelp.map((val: string, key: number) => (
											<div
												key={key}
												className="w-fit p-2 rounded-[10px] bg-white hover:bg-gray-100 cursor-pointer"
												onClick={() => {
													handleSearchByHelp(val)
												}}
											>
												<TextStyleCaption>{val}</TextStyleCaption>
											</div>
									  ))
									: ''}
							</div>
							<div className="p-4 rounded-[4px] bg-white flex flex-col gap-1 items-end justify-end">
								<textarea
									maxLength={300}
									id="description"
									rows={4}
									value={inputText}
									onChange={e => setInputText(e.target.value)}
									className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
									placeholder="Start typing"
								></textarea>
								<Button
									value="Send"
									size="small"
									onClick={postChatCompletion}
									disabled={loading || inputText.trim() === ''}
								/>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<div className="w-full flex items-center justify-center">
				<div
					className={
						showOptions
							? ' gap-2 flex flex-col transform bg-white p-2 rounded-[4px] shadow-t draggable'
							: ' gap-2 flex transform bg-white p-2 rounded-[4px] shadow-t draggable'
					}
					ref={draggableRef}
					style={{
						top: position.y,
						left: position.x,
					}}
				>
					{!showOptions ? (
						<Button
							value="Shortcut"
							icon="help"
							size="small"
							onClick={() => {
								setShowOptions(true)
							}}
						/>
					) : (
						<div className="w-full flex items-center justify-center gap-2">
							<Button
								value="Hidden"
								icon="visibility_off"
								size="small"
								type="warning"
								onClick={() => {
									setShowOptions(false)
								}}
							/>
							<Tooltip title="Drag to the angle you want best" className=" cursor-move">
								<div onMouseDown={handleMouseDown} className="draggable-panel cursor-move">
									<Button type="subtle" size="small" icon="drag_indicator" iconOnly={true} value="robot" />
								</div>
							</Tooltip>
						</div>
					)}

					{showOptions ? (
						<div className={`flex flex-col gap-2`}>
							<Tooltip title="AI Assistant">
								<div>
									<Button
										size="small"
										icon="robot"
										value="GPT's"
										onClick={() => {
											openModal()
										}}
									/>
								</div>
							</Tooltip>
							<Tooltip title="Help">
								<div>
									<Button
										size="small"
										icon="help"
										value="Kinera helper"
										onClick={() => {
											onOpenHelp()
										}}
									/>
								</div>
							</Tooltip>
							<Tooltip title="Private Messages">
								<div>
									<Button
										disabled={!user || !user.address}
										size="small"
										icon="chat"
										value="Chats"
										onClick={() => {
											navigate('messenger')
										}}
									/>
								</div>
							</Tooltip>
						</div>
					) : (
						''
					)}
					{!showOptions ? (
						<Tooltip title="Drag to the angle you want best" className=" cursor-move">
							<div onMouseDown={handleMouseDown} className="draggable-panel cursor-move">
								<Button type="subtle" size="small" icon="drag_indicator" iconOnly={true} value="robot" />
							</div>
						</Tooltip>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	)
}
