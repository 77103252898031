import React, { useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import InputText from 'src/components/_Dsc/InputText'
import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
import UserBalance from 'src/components/balances'
import KineContext from 'src/context/KineContext'
import { updateUserKeys } from 'src/service/profile/profileService'

export const UserConfiguration: React.FC = () => {
	const { user, handleGlobalMessage, setOnQueue } = React.useContext(KineContext)
	const [apiKey, setApyKey] = useState('')
	const [edit, setEdit] = useState(false)

	const handleInputChange = (e: string) => {
		console.log('e')
		setApyKey(e)
	}

	const handleEditApiKey = async () => {
		if (!apiKey) {
			alert('API Key is required.')
			return
		}

		// Verifica se a chave é válida
		const isValid = await validateChatGptApiKey(apiKey)
		if (!isValid) {
			alert('Invalid API Key. Please provide a valid key.')
			return
		}
		setOnQueue(true)
		let payload = {
			chat_gpt: apiKey,
		}

		try {
			let req = await updateUserKeys(payload)
			setEdit(false)
			handleGlobalMessage('API Key updated successfully.')
			setApyKey(req.gpt_api_key)
		} catch (error) {
			console.error('Error updating API key:', error)
			alert('Failed to update API Key. Please try again.')
		}
	}

	// Função para validar a chave
	const validateChatGptApiKey = async (key: string) => {
		try {
			const response = await fetch('https://api.openai.com/v1/models', {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${key}`,
					'Content-Type': 'application/json',
				},
			})

			if (response.ok) {
				return true // Chave válida
			} else {
				console.error('Validation failed:', await response.json())
				return false // Chave inválida
			}
		} catch (error) {
			console.error('Error validating API Key:', error)
			return false // Chave inválida
		}
	}
	const handleEditChange = () => {
		setEdit(!edit)
	}

	useEffect(() => {
		setApyKey(user.gpt_api_key)
	}, [])

	return (
		<div className="flex flex-col w-full">
			<div className="flex  gap-2 flex-col">
				<div className="flex items-center gap-4">
					<TextStyleBodyDefaultMedium>{user.name}</TextStyleBodyDefaultMedium>
					<TextStyleBodyDefaultMedium>
						<UserBalance />
					</TextStyleBodyDefaultMedium>
				</div>
				<div className="flex items-end gap-4 w-fit">
					<InputText
						onChange={handleInputChange}
						label="Chat GPT Api Key:"
						value={apiKey}
						disabled={!edit}
						placeholder="Empty key"
					/>
					<Button
						value={edit ? 'Save' : 'Edit'}
						icon={edit ? 'save' : 'edit'}
						size="small"
						onClick={edit ? handleEditApiKey : handleEditChange}
					/>
				</div>
				<div className="flex items-center gap-4">
					<TextStyleBodyDefaultMedium>
						Current Capacity Storage: <b>{parseFloat(user.max_storage)} / KB</b>
					</TextStyleBodyDefaultMedium>
				</div>
				<div className="flex items-center gap-4">
					<TextStyleBodyDefaultMedium>
						In use Storage: <b>{parseFloat(user.storage) || 0} / KB</b>
					</TextStyleBodyDefaultMedium>
				</div>
			</div>
			{/**
				 * <div className="w-full flex flex-col items-end justify-end">
				<Button value="Save" icon="save" size="small" />
			</div>
				 */}
		</div>
	)
}
