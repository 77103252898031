import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import Close from '../../../../../../assets/close.svg'
import { commentDislike, commentLike, commentOnPost, removeComment } from 'src/service/social/socialService'
import KineContext from 'src/context/KineContext'
import RichTextDisplay from '../../../createTweet/RichText/Display'
import { Tooltip } from '@mui/material'
import DscIcons from 'src/components/_Dsc/icons'
import RichTextEditor from '../../../createTweet/RichText/RichText'
import DscModal from 'src/components/_Dsc/modalImage'
import TextH3 from 'src/components/_Dsc/typography/TextH3'
import Button from 'src/components/_Dsc/Button'
const colorVariantTitle = {
	color: '#000',
	fontSize: '25px',
	fontWeight: 'bold',
}
interface Props {
	setOnAnswer: (value: boolean) => void
	likePost?: (value: string) => void
	hiddenInput: boolean
	id: string
	comments: any
	username?: string
	getCurrentComments: (id: string) => void
}
const CommentBox: React.FC<Props> = ({
	setOnAnswer,
	likePost,
	hiddenInput,
	id,
	comments,
	username,
	getCurrentComments,
}) => {
	const { user, profile, setOnQueue, setGlobalToast } = React.useContext(KineContext)
	const [message, setMessage] = useState('')
	const [currentId, setCurrentId] = React.useState('')
	const [openModal, setOpenModal] = useState(false)

	const [isWriteComment, setIsWriteComment] = React.useState('')

	const navigate = useNavigate()

	const onChangeUnstakeValue = (e: any) => {
		setMessage(e)
	}

	const confirmAction = async (value: any) => {
		setIsWriteComment('')
		let req = await commentOnPost(id, message, user, setOnQueue, value.owner)
		if (req && req.data) {
			comments.push(req.data)

			//getAllNotifyUser()
		}
	}

	const handleCloseComments = () => {
		setIsWriteComment('')
		setOnAnswer(false)
	}

	const getComments = async (value: any) => {
		setMessage(
			`<p><span style="color: #0053e4; font-weight: bold;"><a href="${`https://d-app.kinera.network/social/view/${value.owner}`}" rel="noopener noreferrer" target="_blank">@${
				value.name
			}</a></span>&nbsp;&nbsp;&nbsp;&nbsp</span></p>`,
		)

		setOnAnswer(true)
		setIsWriteComment(value.id)
	}

	const newFormatedDate = (time: any) => {
		const date = new Date(time)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const year = date.getFullYear()
		const hours = String(date.getHours()).padStart(2, '0')
		const minutes = String(date.getMinutes()).padStart(2, '0')

		return `${day}/${month}/${year} - ${hours}:${minutes}`
	}

	const removePostById = async () => {
		setOnQueue(true)
		await removeComment(user, currentId, id)
		await getCurrentComments(id)
		setOnQueue(false)
		setOpenModal(false)
	}

	const handleModal = (commentId: string) => {
		setCurrentId(commentId)
		setOpenModal(true)
	}

	const sendLikeToPost = async (id: string, value: any) => {
		setOnQueue(true)
		let req = await commentLike(id, user.address)

		if (req && req.data.id && !req.remove) {
			value.likes += 1
			value.has_user_liked = true
		}
		if (req.data && req.remove) {
			value.likes -= 1
			value.has_user_liked = false
		}
		setOnQueue(false)
	}

	const sendDislikeToPost = async (id: string, value: any) => {
		setOnQueue(true)
		let req = await commentDislike(id, user.address)

		if (req && req.id && !req.remove) {
			value.dislikes += 1
			value.has_user_unliked = true
		}
		if (req.data && req.remove) {
			value.dislikes -= 1
			value.has_user_unliked = false
		}
		setOnQueue(false)
	}

	const handleClickLoginButton = () => {
		const loginButton = document.getElementById('login-btn') as HTMLButtonElement | null
		if (loginButton) {
			loginButton.click()
		} else {
			console.error('Login button not found')
		}
	}

	return (
		<div>
			{openModal && (
				<DscModal
					open={openModal}
					setOpen={setOpenModal}
					confirmButton={true}
					confirmText={'Proceed'}
					confirmAction={removePostById}
					type="alert"
				>
					<div className="flex flex-col gap-4  overflow-hidden relative min-w-[700px]">
						<div className="flex p-2 items-center justify-between">
							<div className="flex items-center gap-4">
								<span className="material-symbols-outlined text-red-300" style={{ fontSize: '40px' }}>
									error
								</span>
								<TextH3 sx={colorVariantTitle}>Are you sure?</TextH3>
							</div>
							<button
								className="cursor-pointer"
								onClick={() => {
									setOpenModal(false)
								}}
							>
								<img src={Close} alt="img" />
							</button>
						</div>
						<div className="max-h-[400px] w-[500px] text-[#848B94] m-auto overflow-y-auto">
							When you confirm this action, your comment will be deleted.
						</div>
					</div>
				</DscModal>
			)}
			{comments && comments.length
				? comments.map((comment: any, index: number) => (
						<div className=" w-full">
							<div className="flex items-start gap-2 w-full py-[4px]">
								<img
									className="avatar cursor-pointer hover:opacity-70"
									onClick={() => {
										navigate('/social/view/' + comment.owner)
									}}
									src={
										comment.imageLink && comment.imageLink.length > 4 && comment.imageLink !== 'null'
											? comment.imageLink
											: 'https://media.istockphoto.com/id/522855255/vector/male-profile-flat-blue-simple-icon-with-long-shadow.jpg?s=612x612&w=0&k=20&c=EQa9pV1fZEGfGCW_aEK5X_Gyob8YuRcOYCYZeuBzztM='
									}
									alt="avatar"
								/>
								<div key={index} className="border p-2  cursor-pointer  w-full bg-[#f9fafb] rounded-[8px]">
									<div className=" flex flex-col  gap-2  w-full">
										<div className="flex items-center justify-between">
											<span className="truncate text-lg text-bold font-bold">{comment.name}</span>
											<span>{comment && comment.created_at && newFormatedDate(comment.created_at)}</span>
										</div>
										<div>
											<RichTextDisplay initialValue={comment.content} readOnly={true} />
										</div>
									</div>

									<div className="flex items-center gap-4 ">
										<div className="flex items-center text-sm">
											<div className="flex items-center gap-4">
												{user && profile && user.address ? (
													<div className="flex items-center gap-4">
														<Tooltip title="Likes" placement="top-start">
															<button
																onClick={() => {
																	sendLikeToPost(comment.id, comment)
																}}
																className="font-bold   text-[14px] cursor-pointer hover:opacity-70 items-center flex gap-2"
															>
																<span
																	className={
																		comment.has_user_liked
																			? `material-symbols-outlined text-pink-400`
																			: 'material-symbols-outlined'
																	}
																>
																	recommend
																</span>
																{comment.likes}
															</button>
														</Tooltip>
														<Tooltip title="Dislikes" placement="top-start">
															<button
																onClick={() => {
																	sendDislikeToPost(comment.id, comment)
																}}
																className="font-bold   text-[14px] cursor-pointer hover:opacity-70 items-center flex gap-2"
															>
																<span
																	className={
																		comment.has_user_unliked
																			? `material-symbols-outlined text-pink-400`
																			: 'material-symbols-outlined'
																	}
																>
																	thumb_down
																</span>
																{comment.dislikes}
															</button>
														</Tooltip>
													</div>
												) : (
													<div className="flex items-center gap-4">
														<Tooltip title="Likes" placement="top-start">
															<button
																onClick={() => {
																	handleClickLoginButton()
																}}
																className="font-bold   text-[14px] cursor-pointer hover:opacity-70 items-center flex gap-2"
															>
																<span
																	className={
																		comment.has_user_liked
																			? `material-symbols-outlined text-pink-400`
																			: 'material-symbols-outlined'
																	}
																>
																	recommend
																</span>
																{comment.likes}
															</button>
														</Tooltip>
														<Tooltip title="Dislikes" placement="top-start">
															<button
																onClick={() => {
																	handleClickLoginButton()
																}}
																className="font-bold   text-[14px] cursor-pointer hover:opacity-70 items-center flex gap-2"
															>
																<span
																	className={
																		comment.has_user_unliked
																			? `material-symbols-outlined text-pink-400`
																			: 'material-symbols-outlined'
																	}
																>
																	thumb_down
																</span>
																{comment.dislikes}
															</button>
														</Tooltip>
													</div>
												)}
												{user && user.address && (
													<Tooltip title="Comment" placement="top-start">
														{isWriteComment !== comment.id ? (
															<button
																disabled={isWriteComment === comment.id}
																className="gap-2 flex items-center hover:bg-pink-100 hover:font-bold p-2 rounded-lg cursor-pointer text-[#E5007A] font-bold"
																onClick={() => {
																	getComments(comment)
																}}
															>
																<DscIcons icon="chat" />
															</button>
														) : (
															<button
																className="gap-2 flex items-center hover:bg-pink-100 hover:font-bold p-2 rounded-lg cursor-pointer text-[#E5007A] font-bold"
																onClick={() => {
																	handleCloseComments()
																}}
															>
																<DscIcons icon="comments_disabled" />
															</button>
														)}
													</Tooltip>
												)}
												{user && comment && comment.owner === user.address && (
													<Tooltip title="Delete post" placement="top-start">
														<button
															className="flex items-center hover:bg-pink-100 hover:font-bold p-2 rounded-lg cursor-pointer"
															onClick={() => {
																handleModal(comment.id)
															}}
														>
															<svg
																className="w-6 h-6 text-gray-800 dark:text-white"
																aria-hidden="true"
																xmlns="http://www.w3.org/2000/svg"
																fill="none"
																viewBox="0 0 24 24"
															>
																<path
																	stroke="currentColor"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth="2"
																	d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
																/>
															</svg>
														</button>
													</Tooltip>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
							{isWriteComment === comment.id && hiddenInput && user && user.address && (
								<div className="flex gap-2 items-start w-full">
									<img
										src={
											profile && profile.img
												? profile.img
												: 'https://media.istockphoto.com/id/522855255/vector/male-profile-flat-blue-simple-icon-with-long-shadow.jpg?s=612x612&w=0&k=20&c=EQa9pV1fZEGfGCW_aEK5X_Gyob8YuRcOYCYZeuBzztM='
										}
										alt="#"
										className="avatar cursor-pointer hover:opacity-70"
									/>

									<div className="w-full  p-2 rounded-[4px] bg-[#f9fafb]">
										<RichTextEditor onchange={onChangeUnstakeValue} value={message} />
										<div className="flex w-full items-end justify-end">
											{user.address && profile && (
												<div className="flex w-full items-center justify-end mt-2 gap-2">
													<div>
														<Button
															size="small"
															onClick={() => {
																setIsWriteComment('')
															}}
															value="Cancel"
															type="subtle"
														/>
													</div>
													{message.length > 1 ? (
														<Button
															size="small"
															value="Post"
															onClick={() => {
																confirmAction(comment)
															}}
														></Button>
													) : (
														<button className="p-2 bg-gray-200 rounded-lg  mr-4 cursor-not-allowed">Post</button>
													)}
												</div>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
				  ))
				: ''}
		</div>
	)
}

export default CommentBox
