import React from 'react'
import DscModal from '../../../../components/_Dsc/modalImage'
import TextH3 from '../../../../components/_Dsc/typography/TextH3'

import Close from '../../../../assets/close.svg'
import { FestivalService } from 'src/service/festival/festivalService'
import { Loading } from 'src/components/loading'
import { planksToTokens } from 'src/service/substrate/formated'
interface Props {
	open: boolean
	setOpen(value: boolean): void
	data: any
	currentKey: string
	setCurrentKey(value: string): void
}

interface Vote {
	id: string
	user: string
	key: string
	amount: string
	amountAfterWeight: string
}
const UserVotesModal: React.FC<Props> = ({ open, setOpen, data, currentKey, setCurrentKey }) => {
	const [fetch, setFetch] = React.useState<boolean>(true)
	const openModal = () => {
		setOpen(!open)
		setCurrentKey('')
	}

	const getUserVotes = async () => {
		setFetch(true)
		const id = window.location.pathname.split('/')[2]
		await FestivalService.getVotesByConstellationId(id, currentKey)

		setFetch(false)
	}
	const colorVariant = {
		color: '#000',
		fontSize: '17px',
	}
	const colorVariantTitle = {
		color: '#000',
		fontSize: '25px',
		fontWeight: 'bold',
	}

	React.useEffect(() => {
		getUserVotes()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentKey])

	return (
		<DscModal open={open} setOpen={setOpen} type="normal">
			{!fetch ? (
				<div className="flex flex-col gap-4  overflow-hidden relative min-w-[700px]">
					<div className="flex p-2 items-center justify-between">
						<TextH3 sx={colorVariantTitle}>Votes</TextH3>
						<button className="cursor-pointer" onClick={openModal}>
							<img src={Close} alt="img" />
						</button>
					</div>
					<div className="max-h-[400px] overflow-y-auto">
						{data && data.length ? (
							data?.map((value: Vote, key: number) => (
								<div
									key={key}
									className="flex items-center justify-between gap-10 hover:bg-gray-100 p-4 cursor-pointer"
								>
									<div className="flex items-center gap-4">
										<span className="material-symbols-outlined">person</span>
										<TextH3 sx={colorVariant}>{value.user}</TextH3>
									</div>
									<div className="flex items-center gap-2">
										<span className="font-bold text-bold text-black">{planksToTokens(value.amount)} KinexPlay </span>
										<span className=" text-black">Weight {planksToTokens(value.amountAfterWeight)} </span>
									</div>
								</div>
							))
						) : (
							<div className="w-[700px]">
								<p>No data to display</p>
							</div>
						)}
					</div>
				</div>
			) : (
				<Loading />
			)}
		</DscModal>
	)
}

export default UserVotesModal
