import React, { useContext, useEffect, useState } from 'react'
import { IDialog } from '..'
import { useNavigate } from 'react-router-dom'
import { getDialogPrompts } from 'src/service/dialog'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleCaption,
	TextStyleH2,
} from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import Accordion from 'src/components/_Dsc/Accordion'
import axios from 'axios'
import Button from 'src/components/_Dsc/Button'
import KineContext from 'src/context/KineContext'

interface Props {
	selectedDialog: IDialog | null
}

export interface DialogInterface {
	id: number
	dialog_id: number
	title: string
	prompt: string
	created_at: string
	updated_at: string
}

export const DialogDetail: React.FC<Props> = ({ selectedDialog }) => {
	const { user } = useContext(KineContext)
	const navigate = useNavigate()
	const [prompts, setPrompts] = useState<DialogInterface[]>([])
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [responses, setResponses] = useState<any[]>([])
	const [loading, setLoading] = useState<boolean>(false)

	const getPrompts = async () => {
		setInFetch(true)
		if (selectedDialog) {
			let req = await getDialogPrompts(selectedDialog.id)
			if (req && req.data) {
				setPrompts(req.data)
			}
		}
		setInFetch(false)
	}

	const postChatCompletion = async (model: string, content: string) => {
		try {
			const response = await axios.post(
				'https://open-gpt.kinera.network/api/chat/completions',
				{
					model,
					messages: [
						{
							role: 'user',
							content,
						},
					],
					files: [],
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)

			return response.data.choices[0].message.content
		} catch (error) {
			console.error('Error:', error)
			return 'Error fetching response'
		}
	}

	const postChatGPTCompletion = async (content: string) => {
		if (!user?.gpt_api_key) {
			throw new Error('User GPT API Key is not available')
		}
		try {
			const response = await axios.post(
				'https://api.openai.com/v1/chat/completions',
				{
					model: 'gpt-3.5-turbo',
					messages: [
						{
							role: 'user',
							content,
						},
					],
				},
				{
					headers: {
						Authorization: `Bearer ${user.gpt_api_key}`,
						'Content-Type': 'application/json',
					},
				},
			)

			return response.data.choices[0].message.content
		} catch (error) {
			console.error('Error:', error)
			return 'Error fetching response'
		}
	}

	const handleInitDialog = async () => {
		if (!selectedDialog || prompts.length === 0) return

		setLoading(true)

		for (const prompt of prompts) {
			let askResponse: any
			if (selectedDialog.ask === 'chat-gpt') {
				askResponse = await postChatGPTCompletion(prompt.prompt)
			} else {
				askResponse = await postChatCompletion(selectedDialog.ask, prompt.prompt)
			}

			setResponses(prevResponses => [
				...prevResponses,
				{
					title: prompt.title,
					model: selectedDialog.ask,
					response: askResponse,
				},
			])

			let respondResponse: any
			if (selectedDialog.respond === 'chat-gpt') {
				respondResponse = await postChatGPTCompletion(askResponse)
			} else {
				respondResponse = await postChatCompletion(selectedDialog.respond, askResponse)
			}

			setResponses(prevResponses => [
				...prevResponses,
				{
					title: prompt.title,
					model: selectedDialog.respond,
					response: respondResponse,
				},
			])
		}

		setLoading(false)
	}

	useEffect(() => {
		if (!selectedDialog || !selectedDialog.id) {
			navigate('/dialog-ai')
		} else {
			getPrompts()
		}
	}, [])

	return (
		<div className="w-full h-full flex flex-col">
			<TextStyleH2>{selectedDialog?.name}</TextStyleH2>
			<TextStyleCaption>{selectedDialog?.description}</TextStyleCaption>
			<div className="flex items-center justify-center w-full p-2">
				<Button
					value={loading ? 'Processing...' : 'Init Dialog'}
					onClick={handleInitDialog}
					disabled={loading || !user || !user.address}
					size="small"
				/>
			</div>
			<div className="flex flex-col w-full h-full gap-2 mt-4">
				{inFetch ? (
					<Loading />
				) : prompts && prompts.length > 0 ? (
					prompts.map((prompt: DialogInterface, key: number) => (
						<div key={key} className="flex flex-col bg-white">
							<Accordion title={prompt.title} description={`${selectedDialog?.ask} x ${selectedDialog?.respond}`}>
								<TextStyleBodyDefault>{prompt.prompt}</TextStyleBodyDefault>
							</Accordion>
						</div>
					))
				) : (
					<TextStyleCaption>No prompts registered for this dialog</TextStyleCaption>
				)}
			</div>

			<div className="flex flex-col mt-4 gap-4">
				{responses.map((res, index) => (
					<div
						key={index}
						className={
							res.model === selectedDialog?.ask
								? 'w-full items-start flex flex-col'
								: 'w-full items-end justify-end flex flex-col rtl-text'
						}
					>
						<div className="max-w-[100%]">
							<p className="text-bold bold font-bold">{res.title}</p>
							<span className="font-bold text-bold">{res.model}</span>
							<TextStyleBodyDefault>{res.response}</TextStyleBodyDefault>
						</div>
					</div>
				))}
				{loading ? <Loading /> : ''}
			</div>
		</div>
	)
}
