import React, { useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'

import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'

import ViewFile from 'src/pages/myDocuments/components/viewFile'
import { getAllUserFiles } from 'src/service/storage/userImages'

interface Props {
	userAddress: string
}

interface File {
	id: number
	dimensions: string | null
	image_path: string | null
	model: string | null
	steps: string | null
	url: string | null
	user_id: number
	created_at: string
}

const UserFiles: React.FC<Props> = ({ userAddress }) => {
	const [files, setFiles] = React.useState([] as File[])
	const [inFetch, setInFetch] = React.useState<boolean>(false)
	const [selectedImage, setSelectedImage] = useState({} as any)
	const [modal, setModal] = useState(false)

	const requestUserFiles = async () => {
		try {
			setInFetch(true)
			let req = await getAllUserFiles(userAddress)

			setFiles(req)
			setInFetch(false)
		} catch (err) {
			setInFetch(false)
			console.log(err)
		}
	}

	const closeModal = () => {
		setModal(false)
	}

	const handleShowModal = (image: any) => {
		setModal(true)
		setSelectedImage(image)
	}

	React.useEffect(() => {
		requestUserFiles()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAddress])

	return (
		<CardInner className="documents">
			<CardContainer>
				{modal && <ViewFile isProfile selectedImage={selectedImage} onClose={closeModal}></ViewFile>}
				{inFetch ? (
					<Loading />
				) : (
					<div className="flex items-center gap-2 wrap flex-wrap">
						{files && files.length ? (
							files.map((val: any, key: number) => (
								<div
									key={key}
									className="relative grow basis-1/3 min-w-[calc(33.333%-1rem)] flex items-center justify-center group"
								>
									<img alt="image" src={val.url} className="image-card grow basis-1/3 w-full" />
									<div className="absolute post-image opacity-0 group-hover:opacity-100 transition-opacity duration-200">
										<Button
											value="View"
											onClick={() => {
												handleShowModal(val)
											}}
										/>
									</div>
								</div>
							))
						) : (
							<TextStyleBodyDefaultMedium>No data to display</TextStyleBodyDefaultMedium>
						)}
					</div>
				)}
			</CardContainer>
		</CardInner>
	)
}

export default UserFiles
