import React, { useState } from 'react'
import {
	TextStyleBodyDefault,
	TextStyleBodyLarge,
	TextStyleButtonDefault,
	TextStyleCaption,
	TextStyleH1,
} from 'src/components/_Dsc/typography'
import { Profile, UserProfile } from 'src/interface'
import { Community } from 'src/pages/communities'
import TimeUntilEnd from './Counter'
import Button from 'src/components/_Dsc/Button'
import CastCommunityVote from './CastVote'
import { communityService } from 'src/service/community/communityService'
import KineContext from 'src/context/KineContext'
import { Loading } from 'src/components/loading'
import VotesHighlight from './VotesHighlight'
import { decodeHex } from 'src/constants/functions'
import Modal from 'src/components/_Dsc/Modal'
import UserBalance from 'src/components/balances'
import { planksToTokens } from 'src/service/substrate/formated'
import { useNavigate } from 'react-router-dom'

interface Props {
	community: Community
	block: string
	userIsMember: boolean
	userPendingRequest: boolean
}

export interface VotesCommunity {
	communityName: string
	socialUserName: string
	user: string
	voteResult: string
}

const ProposalDetails: React.FC<Props> = ({ community, block, userIsMember, userPendingRequest }) => {
	const { user, onQueue, handleGlobalMessage, setOnQueue } = React.useContext(KineContext)
	const navigate = useNavigate()
	const [open, setOpen] = useState(false)
	const [inFetch, setInFetch] = useState(false)
	const [userHaveVote, setUserHaveVote] = useState(false)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [inFetchUsers, setInFetchUsers] = useState<boolean>(false)
	const [votes, setVotes] = useState<VotesCommunity[]>([] as VotesCommunity[])
	const handleChange = () => {
		setOpen(true)
	}

	const closeModal = () => {
		setShowModal(false)
	}

	const handleVerifyUserVote = async () => {
		setInFetch(true)
		let req = await communityService.verifyUserVoteCommunity(user, community.id)
		if (req && req.length) {
			setVotes(req)
			req.forEach((vote: { communityName: string; user: string; voteResult: string; socialUserName: string }) => {
				if (vote.user === user.address) {
					setUserHaveVote(true)
					setInFetch(false)
				} else {
					setInFetch(false)
				}
			})
		}
		setInFetch(false)
	}

	const applyUserForCommunity = async (community: Community) => {
		setInFetch(true)
		await communityService.applyForCommunity(user, community, handleGlobalMessage)
		window.location.reload()
		setOnQueue(true)
		closeModal()
		setOnQueue(false)
	}

	const applyUserForCommunityMonthly = async (community: Community) => {
		setOnQueue(true)
		await communityService.applyForCommunityMonthly(user, community)
		closeModal()
		setOnQueue(false)
	}

	React.useEffect(() => {
		if (community && community.id && user && user.address) {
			handleVerifyUserVote()
		}
	}, [user, KineContext, open])

	return (
		<div>
			{showModal ? (
				<Modal
					open={showModal}
					title={'Apply for Membership'}
					onConfirm={() => {
						Object.keys(community.communityType)[0] === 'Private'
							? applyUserForCommunityMonthly(community)
							: applyUserForCommunity(community)
					}}
					confirmDisabled={inFetch || onQueue}
					confirmLabel="Apply"
					onClose={closeModal}
					onCancel={closeModal}
					cancelLabel="Cancel"
				>
					<div className="relative w-full h-full">
						{inFetchUsers ? (
							<Loading />
						) : (
							<div>
								{userIsMember ? (
									<TextStyleBodyDefault>You are already in this community</TextStyleBodyDefault>
								) : userPendingRequest ? (
									<TextStyleBodyDefault>
										You have already made the request to join the community, wait for approval
									</TextStyleBodyDefault>
								) : (
									<div className="w-full flex flex-col gap-2">
										{user && user.address ? (
											<div className="flex flex-col">
												<TextStyleBodyDefault>{user.name}</TextStyleBodyDefault>
												<TextStyleBodyDefault className="flex items-center gap-2">
													Your wallet: <UserBalance />
												</TextStyleBodyDefault>
											</div>
										) : (
											''
										)}
										<div className="community-body-img"></div>
										<div className="z-[100] h-[200px] justify-center  flex flex-col gap2">
											<TextStyleH1>{community.name}</TextStyleH1>
											<TextStyleBodyDefault>Type: {Object.keys(community.communityType)[0]} DAO</TextStyleBodyDefault>
											{Object.keys(community.communityType)[0] === 'Private' ? (
												planksToTokens(Object.values(community.communityType)[0].monthlyFee) > 0 ? (
													<TextStyleCaption>
														This community charges a membership fee of
														<b>{planksToTokens(Object.values(community.communityType)[0].monthlyFee)} - KinexPlay</b>
														<span className="ml-1">
															The fee will be deducted from your wallet once you approve the transaction.
														</span>
													</TextStyleCaption>
												) : (
													<TextStyleCaption>Membership of this community is free of charge.</TextStyleCaption>
												)
											) : (
												''
											)}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</Modal>
			) : (
				''
			)}
			{open ? <CastCommunityVote open={open} setOpen={setOpen} community={community} /> : ''}
			<div className="flex  gap-2">
				<div className="bg-white p-4 rounded-[4px] w-full">
					<div className="flex flex-col gap-1">
						{/**
							 * <div className={`flex gap-1 items-baseline w-fit ${community.status}`}>
						
							<TextStyleBodyDefault> {community.status || ''}</TextStyleBodyDefault>
						</div>
							 */}
						<div className={`flex gap-1 items-baseline w-full justify-between overflow-hidden truncate`}>
							<div className="flex items-baseline gap-1 truncate">
								<TextStyleBodyDefault>Owner:</TextStyleBodyDefault>
								<TextStyleBodyDefault className="truncate">
									{community ? community.socialUserName : ''}
								</TextStyleBodyDefault>
							</div>
							{!userIsMember ? (
								<Button
									value="Become a member"
									size="small"
									icon="group"
									onClick={() => {
										setShowModal(true)
									}}
								/>
							) : (
								<Button
									value="View community"
									size="small"
									icon="arrow_right_alt"
									onClick={() => {
										navigate(`/community/${community.id}`)
									}}
								/>
							)}
							{/**
								 * <div className="flex items-baseline gap-1 truncate">
								<TextStyleBodyDefault>Owner:</TextStyleBodyDefault>
								<TextStyleBodyDefault className="truncate"> {community.createdBy || ''}</TextStyleBodyDefault>
							</div>
								 */}
						</div>
					</div>

					<div className="w-full text-center items-center justify-center gap-1 mt-4">
						<div className="flex flex-col gap-1">
							<TextStyleH1>{decodeHex(community.name)}</TextStyleH1>
						</div>

						{/**
							 * {block && community.votingPeriodEnd && community.status !== 'Ended' ? (
							<TimeUntilEnd start={block} end={community.votingPeriodEnd} />
						) : (
							<TextStyleH1>{community.voteResult}</TextStyleH1>
						)}
							 */}
					</div>
					<div className="flex items-center flex-col justify-center gap-1">
						<img src={community.icon} alt="logo community" className="max-w-[300px]" />
						<TextStyleButtonDefault className="text-center">
							{decodeHex(community.proposalReason)}
						</TextStyleButtonDefault>
					</div>
					<div className="flex flex-col w-full">
						<div className="flex gap-1 items-baseline">
							<TextStyleBodyLarge>Type:</TextStyleBodyLarge>
							<TextStyleBodyDefault> {Object.keys(community.communityType)[0] || ''}</TextStyleBodyDefault>
						</div>
						<div className="flex gap-1 items-baseline">
							<TextStyleBodyLarge>Categorie:</TextStyleBodyLarge>
							<TextStyleBodyDefault> {community.category}</TextStyleBodyDefault>
						</div>
						<div>
							<TextStyleBodyDefault>{decodeHex(community.description) || ''}</TextStyleBodyDefault>
						</div>
						{/**
							 * <div className="flex gap-1 items-baseline">
							<TextStyleBodyLarge>Approve:</TextStyleBodyLarge>
							<TextStyleBodyDefault> {community.votesApprove || 0}</TextStyleBodyDefault>
						</div>
						<div className="flex gap-1 items-baseline">
							<TextStyleBodyLarge>Reject:</TextStyleBodyLarge>
							<TextStyleBodyDefault> {community.votesReject || 0}</TextStyleBodyDefault>
						</div>
							 */}
					</div>
					{/**
						 * <div className="flex w-full justify-center items-center">
						{inFetch ? (
							<Loading />
						) : (
							<div>
								{userHaveVote ? (
									<TextStyleBodyDefault>You've already made your vote</TextStyleBodyDefault>
								) : user && user.address ? (
									<Button value="Cast vote" onClick={handleChange} />
								) : (
									<TextStyleBodyDefault>You need to be logged in to vote</TextStyleBodyDefault>
								)}
							</div>
						)}
					</div>
						 */}
				</div>
				{/**
					 * <div className="w-[25%]">
					<VotesHighlight votes={votes} />
				</div>
					 */}
			</div>
		</div>
	)
}

export default ProposalDetails
