import React from 'react'
import HorizontalDivider from 'src/components/_Dsc/divider'
import './style.scss'

import { PostProps } from './interface'
import KineraPost from './components/post'

const Feed: React.FC<PostProps> = ({ posts, profile, setData, viewProfile }) => {
	const [isWriteComment, setIsWriteComment] = React.useState('')
	const [content, setContent] = React.useState('')

	return (
		<>
			{posts && posts.length > 0 && (
				<div className="tweets w-full feed flex flex-col gap-[4px]">
					{posts.map((tweet, i) => (
						<KineraPost
							viewProfile={viewProfile}
							key={i}
							avatarSrc={tweet.avatarSrc}
							username={tweet.username}
							time={tweet.time}
							message={tweet.message}
							id={tweet.id}
							setIsWriteComment={setIsWriteComment}
							isWriteComment={isWriteComment}
							content={content}
							setContent={setContent}
							isProfile={true}
							address={tweet.address}
							is_festival={tweet.is_festival}
							is_rankings={tweet.is_rankings}
							event_id={tweet.event_id}
							user_id={tweet.user_id}
							setData={setData}
							tweet={tweet}
						/>
					))}
				</div>
			)}
		</>
	)
}

export default Feed
