import { Snackbar } from '@mui/material'
import React, { ReactElement } from 'react'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'

interface Props {
	children: ReactElement | undefined
	type: string
}

const DscSnackBar: React.FC<Props> = ({ children, type }) => {
	const { setGlobalSnack, color } = React.useContext(KineContext)
	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={true}
			autoHideDuration={10000}
			onClose={() => setGlobalSnack(false)}
		>
			<div className={`bg-[${color.action}] p-2 flex items-center gap-2 rounded-[4px]`}>
				{type === 'loading' && <Loading />}
				{type === 'done' && <span className="material-symbols-outlined">done</span>}
				{type === 'error' && <span className="material-symbols-outlined">close</span>}
				{children}
			</div>
		</Snackbar>
	)
}

export default DscSnackBar
