import React, { useEffect, useState } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefault, TextStyleCaption, TextStyleH1, TextStyleH3 } from 'src/components/_Dsc/typography'
import { Community } from '../../communities'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { Loading } from 'src/components/loading'
import DscIcons from 'src/components/_Dsc/icons'

import { useNavigate } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import { decodeHex } from 'src/constants/functions'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Communities', link: '/communities' },
	{ label: 'My Communities', link: '' },
]

const UserCommunitiesShortCut: React.FC = () => {
	const { user } = React.useContext(KineContext)
	const navigate = useNavigate()
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [communitites, setCommunities] = useState<Community[]>([])

	const getUserCommunities = async () => {
		setInFetch(true)
		let req = await communityService.getCommunitiesByUser(user.address)
		const sortedCommunities = req.sort((a: Community, b: Community) => Number(b.id) - Number(a.id))

		setCommunities(sortedCommunities)
		setInFetch(false)
	}

	const gotToCommunity = (community: Community) => {
		if (community) {
			navigate(`/community/${community.id}`)
		}
	}

	const getUserCommunitiesRefresh = async () => {
		let req = await communityService.getCommunitiesByUser(user.address)
		const sortedCommunities = req.sort((a: Community, b: Community) => Number(b.id) - Number(a.id))

		setCommunities(sortedCommunities)
	}

	useEffect(() => {
		if (user && user.address) {
			getUserCommunities()
			const interval = setInterval(() => {
				getUserCommunitiesRefresh()
			}, 2500) // 5000 ms = 5 segundos

			return () => clearInterval(interval)
		}
	}, [user])

	return (
        <div className="flex flex-col gap-2 w-full rounded-[4px] mt-2">
        {inFetch ? (
            <div className="p-4">
                <Loading />
            </div>
        ) : (
            <div className="flex flex-col gap-2 max-h-[200px] custom-scroll pr-1 overflow-y-auto overflow-x-hidden">
                {communitites && communitites.length ? (
                    communitites.map((val: Community, index: number) => (
                        <div
                            onClick={() => {
                                gotToCommunity(val)
                            }}
                            key={index}
                            className="bg-white p-1 flex flex-col w-full items-end justify-end gap-2 rounded-[4px] hover:opacity-70 cursor-pointer"
                        >
                            <div className="flex w-full items-center gap-2">
                                    <div className='w-fit'>
                                    <img
                                        src={val.icon}
                                        width="30px"
                                        height="30px"
                                        className="rounded-full w-[30px] h-[30px] rounded-[100%]"
                                        alt="logo community"
                                    />
                                        </div>
                                    <div className="max-w-[80%] flex flex-col gap-1 truncate overflow-hidden truncate-text">
                                        <TextStyleH3 className='truncate overflow-hidden truncate-text'>
                                            {val && val.name ? decodeHex(val.name) : 'Community'}{' '}
                                       
                                            </TextStyleH3>
                                        <TextStyleBodyDefault className='truncate overflow-hidden truncate-text'>{val && val.description ? decodeHex(val.description) : 'Community'}{' '}</TextStyleBodyDefault>
                                    </div>
                                </div>
                        </div>
                    ))
                ) : (
                    <p>
No communities to display</p>
                )}
            </div>
        )}
    </div>
	)
}

export default UserCommunitiesShortCut
