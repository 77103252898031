import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Alert from '@mui/material/Alert'
import { FestivalService } from '../../../../service/festival/festivalService'
import KineContext from '../../../../context/KineContext'
import { Loading } from '../../../../components/loading'
import DscModal from 'src/components/_Dsc/Modal'

interface Props {
	open: boolean
	close: any
	id: string
	movies: any
	description: string
	categorie: any
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 900,
	height: 'auto',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	overflowY: 'auto',
}

const ActiveFestival: React.FC<Props> = ({ open, categorie, close, id, movies, description }) => {
	const { user, color, apiState, setGlobalToast, setGlobalSnack } = React.useContext(KineContext)
	const [endTime, setEndTime] = useState<String>('m')
	const [errorMessage, setErrorMessage] = useState<String>('')
	const [isError, setIsError] = useState<boolean>(false)
	const [totalNumber, setTotalNumber] = useState<number>(0)
	const [sendAction, setSendAction] = useState<boolean>(false)

	const changeEndTime = (event: any) => {
		setEndTime(event)
	}

	const changeEndBlock = (e: any) => {
		const inputAsString = String(e).trim()
		if (inputAsString.charAt(0) === '0' && inputAsString.length > 1) {
			setErrorMessage('The value must be above 30')
			setTotalNumber(0)
			setIsError(true)
			return false
		}
		if (Number(e) < 10) {
			setErrorMessage('The value must be above 30')
			setTotalNumber(e)
			setIsError(true)
			return false
		}

		if (Number(e) >= 10) {
			setErrorMessage('')
			setIsError(false)
			setTotalNumber(e)
		}
	}

	const sendActiveFestival = async () => {
		setSendAction(true)
		await FestivalService.activeFestival(
			id,
			endTime,
			totalNumber,
			user,
			apiState,
			description,
			setGlobalToast,
			categorie,
			setGlobalSnack,
			close,
		)
		close()
		window.location.reload()
	}

	return (
		<div className="w-full h-full flex flex-col items-center justify-center">
			<DscModal
				title="Publish Constellation"
				open={open}
				onCancel={close}
				onClose={close}
				onConfirm={sendActiveFestival}
				confirmLabel="Active"
				cancelLabel="Cancel"
				confirmDisabled={!(endTime !== 'm' && totalNumber >= 1) && !(endTime === 'm' && totalNumber >= 30)}
			>
				<div>
					{movies && movies.length > 1 ? (
						<div className="mt-2 w-auto">
							<div className="flex flex-col items-norml justify-center  gap-2">
								<div className="p-2 rounded-[4px] border">
									<label className="block text-black font-bold mb-2" htmlFor="videoFile">
										Duration
									</label>
									<div className="flex items-normal">
										<input
											type="number"
											placeholder="ex:3"
											className=" p-3 rounded-lg mr-2 border text-black"
											onChange={event => {
												changeEndBlock(event.target.value)
											}}
										/>
										<select
											className="p-3 rounded border"
											onChange={event => {
												changeEndTime(event.target.value)
											}}
										>
											<option value={'m'}>Minutes</option>
											<option value={'h'}>Hour(s)</option>
											<option value={'d'}>Day(s)</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="flex items-center flex-col gap-2">
							<p className={`mt-20 text-center font-bold text-black`}>
								You need to add at least 2 videos to the festival to activate it
							</p>
							<button
								onClick={() => {
									close()
								}}
								className={`w-full  p-[10px] text-[${color.text}] bg-[${color.action}]`}
							>
								Close
							</button>
						</div>
					)}
				</div>
			</DscModal>
		</div>
	)
}

export default ActiveFestival
