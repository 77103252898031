export const formatDate = (dateString: string) => {
	const date = new Date(dateString)
	const day = String(date.getDate()).padStart(2, '0')
	const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
	const year = date.getFullYear()
	const time = date.toTimeString().split(' ')[0] // Get the time portion only (HH:MM:SS)

	return `${day}/${month}/${year} - ${time}`
}

const isHexStrict = (value: string): boolean => {
	// Verifica se a string começa com "0x" e contém apenas caracteres hexadecimais válidos
	return /^0x[0-9a-fA-F]+$/.test(value)
}

export const decodeHex = (hex: string): string => {
	if (isHexStrict(hex)) {
		// Usa a verificação mais restrita
		const hexWithoutPrefix = hex.slice(2) // Remove "0x"

		let decodedString = ''
		for (let i = 0; i < hexWithoutPrefix.length; i += 2) {
			const hexPair = hexWithoutPrefix.substr(i, 2)
			const decimalValue = parseInt(hexPair, 16)
			decodedString += String.fromCharCode(decimalValue)
		}

	

		try {
			// Decodifica apenas se a string for válida
			const safelyDecoded = decodeURIComponent(escape(decodedString))
			///console.log('decodedString (after decodeURIComponent):', safelyDecoded);
			return safelyDecoded
		} catch (error) {
			//	console.error('Failed to decodeURIComponent:', error, 'String:', decodedString);
			return decodedString // Retorna a string sem tentativa de decodificação adicional
		}
	} else {
		return hex // Retorna o valor original se não for hexadecimal
	}
}
