import React, { useState } from 'react'

import { TextField } from '@mui/material'


import './style.scss'
import { FestivalService } from '../../../service/festival/festivalService'
import KineContext from '../../../context/KineContext'
import { RankingService } from '../../../service/ranking'
import { Loading } from '../../loading'
import { globals } from 'src/_helpers'
import { getChannelData } from 'src/service/youtube'
import { Link } from 'react-router-dom'
import DscInput from 'src/components/_Dsc/input'

import Swal from 'sweetalert2'
import Button from 'src/components/_Dsc/Button'

const InputStyle = {
	minWidth: '300px',
}

interface ExternalUpload {
	setExternalFetch: any
	fetchExternal: boolean
	isRanking: boolean
	insertMovieInFestival: any
	close: any
	festivalData?: any
	byProfile: boolean
}

const ExternalUploadMovie: React.FC<ExternalUpload> = ({
	setExternalFetch,
	fetchExternal,
	isRanking,
	close,
	insertMovieInFestival,
	byProfile,
	festivalData,
}) => {
	const { user, apiState } = React.useContext(KineContext)

	const [movieUrl, setMovieUrl] = useState('')
	const [showBtn, setShowBtn] = useState(true)

	const [alertMessage, setAlert] = useState('')
	const [videoSrc, setVideoSrc] = useState<any>([])
	const [unstakeValue, setUnstakeValue] = useState(0)
	const [enableUnstake, setEnableUnstake] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [lockValue, setLockValue] = useState<any>('')
	const handleMovieUrlChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.value) {
			setVideoSrc([])
			setMovieUrl('')
			return false
		}
		setVideoSrc([])
		setMovieUrl(event.target.value)
	}

	const handleSubmit = async () => {
		const festivalId = window.location.pathname.split('/')[2]
		const rankingId = window.location.pathname.split('/')[3]
		//event.preventDefault()

		setShowBtn(false)
		if (!movieUrl.includes('youtube')) {
			alert('Only YouTube URLs are allowed')
			setShowBtn(true)
			return
		}
		if (movieUrl && byProfile && movieUrl.includes('watch')) {
			setAlert('This Ranking list only allows profiles and not videos')
		}

		if (isRanking) {
			if (!movieUrl.includes('youtube')) {
				alert('Please verify Youtube movie url')
				return false
			} else {
				close()
				await RankingService.insertMovie(
					rankingId,
					'Youtube',
					movieUrl,
					user,
					setUpload,
					apiState,
					lockValue,
					insertMovieInFestival,
				)
			}
		} else if (!isRanking) {
			//console.log('asa', movieUrl, isRanking)
			movieUrl.includes('youtube')
				? await FestivalService.insertExternalMovie(festivalId, 'Youtube', movieUrl, user, setUpload, apiState)
				: alert('Please verify Youtube movie url')
			close()
		}
	}

	const searchMovie = async () => {
		if (!isRanking) {
			setEnableUnstake(true)
		}
		if (movieUrl && byProfile && movieUrl.includes('watch')) {
			setAlert('Your URL must be YouTube Profile')
			setVideoSrc([])
			return false
		}
		if (movieUrl && !byProfile && !movieUrl.includes('watch')) {
			setAlert('Check if your URL contains a YouTube video')
			setVideoSrc([])
			return false
		} else {
			setAlert('')
			await getPreviewData(movieUrl)
		}
	}

	const getPreviewData = async (param: string) => {
		if (param.includes('watch') && !byProfile) {
			if (festivalData) {
				//	console.log('asa', festivalData)
				let verify = festivalData.externalMovies
				if (verify.includes(param)) {
					Swal.fire({
						icon: 'info',
						title: 'Oops...',
						text: 'This video is already present in the constellation',
						footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
						willClose: () => {
							//window.location.reload();
						},
					})
					setVideoSrc([])
					return false
				}
				return await globals.getVideoSrc(param, setVideoSrc, 'youtube')
			}
			return await globals.getVideoSrc(param, setVideoSrc, 'youtube')
		} else {
			let req = await getChannelData(param)
			setVideoSrc(req)
			return true
		}
	}

	const onChangeUnstakeValue = (e: any) => {
		const inputAsString = String(e).trim()
		if (inputAsString.charAt(0) === '0' && inputAsString.length > 1) {
			//console.log('Entrada inválida (começa com zero):', inputAsString)
			setErrorMessage('Invalid input: Leading zeros are not allowed.')
			setUnstakeValue(0)
			if (isRanking) {
				setEnableUnstake(false)
			}
			setLockValue(0)
			return false
		}
		if (Number(e) < 10) {
			setErrorMessage(`Minimum Stake is 10 KinexPlay Tokens`)
			setUnstakeValue(e)

			if (isRanking) {
				setEnableUnstake(false)
			}
			setLockValue(e)
			return false
		}

		if (Number(e) >= 10) {
			setLockValue(e)

			if (isRanking) {
				setEnableUnstake(true)
			}

			setErrorMessage('')
			setUnstakeValue(e)
		}
	}

	const setUpload = () => {
		setExternalFetch(!fetchExternal)
	}

	return (
		<form onSubmit={handleSubmit} className="form">
			<div className="flex items-center flex-col" style={{ width: 'inherit' }}>
				<div className="flex items-center gap-4 w-full justify-center">
					<TextField
						label={byProfile ? ' Personal Channel URL' : 'Movie URL'}
						value={movieUrl}
						onChange={handleMovieUrlChange}
						variant="filled"
						style={InputStyle}
					/>

					{isRanking && (
						<div className="p-2">
							<DscInput
								type="number"
								value={unstakeValue}
								label="Value to initial lock"
								placeholder={`Min: 10`}
								onChange={onChangeUnstakeValue}
							/>
							<p className="mt-2 text-red-600">{errorMessage}</p>
						</div>
					)}
				</div>
				{videoSrc && videoSrc.html && !byProfile && (
					<div
						className="w-full main-video flex items-center justify-center mt-10 h-[450px] overflow-hidden rounded-[4px]"
						dangerouslySetInnerHTML={{ __html: videoSrc.html }}
					/>
				)}
				{alertMessage && <p className="mt-2 mb-2">{alertMessage}</p>}
				{videoSrc && byProfile && videoSrc.img && (
					<div className="flex items-center w-[90%]">
						<div className="current-movie flex items-center p-2 mt-[-3px] w-[150px]">
							{videoSrc && (
								<Link to={movieUrl} target="_blank">
									<img
										className="hover:opacity-70 cursor-pointer rounded-[1000%] border-2"
										src={videoSrc?.img}
										alt="icon"
										onClick={() => {
											//showModalMovie(data)
										}}
									/>
								</Link>
							)}
						</div>
						<div className="flex  flex-col w-[80%]">
							<p className="truncate  ml-2">
								{videoSrc?.name} {videoSrc?.sub}
							</p>
							<p className="truncate ml-2 text-small">{videoSrc?.desc || ''}</p>
						</div>
					</div>
				)}
				{showBtn ? (
					<div className="flex items-center gap-2 w-full justify-end padding-btn">
						<Button type="subtle" value="Cancel" onClick={close}></Button>

						{(videoSrc && videoSrc.html) || (videoSrc && videoSrc.img) ? (
							<Button
								onClick={() => {
									handleSubmit()
								}}
								size="small"
								disabled={(!movieUrl.length && movieUrl.includes('youtube')) || !enableUnstake}
								value="Submit"></Button>
						) : (
							<Button
								onClick={() => {
									searchMovie()
								}}
								size="small"
								value="Search"
								disabled={!movieUrl.length && movieUrl.includes('youtube')}></Button>
						)}
					</div>
				) : (
					<Loading />
				)}
			</div>
		</form>
	)
}

export default ExternalUploadMovie
