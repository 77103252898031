import React from 'react'
import './styles.scss'

interface Props {
	children: React.ReactNode
	isSelected: boolean
}

const DscBadge: React.FC<Props> = ({ isSelected, children }) => {
	return (
		<div
			className="dsc-badge"
			style={{
				background: isSelected ? 'rgb(22, 24, 35)' : '#F0F0F0',
				color: isSelected ? '#fff' : '#000',
			}}
		>
			{children}
		</div>
	)
}

export default DscBadge
