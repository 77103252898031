import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Feed from '../social/components/feed/Feed'
import KineContext from '../../context/KineContext'
import {
	getSocialProfileByAddres,
	followAsync,
	unfollowAsync,
	getPostsById,
	getPostsAIById,
} from '../../service/social/socialService'
import './style.scss'
import { Loading } from '../../components/loading'
import MyFestivals from './Festivals'
import ModalFollowersAndFollowing from './modal'
import Tabs from 'src/components/_Dsc/Tabs'
import Button from 'src/components/_Dsc/Button'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleBodyDefaultMedium,
	TextStyleCaption,
	TextStyleH3,
} from 'src/components/_Dsc/typography'
import UserFiles from './Documents'
import { MessageModal } from '../social/components/feed/components/post/components/MessageModal'
import { Tooltip } from '@mui/material'
import { SocialDigitalTwin } from './DigitalTwin'
import TweetBox from '../social/components/createTweet/TweetBox'
import IconButton from 'src/components/_Dsc/IconButton'

interface Props {
	address?: string
	isEdit?: any
}

let options = [
	{ label: 'Posts', value: 'posts' },
	{ label: 'Constellations', value: 'festivals' },
	{ label: 'Files', value: 'files' },
]
const SocialView: React.FC<Props> = () => {
	const [fetch, setFetch] = useState<Boolean>(false)
	const [currentSelectedTab, setCurrentSelectedTab] = useState<string>('posts')
	const [posts, setPosts] = useState<any>([])
	const [isFollow, setIsFollow] = useState<boolean>(false)
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [reqData, setReqData] = useState<any>(null)
	const [currentTab, setCurrentTab] = useState('')
	const [modalMessage, setModalMessage] = React.useState(false)
	const [openInteractModal, setOpenInteractModal] = useState<boolean>(false)
	const { user, color, profile, handleSetGlobalFetch, setGlobalToast } = useContext(KineContext)

	const navigate = useNavigate()

	const handleClick = async (unfollow: boolean) => {
		if (!user) {
			return alert('Please login first')
		}
		if (unfollow) {
			let req = await unfollowAsync(reqData.user, user.address)
			if (req) {
				setIsFollow(false)
				reqData.followers -= 1
				handleSetGlobalFetch()
				setReqData(reqData)
			}
			return true
		} else {
			setIsFollow(true)
			reqData.followers += 1
			await followAsync(reqData.user, user.address)
			handleSetGlobalFetch()
			setReqData(reqData)
			return true
		}
	}

	const getUserDataReq = async () => {
		setFetch(false)
		setGlobalToast(true)
		let req = await getSocialProfileByAddres(window.location.pathname.toString().split('/')[3])

		if (req) {
			if (req.isfollow) {
				setIsFollow(true)
			}
			setReqData(req)
			if (req.is_ai_profile === 1) {
				options = options.filter(
					(val: { label: string; value: string }) => val.label !== 'Constellations' && val.label !== 'Files',
				)
			}
			await getUserPosts(req.is_ai_profile)
		}

		setFetch(true)
		setGlobalToast(false)
	}

	const getUserPosts = async (aiProfile: number) => {
		if (aiProfile === 1) {
			const getPosts: any = await getPostsAIById(window.location.pathname.toString().split('/')[3])
			if (getPosts && getPosts.data) {
				setPosts(getPosts.data)
			}
		} else {
			const getPosts: any = await getPostsById(window.location.pathname.toString().split('/')[3])
			if (getPosts && getPosts.data) {
				setPosts(getPosts.data)
			}
		}
	}

	const handleClickModal = (tab: string) => {
		setCurrentTab(tab)
		setOpenModal(!openModal)
	}

	const onChangeHandler = (value: string) => {
		setCurrentSelectedTab(value)
	}

	useEffect(() => {
		getUserDataReq()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, window.location.pathname.toString().split('/')[3]])

	return (
		<div className="social-view-component pb-[20px] overflow-auto w-full">
			{openInteractModal ? (
				<SocialDigitalTwin
					userAddress={reqData.address}
					openInteractModal={openInteractModal}
					setOpenInteractModal={setOpenInteractModal}
					digitalData={reqData}
				/>
			) : (
				''
			)}
			{openModal && (
				<ModalFollowersAndFollowing open={openModal} targetTab={currentTab} setOpen={setOpenModal} isTarget={false} />
			)}
			{modalMessage ? (
				<MessageModal setModalMessage={setModalMessage} modalMessage={modalMessage} tweet={reqData.id} />
			) : (
				''
			)}

			{fetch ? (
				reqData &&
				reqData.name && (
					<div className="w-full flex flex-col gap-2">
						<div className="bg-white shadow-md rounded-[4px]">
							<div className="p-4">
								<div className="flex flex-col gap-10">
									<div className="relative h-[350px] flex items-end">
										<div
											className="top-[80px]"
											style={{
												width: '240px',
												height: '240px',
												position: 'relative',
												zIndex: 10,
												overflow: 'hidden',
												borderRadius: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												backgroundColor: reqData.img && reqData.img !== 'null' ? 'transparent' : '#ccc',
											}}
										>
											{reqData.img && reqData.img !== 'null' ? (
												<img
													src={reqData.img}
													alt="profile"
													style={{
														width: '240px',
														height: '240px',
														objectFit: 'cover',
														borderRadius: '100%',
													}}
												/>
											) : (
												<p className="data__initial w-[240px] h-[240px] text-6xl">
													{reqData.name ? reqData.name[0].toUpperCase() : ''}
												</p>
											)}
										</div>
										<div
											className="w-full h-full flex items-center cover-image-bg"
											style={{
												backgroundImage: `url(${reqData.cover})`,
												backgroundSize: 'cover',
												position: 'absolute',
												top: 0,
											}}
										></div>
									</div>

									<div className="flex justify-end items-center gap-4 mt-[30px] z-[10]">
										{user && user.address && reqData.user === user.address && (
											<Button
												icon="edit"
												type="subtle"
												size="small"
												onClick={() => {
													navigate('/edit-profile/' + reqData.user, { state: reqData })
												}}
												value="Edit"
											/>
										)}
										<div className="flex items-center gap-[8px]">
											{reqData.is_ai_profile !== 1 && (
												<Button
													size="small"
													icon="psychology_alt"
													value={`Digital Twin`}
													onClick={() => {
														setOpenInteractModal(true)
													}}
												/>
											)}
											{reqData.is_ai_profile !== 1 && (
												<div className="bg-gray-100 rounded-[4px]">
													<Button
														size="small"
														type="subtle"
														icon="person_add"
														value={`Following ${reqData?.following || 0}`}
														onClick={() => {
															handleClickModal('following')
														}}
													/>
												</div>
											)}

											<div className="bg-gray-100 rounded-[4px]">
												<Button
													icon="groups"
													size="small"
													type="subtle"
													value={`Followers ${reqData?.followers || 0}`}
													onClick={() => {
														handleClickModal('followers')
													}}
												/>
											</div>

											{profile && profile.user && user && reqData.user !== profile.user && (
												<>
													{isFollow ? (
														<Button
															size="small"
															type="warning"
															value={'Unfollow'}
															onClick={() => handleClick(true)}
															disabled={!profile}
														/>
													) : (
														<Button
															size="small"
															type="secondary"
															value={'Follow'}
															onClick={() => handleClick(false)}
															disabled={!profile}
														/>
													)}
												</>
											)}
											{reqData && reqData.id && user && user.address && reqData.is_ai_profile !== 1 ? (
												<Tooltip title="Private message" className="bg-gray-100">
													<div>
														<Button
															value="forum"
															iconOnly={true}
															icon="forum"
															size="small"
															type="subtle"
															onClick={() => {
																setModalMessage(true)
															}}
														/>
													</div>
												</Tooltip>
											) : (
												''
											)}
										</div>
									</div>
								</div>

								<div className="flex flex-col gap-1">
									<div className="flex items-center gap-1">
										{reqData.is_ai_profile === 1 ? (
											<Tooltip
												className="cursor-pointer"
												title="This profile corresponds to a character created from an AI model"
											>
												<TextStyleCaption className="bg-blue-300 px-2  rounded-[10px] text-[10px] text-white">
													AI
												</TextStyleCaption>
											</Tooltip>
										) : (
											''
										)}
										<TextStyleBodyDefaultLarge>@{reqData.tag ? reqData.tag : reqData.name}</TextStyleBodyDefaultLarge>
									</div>
									<div className="flex items-center gap-1">
										<TextStyleH3>{reqData ? reqData.name : reqData.name}</TextStyleH3>
										{reqData.is_ai_profile === 1 && (
											<Tooltip
												className="cursor-pointer"
												title="This profile corresponds to a character created from an AI model"
											>
												<span className="material-symbols-outlined">help</span>
											</Tooltip>
										)}
									</div>
									<div>
										<TextStyleBodyDefaultMedium className="text-lg font-medium text-gray-900">
											Bio
										</TextStyleBodyDefaultMedium>
										<TextStyleBodyDefault className="text-truncate truncate">
											{reqData && reqData.description}
										</TextStyleBodyDefault>
									</div>
								</div>
							</div>
							<div className="flex items-center cursor-pointer">
								<Tabs onChange={onChangeHandler} options={options} value={currentSelectedTab} />
							</div>
						</div>
						<div className="w-full flex flex-col overflow-hidden pb-[90px]">
							{currentSelectedTab === 'posts' ? (
								<div className="w-full flex flex-col gap-2">
									{reqData.is_ai_profile === 1 && (
										<TweetBox
											isProfile={true}
											ownerProfile={reqData}
											getData={() => {
												getUserPosts(1)
											}}
											setData={setPosts}
										></TweetBox>
									)}
									{posts && posts.length > 0 && (
										<Feed viewProfile={true} posts={posts} profile={false} setData={false} />
									)}
								</div>
							) : currentSelectedTab === 'files' ? (
								<div className="w-full flex flex-col bg-white p-4">
									<UserFiles
										userAddress={
											window.location.pathname.toString().split('/')[3]
												? window.location.pathname.toString().split('/')[3]
												: ''
										}
									/>
								</div>
							) : (
								<div className="w-full flex flex-col bg-white p-4">
									<MyFestivals keyParam={''} user={window.location.pathname.toString().split('/')[3]} />
								</div>
							)}
						</div>
					</div>
				)
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<Loading />
				</div>
			)}
			<div>
				{user && (!reqData || !reqData.name) && fetch && (
					<p className={`text-[${color.text}] p-2 mt-4`}>The profile you are looking for does not exist here</p>
				)}
			</div>
		</div>
	)
}

export default SocialView
