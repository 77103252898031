import styled from 'styled-components'

import { cssTextStyleBodyDefault, cssTextStyleBodyDefaultMedium } from '../typography'

interface IDsyTabs {
	type?: string
	bold?: boolean
}

export const DsyTabs = styled.div<IDsyTabs>`
	width: 100%;
	//padding-top: var(--padding-default);
	border-bottom: 2px solid var(--border-default);
	display: flex;
	gap: 10px;
`

export const DsyTab = styled.div<IDsyTabs>`
	${cssTextStyleBodyDefault}
	color: var(--text-sub);
	padding: 6px 2px 4px;
	border-bottom: 2px solid var(--border-default);
	margin-bottom: -2px;
	z-index: 1;
	user-select: none;
	cursor: pointer;
`
export const DsyTabSelected = styled(DsyTab)<IDsyTabs>`
	${cssTextStyleBodyDefaultMedium}
	color: var(--text-primary);
	border-bottom: 2px solid var(--border-primary);
`
