import React from 'react'

import './style.scss'

import { useNavigate } from 'react-router-dom'

import KineContext from '../../context/KineContext'

import { Tab, Tabs } from '@mui/material'

import { UserProfile } from './Components/UserProfile'
import UserFestivals from './Components/myFestivals'
import UserModeration from './Components/UserModeration'

import SocialProfileView from './Components/SocialView'
import { translate } from '../../context/Translate'
const tabStyle = {
	marginTop: '10px',
	color: 'white',
}
const Profile: React.FC = () => {
	const { user, color, currentLangues } = React.useContext(KineContext)
	const [selected, setSelected] = React.useState('profile')
	const navigate = useNavigate()

	const handleTabChange = (event: React.SyntheticEvent, newUploadType: string) => {
		setSelected(newUploadType)
	}

	const getTabStyle = (isSelected: any) => ({
		color: 'white',
		fontWeight: isSelected ? 'bold' : 'normal',
		textDecoration: isSelected ? 'underline' : 'none',
	})

	React.useEffect(() => {
		if (!user || !user.address) {
			navigate('/')
		}
	}, [navigate, user])
	return (
		<div className={`flex flex-col  mt-10  pb-2 w-[90%] m-auto bg-[${color.info}] default-shadow profile-component`}>
			<div className="flex items-baseline cursor-pointer border-b ">
				<Tabs centered style={tabStyle} onChange={handleTabChange} value={selected}>
					{/**
           *  <Tab
            label='Social Profile'
            value='socialProfile'
            sx={{ color: 'white' }}
            style={getTabStyle(selected === 'socialProfile')}
          />
           */}
					<Tab
						label={translate('WALLET_STATUS', currentLangues.value)}
						value="profile"
						sx={{ color: 'white' }}
						style={getTabStyle(selected === 'profile')}
					/>
					<Tab
						label={translate('MODERATION', currentLangues.value) + ' ' + '[SOON]'}
						value="festivals"
						sx={{ color: 'white' }}
						style={getTabStyle(selected === 'festivals')}
					/>
				</Tabs>
			</div>
			<div className="flex flex-col body-container w-full">
				{selected === 'profile' && (
					<>
						<UserProfile />
					</>
				)}
				{selected === 'festivals' && <UserFestivals />}
				{selected === 'socialProfile' && <SocialProfileView />}
			</div>
		</div>
	)
}

export default Profile
