import { Divider } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import Button from "src/components/_Dsc/Button";
import Modal from "src/components/_Dsc/Modal";
import { TextStyleBodyDefault, TextStyleBodyDefaultLarge, TextStyleBodyDefaultMedium, TextStyleCaption } from "src/components/_Dsc/typography";
import { Loading } from "src/components/loading";
import KineContext from "src/context/KineContext";
import { UserProfile } from "src/interface";
import { CommunityFile } from "src/pages/community/components/Actions/components/GPT/components/Rags";
import { getUserSocialTwinFiles, getUserTwinFiles } from "src/service/storage/Ipfs";

interface Props {
    openInteractModal: boolean
    setOpenInteractModal: (param: boolean) => void
    digitalData: UserProfile
    userAddress: string
}

export const SocialDigitalTwin: React.FC<Props> = ({openInteractModal, setOpenInteractModal, digitalData, userAddress}) => {
    const { user } = React.useContext(KineContext)
    const [inputText, setInputText] = useState('')
    const [inFetchWorkSpace, setInFetchWorkSpace] = useState<boolean>(false)
    const [responses, setResponses] = useState<string[]>([])
    const [workSpaceFiles, setWorkSpaceFiles] = useState<CommunityFile[]>([])
    const [loading, setLoading] = useState(false)

    const getTwinFiles = async () => {
		setInFetchWorkSpace(true)
		let req = await getUserSocialTwinFiles(userAddress)
		if (req && req.data && req.data.length) {
			setWorkSpaceFiles(req.data)
		}
		setInFetchWorkSpace(false)
	}

    const postChatCompletion = async () => {
		try {
			let filesSelecteds: any = []
			workSpaceFiles.map((val: CommunityFile) => {
				console.log("val", val)
				return filesSelecteds.push({ type: 'file', id: val.gpt_file_id })
			})
			setInputText('')
			const response = await axios.post(
				'https://open-gpt.kinera.network/api/chat/completions',
				{
					model: digitalData.twin_base_model || 'arena-model',
					messages: [
						{
							role: 'user',
							content: inputText,
						},
					],
					files: filesSelecteds,
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)

			setResponses(prev => [...prev, `Question: ${inputText}`, `Answer: ${response.data.choices[0].message.content}`])
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setLoading(false)
		}
	}

    const handleSend = async () => {
		setLoading(true)
		postChatCompletion()
	}


    React.useEffect(() => {
        getTwinFiles()
    },[])

    return (
        <Modal
					open={openInteractModal}
					title="Digital Twin"
					size="xl"
					onCancel={() => {setOpenInteractModal(false)}}
					cancelLabel="Close">
					<div className="p-4 h-full flex bg-[#ededed]">
						<div className="p-4 bg-white gap-4 flex flex-col max-w-[40%] w-[40%] overflow-hidden">
							<div className="flex flex-col items-end justify-end">
								<TextStyleCaption>Name:</TextStyleCaption>
								<div className="flex items-center gap-1 cursor-pointer hover:bg-gray-100">
									<span className="material-symbols-outlined">person</span>
									<TextStyleBodyDefaultLarge>@{digitalData.digital_twin_name}</TextStyleBodyDefaultLarge>
								</div>
							</div>
							<Divider />
							<div className="flex flex-col  truncate">
                               <div className="flex items-center gap-2">
                               <TextStyleCaption>About:</TextStyleCaption>
                    
                               </div>
								<TextStyleBodyDefault className="truncate">{digitalData.digital_twin_description}</TextStyleBodyDefault>
							</div>
							<div className="max-h-[600px] overflow-y-auto">
								{inFetchWorkSpace ? (
									<Loading />
								) : workSpaceFiles && workSpaceFiles.length ? (
									/**
									 * wdorkSpaceFiles.map((file: CommunityFile, key: number) => (
										<div key={key} className="flex items-center gap-2">
											<input
												id="default-checkbox"
												type="checkbox"
												checked
                                                disabled
												
												value={file.gpt_file_id}
												className="text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
											/>
											<TextStyleCaption className="text-sm font-medium text-gray-900">
												{file.document_name}
											</TextStyleCaption>
										</div>
									))
									 */
									''
								) : (
									<TextStyleBodyDefaultLarge>No knowledge base for this Twin</TextStyleBodyDefaultLarge>
								)}
							</div>
						</div>
						<div className="w-full justify-end items-center flex flex-col">
							{responses && responses.length ? (
								<div className="overflow-y-auto p-2 w-full">
									{responses.map((response, index) => (
										<div key={index} className="flex flex-col w-full">
											{response.includes('Question:') ? (
												<div className="w-full flex items-end  justify-end ">
													<div className="w-fit p-2 bg-[#F9F9F9] rounded-[100px]">
														<TextStyleBodyDefaultMedium>
															{response.includes('Question:') ? response.split('Question: ')[1] : ''}
														</TextStyleBodyDefaultMedium>
													</div>
												</div>
											) : (
												''
											)}

											{response.includes('Answer:') ? (
												<div className="p-2 w-full items-start justify-start flex rounded-[10px]">
													<div className="flex w-full h-full items-start gap-2">
														<img
															src="https://alpha.kinera.network/static/media/kinera_.aa99fcdcf04e75b583bf.webp"
															className="w-[30px] rounded-full bg-white p-1"
														/>
														<TextStyleBodyDefaultMedium>
															{response.includes('Answer:') ? response.split('Answer: ')[1] : ''}
														</TextStyleBodyDefaultMedium>
													</div>
												</div>
											) : (
												''
											)}
										</div>
									))}
								</div>
							) : (
								''
							)}
							{loading ? <Loading /> : ''}
							<div className="bg-white p-4 w-[90%] rounded-[10px] mt-4">
								<div className="flex flex-col gap-1 items-end justify-end">
									<textarea
										maxLength={300}
										id="description"
										rows={4}
										value={inputText}
										onChange={e => setInputText(e.target.value)}
										className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
										placeholder="Start typing"></textarea>
									<Button
										value="Send"
										size="small"
										onClick={handleSend}
										disabled={loading || inputText.trim() === ''}
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal>
    )
}