import React from 'react'
import KineContext from '../../context/KineContext'
import { getCurretBalance } from '../../service/profile/profileService'
import { Loading } from '../loading'
import { planksToTokens } from 'src/service/substrate/formated'

interface Props {
	blackText?: boolean
}

const UserBalance: React.FC<Props> = ({ blackText }) => {
	const { user, apiState } = React.useContext(KineContext)
	const [balance, setBalance] = React.useState<any>('')

	const getCurrentToken = () => {
		let formatted = balance && balance.free ? planksToTokens(balance.free) : 0
		return (
			<div className="flex items-center gap-1">
				{formatted.toLocaleString('en-US')} <span>KinexPlay</span>
			</div>
		)
	}

	const verifyUserProfile = async (apiState: any) => {
		let req = await getCurretBalance(user, apiState)
		setBalance(req?.data)
	}

	React.useEffect(() => {
		if (apiState) {
			if (user && user.address) {
				const interval = setInterval(() => {
					verifyUserProfile(apiState)
				}, 2000)
				return () => clearInterval(interval)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])

	return <div>{balance ? <p className={` rounded-4px`}>{getCurrentToken()}</p> : <Loading />}</div>
}

export default UserBalance
