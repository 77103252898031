export const AsideMenuOptions = [
	{
		title: '',
		isLogin: true,
		childrens: [
			{
				label: 'My Constellations',
				icon: 'background_replace',
				haveOptions: false,
				link: 'my-constellations',
				selected: 'my-constellations',
			},
			{
				label: 'Voted Rankings & Constellations',
				icon: 'how_to_vote',
				haveOptions: false,
				soon: false,
				link: 'my-voteds',
				selected: 'my-voteds',
			},
			{
				label: 'Claim Tokens',
				icon: 'editor_choice',
				haveOptions: false,
				link: 'reward',
				selected: 'reward',
			},
		],
	},
	{
		title: 'Social',
		isLogin: true,
		childrens: [
			{
				label: 'Chats',
				icon: 'chat',
				haveOptions: false,
				soon: false,
				link: 'messenger',
				selected: 'messenger',
			},
			{
				label: 'Digital Twin',
				icon: 'psychology_alt',
				isSvg: true,
				haveOptions: false,
				soon: false,
				link: 'digital-twin',
				selected: 'digital-twin',
			},
			{
				label: 'Public Profile',
				icon: 'account_box',
				haveOptions: false,
				link: 'social/view',
			},
			{
				label: 'My Communities',
				icon: 'communities',
				haveOptions: false,
				soon: false,
				link: 'my-communities',
				selected: 'my-communities',
			},

			{
				label: 'Followers & Followings',
				icon: 'favorite',
				haveOptions: false,
			},
			{
				label: 'Favorites',
				icon: 'bookmark_heart',
				haveOptions: false,
				soon: false,
				link: 'favorites',
				selected: 'favorites',
			},
		],
	},
	{
		title: 'Tools',
		isLogin: true,
		childrens: [
			{
				label: 'My documents',
				icon: 'folder_open',
				haveOptions: false,
				soon: false,
				link: 'my-documents',
				selected: 'my-documents',
			},

			/**
			 * {
				icon: 'share_reviews',
				label: "Personalised GPTs",
				haveOptions: false,
				link: 'custom-tools',
			},
			 */
		],
	},
]

export const optionsKinergy: any = [
	{
		name: 'My Festivals & rankings',
		id: 'mrk',
	},
	{
		name: 'Create festival',
		id: 'create',
	},
	{
		name: 'Upload movie',
		id: 'upload',
	},
	{
		name: 'Create categorie',
		id: 'tag',
	},
]

export const optionsSocial = [
	{
		name: 'Social Profile',
		id: 'flw',
	},

	/*
   {
    name: 'Create space',
    id: 'create',
  },
  {
    name: 'Posts',
    id: 'pst',
  },
  {
    name: 'Spaces',
    id: 'spc',
  },
 */
]
