import React, { useState } from 'react'
import InputText from 'src/components/_Dsc/InputText'
import Modal from 'src/components/_Dsc/Modal'
import { TextStyleBodyLarge, TextStyleCaption } from 'src/components/_Dsc/typography'

interface FilePublish {
	prompt: string
	img_url: string
}

interface Props {
	open: boolean
	setOpen: (value: boolean) => void
	file: FilePublish
    setFileTitle: (value: string) => void
	onConfirm: () => void
}

export const PublishModal: React.FC<Props> = ({ open, setOpen, file, onConfirm, setFileTitle }) => {
	const [accept, setAccept] = useState(false)
    
	const handleClose = () => {
		setOpen(false)
	}
	return (
		<Modal
			confirmDisabled={!accept}
			cancelLabel="Cancel"
			open={open}
			onCancel={handleClose}
			onClose={handleClose}
			title="Publish file"
			confirmLabel="Publish"
			onConfirm={onConfirm}>
			<div className="flex items-end gap-1 w-full h-full">
				<div className="flex gap-2 flex-col items-start">
                <div className="overflow-hiden w-full">
						<InputText onChange={setFileTitle} maxLength={30} placeholder='Example: beautifull image' value='' label='Title' required/>
					</div>
					<div className="overflow-hiden">
						<label htmlFor="email" className="block  text-sm font-medium text-gray-900 dark:text-white">
							Prompt:
						</label>
						<TextStyleCaption>
                            {file.prompt}
                        </TextStyleCaption>
					</div>
					<div>
                    <TextStyleBodyLarge>I agree *</TextStyleBodyLarge>
					<div className="flex gap-2 items-start justify-end w-full h-full ">
						<input
							checked={accept}
							id="disabled-checkbox"
							type="checkbox"
							value=""
							onChange={e => {
								setAccept(e.target.checked)
							}}
							className="w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
						/>
						<TextStyleCaption >
							By clicking publish file you agree that you are making this file visible to everyone
						</TextStyleCaption>
					</div>
                    </div>
				</div>
				<div className="flex flex-col items-center justify-center w-full h-full">
					<img src={file.img_url} alt="publish_image" />
				</div>
			</div>
		</Modal>
	)
}
