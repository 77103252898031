import styled, { css } from 'styled-components'

export const cssTextStyle = css`
	font-family: 'Circular Std', sans-serif;
	font-weight: 500;
`

export const cssTextStyleH1 = css`
	${cssTextStyle}
	font-weight: 500;
	font-size: 24px;
	line-height: 130%;
`

export const cssTextStyleH2 = css`
	${cssTextStyle}
	font-weight: 500;
	font-size: 20px;
	line-height: 140%;
`

export const cssTextStyleH3 = css`
	font-weight: 500;
	${cssTextStyle}
`

export const cssTextStyleBodyDefault = css`
	font-size: 14px;
	line-height: 20px;
`

export const cssTextStyleBodyDefaultLarge = css`
	${cssTextStyleBodyDefault}
	font-weight: 350;
	font-family: 'Circular Std', sans-serif;
`

export const cssTextStyleBodyDefaultMedium = css`
	${cssTextStyleBodyDefault}
	font-weight: 500;
	font-family: var(--font-circular-std);
`

export const cssTextStyleBody = css`
	font-size: 16px;
	line-height: 150%;
`

export const cssTextStyleBodyLarge = css`
	${cssTextStyleBody}
	font-weight: 350;
	font-family: 'Circular Std', sans-serif;
`

export const cssTextStyleBodyMedium = css`
	${cssTextStyleBody}
	font-weight: 500;
	font-family: 'Circular Std', sans-serif;
`

export const cssTextStyleButton = css`
	font-size: 16px;
	font-weight: 500;
	font-family: 'Circular Std', sans-serif;
`

export const cssTextStyleButtonLarge = css`
	${cssTextStyleButton}
	line-height: 100%;
`

export const cssTextStyleButtonDefault = css`
	${cssTextStyleButton}
	font-size: 14px;
	line-height: 115%;
`

export const cssTextStyleButtonLinkLarge = css`
	${cssTextStyleButtonLarge}
	text-decoration: underline;
`

export const cssTextStyleButtonLinkdefault = css`
	${cssTextStyleButtonDefault}
	text-decoration: underline;
`

export const cssTextStyleLink = css`
	font-weight: 500;
	line-height: 170%;
	text-decoration: underline;
	font-family: 'Circular Std', sans-serif;
`

export const cssTextStyleLinkLarge = css`
	${cssTextStyleLink}
	font-size: 16px;
`

export const cssTextStyleLinkDefault = css`
	${cssTextStyleLink}
	font-size: 14px;
`

export const cssTextStyleCaption = css`
	font-size: 12px;
	font-weight: 350;
	line-height: 170%;
	font-family: 'Circular Std', sans-serif;
`

export const cssTextStyleCaptionHighlight = css`
	${cssTextStyleCaption}
	font-weight: bold;
`

export const TextStyle = styled.div`
	${cssTextStyle}
`

export const TextStyleH1 = styled.div`
	${cssTextStyleH1}
`

export const TextStyleH2 = styled.div`
	${cssTextStyleH2}
`

export const TextStyleH3 = styled.div`
	${cssTextStyleH3}
`

export const TextStyleBodyDefault = styled.div`
	${cssTextStyleBodyDefault}
`

export const TextStyleBodyDefaultLarge = styled.div`
	${cssTextStyleBodyDefaultLarge}
`

export const TextStyleBodyDefaultMedium = styled.div`
	${cssTextStyleBodyDefaultMedium}
`

export const TextStyleBody = styled.div`
	${cssTextStyleBody}
`

export const TextStyleBodyLarge = styled.div`
	${cssTextStyleBodyLarge}
`

export const TextStyleBodyMedium = styled.div`
	${cssTextStyleBodyMedium}
`

export const TextStyleButton = styled.div`
	${cssTextStyleButton}
`

export const TextStyleButtonLarge = styled.div`
	${cssTextStyleButtonLarge}
`

export const TextStyleButtonDefault = styled.div`
	${cssTextStyleButtonDefault}
`

export const TextStyleButtonLinkLarge = styled.div`
	${cssTextStyleButtonLinkLarge}
`

export const TextStyleButtonLinkdefault = styled.div`
	${cssTextStyleButtonLinkdefault}
`

export const TextStyleLink = styled.div`
	${cssTextStyleLink}
`

export const TextStyleLinkLarge = styled.div`
	${cssTextStyleLinkLarge}
`

export const TextStyleLinkDefault = styled.div`
	${cssTextStyleLinkDefault}
`

export const TextStyleCaption = styled.div`
	${cssTextStyleCaption}
`

export const TextStyleCaptionHighlight = styled.div`
	${cssTextStyleCaptionHighlight}
`
