import { User } from '../../interface'
import withReactContent from 'sweetalert2-react-content'
import { web3FromAddress } from '@polkadot/extension-dapp'
import Swal from 'sweetalert2'
import React from 'react'
import { planksToTokens, tokensToPlanks } from '../substrate/formated'

const getRankingLists = async (api: any) => {
	try {
		let request = await api.query.rankingListModule.rankingLists.entries()
		// console.log(request)
		let removed = ['']
		let maped: any = []
		request?.forEach((value: any) => {
			const id: any = value[0].toHuman()
			if (!removed.includes(value[1].toHuman().name)) {
				return maped.push({ value: value[1].toHuman(), id: id[0] })
			}

			// console.log(maped.push(value[1].toHuman()))
		})

		return maped
	} catch (err) {
		return err
	}
}

const getRankingListsByUser = async (user: String, apiState: any) => {
	try {
		let request = await apiState.query.rankingListModule.rankingLists.entries()
		// console.log(request)
		let maped: any = []
		request?.map((value: any) => {
			const acc: any = value[1].toHuman()

			return acc.creator === user ? maped.push(value[1].toHuman()) : ''
			// console.log(maped.push(value[1].toHuman()))
		})

		return maped
	} catch (err) {
		return err
	}
}

const getRankingListsById = async (id: string, apiState: any) => {
	try {
		let request: any = await apiState.query.rankingListModule.rankingLists(id)
		//console.log(request.toHuman());
		return request.toHuman()
	} catch (err) {
		return err
	}
}

const getMoviesInRanking = async (movies: Array<string>, apiState: any) => {
	try {
		const apiChain: any = await apiState
		const data: any = []

		await Promise.all(
			movies.map(async value => {
				const request: any = await apiChain.query.movieModule.movies(value)
				data.push({
					id: value,
					content: request.toHuman(),
				})
			}),
		)

		return data
	} catch (err) {
		return err
	}
}

const voteInMovie = async (id: string, movieId: string, amount: string, user: any, apiState: any, setFetch: any) => {
	try {
		const connect = await apiState
		const injector = await web3FromAddress(user.address)
		//formated = `${amount}amount`
		let req = await apiState.query.system.account(user.address)
		let formated = req.toHuman()
		let currentBalance: any =
			formated && formated.data && formated.data.free && formated.data.free ? planksToTokens(formated.data.free) : 0

		if (currentBalance && parseInt(currentBalance) >= parseInt(amount)) {
			const send = connect.tx.rankingListModule.voteFor(id, movieId, tokensToPlanks(parseInt(amount)), 1)
			//	await send.signAndSend(user.address, { signer: injector.signer })
			setFetch(true, 'loading')
			await new Promise((resolve, reject) => {
				send
					.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
						if (result.status.isInBlock) {
							console.log('Transaction included in block:', result.status.asInBlock.toString())
						}
						if (result.status.isFinalized) {
							console.log('Transaction finalized')

							setFetch(false, 'done', movieId, amount)
							return true
						} else if (result.status.isError) {
							console.error('Transaction error:', result.status.asError.toString())
							return false
						}
					})
					.catch((error: any) => {
						setFetch(false, 'error')
						if (error.message.includes('Cancelled')) {
							return false
						} else {
							console.error('Unexpected error:', error)
							setFetch(false, 'error')
							return false
						}
					})
			})
		} else {
			setFetch(false, 'error')
			Swal.fire({
				icon: 'info',
				title: 'Not enough tokens...',
				text: 'Check your amount of tokens to proceed with this transaction',
				footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
				willClose: () => {
					//window.location.reload()
				},
			})
			return false
		}
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				//window.location.reload()
			},
		})
		return false
	}
}

const insertMovie = async (
	festivalId: string,
	source: string,
	movieId: string,
	user: User,
	setUpload: any,
	apiState: any,
	enableUnstake: any,
	inserMovieInFestival: any,
) => {
	const MySwal = withReactContent(Swal)
	try {
		const connect = await apiState
		const injector = await web3FromAddress(user.address)
		let req = await apiState.query.system.account(user.address)
		let formated = req.toHuman()

		if (formated && formated.data && formated.data.free && planksToTokens(formated.data.free) >= enableUnstake) {
			inserMovieInFestival(true, 'loading')

			const send = connect.tx.rankingListModule.addExternalMovieToRankingList(
				festivalId,
				source,
				movieId,
				[],
				tokensToPlanks(enableUnstake),
				'None',
			)

			// Esperar a transação finalizar antes de prosseguir
			const transactionResult = await new Promise((resolve, reject) => {
				send
					.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
						if (result.status.isInBlock) {
							console.log('Transaction included in block:', result.status.asInBlock.toString())
						}
						if (result.status.isFinalized) {
							console.log('Transaction finalized')
							inserMovieInFestival(false, 'done')
							setUpload(false)
							resolve(true)
						} else if (result.status.isError) {
							inserMovieInFestival(false, 'error')
							reject(new Error('Transaction failed'))
						}
					})
					.catch((error: any) => {
						inserMovieInFestival(false, 'error')
						if (error.message.includes('Cancelled')) {
							console.log('Transaction signing was cancelled by the user.')
							reject(new Error('Transaction cancelled by user'))
						} else {
							console.error('Unexpected error:', error)
							reject(error)
						}
					})
			})

			console.log('Transaction result:', transactionResult)
		} else {
			inserMovieInFestival(false, 'error')
			Swal.fire({
				icon: 'info',
				title: 'Not enough tokens...',
				text: 'Check your amount of tokens to proceed with this transaction',
				footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
				willClose: () => {
					//window.location.reload()
				},
			})
		}
	} catch (err: any) {
		inserMovieInFestival(false, true) // Erro ocorreu
		console.error('Failed to insert movie:', err)
		MySwal.fire({
			icon: 'error',
			title: 'Oops...',
			text: err.message || 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
		})
	}
}

const unstake = async (festivalId: string, source: string, amount: number, user: User, apiState: any) => {
	try {
		const MySwal = withReactContent(Swal)
		const connect = await apiState
		const injector = await web3FromAddress(user.address)

		const send = connect.tx.rankingListModule.unvoteFrom(festivalId, source, tokensToPlanks(amount))

		await send.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			// window.location.reload()
		})

		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
		})
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				//window.location.reload()
			},
		})
	}
}

export const RankingService = {
	getRankingLists,
	getRankingListsByUser,
	getRankingListsById,
	getMoviesInRanking,
	voteInMovie,
	insertMovie,
	unstake,
}
