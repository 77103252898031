import React, { useEffect, useState } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleBodyDefaultMedium,
	TextStyleCaption,
	TextStyleH1,
	TextStyleH3,
} from 'src/components/_Dsc/typography'
import { Community } from '../communities'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { Loading } from 'src/components/loading'

import { useNavigate, useParams } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import Tabs from 'src/components/_Dsc/Tabs'
import TweetBox from '../social/components/createTweet/TweetBox'
import { getCommunityPosts, getCommunityPostsPublic, getCommunityUpdatePosts } from 'src/service/social/socialService'
import Feed from '../social/components/feed/Feed'
import { Skeleton } from '@mui/material'

import CommunityProposal from '../communityProposal'
import UserCommunityProposal from './components/Proposal'
import PendingApprovalModal from './components/PendingApprovalModal'
import { CommunitySharedDocuments } from './components/Storage/components/CommunitySharedDocuments'

import { CommunityStorage } from './components/Storage'
import { CommunityGovernance } from './components/Governance'
import Members from './components/Governance/Members'
import { ProdutionTools } from 'src/components/ProdutionsTools'
import { decodeHex } from 'src/constants/functions'
import PublicCommunityDetail from './publicTemplate'
import PrivateComunityDetail from './privateTemplate'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Community', link: '' },
]

let categories = [
	{
		label: 'Social Timeline',
		value: 'Posts',
	},
	{
		label: 'Resource Center',
		value: 'Storage',
	},
	{
		label: 'Collaborative Texts',
		value: 'Community Documents',
	},

	{
		label: `Tools`,
		value: 'Tools',
	},

	{
		label: 'Members',
		value: 'Members',
	},

	/**
	 * {
		label: 'Proposals',
		value: 'Proposals',
	},
	 */
]

const options = [
	{ label: 'General', value: 'posts' },
	{ label: 'Updates', value: 'updates' },
]

type Params = {
	id: string
}

const interactions = [1, 2, 3, 4, 5]

const VerifyTypeComunity: React.FC = () => {
	const { user, profile } = React.useContext(KineContext)

	const [inFetch, setInFetch] = useState<boolean>(true)

	const [communityDetails, setCommunityDetails] = useState<Community>({} as Community)
	const [isPublicComunity, setIsPublicComunity] = useState<boolean>(false)
	const { id } = useParams()
	const navigate = useNavigate()

	const getCommunityDetails = async () => {
		setInFetch(true)
		let req: Community = await communityService.getCommunitiesById(window.location.pathname.split('/')[2])
		if (req && req.communityType) {
			let type = Object.keys(req.communityType)[0] === 'Public' || Object.keys(req.communityType)[0] === 'PublicDao'
			if (Object.keys(req.communityType)[0] === 'Public' || Object.keys(req.communityType)[0] === 'PublicDao') {
				setIsPublicComunity(true)
			} else {
				setIsPublicComunity(false)
			}
			setCommunityDetails(req)
		}
		setInFetch(false)
	}

	useEffect(() => {
		getCommunityDetails()
	}, [user,id])

	return (
		<div className="w-full h-full">
			{inFetch ? (
				<Loading />
			) : (
				<div className="w-full h-full">
					{isPublicComunity ? (
						<PublicCommunityDetail communityDetails={communityDetails} />
					) : (
						<PrivateComunityDetail communityDetails={communityDetails} />
					)}
				</div>
			)}
		</div>
	)
}

export default VerifyTypeComunity
