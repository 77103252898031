import React, { useState, useEffect } from 'react'
import './style.scss'
import KineContext from '../../../context/KineContext'
import DscIcons from '../icons'

interface Props {
	children: React.ReactNode
	text: string
	onClose: () => void
}

const Toast: React.FC<Props> = ({ children, text, onClose }) => {
	const { color } = React.useContext(KineContext)
	const [percentage, setPercentage] = useState(100)

	useEffect(() => {
		const duration = 20000 // 8 seconds
		const interval = 10 // update every 10ms
		const decrement = 100 / (duration / interval) // calculate decrement per interval

		const timer = setInterval(() => {
			setPercentage(prev => {
				const nextPercentage = prev - decrement
				if (nextPercentage <= 0) {
					clearInterval(timer)
					if (onClose) onClose()
					return 0
				}
				return nextPercentage
			})
		}, interval)

		return () => clearInterval(timer) // cleanup on component unmount
	}, [onClose])

	return (
		<div className={`toast max-w-[400px] default-shadow bg-white`}>
			<div className="header-toast flex gap-4 w-full justify-between items-center py-4 px-8">
				<DscIcons icon={text.includes('error') ? 'error' : 'check'} color="#ba394f" />
				{children}
				<button onClick={onClose} className="close">
					<DscIcons icon="close" />
				</button>
			</div>
			<div className="bar-percentage" style={{ width: `${percentage}%`, backgroundColor: color }}></div>
		</div>
	)
}

export default Toast
