import { Tooltip } from '@mui/material'
import React from 'react'
import KineContext from 'src/context/KineContext'
import { IKineraPost } from '../../../interface'
import DscIcons from 'src/components/_Dsc/icons'

import { Loading } from 'src/components/loading'
import Dropdown from 'src/components/_Dsc/Dropdown'

const optionsYesNo = [
	{ value: 'post', label: 'View post' },
	{ value: 'profile', label: 'Profile Details' },
]

const shareOption = [
	{ value: 'link', label: 'Copy link' },
	{ value: 'x', label: 'Share on X' },
	{ value: 'telegram', label: 'Share on Telegram' },
]

interface Props {
	tweet: IKineraPost
	sendLikeToPost: (param: string) => void
	hasUserLiked: boolean
	localLikes: number
	sendDislikeToPost: (param: string) => void
	hasUserUnliked: boolean
	localDislikes: number
	handleClickLoginButton: () => void
	isWriteComment: any
	id: string
	showCommentsOnPost: (param: string, tweet: IKineraPost) => void
	setIsWriteComment: (param: boolean) => void
	setPriority: (param: boolean) => void
	handleOpenRepostModal: (param: IKineraPost) => void
	shareMessage: any
	message: string
	user_id: string
	removeFetch: boolean
	removePostById: any
	userSavePost: any
	saved: boolean
}

const PostActions: React.FC<Props> = ({
	tweet,
	sendLikeToPost,
	shareMessage,
	setPriority,
	handleOpenRepostModal,
	showCommentsOnPost,
	setIsWriteComment,
	hasUserLiked,
	localLikes,
	sendDislikeToPost,
	hasUserUnliked,
	localDislikes,
	handleClickLoginButton,
	isWriteComment,
	message,
	id,
	user_id,
	removeFetch,
	removePostById,
	userSavePost,
	saved,
}) => {
	const { user, profile } = React.useContext(KineContext)
	const verifyUserRepost = (tweet: IKineraPost) => {
		if (user && user.address) {
			if (tweet.address === user.address && tweet.user_owner) {
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	}
	return (
		<div className="flex items-center gap-4">
			{user && profile && profile.user && user.address ? (
				<div className="flex items-center gap-4">
					<Tooltip title="Likes">
						<button
							onClick={() => {
								sendLikeToPost(tweet.id)
							}}
							className="font-bold text-[14px] cursor-pointer hover:opacity-70 items-center flex gap-2"
						>
							<span className={hasUserLiked ? `material-symbols-outlined text-pink-400` : 'material-symbols-outlined'}>
								recommend
							</span>
							{localLikes}
						</button>
					</Tooltip>
					<Tooltip title="Dislikes">
						<button
							onClick={() => {
								sendDislikeToPost(tweet.id)
							}}
							className="font-bold text-[14px] cursor-pointer hover:opacity-70 items-center flex gap-2"
						>
							<span
								className={hasUserUnliked ? `material-symbols-outlined text-pink-400` : 'material-symbols-outlined'}
							>
								thumb_down
							</span>
							{localDislikes}
						</button>
					</Tooltip>
				</div>
			) : (
				<div className="flex items-center gap-4">
					<Tooltip title="Likes">
						<button
							onClick={() => {
								handleClickLoginButton()
							}}
							className="font-bold text-[14px] cursor-pointer hover:opacity-70 items-center flex gap-2"
						>
							<span className={hasUserLiked ? `material-symbols-outlined text-pink-400` : 'material-symbols-outlined'}>
								recommend
							</span>
							{localLikes}
						</button>
					</Tooltip>
					<Tooltip title="Dislikes">
						<button
							onClick={() => {
								handleClickLoginButton()
							}}
							className="font-bold text-[14px] cursor-pointer hover:opacity-70 items-center flex gap-2"
						>
							<span
								className={hasUserUnliked ? `material-symbols-outlined text-pink-400` : 'material-symbols-outlined'}
							>
								thumb_down
							</span>
							{localDislikes}
						</button>
					</Tooltip>
				</div>
			)}
			{profile && profile.user && (
				<Tooltip title="Comments">
					{isWriteComment !== id ? (
						<button
							disabled={isWriteComment === id}
							className="gap-2 flex items-center hover:font-boldrounded-lg cursor-pointer text-[#E5007A] font-bold"
							onClick={() => {
								showCommentsOnPost(id, tweet)
							}}
						>
							<DscIcons icon="chat" />
						</button>
					) : (
						<button
							className="gap-2 flex items-center hover:font-bold rounded-lg cursor-pointer text-[#E5007A] font-bold"
							onClick={() => {
								setIsWriteComment(false)
							}}
						>
							<DscIcons icon="comments_disabled" />
						</button>
					)}
				</Tooltip>
			)}
			{!tweet.is_repost && (
				<Tooltip title="Repost">
					<button
						onClick={() => {
							user && user.address ? handleOpenRepostModal(tweet) : handleClickLoginButton()
						}}
						className="font-bold text-[14px] cursor-pointer hover:opacity-70 items-center flex gap-2"
					>
						<span
							className={
								tweet.is_repost && verifyUserRepost(tweet)
									? `material-symbols-outlined text-pink-400`
									: 'material-symbols-outlined'
							}
						>
							autorenew
						</span>
					</button>
				</Tooltip>
			)}
			<Tooltip title="Save">
				<button
					onClick={() => {
						userSavePost(tweet)
					}}
					className="font-bold text-[14px] cursor-pointer hover:opacity-70 items-center flex gap-2"
				>
					<span className={saved ? `material-symbols-outlined text-pink-400` : 'material-symbols-outlined'}>
						bookmark
					</span>
				</button>
			</Tooltip>
			<div
				onClick={() => {
					setPriority(true)
				}}
			>
				<Tooltip title="Share">
					<Dropdown
						setPriority={setPriority}
						icon="share"
						options={shareOption}
						value={['']}
						onChange={(e: any) => {
							shareMessage(e[0], message)
						}}
					></Dropdown>
				</Tooltip>
			</div>

			{profile && profile.user && user_id === profile.id && !removeFetch ? (
				<Tooltip title="Delete post">
					<button
						className="flex items-center hover:bg-pink-100 hover:font-bold p-2 rounded-lg cursor-pointer"
						onClick={() => {
							removePostById(id)
						}}
					>
						<svg
							className="w-6 h-6 text-gray-800 dark:text-white"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
							/>
						</svg>
					</button>
				</Tooltip>
			) : (
				profile && profile.user && user_id === profile.id && !removeFetch && <Loading />
			)}
		</div>
	)
}

export default PostActions
