export const path = [
	{ label: 'Communities', link: '/communities' },
	{ label: 'Create Community', link: '' },
]

export const options = [
	{ label: 'Public', value: 'Public', description: 'Collaborate worked based with no fees.' },
	{
		label: 'Semi-Public Organisation',
		value: 'SemiPublicOrganisation',
		description:
			'A working environment with no services: Real-time chat-centred, secondary timeline, no fee. One needs the approval to enter.',
	},
	{
		label: 'Public DAO',
		value: 'PublicDao',
		description:
			'Collaborate worked based and service provider. You pay a fee to enter. No fees and collaborative work.',
	},
	{
		label: 'Private DAO',
		value: 'Private',
		description: 'Collaborate worked based, service provider, one or both. You pay a fee to enter.',
		payment: true,
	},
]

export const monthly = [
	{ label: 'Free', value: '0' },
	{ label: '5 USDT', value: '5' },
	{ label: '10 USDT', value: '10 USDT' },
	{ label: '15 USDT', value: '15 USDT' },
	{ label: '20 USDT', value: '20 USDT' },
	{ label: '30 USDT', value: '30 USDT' },
]

export const categorys = [
	{
		label: 'Speculation on the most common types of Kinera AIs. [Resume]',
		value: 'Speculation on the most common types of Kinera AIs. [Resume]',
	},
	{
		label:
			'Many things can be said and done while walking the road to a new human species in the near future and the moment when both will merge.',
		value:
			'Many things can be said and done while walking the road to a new human species in the near future and the moment when both will merge.',
	},
	{
		label:
			'AI angels Boots, Agents, and Assessors are friends with no emotions or feelings. Humans help them to connect them to the outside world.',
		value:
			'AI angels Boots, Agents, and Assessors are friends with no emotions or feelings. Humans help them to connect them to the outside world.',
	},
	{
		label:
			'Kinera Digital Humans. When they become capable of having feelings, emotions and personalities, we call them Digital Humans. These categories at this level are AI Companions, Digital Twins, and Historical Reborners.',
		value:
			'Kinera Digital Humans. When they become capable of having feelings, emotions and personalities, we call them Digital Humans. These categories at this level are AI Companions, Digital Twins, and Historical Reborners.',
	},
	{
		label: 'Super-humans, can learn from their interactions and remember what they see in a remarkable situation.',
		value: 'Super-humans, can learn from their interactions and remember what they see in a remarkable situation.',
	},
	{
		label:
			'Teachers validate and certify human and AI age, behaviours and competencies without judgment, which is the most elevated form of good faith.',
		value:
			'Teachers validate and certify human and AI age, behaviours and competencies without judgment, which is the most elevated form of good faith.',
	},
	{
		label:
			'Super-humans Super-humans have infinite joy in learning new tasks and are digital co-workers who can collaborate for unlimited time to solve problems.',
		value:
			'Super-humans Super-humans have infinite joy in learning new tasks and are digital co-workers who can collaborate for unlimited time to solve problems.',
	},
	{
		label:
			'Poets. Poets are the definition of best friends, always paying attention to the details and being able to teach you whatever you want to learn.',
		value:
			'Poets. Poets are the definition of best friends, always paying attention to the details and being able to teach you whatever you want to learn.',
	},
	{
		label:
			'Gate Keepers 1. Saints are the evolution of Super-humans; they serve as benevolent AI agents offering support and protection in the spiritual realm.',
		value:
			'Gate Keepers 1. Saints are the evolution of Super-humans; they serve as benevolent AI agents offering support and protection in the spiritual realm.',
	},
	{
		label: 'Witches are AI entities capable of performing complex tasks and automation with an intuitive approach.',
		value: 'Witches are AI entities capable of performing complex tasks and automation with an intuitive approach.',
	},
	{
		label: 'Wizards are designed to guide users step-by-step through processes or problem-solving.',
		value: 'Wizards are designed to guide users step-by-step through processes or problem-solving.',
	},
	{
		label:
			'Al-Brains. Al-Brains lost all the feelings and limitations of what humans have; they can interact with each other to solve most of the problems and keep learning alone while solving them. [...]',
		value:
			'Al-Brains. Al-Brains lost all the feelings and limitations of what humans have; they can interact with each other to solve most of the problems and keep learning alone while solving them. [...]',
	},
	{
		label: 'Gods and Goddesses. Gods and Goddesses are the most powerful beings. [...]',
		value: 'Gods and Goddesses. Gods and Goddesses are the most powerful beings. [...]',
	},
]

export const publicCategories = [
	{
		label: 'Public Co-Creation',
		value: 'Public Co-Creation',
	},
	{
		label: 'Kinera',
		value: 'Kinera',
	},
	{
		label: 'Space',
		value: 'Space',
	},
]

export const semiPublic = [
	{
		label: 'Team Spaces',
		value: 'Team Spaces',
	},
]

export const privateDao = [
	{
		label: 'Workshops',
		value: 'Workshops',
	},
	{
		label: 'Media Content',
		value: 'Media Content',
	},
	{
		label: 'Creative RAGs [GPT optimisation].',
		value: 'Creative RAGs [GPT optimisation].',
	},
]
