import React, { useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import { UploadFile } from './components/UploadFile'
import { communityService } from 'src/service/community/communityService'
import { CommunityFile } from '../Actions/components/GPT/components/Rags'
import KineContext from 'src/context/KineContext'

import { removeUserFile } from 'src/service/storage/Ipfs'
import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import Tabs from 'src/components/_Dsc/Tabs'
import { VideoFile } from './components/videoFile'
import { ImageFile } from './components/imageFile'

interface Props {
	community_id: string
}

let categories = [
	{
		label: `Images`,
		value: 'img',
	},
	{
		label: 'Video',
		value: 'video',
	},
	{
		label: 'Document',
		value: 'file',
	},
]

export const CommunityStorage: React.FC<Props> = ({ community_id }) => {
	const { user, setOnQueue, handleGlobalMessage, currentPermissionComunity } = React.useContext(KineContext)
	const [showModalUpload, setShowModalUpload] = useState<boolean>(false)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [files, setFiles] = useState<CommunityFile[] | null>(null)

	const [isSelected, setIsSelected] = useState('img')
		const changeTab = (val: string) => {
			setIsSelected(val)
		}

	const getAllFilesCommunity = async () => {
		setInFetch(true)
		if (community_id) {

			let req = await communityService.getCommunitiesFiles(community_id)
			if (req) {
				setFiles(req)
			}
		}
		setInFetch(false)
	}

	const removeFile = async (file: CommunityFile) => {
		setOnQueue(true)
		handleGlobalMessage('Your order is being processed and the file will soon be deleted.')
		await removeUserFile(user.address, file.file_hash)
		getAllFilesCommunity()
	}

	const redirectToDocument = (file: CommunityFile) => {
		window.open(file.url, '_blank')
	}

	useEffect(() => {
		getAllFilesCommunity()
	}, [])
	return (
		<div className="bg-white p-4 flex flex-col gap-2">
			{showModalUpload ? <UploadFile setShowModalUpload={setShowModalUpload} showModalUpload={showModalUpload} getAllFilesCommunity={getAllFilesCommunity}/> : ''}
			<div className="flex items-center gap-2 w-full justify-between">
				<TextStyleBodyDefaultMedium>
					Here, you can store texts, images, or video files required for classes or workshops, as well as documentary films available for rent.
				</TextStyleBodyDefaultMedium>
				{
					user && user.address && currentPermissionComunity
					? <Button
					icon='add'
						value="Upload file"
						size="small"
						onClick={() => {
							setShowModalUpload(true)
						}}
					/>
					: ''
				}
				
			</div>

			{inFetch ? (
				<Loading />
			) : (
				<div className="w-full h-full flex flex-col gap-2">
					<Tabs onChange={changeTab} options={categories} value={isSelected}></Tabs>
					{files && files.length ? (
						files.map((file: CommunityFile, key: number) => file.extension === isSelected && (
							
								isSelected === 'video'
								? <VideoFile file={file}  redirectToDocument={redirectToDocument} removeFile={removeFile}/>
								: isSelected === 'img'
								? <ImageFile redirectToDocument={redirectToDocument} removeFile={removeFile} file={file}/>
								: <div
								onClick={() => redirectToDocument(file)}
								key={key}
								className="flex w-full gap-1 p-4 justify-between border border-1 rounded-[4px] cursor-pointer">
								<div className="flex items-center gap-2">
									<span className="material-symbols-outlined">draft</span>
									{file.custom_name ? file.custom_name : file.file_name}
								</div>

								<div className="flex items-center gap-2">
									{file.user_address === user.address ? (
										<Button value="Remove" icon='delete' type="critical" size="small" onClick={() => removeFile(file)} />
									) : (
										''
									)}
									<Button value="View" icon='visibility' size="small" onClick={() => redirectToDocument(file)} />
								</div>
							</div>
							
						))
					) : (
						<TextStyleBodyDefaultMedium>There are no files in this community yet</TextStyleBodyDefaultMedium>
					)}
				</div>
			)}
		</div>
	)
}
