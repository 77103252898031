import React, { useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import Logo from '../../../../assets/kinera_constellations.png'
import { TextStyleBodyDefault, TextStyleBodyDefaultMedium, TextStyleCaption } from 'src/components/_Dsc/typography'

import KineContext from 'src/context/KineContext'
import { Loading } from 'src/components/loading'
import Modal from 'src/components/_Dsc/Modal'

interface Props {
	file: any
	onClose: () => void
	open: boolean
}
const serverAddress = 'https://ia.kinera.network'

const PublishDocument: React.FC<Props> = ({ file, onClose, open }) => {
	const { user } = React.useContext(KineContext)
	const [isPublicFile, setIsPublicFile] = useState(false)
	const [confirmHandle, setConfirmHandle] = useState(false)
	const [inFetch, setInFetch] = useState(false)
	const handleOverlayClick = (e: any) => {
		// Check if the click is on the overlay and not inside the modal content
		if (e.target.classList.contains('overlay')) {
			onClose()
		}
	}

	const onConfirm = () => {
		console.log('confirm')
	}

	React.useEffect(() => {
		console.log('load')
	}, [])

	return (
		<Modal
			
		
		
			open={open}
			title="Publish Document"
			onCancel={onClose}
			onClose={onClose}>
			<div className="flex flex-col w-full h-full p-4">
				<div className="w-full flex items-center justify-center">
					<img className="h-[400px]" src={Logo} alt="kinera image" />
				</div>
				<TextStyleBodyDefaultMedium>
					By confirming this action you are aware that this document will become public and anyone will be able to view
					it
				</TextStyleBodyDefaultMedium>
                <div className='flex justify-center items-center w-full gap-2'>
                    <Button value='Cancel' type='subtle'  size='small'/>
                    <Button value='Publish' type='warning' size='small'/>
                </div>
			</div>
		</Modal>
	)
}
export default PublishDocument
