import React, { useEffect } from 'react'
import { FestivalService } from '../../service/festival/festivalService'
import Card from '../../components/card/Card'

import { Loading } from '../../components/loading'
import { Skeleton } from '@mui/material'
import KineContext from '../../context/KineContext'
const defaultVal = [1, 2, 3, 4]
const Historic: React.FC = () => {
	const { apiState, color } = React.useContext(KineContext)
	const [data, setData] = React.useState<any | null>(null)
	const [tags, setTags] = React.useState<any | null>(null)
	const [selected, setSelected] = React.useState<any | null>('All')
	const [temp, setTemp] = React.useState<any | null>(null)
	const [fetch, setFetch] = React.useState<boolean | null>(false)
	const getAllData = async () => {
		try {
			//const getApoloRankingsPromise = RankingService.getRankingsByApolo();
			setFetch(true)
			const festivalsPromise = FestivalService.getFestivals(false, apiState)
			const Tags = FestivalService.getAllTags(apiState)
			const [festivals, tags] = await Promise.all([festivalsPromise, Tags])

			setTags(tags)

			setData(festivals)
			setFetch(false)
		} catch (error) {
			console.error(error)
		}
	}

	const selectedFilter = (filter: any) => {
		setSelected(filter)

		if (filter === 'Festival' || filter === 'Festivals' || filter.key === 'All') {
			setTemp(data)
			return true
		} else {
			const filtered = data.filter((val: any) => {
				if (val.categoriesAndTags) {
					return val.categoriesAndTags.some((param: any) => param.includes(filter))
				}
				return false
			})

			setTemp(filtered)
			return filtered
		}
	}

	useEffect(() => {
		if (apiState) {
			getAllData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])

	return (
		<>
			<div className="flex items-center gap-3 mb-4 tags-container">
				{tags &&
					tags.length &&
					tags?.map(
						(value: any) =>
							value.key === 'All' && (
								<div
									onClick={e => {
										selectedFilter(value)
									}}
									className={
										selected === value.key || selected === value
											? 'cursor-pointer rounded-lg header-shadow p-2 text-white tag'
											: 'cursor-pointer rounded-lg border p-2 text-white tag'
									}
								>
									<p>{value.key}</p>
								</div>
							),
					)}
				{tags &&
					tags.length &&
					tags?.map(
						(value: any) =>
							value.key !== 'All' &&
							value.value.map((val: any) => (
								<div
									onClick={e => {
										selectedFilter(val)
									}}
									className={
										selected === val.key || selected === val
											? 'cursor-pointer rounded-lg header-shadow p-2 text-white tag'
											: 'cursor-pointer rounded-lg border p-2 text-white tag'
									}
								>
									<p>{val}</p>
								</div>
							)),
					)}
			</div>

			{selected !== 'Ranking' && selected !== 'RankingList' && (
				<div>
					<div>
						<h1 className="title-categorie">Constellations:</h1>
					</div>

					<div>
						{!fetch ? (
							<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
								{temp
									? temp?.map(
											(value: any, i: number) =>
												i < 6 && (
													<div key={value.id} className={`bg-[${color.info}] default-shadow`}>
														<Card data={value} />
													</div>
												),
									  )
									: data?.map(
											(value: any, i: number) =>
												i < 6 && (
													<div key={value.id} className={`bg-[${color.info}] default-shadow`}>
														<Card data={value} />
													</div>
												),
									  )}
								{!data && <Loading />}
							</div>
						) : (
							<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
								{defaultVal?.map(
									(value: any, i: number) =>
										i < 6 && (
											<div key={i}>
												<Skeleton variant="rectangular" width={'100%'} height={200} />
											</div>
										),
								)}
							</div>
						)}
					</div>
				</div>
			)}
		</>
	)
}

export default Historic
