import styled from 'styled-components'

import { IBreadCrumbStyle } from './interfaces'

import colors from '../../../globalStyle/colors'

export const Container = styled.div`
	display: flex;
`
export const WrapperRoute = styled.div``

export const Route = styled.text<IBreadCrumbStyle>`
	font-size: 14px;
	font-weight: 450;
	line-height: 24px;
	text-align: center;
	color: ${({ lastRoute }) => (lastRoute ? colors.blueLight : colors.grayPlaceholder)};
`

export const Divider = styled.text`
	padding: 0 8px;
	font-size: 14px;
	color: ${colors.grayPlaceholder};
`
