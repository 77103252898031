import React from 'react'

interface Props {
	data: string
	sx: any
	currentBlockNumber: string
	currentSelectedMovie?: boolean
	calculateVotingPower?: any
}

const BlockCounterEnd: React.FC<Props> = ({
	data,
	sx,
	currentBlockNumber,
	currentSelectedMovie,
	calculateVotingPower,
}) => {
	const [counter, setCounter] = React.useState<number>(0)
	const [timeExpired, setTimeExpired] = React.useState<boolean>(false)
	const [isVerify, setIsVerify] = React.useState<boolean>(true)
	React.useEffect(() => {
		if (data && currentBlockNumber) {
			setIsVerify(true)
			const parsedData = parseInt(data.replace(/\D/g, ''), 10)
			let formattedCurrentBlock = parseInt(currentBlockNumber.replace(/\D/g, ''), 10)
			const diff = (Number(parsedData) - Number(formattedCurrentBlock)) * 6
			setCounter(diff)
			const interval = setInterval(() => {
				setCounter((prevCounter: number) => {
					if (prevCounter > 0) {
						return prevCounter - 1
					} else {
						setTimeExpired(true)

						clearInterval(interval)
						return 0
					}
				})
				if (isVerify) {
					setIsVerify(false)
				}
			}, 1000)

			return () => clearInterval(interval)
		}
	}, [data])

	return (
		<div>
			{!isVerify && (
				<div style={sx} className="flex items-center gap-2">
					<div className="flex items-center gap-2">
						{!currentSelectedMovie && !timeExpired && 'Time Until Vote Starts to Drop'}

						<span className="material-symbols-outlined" style={{ fontSize: '14px' }}>
							alarm
						</span>
					</div>

					{timeExpired ? (
						<div className="flex flex-col gap-2 items-center">
							We are In the final 10% of voting time. Now, the weight of each vote will decrease linearly, reaching zero
							at the last second
							<p className="mt-2">Current Weight: 100 = {calculateVotingPower().toFixed(0)}</p>
						</div>
					) : (
						formatTime(counter)
					)}
				</div>
			)}
		</div>
	)
}

// Função auxiliar para formatar o tempo em formato hh:mm:ss
const formatTime = (seconds: number): string => {
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const remainingSeconds = seconds % 60
	return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(
		2,
		'0',
	)}`
}

export default BlockCounterEnd
