import React, { useContext, useEffect, useState } from "react";
import KineContext from "src/context/KineContext";
import { Message } from "src/pages/chat/components/Chat";
import { useSocket } from "src/pages/chat/components/socket";
import { verifypermission } from "src/pages/chat/constants";
import { TextStyleBodyLarge, TextStyleCaption } from "../../typography";
import DscInput from "../../input";
import Button from "../../Button";


interface Props {
    id: number
    handle: boolean
}

export const SingleChat: React.FC<Props> = ({id: chatId, handle}) => {
	const { user } = useContext(KineContext)
	const { sendMessage, joinChat, onMessage } = useSocket()
	const [messages, setMessages] = useState<Message[]>([])
	const [newMessage, setNewMessage] = useState<string>('')
	const [error, setError] = useState<string | null>(null)
	const chatBodyRef = React.useRef<HTMLDivElement | null>(null)

	// Função para rolar para o final do chat
	const scrollToBottom = () => {
		if (chatBodyRef.current) {
			setTimeout(() => {
				chatBodyRef.current!.scrollTop = chatBodyRef.current!.scrollHeight - 50
			}, 50)
		}
	}

	// Busca mensagens do backend
	const fetchMessages = () => {
		if (chatId) {
			fetch(`https://p2p-chat.kinera.network/messages/${chatId}`)
				.then(res => res.json())
				.then(msgs => setMessages(msgs))
				.catch(() => setError('Erro ao carregar mensagens.'))
		}
	}

	// Verifica permissões e inicializa o chat
	const getPermissions = async () => {
		if (chatId) {
			try {
				let req = await verifypermission(chatId)
				if (req.owner_id === user.id || req.peer_id === user.id) {
					joinChat({ chatId, userId: user.id }) // Registra o usuário no chat
					fetchMessages()
				} else {
					setError('Você não tem permissão para acessar este chat.')
				}
			} catch (err) {
				setError('Erro ao verificar o chat.')
			}
		}
	}

	// Envia mensagem
	const handleSendMessage = async () => {
		if (!newMessage.trim() || !chatId || !user || !user.id || !user.name) {
			setError('Dados insuficientes para enviar mensagem.')
			return
		}

		const message: Message = {
			chatId,
			sender_id: user.id,
			sender_name: user.name,
			content: newMessage,
			created_at: new Date().toISOString(),
		}

		sendMessage(message) // Envia a mensagem
		scrollToBottom()
		setNewMessage('') // Limpa o campo de mensagem
	}

	// Lida com mensagens recebidas via socket e evita duplicatas
	const handleIncomingMessage = (message: Message) => {
		setMessages(prev => {
			const exists = prev.some(
				msg => msg.content === message.content && msg.created_at === message.created_at
			)
			if (!exists) {
				return [...prev, message]
			}
			return prev
		})
		//scrollToBottom()
	}

	// Listener de mensagens do socket
	useEffect(() => {
		if (chatId && user) {
			onMessage(handleIncomingMessage);
		}
	
		return () => {
			onMessage(() => {}); // Passa uma função vazia para remover o listener
		};
	}, [chatId, user, onMessage, handle]);
	// Verifica permissões no primeiro carregamento
	useEffect(() => {
		if (user && user.id && chatId) {
			getPermissions()
		}
	}, [user, chatId])

	// Rola para o final quando mensagens mudam
	useEffect(() => {
		scrollToBottom()
	}, [chatBodyRef, messages])

	if (error) {
		return <div>{error}</div>
	}

	return (
       <div className="page-padding chat-component">
         <div className="flex flex-col justify-between h-total gap-2">
        <div className="flex flex-col items-center  gap-2 chat-body" id="chat-body" ref={chatBodyRef}>
            {messages.map((msg, index) => (
                <div
                    key={index}
                    className={`w-full flex flex-col ${msg.sender_id === user.id ? 'items-end justify-end' : 'items-start'}`}>
                    <div className="w-fit bg-[#D9D9DF] padding-message rounded-[10px]">
                        <div className="under-message">
                            <div className="flex items-center gap-2">
                                <TextStyleCaption>{msg.sender_id === user.id ? 'Você' : msg.sender_name}</TextStyleCaption>
                                <TextStyleCaption>{new Date(msg.created_at).toLocaleTimeString()}</TextStyleCaption>
                            </div>
                            <TextStyleBodyLarge>{msg.content}</TextStyleBodyLarge>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div className="flex items-center justify-center w-full">
            <div className="box-message write w-[80%]">
                <div className="relative w-full">
                    <DscInput value={newMessage} type="text" placeholder="Write your message" onChange={setNewMessage} />
                    <div className="absolute right-2 top-1 arrow-btn">
                        <Button  icon="arrow_forward" iconOnly={true} value="arrow_forward" onClick={handleSendMessage} />
                    </div>
                </div>
            </div>
        </div>
    </div>
       </div>
	)
}