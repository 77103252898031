import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface Props {
	children: React.ReactNode
	open: boolean
	setOpen: (value: boolean) => void
}

const ViewMovieModal: React.FC<Props> = ({ children, open, setOpen }) => {
	const handleClose = () => {
		setOpen(false)
	}

	return (
		<React.Fragment>
			<Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				{/**
				 * 	<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
				 */}
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{children}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}

export default ViewMovieModal
