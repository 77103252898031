import styled from 'styled-components'

export const CenteredHorizontalDiv = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`

export const DsyHelpIcon = styled.i`
	font-size: 16px;
	margin-left: 5px;
	top: 3px;
	position: relative;
	margin-top: -2px;
	cursor: default;
`

export const DsyHighlighter = styled.span`
	background-color: var(--action-warning);
`
