import React, { useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import Modal from 'src/components/_Dsc/Modal'
import { SuccessToast } from 'src/components/Toast'
import { ErrorToast } from 'src/components/Toast/Error'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'

interface Props {
	community_id: string
}

const UserCommunityProposal: React.FC<Props> = ({ community_id }) => {
	const { user } = React.useContext(KineContext)
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [showModal, setShowModal] = useState<boolean>(false)
    const [successBuy, setSuccessBuy] = useState(false)
	const [isError, setIsError] = useState(false)

	const createDocumentCommunity = async () => {
		let req = await communityService.createCommunityDocument(title, user.id, description, community_id)
        if(req && req?.id) {
            setSuccessBuy(true)
            onClose()
            setTimeout(() => {
				setSuccessBuy(false)
			}, 4000)
        }else {
            setIsError(true) 
            onClose()
            setTimeout(() => {
				setIsError(false)
			}, 4000)
            
        }
	}

	const onClose = () => {
		setShowModal(false)
	}

	const onOpen = () => {
		setShowModal(true)
	}

	return (
		<div className="flex flex-col w-full bg-white p-4 rounded-[4px]">
            {successBuy ? <SuccessToast message="Document Create Success" /> : ''}
			{isError ? <ErrorToast message="Transaction error, review the information and try again" /> : ''}
			{showModal ? (
				<Modal
					confirmDisabled={!title || !description}
					onCancel={onClose}
					onClose={onClose}
					cancelLabel="Cancel"
					confirmLabel="Create"
					onConfirm={createDocumentCommunity}
					open={showModal}
					title={'Create Community Document'}>
					<div className="flex flex-col gap-2">
						<div>
							<label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900">
								Title
							</label>
							<input
								maxLength={50}
								onChange={e => {
									setTitle(e.target.value)
								}}
								type="text"
								id="title"
								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
								placeholder="Title..."
								required
							/>
							<div className="w-full flex justify-end">
								<p className="text-sm text-gray-500">{title.length}/50</p>
							</div>
						</div>
						<div>
							<label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">
								Description
							</label>
							<textarea
								onChange={e => {
									setDescription(e.target.value)
								}}
								maxLength={300}
								id="description"
								rows={4}
								className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
								placeholder="Write your description..."></textarea>
							<div className="w-full flex justify-end">
								<p className="text-sm text-gray-500">{description.length}/300</p>
							</div>
						</div>
					</div>
				</Modal>
			) : (
				''
			)}

			<div className="flex items-center gap-2">
		
				<Button value="Constellation [soon]" size="small" />
				<Button value="Rankings [soon]" size="small" />
			</div>
		</div>
	)
}

export default UserCommunityProposal
