import { Divider } from '@mui/material'
import React, { useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import { DsyLabel } from 'src/components/_Dsc/Button/styles'
import InputText from 'src/components/_Dsc/InputText'
import { DsyRequired } from 'src/components/_Dsc/InputText/styles'

interface Prompt {
	title: string
	question: string
}

interface Props {
	setIsSelected: (param: string) => void
	prompts: Prompt[]
	setPrompts: (prompts: Prompt[]) => void
}

export const RegisterPrompt: React.FC<Props> = ({ setIsSelected, prompts, setPrompts }) => {
	const onChange = (index: number, field: string, value: string) => {
		const updatedPrompts = [...prompts]
		updatedPrompts[index] = {
			...updatedPrompts[index],
			[field]: value,
		}
		setPrompts(updatedPrompts)
	}

	const addNewPrompts = () => {
		setPrompts([...prompts, { title: '', question: '' }])
	}

	return (
		<div className="w-full h-full flex flex-col gap-4">
			{prompts.map((prompt, index) => (
				<div key={index} className="w-full h-full flex flex-col gap-2">
					{index % 2 != 0 ? <Divider /> : ''}
					<div className="w-full h-full flex flex-col">
						<InputText
							required
							label={`Title`}
							value={prompt.title}
							onChange={value => onChange(index, 'title', value)}
							type="text"
							placeholder="Write your title... Ex: Step 1"
							maxLength={50}
						/>
						<DsyLabel>
							Question <DsyRequired>*</DsyRequired>
						</DsyLabel>
						<textarea
							maxLength={300}
							id={`description-${index}`}
							rows={4}
							value={prompt.question}
							onChange={e => onChange(index, 'question', e.target.value)}
							className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
							placeholder="Write your prompt... Ex: what do you think about bitcoin"
						></textarea>
					</div>
				</div>
			))}
			<div className="w-full h-full flex justify-between items-center">
				<Button value="Add new prompt" iconOnly icon="add" size="small" onClick={addNewPrompts} />
				<Button
					value="Next step"
					type="subtle"
					icon="arrow_forward_ios"
					size="small"
					onClick={() => {
						setIsSelected('agents')
					}}
				/>
			</div>
		</div>
	)
}
