import { Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Button from 'src/components/_Dsc/Button'
import Modal from 'src/components/_Dsc/Modal'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleBodyDefaultMedium,
	TextStyleCaption,
} from 'src/components/_Dsc/typography'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { insertUserFile, removeUserFile, uploadFileToGpt } from 'src/service/storage/Ipfs'
import { Loading } from 'src/components/loading'

export interface CommunityFile {
	document_name: string
	file_hash: string
	custom_name: string
	file_name: string
	type?: string
	file_size: string
	owner_name: string
	url: string
	user_address: string
	gpt_file_id: string
	extension: string
}

export const Rags: React.FC = () => {
	const { user, setOnQueue, handleGlobalMessage, currentPermissionComunity } = React.useContext(KineContext)
	const [files, setFiles] = useState<CommunityFile[] | null>(null)
	const [inFetch, setInFetch] = useState(true)
	const [selectedRag, setSelectedRag] = useState({} as CommunityFile)
	const [openInteractModal, setOpenInteractModal] = useState<boolean>(false)
	const [inputText, setInputText] = useState('')
	const [loading, setLoading] = useState(false)
	const [responses, setResponses] = useState<string[]>([])
	const { id } = useParams()

	const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files ? event.target.files[0] : null
		setInFetch(true)
		handleGlobalMessage('Your file is being processed and will soon be available')
		setOnQueue(true)
		if (file) {
			let reqFileId = await uploadFileToGpt(file)
			if (reqFileId && reqFileId.id) {
				handleInserFile(file, reqFileId.id)
			}
			getRags()
		}
	}

	const handleInserFile = async (file: File, idGpt: string) => {
		await insertUserFile(file, user.address, 'rag', id, idGpt)
	}

	const getRags = async () => {
		if (id) {
			setInFetch(true)
			let req = await communityService.getCommunitiesRags(id)
			if (req) {
				setFiles(req)
			}
			setInFetch(false)
		}
	}

	const removeFile = async (file: CommunityFile) => {
		setOnQueue(true)
		handleGlobalMessage('Your order is being processed and the file will soon be deleted.')
		await removeUserFile(user.address, file.file_hash)
		getRags()
	}

	const handleChangeModal = () => {
		setOpenInteractModal(false)
		setResponses([])
		setInputText('')
	}

	const handleOpenRagModal = async (rag: CommunityFile) => {
		setOpenInteractModal(true)
		setSelectedRag(rag)
		setLoading(false)
		setResponses([])
	}

	const handleSend = async () => {
		setLoading(true)
		postChatCompletion()
	}

	const postChatCompletion = async () => {
		try {
			setInputText('')
			const response = await axios.post(
				'https://open-gpt.kinera.network/api/chat/completions',
				{
					model: 'arena-model',
					messages: [
						{
							role: 'user',
							content: inputText,
						},
					],
					files: [
						{
							type: 'file',
							id: selectedRag.gpt_file_id,
						},
					],
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)

			setResponses(prev => [...prev, `Question: ${inputText}`, `Answer: ${response.data.choices[0].message.content}`])
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getRags()
	}, [])

	return (
		<div className="bg-white p-4 flex flex-col gap-2">
			<div className="flex items-center  w-full justify-end">
				{user && user.address && currentPermissionComunity ? (
					<label className="bg-[#0053e4] text-white flex items-center gap-4 defaut-p border border-1 rounded-[4px] hover:bg-gray-100 cursor-pointer">
						<span className="text-[16px] material-symbols-outlined">add</span>
						<div className="flex flex-col">
							<TextStyleBodyDefaultMedium>Add Document</TextStyleBodyDefaultMedium>
						</div>
						<input disabled={inFetch} type="file" accept=".txt, .pdf" className="hidden" onChange={handleFileSelect} />
					</label>
				) : (
					''
				)}
			</div>
			{openInteractModal && selectedRag ? (
				<Modal
					open={openInteractModal}
					title="Interact with the file"
					size="xl"
					onCancel={handleChangeModal}
					cancelLabel="Close"
				>
					<div className="p-4 h-full flex bg-[#ededed]">
						<div className="p-4 bg-white gap-4 flex flex-col max-w-[40%] w-[40%] overflow-hidden">
							<div className="flex flex-col items-end justify-end">
								<TextStyleCaption>Creator:</TextStyleCaption>
								<div className="flex items-center gap-1 cursor-pointer hover:bg-gray-100">
									<span className="material-symbols-outlined">person</span>
									<TextStyleBodyDefaultLarge>@{selectedRag.owner_name}</TextStyleBodyDefaultLarge>
								</div>
							</div>
							<Divider />
							<div className="flex items-center gap-2">
								<span className="material-symbols-outlined">description</span>
								<TextStyleBodyDefault>{selectedRag.document_name}</TextStyleBodyDefault>
							</div>
						</div>
						<div className="w-full justify-end items-center flex flex-col">
							{responses && responses.length ? (
								<div className="overflow-y-auto p-2 w-full">
									{responses.map((response, index) => (
										<div key={index} className="flex flex-col w-full">
											{response.includes('Question:') ? (
												<div className="w-full flex items-end  justify-end ">
													<div className="w-fit p-2 bg-[#F9F9F9] rounded-[100px]">
														<TextStyleBodyDefaultMedium>
															{response.includes('Question:') ? response.split('Question: ')[1] : ''}
														</TextStyleBodyDefaultMedium>
													</div>
												</div>
											) : (
												''
											)}

											{response.includes('Answer:') ? (
												<div className="p-2 w-full items-start justify-start flex rounded-[10px]">
													<div className="flex w-full h-full items-start gap-2">
														<img
															src="https://alpha.kinera.network/static/media/kinera_.aa99fcdcf04e75b583bf.webp"
															className="w-[30px] rounded-full bg-white p-1"
														/>
														<TextStyleBodyDefaultMedium>
															{response.includes('Answer:') ? response.split('Answer: ')[1] : ''}
														</TextStyleBodyDefaultMedium>
													</div>
												</div>
											) : (
												''
											)}
										</div>
									))}
								</div>
							) : (
								''
							)}
							{loading ? <Loading /> : ''}
							<div className="bg-white p-4 w-[90%] rounded-[10px] mt-4">
								<div className="flex flex-col gap-1 items-end justify-end">
									<textarea
										maxLength={300}
										id="description"
										rows={4}
										value={inputText}
										onChange={e => setInputText(e.target.value)}
										className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
										placeholder="Start typing"
									></textarea>
									<Button
										value="Send"
										size="small"
										onClick={handleSend}
										disabled={loading || inputText.trim() === ''}
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			) : (
				''
			)}

			{!inFetch ? (
				<>
					{files && files.length ? (
						files.map((file: CommunityFile, key: number) => (
							<div
								key={key}
								className="flex w-full gap-1 p-4 justify-between border border-1 rounded-[4px] cursor-pointer"
							>
								<div className="flex items-center gap-2">
									<span className="material-symbols-outlined">picture_as_pdf</span>
									{file.file_name}
								</div>

								<div className="flex items-center gap-2">
									{file.user_address === user.address ? (
										<Button value="Remove" type="critical" size="small" onClick={() => removeFile(file)} />
									) : (
										''
									)}
									<Button value="Interact" size="small" onClick={() => handleOpenRagModal(file)} />
								</div>
							</div>
						))
					) : (
						<TextStyleBodyDefaultMedium>Add documents to be used on your Agents.</TextStyleBodyDefaultMedium>
					)}
				</>
			) : (
				<Loading />
			)}
		</div>
	)
}
