import { Fragment, useEffect } from 'react'

import { IHelpTooltipIcon } from './interface'
import { DsyHelpIcon, DsyHighlighter } from './styles'

export { CenteredHorizontalDiv } from './styles'

export const useCloseOnClickOutsideComponent = (containerRef: any, visible: boolean, toggleVisible: () => void) => {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (visible && containerRef.current && !containerRef.current.contains(event.target)) {
				toggleVisible()
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [containerRef, visible])
}
