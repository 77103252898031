import axios from "axios";
import API from "../api";

export const insertUserFile = async(file: any, user_address: string, type: string, community_id?: string, idGpt?:string) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userAddress', user_address);
        formData.append('document_name', file.name);
        formData.append('type', type);
       
        if(community_id) {
            formData.append('community_id', community_id);
        }

        if(idGpt) {
            formData.append('gpt_file_id', idGpt);
        }

        let req = await API.post(`/api/storage/ipfs/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return req.data;
    } catch (err: any) {
        alert(err.response.data.message)
        return err
    }
}

export const insertUserFileComunity = async(file: any, user_address: string, type: string, community_id: string, extension:string) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userAddress', user_address);
        formData.append('document_name', file.name);
        formData.append('type', type);
        formData.append('extension', extension);
       
        if(community_id) {
            formData.append('community_id', community_id);
        }

       

        let req = await API.post(`/api/storage/ipfs/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return req.data;
    } catch (err: any) {
        alert(err.response.data.message)
        return err
    }
}


export const inserFileByPrompt = async(file: any, user_address: string, type: string, community_id: string, prompt:string, custom_title: string) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userAddress', user_address);
        formData.append('document_name', file.name);
        formData.append('custom_name', custom_title);
        formData.append('type', type);
       
        if(community_id) {
            formData.append('community_id', community_id);
        }

        
        formData.append('prompt', prompt);
        

        let req = await API.post(`/api/storage/ipfs/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return req.data;
    } catch (err: any) {
        alert(err.response.data.message)
        return err
    }
}

export const getUserFiles = async(userId: number) => {
    try {
        let req = await API.get(`/api/storage/ipfs/files/${userId}`)
        if(req) {
            return req.data
        }else {
            return {}
        }
    }catch (err) {
        console.log(err)
    }
}

export const getUserTwinFiles = async(userId: string) => {
    try {
        let req = await API.get(`/api/storage/ipfs/twin/${userId}`)
        if(req) {
            return req.data
        }else {
            return {}
        }
    }catch (err) {
        console.log(err)
    }
}

export const getUserSocialTwinFiles = async(userId: string) => {
    try {
        let req = await API.get(`/api/storage/ipfs/twin/social/${userId}`)
        if(req) {
            return req.data
        }else {
            return {}
        }
    }catch (err) {
        console.log(err)
    }
}

export const uploadFileToGpt = async (file: any) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const req = await axios.post(
            `https://open-gpt.kinera.network/api/v1/files/`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`
                }
            }
        );
        
        return req.data;
    } catch (err) {
        console.error(err);
    }
};


export const removeUserFile = async (userId: string, fileHash: string) => {
    try {
        let payload = {
            userAddress: userId,
            fileHash: fileHash
        }
        let req = await API.post(`/api/storage/user-ipfs-remove`, payload)
        if(req) {
            return req.data
        }else {
            return {}
        }
    }catch (err) {
        console.log(err)
    }
}