import './App.scss'
import './globalStyle/index.scss'
import Router from './router'
import { KineProvider } from './context/KineProvider'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import { SocketProvider } from './pages/chat/components/socket'

function App() {
	return (
		<KineProvider>
			<SocketProvider>
				<BrowserRouter>
					<Router />
				</BrowserRouter>
			</SocketProvider>
		</KineProvider>
	)
}

export default App
