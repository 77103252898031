import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import KineContext from '../../../context/KineContext'
import { logout } from 'src/service/profile/profileService'
import Cookies from 'js-cookie'
export default function LoggedUser(props: any) {
	const [isShowOptions, setIsShowOptions] = React.useState(false)
	const { setUser, user } = props
	const { setIsModerator, setGlobalProfile } = React.useContext(KineContext)

	const handleChange = () => {
		setIsShowOptions(!isShowOptions)
	}

	const logOut = async () => {
		setUser({})
		setGlobalProfile(false)
		setIsModerator(false)
		await logout()

		let userData = Cookies.get('kinera-cookie')
		if (userData) {
			Cookies.remove('kinera-cookie')
		}

		//window.location.reload()
	}

	const handleClickOutside = (event: any) => {
		if (event.target instanceof Node && !document.getElementById('dropdown')?.contains(event.target)) {
			setIsShowOptions(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<>
			{user && user.name && (
				<>
					<div className="cursor-pointer text-black font-bold" onClick={handleChange}>
						{user.name}
					</div>

					<div
						id="dropdown"
						className={
							isShowOptions
								? 'z-10 absolute mt-3  bg-gray-200 divide-y divide-gray-100  shadow w-[182px] rounded-b-[4px]  left-[2px]'
								: 'hidden'
						}
					>
						<ul className="py-2 text-sm text-black" aria-labelledby="dropdownDefaultButton" onClick={handleChange}>
							{/**
           *       <li>
                <Link
                  to='/profile'
                  className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                >
                  Profile
                </Link>
              </li>
           */}
							<li>
								<Link
									to="/config"
									className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
								>
									Settings
								</Link>
							</li>
							{/**
               *   <li>
                <Link to='/reward' className='block px-4 py-2 hover:bg-gray-100 '>
                  Earnings
                </Link>
              </li>
               */}
							<li>
								<Link
									to="#"
									className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
									onClick={logOut}
								>
									Sign out
								</Link>
							</li>
						</ul>
					</div>
				</>
			)}
		</>
	)
}
