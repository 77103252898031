import React, { useState, useRef, useEffect } from 'react'
import InputText from 'src/components/_Dsc/InputText'
import { TextStyleBodyDefaultLarge, TextStyleCaption } from 'src/components/_Dsc/typography'
import KineContext from 'src/context/KineContext'
import { insertUserFile, removeUserFile, uploadFileToGpt } from 'src/service/storage/Ipfs'
import Button from 'src/components/_Dsc/Button' // Substitua com o caminho correto para o componente Button
import { Loading } from 'src/components/loading'
import { updateUser } from 'src/service/profile/profileService'
import { UserProfile } from 'src/interface'
import { DsyLabel } from 'src/components/_Dsc/Button/styles'
import { DsyRequired } from 'src/components/_Dsc/InputText/styles'
import { models } from 'src/pages/community/components/Actions/components/GPT/components/Models'

interface Props {
	setIsEdit: (param: boolean) => void
	currentFiles: FileInfo[] | null
	digitalTwin: UserProfile
	getTwinFiles: () => void
}

export interface FileInfo {
	file_name: string
	file_size: number
	file: File
	file_hash: string
}

export const DigitalTwinForm: React.FC<Props> = ({ setIsEdit, currentFiles, digitalTwin, getTwinFiles }) => {
	const { user } = React.useContext(KineContext)
	const [files, setFiles] = useState<FileInfo[]>([])
	const [inInsertFile, setInInsertFile] = useState<boolean>(false)
	const [inRemoveFile, setInRemoveFile] = useState<boolean>(false)
	const [params, setParams] = useState('')
	const [type, setType] = useState('')
	const [name, setName] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const fileInputRef = useRef<HTMLInputElement | null>(null)

	const onUpdateDigitalTwinData = async () => {
		const formData = new FormData()
		formData.append('user', user.address)
		formData.append('digital_twin_name', name)
		formData.append('digital_twin_description', description)
		formData.append('twin_prompt', params)
		formData.append('twin_base_model', type)
		let req = await updateUser(formData)
		return req
	}

	const handleButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click() // Abre o seletor de arquivos
		}
	}

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return

		const selectedFiles = Array.from(event.target.files)

		for (const file of selectedFiles) {
			const isValidSize = file.size <= 600 * 1024 // 600KB
			const isValidType = file.type === 'application/pdf' || file.type === 'text/plain'

			if (!isValidSize) {
				alert(`The maximum file size is 600 KB per file. The file "${file.name}" exceeds this limit.`)
				continue
			} else if (!isValidType) {
				alert(`Invalid file type for "${file.name}". Only PDF and TXT files are allowed.`)
				continue
			}

			try {
				setInInsertFile(true)
				let reqFileId = await uploadFileToGpt(file)
				if (reqFileId && reqFileId.id) {
					const req = await insertUserFile(file, user.address, 'twin', '', reqFileId.id)
					if (req) {
						// Se o upload for bem-sucedido, adiciona o arquivo à lista
						setFiles(prevFiles => [
							...prevFiles,
							{
								file_hash: req.file_hash,
								file_name: file.name,
								file_size: file.size,
								file: file,
							},
						])
					} else {
						alert(`Failed to upload the file "${file.name}".`)
					}
				}
			} catch (error) {
				console.error(`Error uploading the file "${file.name}":`, error)
				alert(`An error occurred while uploading the file "${file.name}".`)
			}
		}
		setInInsertFile(false)
	}

	const removeFile = async (index: number, fileInfo: FileInfo) => {
		setInRemoveFile(true)
		let req = await removeUserFile(user.address, fileInfo.file_hash)
		if (req) {
			setFiles(prevFiles => prevFiles.filter((_, i) => i !== index))
		}
		setInRemoveFile(false)
	}

	const onConfirmEdit = async () => {
		let req = await onUpdateDigitalTwinData()
		if (req) {
			await getTwinFiles()
			setIsEdit(false)
		}
	}

	useEffect(() => {
		if (currentFiles) {
			setFiles(currentFiles)
		}
		if (digitalTwin) {
			setName(digitalTwin.digital_twin_name || '')
			setDescription(digitalTwin.digital_twin_description || '')
			setParams(digitalTwin.twin_prompt || '')
			setType(digitalTwin.twin_base_model || '')
		}
	}, [currentFiles, digitalTwin])

	return (
		<div className="flex items-center w-full flex-col gap-2 p-2 bg-white rounded-[4px]">
			<TextStyleBodyDefaultLarge>Configure Digital Twin</TextStyleBodyDefaultLarge>
			<div className="flex flex-col w-full gap-2">
				<div>
					<InputText required maxLength={50} label="Digital Twin Name" value={name} onChange={(e: any) => setName(e)} />

					<InputText
						required
						maxLength={50}
						label="Digital Twin Description"
						value={description}
						onChange={(e: any) => setDescription(e)}
					/>

					<div className="flex flex-col gap-2 mb-4">
						<div className="flex items-center gap-2">
							{models.map((val: { label: string; value: string }, key: number) => (
								<div className="flex items-center " key={key}>
									<input
										id="radio-image"
										type="radio"
										value="img"
										name="file-type"
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
										onChange={() => setType(val.value)}
										checked={type === val.value}
									/>
									<label htmlFor="radio-image" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
										{val.label}
									</label>
								</div>
							))}
						</div>
						<div className="flex flex-col">
							<div className="flex items-center gap-1">
								<DsyLabel className="font-[14px] text-[14px]">Personal Prompt</DsyLabel>
								<DsyRequired>*</DsyRequired>
							</div>
							<div className="w-full flex flex-col items-end justify-end">
								<textarea
									onChange={e => {
										setParams(e.target.value)
									}}
									value={params}
									maxLength={500}
									id="description"
									rows={4}
									className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
									placeholder="Write your character system prompt content here exmp: You are Elon Musk, owner of Tesla, acting as an assistant"
								></textarea>
								<div className="w-full items-end flex justify-end">
									<TextStyleCaption>{params.length || 0} / 500</TextStyleCaption>
								</div>
							</div>
						</div>
					</div>

					{!inInsertFile ? <Button size="small" onClick={handleButtonClick} value="Upload New File" /> : <Loading />}

					<input
						type="file"
						multiple
						accept=".pdf,.txt"
						onChange={handleFileChange}
						ref={fileInputRef}
						style={{ display: 'none' }} // Oculta o input de arquivo
					/>
				</div>

				<div className="">
					<TextStyleBodyDefaultLarge>Uploaded Files:</TextStyleBodyDefaultLarge>
					<div className="flex flex-col gap-2">
						{files.map((fileInfo, index) => (
							<div key={index} className="flex items-center justify-between p-1 border border-[#ededed] rounded-[4px]">
								<span>
									{fileInfo.file_name} - {(fileInfo.file_size / 1024).toFixed(2)} KB
								</span>
								{inRemoveFile ? (
									<Loading />
								) : (
									<Button size="small" type="critical" onClick={() => removeFile(index, fileInfo)} value="Remove" />
								)}
							</div>
						))}
					</div>
				</div>

				<div className="flex items-end justify-end w-full">
					<Button
						onClick={onConfirmEdit}
						size="small"
						value="Save"
						disabled={!params || !params.length || !name || !description}
					/>
				</div>
			</div>
		</div>
	)
}
