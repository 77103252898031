import React from 'react'
import KineContext from '../../../../context/KineContext'
import SocialView from '../../../socialView'

const SocialProfileView: React.FC = () => {
	const { user } = React.useContext(KineContext)

	return (
		<div className="w-full h-full">{user && user.address && <SocialView address={user.address} isEdit={true} />}</div>
	)
}

export default SocialProfileView
