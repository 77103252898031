import styled from 'styled-components'
import { CenteredHorizontalDiv } from '../Helpers/styles'

export const Card = styled.div`
	border-radius: var(--border-radius);
	background-color: var(--text-on);
	width: 100%;
`

export const CardContainer = styled.div`
	padding: var(--padding-default);
	min-height: 64px;
	display: flex;
	flex-direction: column;
`

export const CardRow = styled.div`
	margin-bottom: var(--padding-row);
	&:last-child {
		margin-bottom: 0;
	}
`

export const CardHeader = styled.div`
	padding: var(--padding-default);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--border-default);
`

export const CardHeaderTitle = styled.div`
	font-family: 'Circular Std';
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: var(--text-default);
`

export const CardHeaderTitleRequired = styled(CardHeaderTitle)`
	color: var(--text-critical);
`

export const CardHeaderTitleLink = styled.a`
	text-decoration: underline;
	&:hover {
		text-decoration: underline;
	}
`

export const CardHeaderAction = styled(CenteredHorizontalDiv)``

export const CardBody = styled.div`
	padding: var(--padding-default);
`

export const CardInner = styled.div`
	border-radius: var(--border-radius);
	border: 1px solid var(--border-default);
`

export const CardInnerHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 24px;
`

export const CardInnerHeaderPb16 = styled(CardInnerHeader)`
	padding-bottom: 16px;
`

export const CardInnerHeaderPb8 = styled(CardInnerHeader)`
	padding-bottom: 8px;
`

export const CardInnerHeaderPb0 = styled(CardInnerHeader)`
	padding-bottom: 0px;
`

export const CardInnerGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
`

export const CardInnerGridHalf = styled(CardInnerGrid)`
	grid-template-columns: 1fr 1fr;
	column-gap: 16px;
`

export const CardInnerGridThird = styled(CardInnerGrid)`
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 16px;
`
