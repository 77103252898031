import { web3FromAddress } from '@polkadot/extension-dapp'
import { Keyring } from '@polkadot/api'
import Swal from 'sweetalert2'
import { AccountId } from '@polkadot/types/interfaces'
import withReactContent from 'sweetalert2-react-content'
import { User } from '../../interface'
import React from 'react'
import Cookies from 'js-cookie'
const applyForModeration = async (user: User, api: any) => {
	if (!user || !user.address) {
		const storedUser = Cookies.get('kinera-cookie')
		if (storedUser) {
			user = JSON.parse(storedUser)
		} else {
			throw new Error('No user data available.')
		}
	}
	try {
		const MySwal = withReactContent(Swal)
		const connect = await api
		const injector = await web3FromAddress(user.address)

		const send = connect.tx.moderationModule.applyForModeration()
		await send.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			// window.location.reload()
		})
		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
		})
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				window.location.reload()
			},
		})
	}
}

const verifyModerator = async (user: User, api: any) => {
	try {
		const keyring = new Keyring({ type: 'sr25519' })

		const connect = await api

		const accountId = keyring.decodeAddress(user.address) as AccountId

		const send = await connect.query.moderationModule.moderators(accountId)
		return send.toHuman()
	} catch (err) {
		console.log(err)
	}
}

const reportContent = async (id: any, type: number, user: User, text: any, api: any) => {
	console.log(id, type, user, text)
	try {
		const keyring = new Keyring({ type: 'sr25519' })

		const MySwal = withReactContent(Swal)
		const connect = await api
		const injector = await web3FromAddress(user.address)
		const accountId = keyring.decodeAddress(user.address) as AccountId

		const send = connect.tx.moderationModule.createReport(id, type, accountId, text, [])
		await send.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			// window.location.reload()
		})
		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
		})
	} catch (err) {
		console.log('error', err)

		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				window.location.reload()
			},
		})
	}
}

const getReports = async (api: any) => {
	try {
		const connect = await api
		const send = await connect.query.moderationModule.reports.entries()
		let allReports: any = []

		send.map(async (val: any) => {
			await allReports.push({ val: val[1].toHuman(), name: val[0].toHuman() })
		})
		return allReports
	} catch (err) {
		console.log(err)
	}
}

const getReportByContent = async (id: any, type: any, api: any) => {
	try {
		const connect = await api
		const send = await connect.query.moderationModule.reports([parseInt(id), type])
		return send.toHuman()
	} catch (err) {
		console.log(err)
	}
}

export const ModerationService = {
	applyForModeration,
	reportContent,
	verifyModerator,
	getReports,
	getReportByContent,
}
