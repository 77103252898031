import React from 'react'
import Feed from '../components/feed/Feed'
import TweetBox from '../components/createTweet/TweetBox'
import { getAllPosts, getAllSocialPosts } from '../../../service/social/socialService'
import KineContext from '../../../context/KineContext'
import './style.scss'
import { Skeleton } from '@mui/material'
import DscTab from 'src/components/_Dsc/tab'
import Button from 'src/components/_Dsc/Button'
import { useNavigate } from 'react-router-dom'
const interactions = [1, 2, 3, 4, 5]

const HomeSocial: React.FC = () => {
	const navigate = useNavigate()
	const categories = ['For you', 'Rankings', 'Constellations']
	const [isSelected, setIsSelected] = React.useState('For you')
	const [data, setData] = React.useState<any>([])
	const [constellationsData, setConstellationsData] = React.useState<any>([])
	const [fetch, setFetch] = React.useState<boolean>(false)
	const [selectedTab, setSelectedTab] = React.useState<string>('For you')
	const { setGlobalToast, apiState, profile } = React.useContext(KineContext)
	const getData = async () => {
		try {
			setGlobalToast(true)
			setFetch(false)
			let posts = await getAllPosts(apiState)

			setData(posts)
			setGlobalToast(false)
			setFetch(true)
		} catch (error) {
			console.error('Ocorreu um erro ao buscar os dados:', error)
			setFetch(true)
			setGlobalToast(false)
		}
	}

	const getFestivalsPost = async () => {
		try {
			setFetch(false)
			let req = await getAllSocialPosts()
			setConstellationsData(req)
			setFetch(true)
		} catch (e) {
			setFetch(true)
		}
	}

	const handleTabChange = (tab: string) => {
		setIsSelected(tab)
		setSelectedTab(tab)
		if (tab === 'Constellations' && (!constellationsData || !constellationsData[0])) {
			getFestivalsPost()
		}
	}

	const goToRegister = () => {
		navigate('/register-profile')
	}

	React.useEffect(() => {
		getData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="home-social relative mb-5 rounded-[4px] overflow-y-auto min-w-[650px]">
			<div className="h-[57px] relative w-full">
				<div className="w-full h-[57px] flex items-center social-tab overflow-hidden">
					{categories.map((val: string, i: number) => (
						<div key={i}>
							<DscTab isSelected={val === isSelected}>
								<button
									onClick={() => {
										handleTabChange(val)
									}}>
									{val}
								</button>
							</DscTab>
						</div>
					))}
				</div>
			</div>
			<section className="timeline h-full pb-[10px] w-full flex flex-col gap-2">
				{fetch ? (
					<div>
						{fetch && profile && profile.user && <TweetBox setData={setData} getData={getData} />}
						{fetch && (!profile || !profile.user) && (
							<div className="p-[8px] w-full justify-center items-center flex">
								<Button value="Create social profile" onClick={goToRegister} />
							</div>
						)}
					</div>
				) : (
					<div className="p-4">
						<Skeleton variant="rectangular" width={'100%'} height={200} />
					</div>
				)}
				{fetch ? (
					<Feed posts={selectedTab === 'For you' ? data : constellationsData} profile={profile} setData={setData} />
				) : (
					interactions.map((value: any, index: number) => (
						<div className="flex flex-col items-center p-4 border-y border-y-2" key={index}>
							<div className="flex items-center justify-between mb-2 w-full">
								<Skeleton variant="circular" width={60} height={60} />
								<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'80%'} />
							</div>
							<Skeleton variant="rectangular" width={'100%'} height={100} />
							<div className="mt-2 mb-2 w-full">
								<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'30%'} />
							</div>
						</div>
					))
				)}
			</section>
		</div>
	)
}

export default HomeSocial
