import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import Typography from '@mui/material/Typography'

import { ModerationService } from '../../service/moderation/moderationService'
import KineContext from '../../context/KineContext'

interface Props {
	opened: boolean
	setOpen: any
	isRanking?: boolean
	currentEnum: number
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	borderRadius: '6px',
	boxShadow:
		'29px 24px 11px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 31px 46px 27px rgba(0,0,0,0.12)',
	p: 10,
	minWidth: '60%',
}

export default function ReportModal(props: Props) {
	const { opened, setOpen, isRanking, currentEnum } = props
	const { user, apiState } = React.useContext(KineContext)

	const [selectedOption, setSelectedOption] = React.useState<any>(null)
	const options = !isRanking
		? [
				{ name: 'Sexual content', value: 1 },
				{ name: 'Violent or repulsive content', value: 2 },
				{ name: 'Hateful or abusive content', value: 3 },
				{ name: 'Harassment or bullying', value: 4 },
				{ name: 'Dangerous or harmful acts', value: 5 },
				{ name: 'Spam or misleading', value: 7 },
				{ name: 'Spam or misleading', value: 8 },
		  ]
		: [{ name: 'Incorrect category', value: 1 }]

	const handleClose = () => {
		setOpen(false)
		setSelectedOption(null)
	}

	const handleChange = (event: any) => {
		setSelectedOption(event.target.value)
	}

	const reportContent = async () => {
		let id = window.location.pathname.split('/')[3]
		await ModerationService.reportContent(id, currentEnum, user, selectedOption, apiState)
		setSelectedOption(null)
	}

	React.useEffect(() => {
		setSelectedOption(null)
	}, [])

	return (
		<div>
			<Modal
				aria-labelledby="spring-modal-title"
				aria-describedby="spring-modal-description"
				open={opened}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
			>
				<Box sx={style}>
					<Typography id="spring-modal-title" variant="h4" component="h1">
						Report content
					</Typography>

					<Typography id="spring-modal-title">
						By taking this action, you are aware that the content may be removed for the specified reason:
					</Typography>
					<div className="mt-5 w-full border-2 p-2">
						{/**
             * <TextareaAutosize
  onChange={(event) => {
    setText(event.target.value);
  }}
  aria-label='minimum height'
  minRows={3}
  placeholder='write the reason for the report'
  style={{ width: '100%' }}
/>;

             */}
						{options.map((val, index) => (
							<div key={index} className="flex items-center gap-4 p-2">
								<input
									type="radio"
									name="option"
									value={val.name}
									checked={selectedOption === val.name}
									onChange={handleChange}
								/>
								<span>{val.name}</span>
							</div>
						))}
					</div>
					<div className="w-full flex items-end justify-end mt-2">
						<button
							onClick={handleClose}
							type="submit"
							className="bg-red-600 text-white items-center flex font-medium mr-4 px-4  h-[32px]"
						>
							Cancel
						</button>

						<button
							disabled={!selectedOption}
							onClick={() => {
								reportContent()
							}}
							className={
								selectedOption
									? 'secondary-blue p-1 roundend-full text-white flex items-center hover:opacity-80'
									: 'bg-gray-300 p-1 roundend-full text-white flex items-center hover:opacity-80'
							}
						>
							Apply Report
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
