import { useCallback, useEffect, useState } from 'react'

interface Props {
	ref: any
	calculateFor?: 'topLeft' | 'bottomRight' // Opcional agora
}

interface Position {
	x: number
	y: number
}

export const useDrag = ({ ref, calculateFor = 'topLeft' }: Props) => {
	const [dragInfo, setDragInfo] = useState({
		startX: 0,
		startY: 0,
		top: 0,
		left: 0,
		width: 0,
		height: 0,
	})
	const [finalPosition, setFinalPosition] = useState<Position>({
		x: 0,
		y: 0,
	})
	const [isDragging, setIsDragging] = useState(false)

	// Função para calcular a posição inicial (embaixo e centralizado)
	const calculateInitialPosition = useCallback(() => {
		const draggableElement = ref.current
		if (!draggableElement) return

		// Verifica se há uma posição salva no localStorage
		const savedPosition = localStorage.getItem('draggablePosition')

		if (savedPosition) {
			// Se houver, utiliza a posição salva
			const { x, y } = JSON.parse(savedPosition)
			setFinalPosition({ x, y })
			return
		}

		// Caso contrário, calcula a posição inicial padrão
		const { width, height } = draggableElement.getBoundingClientRect()

		setFinalPosition({
			x: (window.innerWidth - width) / 2, // Centro horizontal
			y: window.innerHeight - height - 40, // Próximo à base (ajuste de 40px)
		})
	}, [ref])

	const updateFinalPosition = useCallback(
		(width: number, height: number, x: number, y: number) => {
			const newPosition =
				calculateFor === 'bottomRight'
					? {
							x: Math.max(Math.min(window.innerWidth - width, window.innerWidth - (x + width)), 0),
							y: Math.max(Math.min(window.innerHeight - height, window.innerHeight - (y + height)), 0),
					  }
					: {
							x: Math.min(Math.max(0, x), window.innerWidth - width),
							y: Math.min(Math.max(0, y), window.innerHeight - height),
					  }

			setFinalPosition(newPosition)

			// Salva a nova posição no localStorage
			localStorage.setItem('draggablePosition', JSON.stringify(newPosition))
		},
		[calculateFor],
	)

	const handleMouseUp = (evt: MouseEvent) => {
		evt.preventDefault()
		setIsDragging(false)
	}

	const handleMouseDown = (evt: React.MouseEvent) => {
		evt.preventDefault()

		const { clientX, clientY } = evt
		const draggableElement = ref.current

		if (!draggableElement) {
			return
		}

		const { top, left, width, height } = draggableElement.getBoundingClientRect()

		setIsDragging(true)
		setDragInfo({
			startX: clientX,
			startY: clientY,
			top,
			left,
			width,
			height,
		})
	}

	const handleMouseMove = useCallback(
		(evt: MouseEvent) => {
			const draggableElement = ref.current

			if (!isDragging || !draggableElement) return

			evt.preventDefault()

			const { clientX, clientY } = evt

			const position = {
				x: dragInfo.startX - clientX,
				y: dragInfo.startY - clientY,
			}

			const { top, left, width, height } = dragInfo

			updateFinalPosition(width, height, left - position.x, top - position.y)
		},
		[isDragging, dragInfo, ref, updateFinalPosition],
	)

	const recalculate = (width?: number, height?: number) => {
		const draggableElement = ref.current
		if (!draggableElement) return

		const { top, left, width: boundingWidth, height: boundingHeight } = draggableElement.getBoundingClientRect()

		updateFinalPosition(width ?? boundingWidth, height ?? boundingHeight, left, top)
	}

	useEffect(() => {
		// Calcula a posição inicial quando o componente monta

		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)

		return () => {
			document.removeEventListener('mousemove', handleMouseMove)
			document.removeEventListener('mouseup', handleMouseUp)
		}
	}, [handleMouseMove])

	useEffect(() => {
		calculateInitialPosition()
	}, [])

	return {
		position: finalPosition,
		handleMouseDown,
		recalculate,
	}
}
