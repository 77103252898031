import { Divider, Tooltip } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import { DsyLabel } from 'src/components/_Dsc/Button/styles'

import InputText from 'src/components/_Dsc/InputText'
import { DsyRequired } from 'src/components/_Dsc/InputText/styles'
import Modal from 'src/components/_Dsc/Modal'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleBodyDefaultMedium,
	TextStyleCaption,
} from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { insertUserFile } from 'src/service/storage/Ipfs'
import { CommunityFile } from '../Actions/components/GPT/components/Rags'
import { models } from '../Actions/components/GPT/components/Models'

export interface Character {
	id: string
	community_id: string
	name: string
	description: string
	tags: string
	params: string
	owner_id: 44
	owner_name: string
	img_url: string
	is_public: number
	created_at: string
	base_param: string
	updated_at: string
	interactions: string
}

export const Bazzar: React.FC = () => {
	const { user, setOnQueue, handleGlobalMessage, currentPermissionComunity } = React.useContext(KineContext)
	const [showModal, setShowModal] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [inFetch, setInFetch] = useState(false)
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [params, setParams] = useState('')
	const [tags, setTags] = useState('')
	const [characters, setCharacters] = useState<Character[]>([])
	const [selectedFile, setSelectedFile] = useState<File | null>(null) // Estado para armazenar a imagem selecionada
	const [preview, setPreview] = useState<string | null>(null) // Estado para armazenar o preview da imagem
	const [type, setType] = useState('')
	const [selectedChar, setSelectedChar] = useState<Character>({} as Character)
	const { id } = useParams()
	const [selectedFileIds, setSelectedFileIds] = useState<string>('')
	const [inFetchFiles, setInFetchFiles] = useState<boolean>(true)
	const [files, setFiles] = useState<CommunityFile[] | null>(null)
	const [showModalSelectedCharacter, setShowModalSelectedCharacter] = useState<boolean>(false)
	const [selectedCharacter, setSelectedCharacter] = useState<Character>({} as Character)
	const [inputText, setInputText] = useState('')
	const [showModalPublish, setShowModalPublish] = useState<boolean>(false)
	const [showPrivateCharacter, setShowPrivateCharacter] = useState<boolean>(false)
	const [responses, setResponses] = useState<string[]>([])
	const [loading, setLoading] = useState(false)

	const closeModal = () => {
		setShowModal(false)
	}

	const handleInputChange = (param: string, origin: string) => {
		if (origin === 'params') {
			setParams(param)
		} else if (origin === 'title') {
			setTitle(param)
		} else if (origin === 'description') {
			setDescription(param)
		} else if (origin === 'tags') {
			setTags(param)
		}
	}

	const onClean = () => {
		setTitle('')
		setDescription('')
		setSelectedFile(null)
		setPreview(null)
		setParams('')
	}

	const onRemoveImage = () => {
		setPreview(null)
		setSelectedFile(null)
	}

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0]
			setSelectedFile(file)
			setPreview(URL.createObjectURL(file)) // Cria uma URL temporária para o preview da imagem
		}
	}

	const handleConfirmCreate = async () => {
		setInFetch(true)
		if (!isEdit) {
			let req = await insertUserFile(selectedFile, user.address, 'img')

			if (req && req.url && id) {
				let payload = {
					community_id: id,
					name: title,
					description: description,
					params: params,
					img_url: req.url,
					base_param: type,
					tags: selectedFileIds,
				}
				await communityService.createCommunityCharacter(payload, user.address)
				setShowModal(false)
				setOnQueue(true)
				onClean()
				getAllCharactersCommunity()
				handleGlobalMessage('Action completed successfully, your Character will be listed soon')
			}
		} else {
			let url = ''
			if (selectedFile) {
				let req = await insertUserFile(selectedFile, user.address, 'img')
				if (req && req.url && id) {
					url = req.url
				}
			} else {
				url = selectedChar.img_url
			}
			if (id) {
				let payload = {
					community_id: id,
					name: title,
					description: description,
					params: params,
					img_url: url,
					base_param: type,
					id: selectedChar.id,
					tags: selectedFileIds,
				}
				await communityService.updateCharacter(payload)
				setShowModal(false)
				setOnQueue(true)
				onClean()
				getAllCharactersCommunity()
				handleGlobalMessage('Action completed successfully, your Character will be listed soon')
			}
		}

		setInFetch(false)
	}

	const getAllCharactersCommunity = async () => {
		setInFetch(true)
		if (id) {
			let req = await communityService.getAllCommunitiesCharacters(id)
			if (req && req.data) {
				setCharacters(req.data)
			}
		}
		setInFetch(false)
	}

	const enableEdit = (character: Character) => {
		setSelectedChar(character)
		setIsEdit(true)
		setTitle(character.name)
		setDescription(character.description)
		setType(character.base_param)
		setParams(character.params)
		setPreview(character.img_url)
		setShowModal(true)
	}

	const onRemoveUserCharacter = async (character: Character) => {
		if (id) {
			let payload = {
				community_id: id,
				id: character.id,
			}
			await communityService.removeUseChar(payload)
			getAllCharactersCommunity()
		}
	}

	const showModalInteract = (character: Character) => {
		setShowModalSelectedCharacter(true)
		setSelectedCharacter(character)
	}

	const postChatCompletion = async () => {
		try {
			setInputText('')
			setLoading(true)
			const response = await axios.post(
				'https://open-gpt.kinera.network/api/chat/completions',
				{
					model: selectedCharacter.base_param ?? 'arena-model',
					messages: [
						{
							role: 'user',
							content: `${selectedCharacter.params} based on this answer: ${inputText}`,
						},
					],
					files: [],
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)

			setResponses(prev => [...prev, `Question: ${inputText}`, `Answer: ${response.data.choices[0].message.content}`])
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setLoading(false)
		}
	}

	const handleModalSelectedChar = () => {
		setShowModalSelectedCharacter(!showModalSelectedCharacter)
	}

	const handleCheckboxChange = (fileId: string, isChecked: boolean) => {
		setSelectedFileIds(prev => {
			const idsArray = prev ? prev.split(',') : []
			if (isChecked) {
				// Adiciona o ID se marcado
				if (!idsArray.includes(fileId)) {
					idsArray.push(fileId)
				}
			} else {
				// Remove o ID se desmarcado
				const index = idsArray.indexOf(fileId)
				if (index > -1) {
					idsArray.splice(index, 1)
				}
			}
			const updatedIds = idsArray.join(',')
			setSelectedFileIds(updatedIds) // Exibe no console o estado atualizado
			return updatedIds
		})
	}

	const openCreateChar = () => {
		setShowModal(true)
		onClean()
		getRags()
	}

	const getRags = async () => {
		if (id) {
			setInFetchFiles(true)
			let req = await communityService.getCommunitiesRags(id)
			if (req) {
				setFiles(req)
			}
			setInFetchFiles(false)
		}
	}

	const showPublishModal = (character: Character) => {
		setSelectedChar(character)
		setShowModalPublish(true)
	}

	const showPrivateModalCharacter = (character: Character) => {
		setSelectedChar(character)
		setShowPrivateCharacter(true)
	}

	const handlePublishCharacter = async () => {
		if (id) {
			let payload = {
				id: selectedChar.id,
				community_id: id,
				status: true,
			}

			await communityService.publishCharacter(payload)
			setShowModalPublish(false)
			getAllCharactersCommunity()
		}
	}

	const handlePrivateCharacter = async () => {
		if (id) {
			let payload = {
				id: selectedChar.id,
				community_id: id,
				status: false,
			}

			await communityService.publishCharacter(payload)
			setShowPrivateCharacter(false)
			getAllCharactersCommunity()
		}
	}

	useEffect(() => {
		getAllCharactersCommunity()
	}, [])

	return (
		<div className="bg-white p-4 flex flex-col gap-2">
			<TextStyleBodyDefaultLarge>Publish your project in the Bazaar</TextStyleBodyDefaultLarge>
			{selectedCharacter ? (
				<Modal
					open={showModalSelectedCharacter}
					title="Interact with the file"
					size="xl"
					onCancel={handleModalSelectedChar}
					cancelLabel="Close"
				>
					<div className="p-4 h-full flex bg-[#ededed]">
						<div className="p-4 bg-white gap-4 flex flex-col max-w-[40%] w-[40%] overflow-hidden">
							<div className="flex flex-col items-end justify-end">
								<TextStyleCaption>Creator:</TextStyleCaption>
								<div className="flex items-center gap-1 cursor-pointer hover:bg-gray-100">
									<span className="material-symbols-outlined">person</span>
									<TextStyleBodyDefaultLarge>@{selectedCharacter.owner_name}</TextStyleBodyDefaultLarge>
								</div>
							</div>
							<Divider />
							<div className="flex flex-col gap-2">
								<div className="flex items-center gap-2">
									<span className="material-symbols-outlined">description</span>
									<TextStyleBodyDefault>{selectedCharacter.name}</TextStyleBodyDefault>
								</div>
								<div>
									<TextStyleCaption>About:</TextStyleCaption>
									<div className="overflow-y-auto">
										<TextStyleBodyDefault>{selectedCharacter.description}</TextStyleBodyDefault>
									</div>
								</div>
							</div>
						</div>
						<div className="w-full justify-end items-center flex flex-col">
							{responses && responses.length ? (
								<div className="overflow-y-auto p-2 w-full">
									{responses.map((response, index) => (
										<div key={index} className="flex flex-col w-full">
											{response.includes('Question:') ? (
												<div className="w-full flex items-end  justify-end ">
													<div className="w-fit p-2 bg-[#F9F9F9] rounded-[100px]">
														<TextStyleBodyDefaultMedium>
															{response.includes('Question:') ? response.split('Question: ')[1] : ''}
														</TextStyleBodyDefaultMedium>
													</div>
												</div>
											) : (
												''
											)}

											{response.includes('Answer:') ? (
												<div className="p-2 w-full items-start justify-start flex rounded-[10px]">
													<div className="flex w-full h-full items-start gap-2">
														<img
															src="https://alpha.kinera.network/static/media/kinera_.aa99fcdcf04e75b583bf.webp"
															className="w-[30px] rounded-full bg-white p-1"
														/>
														<TextStyleBodyDefaultMedium>
															{response.includes('Answer:') ? response.split('Answer: ')[1] : ''}
														</TextStyleBodyDefaultMedium>
													</div>
												</div>
											) : (
												''
											)}
										</div>
									))}
								</div>
							) : (
								''
							)}
							{loading ? <Loading /> : ''}
							<div className="bg-white p-4 w-[90%] rounded-[10px] mt-4">
								<div className="flex flex-col gap-1 items-end justify-end">
									<textarea
										maxLength={300}
										id="description"
										rows={4}
										value={inputText}
										onChange={e => setInputText(e.target.value)}
										className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
										placeholder="Start typing"
									></textarea>
									<Button
										value="Send"
										size="small"
										onClick={postChatCompletion}
										disabled={loading || inputText.trim() === ''}
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			) : (
				''
			)}
			{showModalPublish ? (
				<Modal
					title="Publish Project"
					open={showModalPublish}
					onClose={() => {
						setShowModalPublish(false)
					}}
					onCancel={() => {
						setShowModalPublish(false)
					}}
					onConfirm={handlePublishCharacter}
					confirmLabel={'Publish'}
					confirmDisabled={!selectedCharacter}
					cancelLabel="Cancel"
				>
					<div className="flex flex-col gap-1">
						<TextStyleBodyDefault>
							Are you sure you want to make your project public? This way your Project will be displayed in the 'Bazaar'
							tab and can be used by other users
						</TextStyleBodyDefault>
					</div>
				</Modal>
			) : (
				''
			)}
			{showPrivateCharacter ? (
				<Modal
					title="Private Project"
					open={showPrivateCharacter}
					onClose={() => {
						setShowPrivateCharacter(false)
					}}
					onCancel={() => {
						setShowPrivateCharacter(false)
					}}
					onConfirm={handlePrivateCharacter}
					confirmLabel={'Private'}
					confirmDisabled={!selectedCharacter}
					cancelLabel="Cancel"
				>
					<div className="flex flex-col gap-1">
						<TextStyleBodyDefault>
							When reverting the public project, the timeline of the social profile associated with the Project will be
							disabled.
						</TextStyleBodyDefault>
					</div>
				</Modal>
			) : (
				''
			)}
			{showModal ? (
				<Modal
					title="Create new Character"
					open={showModal}
					onClose={closeModal}
					onCancel={closeModal}
					onConfirm={handleConfirmCreate}
					confirmLabel={isEdit ? 'Save' : 'Create'}
					confirmDisabled={!preview || !title || !description || !params || inFetch || !type}
					cancelLabel="Cancel"
				>
					{inFetch ? (
						<Loading />
					) : (
						<div className="flex flex-col gap-1">
							<div className="w-full flex flex-col items-center justify-center p-2 gap-2">
								<div className="flex items-center flex-col">
									<div className="flex items-center gap-1">
										<DsyLabel className="font-[14px] text-[14px]">Project Image</DsyLabel>
										<DsyRequired>*</DsyRequired>
									</div>
									<div
										className="w-fit plus-border-style cursor-pointer hover:bg-gray-100"
										onClick={() => !preview && document.getElementById('file-input')?.click()}
									>
										{preview ? (
											<img src={preview} alt="Preview" className="w-20 h-20 object-cover rounded-full" />
										) : (
											<span className="material-symbols-outlined text-[30px]">add_circle</span>
										)}
									</div>
								</div>
								<input id="file-input" type="file" accept="image/*" className="hidden" onChange={handleFileChange} />
								{selectedFile && <Button value="Remove image" size="small" onClick={onRemoveImage} />}
							</div>
							<InputText
								placeholder="Title"
								type="text"
								value={title}
								label="Project name"
								maxLength={30}
								required
								onChange={(e: any) => {
									handleInputChange(e, 'title')
								}}
							/>
							<InputText
								type="text"
								value={description}
								maxLength={300}
								label="Description"
								placeholder="Add a short description about what this model does"
								required
								onChange={(e: any) => {
									handleInputChange(e, 'description')
								}}
							/>
							<div className="flex flex-col  gap-1">
								<div className="flex items-center gap-1">
									<DsyLabel className="font-[14px] text-[14px]">Base model</DsyLabel>
									<DsyRequired>*</DsyRequired>
								</div>
								<div className="flex items-center gap-2">
									{models.map((val: { label: string; value: string }, key: number) => (
										<div className="flex items-center " key={key}>
											<input
												id="radio-image"
												type="radio"
												value="img"
												name="file-type"
												className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
												onChange={() => setType(val.value)}
												checked={type === val.value}
											/>
											<label
												htmlFor="radio-image"
												className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
											>
												{val.label}
											</label>
										</div>
									))}
								</div>
							</div>
							<div className="flex flex-col">
								<div className="flex items-center gap-1">
									<DsyLabel className="font-[14px] text-[14px]">System prompt</DsyLabel>
									<DsyRequired>*</DsyRequired>
								</div>
								<textarea
									onChange={e => {
										handleInputChange(e.target.value, 'params')
									}}
									value={params}
									maxLength={1000}
									id="description"
									rows={4}
									className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
									placeholder="Write your character system prompt content here exmp: You are Elon Musk, owner of Tesla, acting as an assistant"
								></textarea>
							</div>

							{/**
								 * <div className="flex flex-col gap-2">
								{inFetchFiles ? (
									<Loading></Loading>
								) : (
									<div className="max-w-h-[300px] overflow-y-auto gap-2 flex flex-col">
										<DsyLabel>Knowledge base</DsyLabel>
										{files && files.length ? (
											files.map((fie: CommunityFile, key: number) => (
												<div>
													<div className="flex items-center">
														<input
															id="default-checkbox"
															type="checkbox"
															onChange={e => handleCheckboxChange(fie.gpt_file_id, e.target.checked)}
															value={fie.gpt_file_id}
															className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
														/>
														<label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900">
															{fie.document_name}
														</label>
													</div>
												</div>
											))
										) : (
											<TextStyleBodyDefaultMedium>
												No to knowledge bases added in this community
											</TextStyleBodyDefaultMedium>
										)}
									</div>
								)}
							</div>
								 */}
						</div>
					)}
				</Modal>
			) : (
				''
			)}

			{inFetch ? (
				<Loading />
			) : characters && characters.length ? (
				<div className="flex gap-2 flex-wrap">
					{characters.map((character: Character, key: number) => (
						<div className="text-left bg-gray-50 dark:bg-gray-850 group-hover:dark:bg-gray-850/80 min-w-64 max-w-72 border border-gray-100 dark:border-none rounded-lg flex flex-col justify-between">
							<div className="w-full relative">
								<div className="absolute right-1 top-1">
									<div className="flex gap-1 items-center">
										<Button
											value="Delete"
											type="critical"
											icon="delete"
											size="small"
											onClick={() => {
												onRemoveUserCharacter(character)
											}}
										/>
										{character.is_public === 1 ? (
											<Button
												value="Unpublish"
												type="warning"
												icon="public"
												size="small"
												onClick={() => {
													showPrivateModalCharacter(character)
												}}
											/>
										) : (
											<Button
												value="Publish"
												icon="public"
												size="small"
												onClick={() => {
													showPublishModal(character)
												}}
											/>
										)}

										<Button
											value="Edit"
											icon="edit"
											size="small"
											onClick={() => {
												enableEdit(character)
											}}
										/>
									</div>
								</div>
								<img src={character.img_url} alt="model" loading="lazy" />
								<div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-850 to-transparent text-white text-xs font-medium bottom-0 p-1"></div>
							</div>
							<div className="flex flex-1 min-w-0 px-2.5 py-6  z-20">
								<div className="flex flex-col justify-start h-full flex-1 self-center truncate">
									<div className="flex">
										<div className="capitalize font-medium    mb-0.5">{character.name}</div>
									</div>
									<Tooltip title={character.description}>
										<div className="text-xs truncate    truncate-text">{character.description}</div>
									</Tooltip>
								</div>
								<div className="flex flex-col gap-1">
									<div className="mt-1 truncate-text text-[0.6rem] text-gray-500">@{character.owner_name}</div>
									<Button
										icon="chat"
										value="Interact"
										size="small"
										onClick={() => {
											showModalInteract(character)
										}}
									/>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				<TextStyleBodyDefaultMedium>This community is still without characters</TextStyleBodyDefaultMedium>
			)}
		</div>
	)
}
