import React from 'react'
import DscIcons from '../../../../components/_Dsc/icons'
import { Link } from 'react-router-dom'

interface INotification {
	content: string
	id: number
	status: number
	type: string
	user_id: number
}

interface Props {
	val: INotification
	updateNotifyUser: (value: INotification) => void
}

const SocialNotification: React.FC<Props> = ({ val, updateNotifyUser }) => {
	return (
		<div className="p-2 cursor-pointer hover:bg-gray-100 hover:text-blue-400  rounded-[4px]">
			<div className="flex items-center gap-2 ml-[16px] ">
				<DscIcons icon={val.status === 0 ? 'visibility' : 'done'} />
				{val.type === '2' && (
					<div>
						{val.content.includes('::') ? (
							<button
								onClick={() => {
									updateNotifyUser(val)
								}}
							>
								<Link
									to={'/social/post/' + val.content.split('::')[1]}
									className={val.status === 0 ? 'text-blue-700' : 'text-gray-500'}
								>
									{val.content.split('::')[0]}
								</Link>
							</button>
						) : (
							<button
								onClick={() => {
									updateNotifyUser(val)
								}}
							>
								<Link
									to={'/social/view' + val.content.split('::')[1]}
									className={val.status === 0 ? 'text-blue-700' : 'text-gray-500'}
								>
									{val.content}
								</Link>
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default SocialNotification
