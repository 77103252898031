import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import { TextStyleBodyDefault, TextStyleBodyDefaultMedium, TextStyleCaption } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import { communityService } from 'src/service/community/communityService'
import { CreateDocument } from './CreateDocument'
import { SuccessToast } from 'src/components/Toast'
import { ErrorToast } from 'src/components/Toast/Error'
import Button from 'src/components/_Dsc/Button'
import KineContext from 'src/context/KineContext'
import Tabs from 'src/components/_Dsc/Tabs'

interface Props {
	community_id: string
}

interface FileCommunity {
	id: string
	user_id: string
	html: string
	title: string
	owner_name: string
	description: string
	privacity: string
	is_community: string
	community_id: string
	created_at: string
	updated_at: string
}

let categories = [
	{
		label: `My documents`,
		value: 'my_documents',
	},
	{
		label: 'Shared with me',
		value: 'shared',
	},
]

export const CommunitySharedDocuments: React.FC<Props> = ({ community_id }) => {
	const { user, currentPermissionComunity } = useContext(KineContext)
	const navigate = useNavigate()
	const [data, setData] = useState<any>([])
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [successBuy, setSuccessBuy] = useState(false)
	const [isError, setIsError] = useState(false)
	const [showModal, setShowModal] = useState<boolean>(false)

	const [isSelected, setIsSelected] = useState('my_documents')

	const changeTab = (val: string) => {
		setIsSelected(val)
	}

	const getData = async () => {
		setInFetch(true)
		let req = await communityService.getCommunityDocuments(community_id)
		if (req && req.shared_with_me || req.my_documents) {
			
			setData(req)
		}
		setInFetch(false)
	}

	const redirectToDocument = (id: string) => {
		window.open(`https://shared-doc.kinera.network/document/${id}`)
	}

	const onOpen = () => {
		setShowModal(true)
	}

	React.useEffect(() => {
		getData()
	}, [])

	return (
		<div className="bg-white p-4 flex flex-col gap-2">
			<div className="flex items-center gap-2 w-full justify-end">
				{user && user.address && currentPermissionComunity ? (
					<Button icon="add" value="Projects" size="small" onClick={onOpen} />
				) : (
					''
				)}
			</div>
			{successBuy ? <SuccessToast message="Document Create Success" /> : ''}
			{isError ? <ErrorToast message="Transaction error, review the information and try again" /> : ''}
			{showModal ? (
				<CreateDocument
					setSuccessBuy={setSuccessBuy}
					setIsError={setIsError}
					showModal={showModal}
					setShowModal={setShowModal}
					community_id={community_id}
					getData={getData}
				/>
			) : (
				''
			)}
			{inFetch ? (
				<Loading />
			) : data && data.my_documents || data.shared_with_me ? (
				<div className="flex flex-col gap-1">
					<Tabs onChange={changeTab} options={categories} value={isSelected}></Tabs>
					{
						isSelected === 'my_documents'
						? data && data.my_documents && data.my_documents.length ?
						data.my_documents.map((val: FileCommunity, key: number) => (
							<div
								key={key}
								onClick={() => {
									redirectToDocument(val.id)
								}}
								className="hover:bg-gray-200  cursor-pointer bg-white rounded-[4px] border border-[#ededed] p-2 flex items-center justify-between gap-2">
								<TextStyleBodyDefault>Document: {val.title}</TextStyleBodyDefault>
	
								<div className="flex items-center gap-2">
									<TextStyleBodyDefault>{val.owner_name}</TextStyleBodyDefault>
									<InitialSimbol profile={{ img: undefined, name: val.owner_name }} />
								</div>
							</div>
						))
						: <TextStyleCaption>You do not have documents in this community yet</TextStyleCaption>
						:  data && data.shared_with_me && data.shared_with_me.length ?
						data.shared_with_me.map((val: FileCommunity, key: number) => (
							<div
								key={key}
								onClick={() => {
									redirectToDocument(val.id)
								}}
								className="hover:bg-gray-200  cursor-pointer bg-white rounded-[4px] border border-[#ededed] p-2 flex items-center justify-between gap-2">
								<TextStyleBodyDefault>Document: {val.title}</TextStyleBodyDefault>
	
								<div className="flex items-center gap-2">
									<TextStyleBodyDefault>{val.owner_name}</TextStyleBodyDefault>
									<InitialSimbol profile={{ img: undefined, name: val.owner_name }} />
								</div>
							</div>
						))
						: <TextStyleCaption>No documents shared with you</TextStyleCaption>
					}
				</div>
			) : (
				<TextStyleBodyDefaultMedium>There are no files in this community at the moment</TextStyleBodyDefaultMedium>
			)}
		</div>
	)
}
