import { Skeleton } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import InputText from 'src/components/_Dsc/InputText'
import { TextStyleBodyDefault, TextStyleBodyDefaultLarge, TextStyleCaption } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import { formatDate } from 'src/constants/functions'
import KineContext from 'src/context/KineContext'
import { createUserPrompt, getAllUsersPrompt } from 'src/service/profile/profileService'
import { insertUserFile } from 'src/service/storage/Ipfs'

interface Props {
	communityId?: string
}

export interface Prompt {
	community_id: string
	created_at: string
	id: string
	owner_name: string
	prompt_text: string
	type: string
	updated_at: string
	user_id: number
}

export const MovieGenerate: React.FC<Props> = ({ communityId }) => {
	const { user } = React.useContext(KineContext)
	const [promptText, setPromptText] = useState('')
	const [image, setImage] = useState<File | null>(null)
	const [prompts, setPrompts] = useState<Prompt[]>([])
	const [imageUrl, setImageUrl] = useState<string>('')
	const [videoUrl, setVideoUrl] = useState<string | null>(null)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [inFetchPrompt, setInFetchPrompt] = useState<boolean>(false)
	const [inFetchPrompts, setInFetchPrompts] = useState(false)

	const fileInputRef = React.useRef<HTMLInputElement | null>(null)

	const enchamntmentPrompt = async () => {
		try {
			setPromptText('')
			setInFetchPrompt(true)
			const response = await axios.post(
				'https://open-gpt.kinera.network/api/chat/completions',
				{
					model: 'llama3.1:latest',
					messages: [
						{
							role: 'user',
							content: promptText
								? `enchantment prompt to generate video max char: 512 ${promptText}. Just give me the prompt without any additional response.`
								: 'enchantment prompt to generate video max char: 512. Just give me the prompt without any additional response.',
						},
					],
					files: [],
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)
			setPromptText(response.data.choices[0].message.content)
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setInFetchPrompt(false)
		}
	}

	const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			setImage(e.target.files[0])
		}
	}

	const handleImageUrl = (e: string) => {
		setImageUrl(e)
	}

	const handleUploadImage = async () => {
		setInFetch(true)
		let req = await insertUserFile(image, user.address, 'img')
		if (!req || !req.success) {
			setInFetch(false)
			return
		} else return req.url
	}

	const handleGenerateVideo = async () => {
		if (!promptText || (!image && !imageUrl)) {
			alert('Please provide both a prompt and an image.')
			return
		}
		setInFetch(true)
		setVideoUrl('')
		let imageUrlC = ''
		if (image && !imageUrl) {
			imageUrlC = await handleUploadImage()
			await onCreatePrompt()
		} else {
			imageUrlC = imageUrl
		}
		let payload = {
			image: imageUrlC,
			promptText: promptText,
		}

		try {
			const response = await axios.post('https://p2p-chat.kinera.network/generate-video', payload)
			if (response && response.data && response.data.videoUrl) {
				setVideoUrl(response.data.videoUrl)
				setInFetch(false)
			}
		} catch (error) {
			console.error('Error generating video:', error)
			setInFetch(false)
		}
	}

	const onCreatePrompt = async () => {
		if (!communityId) {
			return
		}
		try {
			let req = await createUserPrompt(promptText, communityId, '2')
			if (req) {
				getAllUserPrompts()
			}
		} catch (error) {
			console.error('Error:', error)
		}
	}

	const getAllUserPrompts = async () => {
		if (!communityId) {
			return
		}
		try {
			setInFetchPrompts(true)
			let req = await getAllUsersPrompt('2')
			setPrompts(req)
			setInFetchPrompts(false)
		} catch (error) {
			console.error('Error:', error)
		}
	}

	useEffect(() => {
		setPromptText('')
		setImage(null)
		setImageUrl('')
		setVideoUrl('')
		getAllUserPrompts()
	}, [])

	return (
		<div className="w-full h-full flex flex-col gap-2">
			<div className="flex items-start gap-2">
				<div className="min-w-[50%]">
					{videoUrl && (
						<div className="flex items-center justify-center p-2 w-full">
							<video controls className="mt-4 w-full max-w-md">
								<source src={videoUrl} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</div>
					)}
					{inFetch && (
						<div className="flex items-center justify-center p-2 w-full">
							<Skeleton animation="wave" variant="rectangular" width={410} height={218} />
						</div>
					)}

					<div className="input-style flex flex-col rounded-[4px]">
						<div className="flex flex-col p-2 gap-2 bg-white">
							<div className="flex flex-col gap-1 w-full">
								<label className="block text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">
									Upload file
								</label>
								<Button
									value="Choose File"
									icon="upload_file"
									size="small"
									onClick={() => fileInputRef.current?.click()}
								/>
								{image && (
									<TextStyleCaption>
										Selected: {image.name} ({(image.size / 1024).toFixed(2)} KB)
									</TextStyleCaption>
								)}
								<input
									id="file_input"
									type="file"
									accept="image/*"
									ref={fileInputRef}
									onChange={handleImageUpload}
									style={{ display: 'none' }}
								/>
							</div>
							<div className="flex flex-col gap-1 w-full">
								<label className="block text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">
									Url file
								</label>
								<InputText value={imageUrl} onChange={handleImageUrl} placeholder="Write your image url" />
							</div>
							<div className="flex flex-col items-end w-full gap-2">
								<textarea
									maxLength={512}
									id="description"
									rows={4}
									value={promptText}
									onChange={e => setPromptText(e.target.value)}
									className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-600"
									placeholder="Start typing"
								></textarea>
								<div className="flex items-center gap-2">
									<Button
										value="Generate"
										icon="animated_images"
										size="small"
										onClick={handleGenerateVideo}
										disabled={!promptText || (!image && !imageUrl) || inFetch || inFetchPrompt}
									/>
								</div>
							</div>
						</div>
						<div className="mt-2 bg-[#ececec] pl-2">
							<TextStyleBodyDefaultLarge className="text-sm mt-2 w-full items-center gap-1 flex font-bold">
								<span className="material-symbols-outlined text-lg">settings</span>
								Engine
							</TextStyleBodyDefaultLarge>
							<div className="engine flex flex-col">
								<p className="flex gap-2 title text-2xl">
									<img
										src="https://framerusercontent.com/images/7Nhoxwn9eWYrqKjEewfXutR90U.png?scale-down-to=1024"
										width={'30px'}
										alt="Comfy Logo"
									/>
									Comfy
								</p>
								<div className="flex items-center gap-2">
									<p className="text-sm">AnimateDiff</p>
									<span className="material-symbols-outlined">movie</span>
								</div>
								<div className="flex items-center gap-2">
									<p className="text-sm">CogVideoX</p>
									<span className="material-symbols-outlined">movie</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="min-w-[50%] max-h-[500px] overflow-y-auto flex flex-col gap-2 p-[5px]">
					{!inFetchPrompts ? (
						prompts && prompts.length > 0 ? (
							prompts.map((prompt: Prompt, key: number) => (
								<div key={key} className="p-2 bg-[#ececec] rounded-[4px]">
									<div className="w-full flex items-center justify-between">
										<TextStyleBodyDefault>@{prompt.owner_name}</TextStyleBodyDefault>
										<TextStyleCaption>{formatDate(prompt.created_at)}</TextStyleCaption>
									</div>
									<TextStyleCaption>{prompt.prompt_text}</TextStyleCaption>
								</div>
							))
						) : (
							''
						)
					) : (
						<Loading />
					)}
				</div>
			</div>
		</div>
	)
}
