import * as React from 'react'

import { Loading } from '../../loading'
import { Link } from 'react-router-dom'

import VideoPlayer from '../../movie'

interface External {
	fetch: any
	fetchInser: string
	insertMovieInFestival: any
	data: any
}

const InternalUploadMovie: React.FC<External> = ({ fetch, fetchInser, insertMovieInFestival, data }) => {
	return (
		<>
			<div>
				{!fetch && data ? (
					<div className="flex flex-wrap items-center">
						{data && data.length ? (
							data?.map((value: any) => (
								<div className="mt-2 p-2 m-2 border-1 max-w-[250px]">
									{value.data.link && value.data.link.includes('youtube') ? (
										<VideoPlayer link={value.data.link} />
									) : (
										<video src={value.data.link} className="w-full h-full" controls />
									)}
									<div className="flex flex-col p-2 gap-2">
										<div className="flex flex-col items-center gap-2">
											{value.data.name}
											<p>Classification: {value.data.classification}</p>
										</div>
										{fetchInser === value.id ? (
											<Loading />
										) : (
											<button
												className="text-xs font-medium p-3 px-5 rounded-lg def-btn text-white ml-2 m-2"
												onClick={() => {
													insertMovieInFestival(value.id, value)
												}}>
												Select Movie{' '}
											</button>
										)}
									</div>
								</div>
							))
						) : (
							<p className="mt-4 h-[400px] w-full flex items-center justify-center">
								No available videos were found, you can insert new videos through your
								<Link to="/profile" className="ml-2 text-[#893cdb] underline">
									profile
								</Link>
							</p>
						)}
					</div>
				) : (
					<Loading />
				)}
			</div>
		</>
	)
}

export default InternalUploadMovie
