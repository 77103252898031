import React, { useState, useRef, useEffect } from 'react'
import { TextStyleBodyDefault, TextStyleCaption } from 'src/components/_Dsc/typography'

interface Option {
    label: string
    value: string
    description?: string
}

interface Props {
    options: Option[]
    disabled?: boolean
    onChange: (value: string) => void
}

export const DropdownCategories: React.FC<Props> = ({ options, onChange, disabled }) => {
    const [currentOption, setCurrentOption] = useState(``)
    const [open, setOpen] = useState<boolean>(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleChange = (option: string) => {
        onChange(option)
        setCurrentOption(option)
        setOpen(false)
    }

    // Fecha o dropdown ao clicar fora
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <div ref={dropdownRef} className="flex flex-col gap-1 rounded-[4px] w-full">
            <div
                className={`bg-white p-2 w-full flex items-center justify-between rounded-[4px] ${open ? `border-[#434ce8] border-2` : ``}`}
                onClick={handleOpen}
            >
                <TextStyleCaption>{currentOption || `Select your category`}</TextStyleCaption>
                {
                    open
                        ? <span className="material-symbols-outlined">
                            arrow_drop_up
                        </span>
                        : <span className="material-symbols-outlined">arrow_drop_down</span>
                }
            </div>

            {open && (
                <div className="relative bg-white rounded-[4px] w-full">
                    <div className="flex flex-col rounded-[4px] gap-2 z-[100] absolute bg-white w-full">
                        {options && options.length
                            ? options.map((val: Option, key: number) => (
                                <div
                                    onClick={() => { !disabled ? handleChange(val.value) : console.log("disabled") }}
                                    className={`p-2 hover:bg-gray-300 cursor-pointer ${currentOption === val.value && `bg-gray-300`}`}
                                    key={key}
                                >
                                    <TextStyleCaption>{val.label}</TextStyleCaption>
                                    {
                                        val.description 
                                        ? <TextStyleCaption>{val.description}</TextStyleCaption>
                                        : ''
                                    }
                                </div>
                            ))
                            : ''}
                    </div>
                </div>
            )}
        </div>
    )
}
