import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import UserAvatar from 'src/components/_Dsc/Avatar'
import Button from 'src/components/_Dsc/Button'
import IconButton from 'src/components/_Dsc/IconButton'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import { TextStyleBodyDefaultLarge } from 'src/components/_Dsc/typography'
import { MessageModal } from 'src/pages/social/components/feed/components/post/components/MessageModal'

export interface UserVoice {
	id: string
	name: string
	img: string
}

interface Props {
	user: UserVoice
	tab: string
	setOnlineMembersVoice: (voice: UserVoice[]) => void
	setIsSelectedTimeline: (param: string) => void
}

const VoiceChannel: React.FC<Props> = ({ user, tab, setOnlineMembersVoice, setIsSelectedTimeline }) => {
	const [users, setUsers] = useState<UserVoice[]>([])
	const [mutedUsers, setMutedUsers] = useState<Set<string>>(new Set()) // IDs dos usuários mutados
	const [socket, setSocket] = useState<WebSocket | null>(null)
	const [isMicEnabled, setIsMicEnabled] = useState(false)
	const [audioStream, setAudioStream] = useState<MediaStream | null>(null)
	const [audioProcessor, setAudioProcessor] = useState<ScriptProcessorNode | null>(null)
	const [modalMessage, setModalMessage] = React.useState(false)
	const [selectedPeerId, setSelectedPeerId] = React.useState(0)
	const onSendPrivateMessage = (peer: string) => {
		setSelectedPeerId(parseInt(peer))
		setModalMessage(true)
	}
	useEffect(() => {
		const communityId = window.location.pathname.split('/').pop() // Obtém o ID da comunidade da URL
		const ws = new WebSocket(`wss://p2p-voice.kinera.network/ws/voice?communityId=${communityId}`)

		ws.onopen = () => {
			console.log('Conectado ao WebSocket')
			ws.send(
				JSON.stringify({
					type: 'join',
					user,
					communityId,
				}),
			)
		}

		ws.onmessage = event => {
			const data = JSON.parse(event.data)

			if (data.type === 'user-list') {
				setUsers(data.users)
				setOnlineMembersVoice(data.users)
			}

			if (data.type === 'audio' && !mutedUsers.has(data.sender)) {
				playAudio(data.audio)
			}
		}

		ws.onclose = () => {
			console.log('Conexão encerrada')
		}

		setSocket(ws)

		return () => {
			ws.close()
		}
	}, [user, mutedUsers])

	const toggleMic = async () => {
		if (!isMicEnabled) {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
				const audioContext = new AudioContext()
				const source = audioContext.createMediaStreamSource(stream)
				const processor = audioContext.createScriptProcessor(2048, 1, 1)

				source.connect(processor)
				processor.connect(audioContext.destination)

				processor.onaudioprocess = event => {
					if (socket && socket.readyState === WebSocket.OPEN) {
						const audioData = event.inputBuffer.getChannelData(0)
						socket.send(
							JSON.stringify({
								type: 'audio',
								audio: Array.from(audioData),
							}),
						)
					}
				}

				setAudioStream(stream)
				setAudioProcessor(processor)
				setIsMicEnabled(true)
			} catch (error) {
				console.error('Erro ao habilitar o microfone:', error)
			}
		} else {
			// Desativa o microfone
			if (audioProcessor) {
				audioProcessor.disconnect()
			}

			if (audioStream) {
				audioStream.getTracks().forEach(track => track.stop())
			}

			setAudioProcessor(null)
			setAudioStream(null)
			setIsMicEnabled(false)
		}
	}

	const toggleMute = (userId: string) => {
		setMutedUsers(prevMutedUsers => {
			const newMutedUsers = new Set(prevMutedUsers)
			if (newMutedUsers.has(userId)) {
				newMutedUsers.delete(userId) // Desmuta
			} else {
				newMutedUsers.add(userId) // Muta
			}
			return newMutedUsers
		})
	}

	const playAudio = (audioData: number[]) => {
		const audioContext = new AudioContext()
		const buffer = audioContext.createBuffer(1, audioData.length, audioContext.sampleRate)
		const channelData = buffer.getChannelData(0)

		channelData.set(audioData)

		const source = audioContext.createBufferSource()
		source.buffer = buffer
		source.connect(audioContext.destination)
		source.start()
	}

	return (
		<div>
			{modalMessage ? (
				<MessageModal setModalMessage={setModalMessage} modalMessage={modalMessage} tweet={selectedPeerId} />
			) : (
				''
			)}
			<div className="flex flex-col gap-4">
				<div className='flex items-center gap-2'>
				{isMicEnabled ? (
					<Button onClick={() => toggleMic()} value="Disable microphone" icon="mic_off" size="small" />
				) : (
					<Button onClick={() => toggleMic()} value="Active microphone" icon="mic" size="small" />
				)}
				<Button  disabled value="Share screen" icon="present_to_all" size="small" />
				<Button type='critical'  onClick={() => {setIsSelectedTimeline('posts')}} value="Exit" icon="close" size="small" />
				</div>
				{
					tab === 'Posts'
					? <div>
					<TextStyleBodyDefaultLarge>Connected users</TextStyleBodyDefaultLarge>
					{users.map((u, index) => (
						<div className="flex items-center gap-2" key={index}>
							<InitialSimbol profile={{ img: u.img, name: u.name }} />
							<strong>{u.name}</strong>
							{isMicEnabled ? (
								<Button type="subtle" iconOnly value="Mic" icon="mic" size="small" />
							) : (
								<Button type="subtle" iconOnly value="Mic" icon="mic_off" size="small" />
							)}
							{u.id != user.id && (
								<div className="flex items-center gap-2">
									<Tooltip title="Private message" className="bg-gray-100">
										<div>
											<Button
												value="forum"
												iconOnly={true}
												icon="forum"
												size="small"
												type="subtle"
												onClick={() => {
													onSendPrivateMessage(u.id)
												}}
											/>
										</div>
									</Tooltip>
									{mutedUsers.has(u.id) ? (
										<Button onClick={() => toggleMute(u.id)} iconOnly value="Mute" icon="volume_off" size="small" />
									) : (
										<Button onClick={() => toggleMute(u.id)} iconOnly value="Mute" icon="volume_up" size="small" />
									)}
								</div>
							)}
						</div>
					))}
				</div>
					: ''
				}
				
			</div>
		</div>
	)
}

export default VoiceChannel
