import React from 'react'
import ActionButton from 'src/components/_Dsc/_button/Action'
import Button from 'src/components/_Dsc/Button'
import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'

import BlockCounterEnd from 'src/pages/details/components/EndedTime'
import { FestivalService } from 'src/service/festival/festivalService'
import { dailyReward, getCurrentBlockReward, getReward } from 'src/service/profile/profileService'
import Swal from 'sweetalert2'

const styleCounterEnd = {
	fontSize: '14px',
}

const DailyReward: React.FC = () => {
	const { user, apiState, color } = React.useContext(KineContext)
	const [currentData, setCurrentData] = React.useState<any>([])
	const [counter, setCounter] = React.useState('')
	const [currentBlock, setCurrentBlock] = React.useState('')
	const [fetch, setFetch] = React.useState(true)
	const [inReward, setInReward] = React.useState<boolean>(false)

	const rewardTokens = async () => {
		await dailyReward(user, apiState)
		setInReward(false)
	}

	const getCurrentDataReward = async () => {
		setFetch(true)
		let block = await FestivalService.getCurrentAccBlock(apiState)
		setCurrentBlock(block)
		let req = await getCurrentBlockReward(user)
		if (req && req.data) {
			setCurrentData(req.data)
		}
		setFetch(false)
	}

	const verifyUpdate = async () => {
		//setFetch(true)
		let block = await FestivalService.getCurrentAccBlock(apiState)
		setCounter(block)

		//setFetch(false)
	}

	const getCurrentData = async () => {
		setInReward(true)
		let req = await FestivalService.getCurrentAccBlock(apiState)

		if (req) {
			let param = await getReward(user, req)
			if (param && param.success) {
				await rewardTokens()
			} else {
				setInReward(false)
				Swal.fire({
					icon: 'info',
					title: 'Its not time yet...',
					text: 'You must wait until the necessary time to make a new redemption',
					footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
					willClose: () => {
						//window.location.reload()
					},
				})
			}
		}
	}

	React.useEffect(() => {
		getCurrentDataReward()
		if (apiState) {
			const interval = setInterval(() => {
				verifyUpdate()
			}, 2000)
			return () => clearInterval(interval)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])
	return (
		<div className="w-full mt-2">
			{!fetch && currentData && currentBlock ? (
				<div className="bg-white  w-full">
					<TextStyleBodyDefaultMedium>
						Collect your daily tokens for interaction on the platform
					</TextStyleBodyDefaultMedium>
					{currentData &&
					currentBlock &&
					currentData.next_receive &&
					parseInt(currentData.next_receive.replace(/,/g, '')) > parseInt(counter.replace(/,/g, '')) ? (
						<div
							className={`mt-2 flex items-center  w-fit bg-[#0053e4] p-2 text-white text-bold font-bold gap-2 flex `}
						>
							<TextStyleBodyDefaultMedium>Next free reward:</TextStyleBodyDefaultMedium>
							<BlockCounterEnd
								currentSelectedMovie={true}
								data={currentData?.next_receive}
								sx={styleCounterEnd}
								currentBlockNumber={currentBlock}
							/>
						</div>
					) : (
						<div className="mt-2  w-fit">
							{inReward ? <Loading /> : <Button value="Claim" onClick={getCurrentData} />}
						</div>
					)}
				</div>
			) : (
				<Loading />
			)}
		</div>
	)
}

export default DailyReward
