import React, { useEffect, useRef, useState } from 'react'
import { TextStyleBodyDefault, TextStyleBodyDefaultMedium, TextStyleCaption } from '../typography'
import Button from '../Button'
import KineContext from 'src/context/KineContext'
import { getAllUserChats } from 'src/service/messenger'
import { Chat } from 'src/pages/chat'
import InitialSimbol from '../InitialSimbol'
import Modal from '../Modal'
import { SingleChat } from './components/SingleChat'
import { Loading } from 'src/components/loading'

export const Chats: React.FC = () => {
	const { user } = React.useContext(KineContext)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [isExpand, setIsExpand] = useState<boolean>(false)
	const [selectedPeer, setSelectedPeer] = useState<number>(0)
	const [chats, setChats] = useState<Chat[]>([])
	const containerRef = useRef<HTMLDivElement | null>(null)

	const getCurrentUserChats = async () => {
		setInFetch(true)
		let req = await getAllUserChats(user.id)
		setChats(req)
		setInFetch(false)
	}

	const formatDate = (dateString: string) => {
		const date = new Date(dateString)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
		const year = date.getFullYear()
		const time = date.toTimeString().split(' ')[0] // Get the time portion only (HH:MM:SS)

		return `${day}/${month}/${year} - ${time}`
	}

	const handleChange = () => {
		setIsExpand(!isExpand)
	}

	const handleChangeChat = (peer: number) => {
		setSelectedPeer(peer)
		setShowModal(true)
	}

	const onCloseModal = () => {
		setShowModal(false)
	}

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
				setIsExpand(false) // Fecha o menu quando clicar fora
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		if (user) {
			getCurrentUserChats()
		}
	}, [user])
	return (
		<div ref={containerRef} className="fixed bottom-0 right-2">
			<Modal onCancel={onCloseModal} onClose={onCloseModal} title="Chat" open={showModal}>
				<SingleChat id={selectedPeer} handle={showModal} />
			</Modal>
			<div onClick={handleChange} className="p-2 rounded-[4px] max-w-72 bg-white flex gap-2 hover:bg-gray-200">
				<div className="flex items-center justify-between w-full hover:cursor-pointer">
					<TextStyleBodyDefault>Messages</TextStyleBodyDefault>
					<Button
						value={isExpand ? 'Hidden' : 'Expand'}
						icon={isExpand ? 'unfold_less' : 'expand_content'}
						size="small"
						type="subtle"
					/>
				</div>
			</div>
			{isExpand ? (
				inFetch ? (
					<Loading />
				) : chats && chats.length ? (
					chats.map((chat, key: number) => (
						<div
							key={key}
							onClick={() => {
								handleChangeChat(chat.id)
							}}
							className="flex items-center gap-4 justify-between bg-[#ffff] p-2 rounded-[4px] cursor-pointer hover:opacity-70"
						>
							<div className="flex items-center gap-4">
								<InitialSimbol
									profile={{
										img: undefined,
										name: chat.peer_name === user.name ? chat.owner_name : chat.peer_name,
									}}
								/>

								<div className="flex flex-col gap-1">
									<TextStyleBodyDefaultMedium>
										{chat.peer_name === user.name ? chat.owner_name : chat.peer_name}
									</TextStyleBodyDefaultMedium>
									<TextStyleCaption>{formatDate(chat.created_at)}</TextStyleCaption>
								</div>
							</div>
						</div>
					))
				) : (
					<TextStyleCaption>No conversations available at the moment</TextStyleCaption>
				)
			) : (
				''
			)}
		</div>
	)
}
