import React, { useEffect } from 'react'
import { FestivalService } from '../../service/festival/festivalService'

import { Link, useNavigate } from 'react-router-dom'
import Card from '../../components/card/Card'
import './style.scss'
import { Loading } from '../../components/loading'

import KineContext from '../../context/KineContext'
import { translate } from '../../context/Translate'
import Dropdown from 'src/components/dropdown'
import DscBadge from 'src/components/_Dsc/badge'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import Button from 'src/components/_Dsc/Button'
import { TextStyleBodyDefault, TextStyleCaption, TextStyleH1 } from 'src/components/_Dsc/typography'

const Festivals: React.FC = () => {
	const path = [
		{ label: 'Home', link: '/' },
		{ label: 'Constellations', link: '' },
	]
	const { user, color, apiState, profile, currentLangues } = React.useContext(KineContext)
	const [data, setData] = React.useState<any>([])
	const [fetch, setFetch] = React.useState<any>([])
	const navigate = useNavigate()

	const [currentBlockNumber, setCurrentBlockNumber] = React.useState('')
	const [showTemp, setShowTemp] = React.useState<any | null>(null)
	const [selected, setSelected] = React.useState<any | null>('All')
	const [temp, setTemp] = React.useState<any | null>(null)
	const [accLabel, setAccLabel] = React.useState<any | null>('All')
	const [tags, setTags] = React.useState<any | null>(null)
	const [accCategory, setAccCategory] = React.useState<any | null>(null)

	const getAllFestivals = async () => {
		try {
			setFetch(true)
			//const getApoloRankingsPromise = RankingService.getRankingsByApolo();
			const festivalsPromise = FestivalService.getFestivals(false, apiState)

			const Tags = FestivalService.getAllTags(apiState)
			const [festivals, tags] = await Promise.all([festivalsPromise, Tags])

			setTags(tags)

			setData(festivals)

			setFetch(false)
		} catch (error) {
			console.error(error)
		}
	}

	const currentBlock = async () => {
		let req = await FestivalService.getCurrentAccBlock(apiState)
		setCurrentBlockNumber(req)
	}

	const updatedCategory = (category: string) => {
		if (category === 'All') {
			setAccCategory(tags)
			setTemp(data)
			setSelected(null)
			setAccLabel(category)
		} else {
			const acc = tags.filter((val: any) => val.key === category || val.param === category)

			setAccLabel(category)
			setAccCategory(acc[0])
			return accCategory
		}
	}

	const selectedFilter = (filter: any) => {
		setSelected(filter)

		if (filter === 'Festival' || filter === 'Festivals' || filter.key === 'All') {
			setTemp(data)
			setShowTemp(false)
			return true
		} else {
			const filtered = data.filter((val: any) => {
				if (val.categoriesAndTags) {
					return val.categoriesAndTags.some((param: any) => param.includes(filter))
				}
				return false
			})

			setTemp(filtered)
			setShowTemp(true)
			return filtered
		}
	}

	useEffect(() => {
		if (apiState) {
			currentBlock()
			getAllFestivals()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])

	return (
		<div className="max-app page-padding flex flex-col ">
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer>
					<div className="flex flex-col ">
						<div className="flex items-center justify-between w-full">
							<TextStyleH1>Constellations</TextStyleH1>
							{user && user.address && profile && (
								<Button
									size="small"
									onClick={() => {
										navigate('/create-Constellations')
									}}
									value={translate('CREATE_CONSTELLATION', currentLangues.value)}
								></Button>
							)}
						</div>
						<div className="flex  justify-between">
							<div>
								<TextStyleBodyDefault>{translate('CREATE_FESTIVAL_TEXT', currentLangues.value)}</TextStyleBodyDefault>
								<TextStyleCaption>{translate('FESTIVAL_DETAILS_TEXT', currentLangues.value)}</TextStyleCaption>
							</div>
						</div>
					</div>

					<div className="content flex flex-col bg-white p-4 rounded-[4px] mt-4">
						<div className="flex flex-col gap-4">
							{!fetch && (
								<>
									<div className="flex items-center gap-3 first ">
										<Dropdown props={tags} updatedCategory={updatedCategory} selected={accLabel} />
									</div>
									{accLabel && accLabel !== 'All' && (
										<div className="flex items-center gap-3 tags-container">
											{accCategory &&
												accCategory.value &&
												accCategory.value.map((val: any) => (
													<DscBadge isSelected={val === selected}>
														<button
															onClick={e => {
																selectedFilter(val)
															}}
														>
															{val}
														</button>
													</DscBadge>
												))}
										</div>
									)}
								</>
							)}
							<div className="vertical-tabs w-full">
								{!user ||
									(!profile && (
										<Link
											className={`p-2 rounded-[4px] bg-[${color.action}] w-full flex gap-4 items-center`}
											role="button"
											to="/register-profile"
										>
											<svg
												className="w-6 h-6 text-white"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
											>
												<path
													stroke="currentColor"
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M12 7.8v8.4M7.8 12h8.4m4.8 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
												/>
											</svg>
											<span className={`text-[${color.text}] flex items-center justify-center`}>
												{translate('CREATE_SOCIAL_PROFILE_TO_CREATE_CONSTELLATION', currentLangues.value)}
											</span>
										</Link>
									))}
							</div>
						</div>
						<div className="content-main w-full">
							<div className="flex  items-center grow gap-4 wrap flex-wrap">
								{showTemp
									? temp?.map(
											(value: any, i: number) => i < 6 && <Card data={value} currentBlockNumber={currentBlockNumber} />,
									  )
									: data &&
									  data.length > 0 &&
									  data?.map(
											(value: any, i: number) => i < 4 && <Card data={value} currentBlockNumber={currentBlockNumber} />,
									  )}
								{!data && <Loading />}
							</div>
						</div>
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default Festivals
