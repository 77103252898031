import React, { useEffect, useRef } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import '../styles.scss'

const AnyToAny: React.FC = () => {
	const path = [
		{ label: 'Produtions Tools', link: '/custom-tools' },
		{ label: 'Any to Any', link: '' },
	]

	return (
		<div className="page-padding h-full any-to-any">
			<Breadcrumb routes={path} />
			<CardInner className="h-full">
				<CardContainer className="h-full">
					<iframe src="https://anytoany.omegatron.ai/" className="h-full"></iframe>
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default AnyToAny
