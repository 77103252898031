import React from 'react'
import { IKineraPost } from '../../../interface'
import RichTextEditor from 'src/pages/social/components/createTweet/RichText/RichText'

import KineContext from 'src/context/KineContext'
import { useNavigate } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'

interface Props {
	tweet: IKineraPost
	content: any
	isComment: any
	isProfile: any
	setContent: (param: string) => void
	setIsWriteComment: any
	commentOnPostById: (param: string) => void
	id: string
}

const WriteComment: React.FC<Props> = ({
	tweet,
	isProfile,
	isComment,
	setContent,
	content,
	setIsWriteComment,
	commentOnPostById,
	id,
}) => {
	const { user, profile } = React.useContext(KineContext)
	const navigate = useNavigate()
	return (
		<div className="flex gap-2 items-start w-full mt-2">
			<img
				onClick={() => {
					navigate('/social/view/' + tweet.address)
				}}
				src={
					profile && profile.img
						? profile.img
						: 'https://media.istockphoto.com/id/522855255/vector/male-profile-flat-blue-simple-icon-with-long-shadow.jpg?s=612x612&w=0&k=20&c=EQa9pV1fZEGfGCW_aEK5X_Gyob8YuRcOYCYZeuBzztM='
				}
				alt="#"
				className="avatar cursor-pointer mr-2 hover:opacity-70 opacity-0"
			/>

			<div className="w-full h-full flex flex-col p-2 rounded-[8px] bg-white">
				<RichTextEditor onchange={setContent} value={content} />

				<div className="flex w-full items-end justify-end">
					{user && user.address && isProfile && !isComment && (
						<div className="flex w-full items-center justify-end mt-2 gap-2">
							<div>
								<Button
									onClick={() => {
										setIsWriteComment('')
									}}
									value="Cancel"
									type="subtle"
									size="small"
								/>
							</div>
							{content.length > 1 ? (
								<Button
									size="small"
									value="Comment"
									onClick={() => {
										commentOnPostById(id)
									}}
								></Button>
							) : (
								<button>Post </button>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default WriteComment
