import React from 'react'
import { useNavigate } from 'react-router-dom'
import './style.scss'
import Tooltip from '@mui/material/Tooltip'
import { Festival } from '../../interface'

import KineContext from '../../context/KineContext'
import TextH3 from '../_Dsc/typography/TextH3'
import BlockCounter from '../../pages/details/components/Counter'
import { FestivalService } from 'src/service/festival/festivalService'
import { planksToTokens } from 'src/service/substrate/formated'
import { TextStyleCaption } from '../_Dsc/typography'
import { decodeHex } from 'src/constants/functions'

const Card: React.FC<{
	data: Festival
	movie?: boolean
	action?: any
	small?: any
	currentBlockNumber?: string
	removeLock?: boolean
}> = ({ data, currentBlockNumber, removeLock }) => {
	const { color, apiState } = React.useContext(KineContext)
	const navigate = useNavigate()
	const redirect = (id: any) => {
		navigate('/details/' + id)
	}
	const [currentBlockNumberState, setCurrentBlockNumberState] = React.useState('')
	const [fetch, setFetch] = React.useState(true)


	const currentBlock = async () => {
		setFetch(true)
		let req = await FestivalService.getCurrentAccBlock(apiState)
		setCurrentBlockNumberState(req)
		setFetch(false)
	}

	
	const styleCounter = {
		fontSize: '16px',
	}

	React.useEffect(() => {
		currentBlock()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<div
			className={` rounded-[4px] card-movie-bg  flex flex-col justify-between  cursor-pointer cards-hover grow flex `}
			onClick={() => {
				redirect(data.id)
			}}>
			<div className="ml-2">
				<div className={` flex flex-col  w-full  text-[${color.text}]`}>
					<h3 className="truncate w-full flex items-center  font-bold ">{decodeHex(data.name)}</h3>
				</div>
				<div className="flex flex-col gap-1 justify-between  ">
					<TextH3>{decodeHex(data.description)}</TextH3>
					<div className="flex items-center justify-between">
						<div className="flex items-center gap-1 hover:bg-gray-100 p-1 rounded-[4px]">
							<span className="material-symbols-outlined">account_circle</span>
							<TextH3>{data.ownerId}</TextH3>
						</div>
						<TextH3>
							Status: {data.status === 'AwaitingActivation' ? 'Pending' : data.status === 'Active' ? 'Active' : 'End'}
						</TextH3>
					</div>
					{!fetch && !removeLock && (
						<div className="-ml-[2px]">
							<BlockCounter
								data={data.blockStartEnd}
								sx={styleCounter}
								currentBlockNumber={currentBlockNumber ? currentBlockNumber : currentBlockNumberState}
							/>
						</div>
					)}

					{data.categoriesAndTags && (
						<div className="flex items-center gap-2  tags justify-between mr-4">
							{data?.categoriesAndTags?.map((value: string, i: number) => (
								<div className="font-bold" key={i}>
									<TextStyleCaption>#{value[1]}</TextStyleCaption>
								</div>
							))}
							{data && data.totalLockup && (
								<Tooltip title="Kinex Token" placement="right-start">
									<div className="font-bold flex items-center gap-1">
										<TextStyleCaption>{planksToTokens(parseInt(data.totalLockup))} Kinex</TextStyleCaption>
									</div>
								</Tooltip>
							)}
						</div>
					)}
					{!data.categoriesAndTags ||
						(!data.categoriesAndTags.length && (
							<div className="flex items-center gap-2 ml-2 tags invisible">
								<div className="tag">''</div>
							</div>
						))}
				</div>
			</div>
		</div>
	)
}

export default Card
