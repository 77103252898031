import React from 'react'

interface Props {
	text: string
	size: string
}

export const TextBold: React.FC<Props> = ({ text, size }) => {
	return (
		<p className={` font-bold`} style={{ fontSize: `${size}px` }}>
			{text}
		</p>
	)
}
