import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefault, TextStyleCaption } from 'src/components/_Dsc/typography'
import TextH3 from 'src/components/_Dsc/typography/TextH3'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { FestivalService } from 'src/service/festival/festivalService'
import { planksToTokens } from 'src/service/substrate/formated'

const MyVotedFromConstellations: React.FC = () => {
	const { apiState, user } = useContext(KineContext)
	const [festivalSummary, setFestivalSummary] = useState([] as any)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const navigate = useNavigate()

	const getAllData = async () => {
    console.log('entrei')
		try {
			setInFetch(true)
			let req = await FestivalService.getUserFestivalWalletData(user, apiState)

			if (req && req.activeVotedFestivals) {
				const uniqueFestivals = Array.from(new Set(req.activeVotedFestivals))

				const festivalData: any[] = []

				for (const festivalId of uniqueFestivals) {
					const festival = await FestivalService.getFestivalsById(festivalId, apiState)

					let votos = 0
					let totalLockup = '0'

					for (const [movieUrl, votes] of Object.entries(festival.voteMap)) {
						for (const vote of votes as any[]) {
							if (vote.voter === user.address) {
								votos += 1
								totalLockup = totalLockup === '0' ? vote.amount : `${totalLockup} + ${vote.amount}`
							}
						}
					}

					if (votos > 0) {
						festivalData.push({
							id: festival.id,
							title: festival.name,
							description: festival.description,
							votos: votos,
							totalLockup: totalLockup,
						})
					}
				}

				setFestivalSummary(festivalData)
				setInFetch(false)
			}
			setInFetch(false)
		} catch (error) {
			setInFetch(false)
			console.error(error)
		}
	}

	const goToFestival = (id: string) => {
		window.open(`details/` + id, '_blank')
	}

	useEffect(() => {
		if (apiState) {
			getAllData()
		}
		if (!user || !user.address) {
			navigate('/')
		}
	}, [apiState, user])

	return (
		<CardInner className="mt-4">
			<CardContainer>
				{inFetch ? (
					<Loading />
				) : (
					<div className="flex flex-col gap-2 ">
						{festivalSummary && festivalSummary.length ? (
							festivalSummary.map((festival: any, index: number) => (
								<div
									className="hover:bg-gray-200 p-2 hover:cursor-pointer"
									key={index}
									onClick={() => {
										goToFestival(festival.id)
									}}>
									<TextH3>Festival: {festival.title}</TextH3>
									<TextStyleCaption>Votes: {festival.votos}</TextStyleCaption>
									<TextStyleCaption>
										Total Lockup: {festival.totalLockup ? `${planksToTokens(festival.totalLockup)} Kinex` : ''}
									</TextStyleCaption>
								</div>
							))
						) : (
							<TextStyleBodyDefault>No votes to show</TextStyleBodyDefault>
						)}
					</div>
				)}
			</CardContainer>
		</CardInner>
	)
}

export default MyVotedFromConstellations
