import React, { useEffect, useState } from 'react'

import KineContext from '../../../context/KineContext'
import { NotificationService } from '../../../service/notification/notificationService'

import TextH3 from '../../../components/_Dsc/typography/TextH3'

import FestivalNotification from './festivals'
import SocialNotification from './social'
import DscIcons from '../../../components/_Dsc/icons'
import './style.scss'
import Tabs from 'src/components/_Dsc/Tabs'
import Button from 'src/components/_Dsc/Button'
import { TextStyleH1 } from 'src/components/_Dsc/typography'

interface INotification {
	content: string
	id: number
	status: number
	type: string
	user_id: number
}

const Notifications: React.FC = () => {
	const { user, notifications, setNotifications } = React.useContext(KineContext)
	const [isSelected, setIsSelected] = useState('All')
	const [clone, setClone] = useState<INotification[]>([])
	const updateNotifyUser = async (notify: INotification) => {
		NotificationService.updateNotify(user.address, notify.content, parseInt(notify.type), 1, notify.id)
		let copy = notifications
		copy.forEach((val: INotification, index: number) => {
			if (val === notify) {
				val.status = 1
			}
		})
		setNotifications(copy)
	}

	const removeAllNotify = async () => {
		NotificationService.removeAll(user.address)
		setNotifications([])
	}

	const setFilterNotifications = (val: string) => {
		setIsSelected(val)
		setNotifications(clone)
		if (val === 'Festivals') {
			if (clone && clone.length >= 1) {
				let filtered = clone.filter((val: INotification) => val.type === '1')

				return setNotifications(filtered)
			}
			return
		}
		if (val === 'Social') {
			if (clone && clone.length >= 1) {
				let filtered = clone.filter((val: INotification) => val.type === '2')

				return setNotifications(filtered)
			}
			return
		} else {
			return setNotifications(clone)
		}
	}

	let H3STYLE = {
		color: 'black',
		fontSize: '33px',
	}

	let DELETSTYLE = {
		color: 'black',
		fontSize: '20px',
	}

	const categories = [
		{
			label: 'All',
			value: 'All',
		},
		{
			label: 'Festivals',
			value: 'Festivals',
		},
		{
			label: 'Social',
			value: 'Social',
		},
	]

	useEffect(() => {
		setClone(notifications)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="w-full h-full flex flex-col mb-10 background-notification" id="notify-container">
			<div className="mt-[30px] ml-4 mb-2">
				<TextStyleH1>Notifications</TextStyleH1>
			</div>
			<div className="w-full flex items-center ">
				<Tabs onChange={setFilterNotifications} options={categories} value={isSelected}></Tabs>
			</div>

			{!notifications ||
				(notifications.length < 1 && (
					<div className="flex items-center gap-2 justify-center mt-4">
						<Button value="No notifications at the moment" size="small"></Button>
					</div>
				))}

			<div className="mt-4">
				{notifications &&
					notifications.map((val: INotification, index: number) => (
						<div key={index}>
							{val.type === '1' && (isSelected === 'Festivals' || isSelected === 'All') && (
								<FestivalNotification val={val} updateNotifyUser={updateNotifyUser} />
							)}
							{val.type === '2' && (isSelected === 'Social' || isSelected === 'All') && (
								<SocialNotification val={val} updateNotifyUser={updateNotifyUser} />
							)}
						</div>
					))}
			</div>

			{notifications && notifications.length >= 1 && (
				<div className="w-full flex items-center justify-center p-2 ">
					<Button
						size="small"
						onClick={() => {
							removeAllNotify()
						}}
						value="Clear all notifications"
					></Button>
				</div>
			)}
		</div>
	)
}

export default Notifications
