import React from 'react'
import En from '../translate/en'
import Es from '../translate/es'
import Fr from '../translate/fr'

export const translate = (key: string, lore: string): string => {
	let archive: any = lore === 'en' ? En : lore === 'es' ? Es : Fr

	return archive[key]
}
