import React from 'react'
import { CommunityFile } from '../../Actions/components/GPT/components/Rags'
import { TextStyleBodyDefaultLarge, TextStyleCaption } from 'src/components/_Dsc/typography'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import Button from 'src/components/_Dsc/Button'
import KineContext from 'src/context/KineContext'

interface Props {
	file: CommunityFile
	redirectToDocument: (file: CommunityFile) => void
	removeFile: (file: CommunityFile) => void
}
export const ImageFile: React.FC<Props> = ({ file, redirectToDocument, removeFile }) => {
	const { user } = React.useContext(KineContext)

	return (
		<div className="w-full flex flex-col gap-2">
			<div className="flex flex-col">
				<div className="flex items-center gap-2">
					<InitialSimbol profile={{ img: 'null', name: file.owner_name }} />
					<TextStyleBodyDefaultLarge>{file.owner_name}</TextStyleBodyDefaultLarge>
				</div>
				<TextStyleCaption>{file.custom_name || file.document_name}</TextStyleCaption>
			</div>
			<img className="max-w-[300px]" src={file.url} alt="image" />
			<div className="flex items-end gap-2 w-full justify-end">
				{file.user_address === user.address ? (
					<Button value="Remove" icon="delete" type="critical" size="small" onClick={() => removeFile(file)} />
				) : (
					''
				)}
				<Button value="View" icon="visibility" size="small" onClick={() => redirectToDocument(file)} />
			</div>
		</div>
	)
}
