import API from "../api";

export const addMoreStorage = async (address:string, capacity: number) => {
    let payload = {
        address:address,
        capacity:capacity
    }
   try {
    let req = await API.post(`api/founds/storage`,payload)
  if(req) {
    return req.data
  }else {
    return {}
  }
   }catch(err) {
    console.log(err)
   }
}

export const getTransactions = async (address:string) => {

   try {
    let req = await API.get(`api/founds/transactions/${address}`)
  if(req) {
    return req.data
  }else {
    return {}
  }
   }catch(err) {
    console.log(err)
   }
}