import { Divider } from '@mui/material'
import React, { useContext, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import { DsyLabel } from 'src/components/_Dsc/Button/styles'
import { TextStyleCaption } from 'src/components/_Dsc/typography'
import KineContext from 'src/context/KineContext'
import { models } from 'src/pages/community/components/Actions/components/GPT/components/Models'

interface Props {
	setIsSelected: (selected: string) => void
	agents: { question: string; answer: string }
	setAgents: (agents: { question: string; answer: string }) => void
	selectedResponder: string
	setSelectedResponder: (selected: string) => void
	setSelectedAsker: (selected: string) => void
	selectedAsker: string
	confirmCreateForm: () => void
	disabled: boolean
}

export const RegisterAgents: React.FC<Props> = ({
	setIsSelected,
	agents,
	setAgents,
	selectedResponder,
	setSelectedResponder,
	setSelectedAsker,
	selectedAsker,
	confirmCreateForm,
	disabled,
}) => {
	const { user } = useContext(KineContext)
	const handleResponderSelection = (value: string) => {
		setSelectedResponder(value)
		// Atualiza diretamente o estado de `agents` com o valor selecionado
		setAgents({
			...agents,
			answer: value,
		})
	}

	const handleAskerSelection = (value: string) => {
		setSelectedAsker(value)
		// Atualiza diretamente o estado de `agents` com o valor selecionado
		setAgents({
			...agents,
			question: value,
		})
	}

	return (
		<div className="w-full h-full flex flex-col gap-4">
			<TextStyleCaption>Select 2 agents to start a conversation, you can select from the options:</TextStyleCaption>

			{/* Section: Who will ask */}
			<div className="flex flex-col gap-2">
				<TextStyleCaption>Who will ask:</TextStyleCaption>
				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-1">
						<DsyLabel className="font-[14px] text-[14px]">Models</DsyLabel>
					</div>
					<div className="flex items-center gap-2">
						{models.map((val: { label: string; value: string }, key: number) => (
							<div className="flex items-center" key={key}>
								<input
									type="radio"
									value={val.value}
									name="asker-selection"
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
									onChange={() => handleAskerSelection(val.value)}
									checked={selectedAsker === val.value}
								/>
								<label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{val.label}</label>
							</div>
						))}
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-1">
						<DsyLabel className="font-[14px] text-[14px]">GPT's</DsyLabel>
					</div>
					<div className="flex items-center gap-2">
						{['chat-gpt', 'claude'].map(value => (
							<div className="flex items-center" key={value}>
								<input
									disabled={!user || !user.gpt_api_key || value === 'claude'}
									type="radio"
									value={value}
									name="asker-selection"
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
									onChange={() => handleAskerSelection(value)}
									checked={selectedAsker === value}
								/>
								<label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
									{value === 'chat-gpt' ? 'Chat GPT' : 'Claude AI'}
								</label>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* Section: Who will respond */}
			<div className="flex flex-col gap-2">
				<TextStyleCaption>Who will respond:</TextStyleCaption>
				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-1">
						<DsyLabel className="font-[14px] text-[14px]">Models</DsyLabel>
					</div>
					<div className="flex items-center gap-2">
						{models.map((val: { label: string; value: string }, key: number) => (
							<div className="flex items-center" key={key}>
								<input
									type="radio"
									value={val.value}
									name="responder-selection"
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
									onChange={() => handleResponderSelection(val.value)}
									checked={selectedResponder === val.value}
								/>
								<label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{val.label}</label>
							</div>
						))}
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-1">
						<DsyLabel className="font-[14px] text-[14px]">GPT's</DsyLabel>
					</div>
					<div className="flex items-center gap-2">
						{['chat-gpt', 'claude'].map(value => (
							<div className="flex items-center" key={value}>
								<input
									disabled={!user || !user.gpt_api_key || value === 'claude'}
									type="radio"
									value={value}
									name="responder-selection"
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
									onChange={() => handleResponderSelection(value)}
									checked={selectedResponder === value}
								/>
								<label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
									{value === 'chat-gpt' ? 'Chat GPT' : 'Claude AI'}
								</label>
							</div>
						))}
					</div>
				</div>
			</div>

			<Divider />

			<div className="w-full h-full flex justify-between items-center">
				<Button
					value="Return Prompts"
					type="subtle"
					icon="arrow_back"
					size="small"
					onClick={() => {
						setIsSelected('prompts')
					}}
				/>
				<Button
					disabled={disabled}
					value="Create dialog"
					icon="save_as"
					size="small"
					onClick={() => {
						confirmCreateForm()
					}}
				/>
			</div>
		</div>
	)
}
