import { ApiPromise, WsProvider } from '@polkadot/api'

import jsonrpc from '@polkadot/types/interfaces/jsonrpc'
import { metadata } from './metadata'

let curentConn = false

export async function api() {
	if (!curentConn) {
		curentConn = true
		const isLocal = window.location.host.includes('localhost')
		let url = isLocal ?'wss://dev.kinera.network' : 'wss://node.kinera.network'

		const wsProvider = new WsProvider(url)
		const types = metadata
		const api = await ApiPromise.create({
			provider: wsProvider,
			types,
			rpc: jsonrpc,
		})

		return api
	}
}
