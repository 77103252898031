import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefault, TextStyleCaption, TextStyleH1 } from 'src/components/_Dsc/typography'
import { Character } from '../community/components/Bazzar'
import { getPublicCharacters } from 'src/service/community/communityService'
import { Loading } from 'src/components/loading'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Bazaar Preview', link: '' },
]

export const BazzarPreview: React.FC = () => {
	const [publiCharacters, setPublicCharacters] = useState<Character[]>([])
	const [inFetch, setInFetch] = React.useState<boolean>(true)
	const navigate = useNavigate()
	const getAllCharacters = async () => {
		setInFetch(true)
		let req = await getPublicCharacters()
		if (req && req.data) {
			setPublicCharacters(req.data)
		}
		setInFetch(false)
	}
	useEffect(() => {
		getAllCharacters()
	}, [])
	return (
		<div className="w-full flex flex-col h-full page-padding">
			<Breadcrumb routes={path} />
			<CardInner className="flex flex-col h-full">
				<CardContainer className="gap-2 flex flex-col h-full">
					<div className="w-full flex flex-col h-full">
						<div className="flex flex-col">
							<TextStyleH1>Bazaar Preview</TextStyleH1>
							<TextStyleCaption></TextStyleCaption>
						</div>

						{inFetch ? (
							<Loading />
						) : (
							<div className="flex gap-2 flex-wrap bg-white p-2">
								{
                                    publiCharacters && publiCharacters.length > 0 
                                    ? publiCharacters.map((character: Character, key: number) => (
                                        <div
                                            key={key}
                                            onClick={() => {
                                                navigate(`/project/${character.id}`)
                                            }}
                                            className=" h-[146px] cursor-pointer hover:opacity-60 hover:border hover:border-1 rounded-[4px] text-left bg-gray-50  min-w-[312px] max-w-[312px] truncate overflow-hidden border border-gray-100  rounded-lg flex flex-col justify-between">
                                            <div className="flex items-start gap-2 p-2 w-full h-full w-full truncate">
                                                <img className="fit-image" src={character.img_url} width={100} alt="model" />
                                                <div className="flex flex-col w-full h-full justify-between overflow-hidden truncate">
                                                    <div className="flex flex-col  truncate text-truncate">
                                                        <div className="flex flex-col   font-medium ">
                                                            <TextStyleBodyDefault className='truncate text-truncate overflow-hidden '>{character.name}</TextStyleBodyDefault>
                                                            <TextStyleCaption className="opacity-50">By @{character.owner_name}</TextStyleCaption>
                                                        </div>
    
                                                        <div className="text-xs overflow-hidden  dark:text-gray-400 max-h-14 truncate text-truncate">
                                                            {character.description}
                                                        </div>
                                                    </div>
                                                    <div className="w-full flex items-end justify-end">
                                                        <div className="flex flex-row gap-2">
                                                            <div className="flex flex-row gap-1 items-center">
                                                                <svg
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    width="14px"
                                                                    height="14px"
                                                                    className="text-muted-foreground">
                                                                    <path
                                                                        d="M21.5 12c0-5-3.694-8-9.5-8s-9.5 3-9.5 8c0 1.294.894 3.49 1.037 3.83l.037.092c.098.266.49 1.66-1.074 3.722 2.111 1 4.353-.644 4.353-.644 1.551.815 3.397 1 5.147 1 5.806 0 9.5-3 9.5-8Z"
                                                                        stroke="#000"
                                                                        stroke-linecap="square"
                                                                        stroke-linejoin="round"
                                                                        stroke-width="2"></path>
                                                                </svg>
                                                                <p className="text-sm text-muted-foreground">{character.interactions || 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    : <TextStyleCaption>No projects published in the bazzar at the moment</TextStyleCaption>
                                }
							</div>
						)}
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}
