import React, { createContext, useContext, useEffect } from 'react'
import { io, Socket } from 'socket.io-client'
import KineContext from 'src/context/KineContext'
import { baseSocketUrl } from '../constants'

type SocketContextType = {
	sendMessage: (message: any) => void
	joinChat: (params: { chatId: number | string; userId: number }) => void
	onMessage: (callback: (message: any) => void) => void
}

const SocketContext = createContext<SocketContextType | null>(null)

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
	const { user } = useContext(KineContext)
	const socket: Socket = io(baseSocketUrl) // Servidor WebSocket

	useEffect(() => {
		if (user) {
			socket.emit('register', user.id) // Registra o usuário
		}

		return () => {
			socket.disconnect() // Desconecta ao desmontar o componente
		}
	}, [user])

	const sendMessage = (message: any) => {
		socket.emit('sendMessage', message)
	}

	const joinChat = ({ chatId, userId }: { chatId: number | string; userId: number }) => {
		socket.emit('joinChat', { chatId, userId })
	}

	const onMessage = (callback: (message: any) => void | null) => {
		if (callback) {
			socket.on('receiveMessage', callback)
		} else {
			socket.off('receiveMessage') // Remove o listener ao desmontar
		}
	}

	return <SocketContext.Provider value={{ sendMessage, joinChat, onMessage }}>{children}</SocketContext.Provider>
}

export const useSocket = () => {
	const context = useContext(SocketContext)

	if (!context) {
		throw new Error('useSocket deve ser usado dentro de um SocketProvider')
	}

	return context
}
