import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Aside from './asideMenu'
import KineContext from 'src/context/KineContext'
import SocialSidebar from './social/leftMenu'
import PopularProfiles from './social/popularProfiles'

interface Props extends React.PropsWithChildren {
	isLogin: boolean
	setIsLogin: (value: boolean) => void
}

const MainLayout: React.FC<Props> = ({ children, isLogin, setIsLogin }) => {
	const { showFullMenu, user, setShowFullMenu } = useContext(KineContext)
	const location = useLocation()

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 1100) {
				setShowFullMenu(false)
			} else {
				setShowFullMenu(true)
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [setShowFullMenu])

	const isSocialRoute = location.pathname.includes('social')
	const isCustomToolsRoute = location.pathname.includes('custom-tools')

	return (
		<div className={isLogin ? 'opacity-10 w-full h-full flex' : 'flex w-full justify-center gap-2'}>
			{!isCustomToolsRoute && (
				<div className={showFullMenu ? 'min-w-[278px] w-[278px]' : 'min-w-[80px]'}>
					<Aside />
				</div>
			)}

			{isSocialRoute ? (
				<div className="flex w-full gap-2 h-full page-padding max-w-[1350px]">
					{/**<SocialSidebar setIsLogin={setIsLogin} /> */}
					<div className="flex-grow  w-full">{children}</div>
					<div className="flex flex-grow grow w-full min-w-[350px] max-w-[350px] profiles-container">
						<PopularProfiles />
					</div>
				</div>
			) : (
				<>{children}</>
			)}
		</div>
	)
}

export default MainLayout
