import React from "react";
import { TextStyleBodyDefault, TextStyleCaption } from "../_Dsc/typography";

interface Props {
    message: string;
}

export const ErrorToast:React.FC<Props> = ({ message }) => {
  return (
    <div className="w-full flex items-center relative justify-center">
      <div
        className="flex border-[#edede] border-2 fixed justify-center gap-2 z-[100] top-10  flex items-center w-fit p-2 text-gray-500 bg-white rounded-lg shadow"
      >
        <div className="rounded-full inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-700 bg-red-600 ">
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
          </svg>
        
        </div>
        <TextStyleBodyDefault>{message}</TextStyleBodyDefault>
      
      </div>
    </div>
  );
};