import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefault, TextStyleCaption } from 'src/components/_Dsc/typography'
import TextH3 from 'src/components/_Dsc/typography/TextH3'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { RankingService } from 'src/service/ranking'
import { planksToTokens } from 'src/service/substrate/formated'

const MyVotedFromRankings: React.FC = () => {
	const { apiState, user } = useContext(KineContext)
	const [rankingsSummary, setRankingsSummary] = useState([] as any)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const navigate = useNavigate()
	const getAllData = async () => {
		try {
			setInFetch(true)

			const rankingListsPromise = RankingService.getRankingLists(apiState)
			const [rankingLists] = await Promise.all([rankingListsPromise])

			const userRankings = rankingLists
				.filter((ranking: any) => user.address in ranking.value.votesByUser)
				.map((ranking: any) => {
					const votesByUser = ranking.value.votesByUser[user.address]
					const votos = Object.keys(votesByUser).length
					const totalLockup = Object.values(votesByUser).reduce((acc: string, vote: any) => {
						const lockedAmount = vote.lockedAmount
						return acc === '0' ? lockedAmount : `${acc} + ${lockedAmount}`
					}, '0')

					return {
						id: ranking.id,
						title: ranking.value.name,
						description: ranking.value.description,
						votos: votos,
						totalLockup: totalLockup,
					}
				})

			setRankingsSummary(userRankings)
			setInFetch(false)
		} catch (error) {
			console.error(error)
		}
	}

	const goToRanking = (id: string) => {
		window.open(`ranking/details/` + id, '_blank')
	}

	React.useEffect(() => {
		if (apiState) {
			getAllData()
		}
	}, [apiState])

	return (
		<CardInner className="mt-4">
			<CardContainer>
				{inFetch ? (
					<Loading />
				) : (
					<div className="flex  flex-col gap-2 ">
						{rankingsSummary && rankingsSummary.length ? (
							rankingsSummary.map((ranking: any, index: number) => (
								<div
									className="hover:bg-gray-200 p-2 hover:cursor-pointer"
									key={index}
									onClick={() => {
										goToRanking(ranking.id)
									}}>
									<TextH3>Ranking:{ranking.title}</TextH3>
									<TextStyleCaption>Votes: {ranking.votos}</TextStyleCaption>
									<TextStyleCaption>
										Total Lockup: {ranking.totalLockup ? `${planksToTokens(ranking.totalLockup)} Kinex` : ''}
									</TextStyleCaption>
								</div>
							))
						) : (
							<TextStyleBodyDefault>No votes to show</TextStyleBodyDefault>
						)}
					</div>
				)}
			</CardContainer>
		</CardInner>
	)
}

export default MyVotedFromRankings
