import React, { useEffect, useState } from 'react'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import { TextStyleCaption } from 'src/components/_Dsc/typography'

interface User {
	id: string
	name: string
	tag: string
	description: string
	img: string
}

interface Props {
	user: User
}

const OnlineMembers: React.FC<Props> = ({ user }) => {
	const [members, setMembers] = useState<User[]>([])

	useEffect(() => {
		const communityId = window.location.pathname.split('/').pop()
		const ws = new WebSocket(`wss://p2p-voice.kinera.network/ws/online-members?communityId=${communityId}`)

		ws.onopen = () => {
			ws.send(JSON.stringify({ type: 'join', user }))
		}

		ws.onmessage = event => {
			const data = JSON.parse(event.data)

			if (data.type === 'online-members') {
				setMembers(data.users)
			}
		}

		ws.onclose = () => {
			console.log('Conexão encerrada com membros online')
		}

		return () => {
			ws.close()
		}
	}, [user])

	return (
		<div>
			<h3>Online</h3>
			<div className="flex flex-col gap-2">
				{members.map(member => (
					<div key={member.id} className="flex items-center gap-2">
						<InitialSimbol profile={{ img: member.img, name: member.name }} />
						<TextStyleCaption>{member.name}</TextStyleCaption>
						<div className="min-w-[10px] min-h-[10px] bg-green-500 rounded-full" />
					</div>
				))}
			</div>
		</div>
	)
}

export default OnlineMembers
