import React, { SetStateAction, useState } from 'react'
import KineContext from '../../../../context/KineContext'
import { createPost, createPostAI } from '../../../../service/social/socialService'
import RichTextEditor from './RichText/RichText'

import { storage } from '../../../../service/storage/firebase'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import ReactPlayer from 'react-player'
import './styles.scss'
import DscIcons from 'src/components/_Dsc/icons'
import ActionButton from 'src/components/_Dsc/_button/Action'
import Button from 'src/components/_Dsc/Button'
import UserAvatar from 'src/components/_Dsc/Avatar'
import { Community } from 'src/pages/communities'

interface TweetBoxProps {
	setData?: (value: SetStateAction<any>) => void
	getData?: () => void
	community?: Community
	isProfile?: boolean
	ownerProfile?: { address: string; user: string }
}

const TweetBox: React.FC<TweetBoxProps> = ({ setData, getData, community, isProfile, ownerProfile }) => {
	const { user, setGlobalToast, setGlobalSnack, profile } = React.useContext(KineContext)
	const [selectedFiles, setSelectedFiles] = React.useState<any[]>([])
	const [text, setText] = React.useState<any>('')
	const [hashtags, setHashtags] = useState<string[]>([])
	const [currentImages, setCurrentImages] = useState<any[]>([])
	const [hoveredImageIndex, setHoveredImageIndex] = useState<number | null>(null)

	/**
   * const handleAddHashtag = (event: any) => {
		const value = event.target.value

		if (value.endsWith(' ') || value.endsWith('#') || event.key === 'Enter') {
			const words = value.trim().split(/\s+/)
			const lastWord = words[words.length - 1]

			const hashtag = lastWord.startsWith('#') ? lastWord.substring(1) : lastWord
			if (hashtag !== '' && hashtag.length <= 40 && hashtags.length < 3) {
				setHashtags([...hashtags, hashtag])
				event.target.value = ''
			}
		}
	}

	const handleRemoveHashtag = (index: number) => {
		const updatedHashtags = [...hashtags]
		updatedHashtags.splice(index, 1)
		setHashtags(updatedHashtags)
	}

	const handleEditHashtag = (index: number) => {
		const editedHashtag = prompt('Edit the hashtag:', hashtags[index])?.trim()
		if (editedHashtag && editedHashtag !== '' && editedHashtag.length <= 40) {
			const updatedHashtags = [...hashtags]
			updatedHashtags[index] = editedHashtag
			setHashtags(updatedHashtags)
		}
	}
   */

	const handleSubmit = async () => {
		const filteredHashtags = hashtags.filter(hashtag => hashtag.trim() !== '')
		const hashtagString = filteredHashtags.join(',')

		setHashtags([])
		if (selectedFiles) {
			try {
				const downloadURLs = await Promise.all(
					selectedFiles.map(async file => {
						const storageRef = ref(storage, `files/${file.name}`)
						const uploadTask = uploadBytesResumable(storageRef, file)
						const snapshot = await uploadTask

						return getDownloadURL(snapshot.ref)
					}),
				)
				if (isProfile) {
					let req = await createPostAI(
						ownerProfile,
						text,
						hashtagString,
						downloadURLs,
						setGlobalToast,
						false,
						'',
						0,
						setGlobalSnack,
						community ? community : null,
					)
					if (req && getData) {
						await getData()
					}
					return
				} else {
					let req = await createPost(
						user,
						text,
						hashtagString,
						downloadURLs,
						setGlobalToast,
						false,
						'',
						0,
						setGlobalSnack,
						community ? community : null,
					)
					if (req && getData) {
						await getData()
					}
				}
			} catch (error) {
				console.error('Erro ao obter URLs de download:', error)
			}
		} else {
			if (isProfile) {
				let req = await createPostAI(
					ownerProfile,
					text,
					hashtagString,
					[],
					setGlobalToast,
					false,
					'',
					0,
					setGlobalSnack,
				)
				if (req && getData) {
					getData()
				}
				return
			} else {
				let req = await createPost(user, text, hashtagString, [], setGlobalToast, false, '', 0, setGlobalSnack)
				if (req && getData) {
					getData()
				}
			}
		}
		setText(null) // Mudança aqui
		setSelectedFiles([])
	}

	const handleFileChange = (event: any) => {
		setHoveredImageIndex(null)
		const files = Array.from(event.target.files)

		setSelectedFiles([...selectedFiles, ...files])
		const urls = files.map((file: any) => URL.createObjectURL(file))
		setCurrentImages([...currentImages, ...urls]) // Atualiza a imagem atual
	}

	const handleRemoveImage = (index: number) => {
		const updatedFiles = [...selectedFiles]
		updatedFiles.splice(index, 1)
		setSelectedFiles(updatedFiles)

		const updatedImages = [...currentImages]
		updatedImages.splice(index, 1)
		setCurrentImages(updatedImages)
	}

	const changeText = (text: string) => {
		setText(text)
	}

	const renderContent = () => {
		if (!text) return
		const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu.be\/)([a-zA-Z0-9_-]+)/gi
		const matches = text.match(regex)
		if (matches) {
			return matches.map((url: string, index: number) => <ReactPlayer url={url} controls width="100%" key={index} />)
		}
		return null
	}

	return (
		<>
			{user && user.address ? (
				<div className="create-post-box bg-white p-4">
					<div className="gap-2 rounded-[4px] p-2 flex items-start">
						<UserAvatar size="lg" />
						<div className="w-full">
							<div className="w-full flex flex-col h-full justify-center">
								<RichTextEditor onchange={changeText} value={text} />
							</div>
						</div>
					</div>
					{text && text.includes('/www.youtube.com/watch') && (
						<div className="show-preview-movie ">{renderContent()}</div>
					)}
					<div className="flex items-center justify-between ">
						<div className="flex flex-col">
							<div className="w-full flex items-end justify-end gap-4  relative">
								<button className="hover:bg-gray-300 rounded-lg flex items-center justify-center hover:text-blue-400">
									<input
										type="file"
										accept="image/png, image/gif, image/jpeg"
										id="file"
										style={{ display: 'none' }}
										onChange={e => handleFileChange(e)}
										multiple // Permitir seleção múltipla
									/>
									{currentImages.length < 5 && (
										<label htmlFor="file">
											<DscIcons icon="add_photo_alternate" />
										</label>
									)}
								</button>

								{/**
                 * 	<button className="hover:bg-gray-300 rounded-lg flex items-center justify-center hover:text-blue-400">
									<DscIcons icon="add_link" />
								</button>

								<button className="hover:bg-gray-300 rounded-lg flex items-center justify-center hover:text-blue-400">
									<DscIcons icon="add_reaction" />
								</button>
                 */}

								<div className="gap-2 flex">
									{currentImages.map((image, index) => (
										<div
											key={index}
											className="relative flex items-center flex-col"
											onMouseEnter={() => setHoveredImageIndex(index)}
											onMouseLeave={() => setHoveredImageIndex(null)}
										>
											<img
												src={image}
												alt="selected"
												className={
													hoveredImageIndex !== index ? 'rounded-full' : 'rounded-full bg-transparent opacity-[0.8]'
												}
												style={{ height: '30px', width: '30px' }}
											/>
											{hoveredImageIndex === index && (
												<button
													onClick={() => handleRemoveImage(index)}
													className="absolute top-[31px] right-[30px] transform translate-x-full bg-gray-200 -translate-y-full rounded-full p-1"
												>
													<svg
														className="w-6 h-6 text-gray-800 dark:text-white"
														aria-hidden="true"
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
													>
														<path
															stroke="currentColor"
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
														/>
													</svg>
												</button>
											)}
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="flex items-center gap-2">
							<Button value="Post" onClick={handleSubmit} size="small" disabled={!text || text.length < 1} />
						</div>
					</div>
				</div>
			) : (
				<h1>Connect your account for create Posts</h1>
			)}
		</>
	)
}

export default TweetBox
