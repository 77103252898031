import React from 'react'

import KineContext from '../../context/KineContext'
import UserBalance from '../balances'
import Button from '../_Dsc/Button'

interface Props {
	action: any
	data: any
	isDisabled: boolean
	isFetch?: boolean
	isFestival?: boolean
	maxEntry?: string
}

const Vote: React.FC<Props> = ({ action, data, isFetch, maxEntry, isFestival }) => {
	const { color } = React.useContext(KineContext)
	const [comment, setComment] = React.useState('')

	const onSubmit = (data: any, comment: string) => {
		action(data, comment)
		setComment('')
	}
	return (
		<div className="flex items-center gap-5">
			<div className="flex  flex-col w-[40%]">
				<input
					className="w-full p-3 rounded-lg text-sm  text-black border-2"
					value={comment}
					type="number"
					onKeyDown={e => {
						const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace']
						if (!allowedKeys.includes(e.key)) {
							e.preventDefault()
						}
					}}
					onChange={e => {
						const value = e.target.value
						if (value.includes(',') || value.includes('.')) {
							// Remover a vírgula ou o ponto da entrada
							e.target.value = value.replace(/[,\.]/g, '')
							setComment('')
						} else {
							setComment(value)
						}
					}}
					placeholder={isFestival && Number(maxEntry) > 1 ? `Max value for vote ${maxEntry}` : 'Value for vote'}
				/>
			</div>

			{!isFetch && (
				<Button
					size="small"
					onClick={() => {
						onSubmit(data, comment)
					}}
					value="Vote"
				></Button>
			)}
			<div>
				<UserBalance blackText={true} />
			</div>
		</div>
	)
}

export default Vote
