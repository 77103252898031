import React, { useState } from 'react'
import Tabs from 'src/components/_Dsc/Tabs'
import { TextStyleBodyDefaultLarge, TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
import { Models } from './components/Models'
import { WorkSpace } from './components/WorkSpace'
import { Rags } from './components/Rags'
import { Characters } from './components/Characters'

const options = [
	{ label: 'Language Models', value: 'Models' },
	
	{ label: 'Knowledge Base', value: 'Knowledge' },
    { label: `Workspace's`, value: 'Prompts' },
	{ label: 'Agents', value: 'characters' },
]

export const GPT: React.FC = () => {
	const [isSelected, setIsSelected] = useState('Models')

	const handleTabsChange = (val: string) => {
		setIsSelected(val)
	}

	return (
		<div className="flex items-start gap-4">
			<div className="options flex flex-col gap-2">
				{options.map((val: { label: string; value: string }, key: number) => (
					<div
						key={key}
						onClick={() => {
							handleTabsChange(val.value)
						}}
						className={`${
							val.value === isSelected ? 'text-blue-600 bg-gray-200 rounded-[4px]' : ''
						} p-1 cursor-pointer hover:underline  whitespace-nowrap`}>
						<TextStyleBodyDefaultLarge>{val.label}</TextStyleBodyDefaultLarge>
					</div>
				))}
			</div>
			<div className='bg-white w-full p-2'>
            {isSelected === 'Models' ? (
				<Models />
			)  : isSelected === 'Knowledge' ? (
				<Rags />
			) : isSelected === 'characters' ? (
				<Characters />
			) :  isSelected === 'Prompts' ? (
				<WorkSpace />
			) : ''}
            </div>
		</div>
	)
}
