import React, { useState } from 'react'
import Tabs from '../_Dsc/Tabs'
import { ImageGenerate } from './components/imageGenerate'
import { MovieGenerate } from './components/ImageToMovie'
import { TextToMovie } from './components/TextToMovie'

import { GPT } from 'src/pages/community/components/Actions/components/GPT'

interface Props {
	isCommunity: boolean
	community_id?: string
	setResponse?: (response: string) => void
	setLoading?: (param: boolean) => void
	hasUserPermission?: boolean
}

let categories = [
	{
		label: `GPT's`,
		value: 'gpts',
	},
	{
		label: 'Image generator',
		value: 'img',
	},
	{
		label: 'Image to Video',
		value: 'movie',
	},
	{
		label: 'Text to Video',
		value: 'prompt',
	},
]

export const ProdutionTools: React.FC<Props> = ({
	isCommunity,
	community_id,
	setResponse,
	setLoading,
	hasUserPermission,
}) => {
	const [isSelected, setIsSelected] = useState('gpts')

	const changeTab = (val: string) => {
		setIsSelected(val)
	}
	return (
		<div className="flex flex-col w-full h-full  page-padding gap-2 prodution-tools-component rounded-[4px]">
			<Tabs onChange={changeTab} options={categories} value={isSelected}></Tabs>
			{isSelected === 'gpts' && community_id && <GPT />}
			{isSelected === 'img' && (
				<ImageGenerate
					communityId={community_id}
					setResponse={setResponse}
					setLoading={setLoading}
					isCommunity={isCommunity}
				/>
			)}
			{isSelected === 'movie' && <MovieGenerate communityId={community_id} />}
			{isSelected === 'prompt' && <TextToMovie communityId={community_id} />}
		</div>
	)
}
