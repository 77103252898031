import React, { useState, useRef, useEffect } from 'react'
import { useAVToggle, useHMSActions } from '@100mslive/react-sdk'
import Button from 'src/components/_Dsc/Button'
import { Tooltip } from '@mui/material'

interface Props {
	setIsSelectedTimeline: (param: string) => void
}

const Controls: React.FC<Props> = ({ setIsSelectedTimeline }) => {
	const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } = useAVToggle()
	const hmsActions = useHMSActions() // Ações do HMS

	const [isRecording, setIsRecording] = useState(false)
	const [initialized, setInitialized] = useState(false) // Controla se o componente já foi inicializado
	const mediaRecorderRef = useRef<MediaRecorder | null>(null)
	const recordedChunksRef = useRef<Blob[]>([])
	const displayStreamRef = useRef<MediaStream | null>(null)

	const startRecording = async () => {
		try {
			const displayStream = await navigator.mediaDevices.getDisplayMedia({
				video: true,
				audio: true,
			})

			displayStreamRef.current = displayStream

			const audioStream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			})

			const combinedStream = new MediaStream([...displayStream.getTracks(), ...audioStream.getTracks()])

			const mediaRecorder = new MediaRecorder(combinedStream, {
				mimeType: 'video/webm; codecs=vp8,opus',
			})

			mediaRecorder.ondataavailable = event => {
				if (event.data.size > 0) {
					recordedChunksRef.current.push(event.data)
				}
			}

			mediaRecorder.onstop = () => {
				const blob = new Blob(recordedChunksRef.current, {
					type: 'video/webm',
				})
				const url = URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.style.display = 'none'
				a.href = url
				a.download = 'meet_recording.webm'
				document.body.appendChild(a)
				a.click()
				window.URL.revokeObjectURL(url)

				recordedChunksRef.current = []
				displayStream.getTracks().forEach(track => track.stop())
			}

			mediaRecorder.start()
			mediaRecorderRef.current = mediaRecorder
			setIsRecording(true)
		} catch (error) {
			console.error('Failed to start recording:', error)
		}
	}

	const stopRecording = () => {
		if (mediaRecorderRef.current) {
			mediaRecorderRef.current.stop()
			setIsRecording(false)
		}
	}

	// Função para desconectar da sala
	const leaveRoom = async () => {
		try {
			await hmsActions.leave()
			setIsSelectedTimeline('posts')
			console.log('User has left the room')
		} catch (error) {
			console.error('Failed to leave the room:', error)
		}
	}

	return (
		<div>
			<div className="flex gap-2 container w-fit mx-auto">
				<Tooltip title={isLocalAudioEnabled ? 'Disabled microphone' : 'Enable microphone'} placement="top">
					<div>
						<Button
							size="small"
							value="Mic"
							onClick={toggleAudio}
							iconOnly
							icon={isLocalAudioEnabled ? 'mic' : 'mic_off'}
						/>
					</div>
				</Tooltip>
				<Tooltip title={isLocalVideoEnabled ? 'Disabled Web cam' : 'Enable Web cam'} placement="top">
					<div>
						<Button
							iconOnly
							size="small"
							value="Web Cam"
							icon={isLocalVideoEnabled ? 'visibility' : 'visibility_off'}
							onClick={() => {
								if (toggleVideo) {
									toggleVideo()
								} else {
									console.warn('Video toggle not available')
								}
							}}
						/>
					</div>
				</Tooltip>
				<Tooltip title={isRecording ? 'Disabled Record' : 'Start Record'} placement="top">
					<div>
						<Button
							size="small"
							iconOnly
							value="Record"
							icon={isRecording ? 'screen_record' : 'radio_button_checked'}
							onClick={isRecording ? stopRecording : startRecording}
						/>
					</div>
				</Tooltip>
				<Tooltip title='Exit room' placement="top">
					<div>
					<Button iconOnly icon="logout" size="small" value="Leave Room" onClick={leaveRoom} />
						</div>
						</Tooltip>
				
			</div>
		</div>
	)
}

export default Controls
