import React, { useEffect, useState } from 'react'

import { FestivalService } from '../../service/festival/festivalService'
import './style.scss'

import Card from '../../components/card/Card'
import RankingCards from '../../components/tableList/TableList'
import { RankingService } from '../../service/ranking/index'
import { Loading } from '../../components/loading'
import { Skeleton } from '@mui/material'
import Dropdown from '../../components/dropdown'
import KineContext from '../../context/KineContext'
import { getAllPosts } from '../../service/social/socialService'
import Feed from '../social/components/feed/Feed'
import { translate } from '../../context/Translate'

import { group1, group2, group3, group4 } from 'src/constants/rankings'

import DscBadge from 'src/components/_Dsc/badge'
import Accordion from 'src/components/_Dsc/Accordion'
import { TextStyleBodyDefault, TextStyleCaption } from 'src/components/_Dsc/typography'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import Tabs from 'src/components/_Dsc/Tabs'
import { communityService, getPublicCharacters } from 'src/service/community/communityService'
import { Character } from '../community/components/Actions/components/GPT/components/Characters'
import { useNavigate } from 'react-router-dom'
import { Community } from '../communities'

const defaultVal = [1, 2, 3, 4]
const categories = [
	{
		label: 'Posts',
		value: 'Posts',
	},
	{
		label: 'Constellations',
		value: 'Constellations',
	},
]

function Home() {
	const { apiState, profile, currentLangues } = React.useContext(KineContext)
	const [data, setData] = React.useState<any | null>(null)
	const [publiCharacters, setPublicCharacters] = useState<Character[]>([])
	const [ranking, setRanking] = React.useState<any | null>(null)
	const [tags, setTags] = React.useState<any | null>(null)
	const [temp, setTemp] = React.useState<any | null>(null)
	const [showTemp, setShowTemp] = React.useState<any | null>(null)
	const [selected, setSelected] = React.useState<any | null>('All')
	const [accLabel, setAccLabel] = React.useState<any | null>('All')
	const [accCategory, setAccCategory] = React.useState<any | null>(null)
	const [fetch, setFetch] = React.useState<boolean | null>(false)
	const [dataPosts, setDataPosts] = React.useState<any>([])
	const [fetchPosts, setFetchPosts] = React.useState<boolean | null>(false)
	const [currentBlockNumber, setCurrentBlockNumber] = React.useState('')
	const [isSelected, setIsSelected] = React.useState('Posts')
	const [fetchCommunities, setFetchCommunities] = React.useState<boolean>(false)
	const [communities, setCommunities] = useState<Community[]>([])

	const navigate = useNavigate()

	const changeCurrentTab = (tab: string) => {
		setIsSelected(tab)
	}

	const getAllData = async () => {
		try {
			setFetch(true)
			//const getApoloRankingsPromise = RankingService.getRankingsByApolo();
			const festivalsPromise = FestivalService.getFestivals(false, apiState)
			const rankingListsPromise = RankingService.getRankingLists(apiState)
			const Tags = FestivalService.getAllTags(apiState)
			const [festivals, rankingLists, tags] = await Promise.all([festivalsPromise, rankingListsPromise, Tags])

			setTags(tags)
			setRanking(rankingLists)
			setData(festivals)

			setFetch(false)
		} catch (error) {
			console.error(error)
		}
	}

	const getAllCharacters = async () => {
		let req = await getPublicCharacters()
		if (req && req.data) {
			setPublicCharacters(req.data)
		}
	}

	const updatedCategory = (category: string) => {
		if (category === 'All') {
			setAccCategory(tags)
			setTemp(data)
			setSelected(null)
			setAccLabel(category)
		} else {
			const acc = tags.filter((val: any) => val.key === category || val.param === category)
			setAccLabel(category)
			setAccCategory(acc[0])
			return accCategory
		}
	}

	const selectedFilter = (filter: any) => {
		setSelected(filter)

		if (filter === 'Festival' || filter === 'Festivals' || filter.key === 'All') {
			setTemp(data)
			setShowTemp(false)
			return true
		} else {
			const filtered = data.filter((val: any) => {
				if (val.categoriesAndTags) {
					return val.categoriesAndTags.some((param: any) => param.includes(filter))
				}
				return false
			})
			setTemp(filtered)
			setShowTemp(true)
			return filtered
		}
	}

	const getData = async () => {
		try {
			setFetchPosts(false)
			let posts = await getAllPosts(apiState)
			setDataPosts(posts)

			setFetchPosts(true)
		} catch (error) {
			console.error('Ocorreu um erro ao buscar os dados:', error)
		}
	}

	const currentBlock = async () => {
		let req = await FestivalService.getCurrentAccBlock(apiState)
		setCurrentBlockNumber(req)
	}

	const getActiveCommunities = async (): Promise<void> => {
		setFetchCommunities(true)

		try {
			// Fetch communities
			let req: Community[] = await communityService.getCommunities('Approve')

			if (req) {
				// Ordenar as comunidades pelo número de membros em ordem decrescente
				const sortedCommunities = req.sort((a, b) => parseInt(b.members) - parseInt(a.members))

				// Manter apenas as 10 comunidades com mais membros
				const topCommunities = sortedCommunities.slice(0, 12)

				setCommunities(topCommunities)
				console.log(topCommunities)
			}
		} catch (error) {
			console.error('Error fetching communities:', error)
		} finally {
			setFetchCommunities(false)
		}
	}

	useEffect(() => {
		getAllCharacters()
		if (apiState) {
			getActiveCommunities()
			getAllData()
			getData()
			currentBlock()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])

	return (
		<div className="pb-[30px]">
			<div className="page-padding kinergy-home gap-2 flex flex-col">
				<div className="flex flex-col gap-2">
					<div className="flex items-center gap-2">
						<TextStyleBodyDefault>Popular Bazzar</TextStyleBodyDefault>
					</div>
					<div className="flex gap-2 flex-wrap">
						{publiCharacters.map((character: Character, key: number) => (
							<div
								key={key}
								onClick={() => {
									navigate(`/project/${character.id}`)
								}}
								className=" cursor-pointer hover:opacity-60 hover:border hover:border-1 rounded-[4px] text-left bg-gray-50  min-w-[355px] max-w-[355px] truncate overflow-hidden border border-gray-100  rounded-lg flex flex-col justify-between"
							>
								<div className="flex items-start gap-2 p-2 w-full h-full w-full">
									<img className="fit-image  min-h-[114px] w-[90px]" src={character.img_url} width={90} alt="model" />
									<div className="flex flex-col w-full h-full justify-between ">
										<div className="flex flex-col">
											<div className="flex flex-col  capitalize font-medium text-ellipsis overflow-hidden ">
												<TextStyleBodyDefault className="truncate overflow-hidden truncate-text">
													{character.name}
												</TextStyleBodyDefault>
												<TextStyleCaption className="opacity-50">By @{character.owner_name}</TextStyleCaption>
											</div>

											<p className="text-xs line-clamp-3 truncate text-truncate-three  truncate-text break-words">
												{character.description}
											</p>
										</div>
										<div className="w-full flex items-end justify-end">
											<div className="flex flex-row gap-2">
												<div className="flex flex-row gap-1 items-center">
													<svg
														viewBox="0 0 24 24"
														fill="none"
														width="14px"
														height="14px"
														className="text-muted-foreground"
													>
														<path
															d="M21.5 12c0-5-3.694-8-9.5-8s-9.5 3-9.5 8c0 1.294.894 3.49 1.037 3.83l.037.092c.098.266.49 1.66-1.074 3.722 2.111 1 4.353-.644 4.353-.644 1.551.815 3.397 1 5.147 1 5.806 0 9.5-3 9.5-8Z"
															stroke="#000"
															stroke-linecap="square"
															stroke-linejoin="round"
															stroke-width="2"
														></path>
													</svg>
													<p className="text-sm text-muted-foreground">{character.interactions || 0}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				{fetchCommunities ? (
					<Loading />
				) : (
					communities &&
					communities.length && (
						<div className="flex flex-col gap-2">
							<div className="flex items-center gap-2">
								<TextStyleBodyDefault>Popular Communities</TextStyleBodyDefault>
							</div>
							<div className="flex wrap flex-wrap gap-2">
								{communities.map((community: Community, key: number) => (
									<div
										onClick={() => {
											navigate(`/community/proposal/${community.id}`)
										}}
										key={key}
										className="cursor-pointer hover:opacity-70 min-w-[200px] grow bg-gray-50 rounded-[10px] flex flex-row gap-2 p-3 items-center bg-surface-elevation-1 hover:bg-scrim-8 rounded-spacing-s"
										style={{ width: 312 }}
									>
										<span
											className="relative border-2 flex h-auto w-full overflow-hidden rounded-full shrink-0 grow-0"
											title={community.name}
											style={{ width: 54, height: 54, borderRadius: 54 }}
										>
											<img
												alt="HyperGlot"
												loading="lazy"
												width={54}
												height={54}
												decoding="async"
												data-nimg={1}
												className="object-cover object-center bg-card shrink-0 grow-0 h-full"
												src={community.icon}
												style={{ color: 'transparent' }}
											/>
										</span>
										<div className="flex flex-col">
											<p className="line-clamp-1 text-ellipsis">{community.name}</p>
											<p className="line-clamp-1 text-ellipsis text-muted-foreground text-md">{community.category}</p>
										</div>
									</div>
								))}
							</div>
						</div>
					)
				)}
				{selected !== 'Ranking' && selected !== 'RankingList' && (
					<CardInner>
						<CardContainer className="flex flex-col gap-4 bg-white ">
							<div className="rounded-[4px]">
								<div className="flex items-center gap-2">
									<TextStyleBodyDefault>{translate('CONSTELATIONS', currentLangues.value)}</TextStyleBodyDefault>
									{!fetch && (
										<div>
											<Dropdown props={tags} updatedCategory={updatedCategory} selected={accLabel} />
										</div>
									)}
								</div>
								{!fetch && (
									<div className="mt-4">
										{accLabel && accLabel !== 'All' && (
											<div className="flex items-center gap-3 tags-container min-h-[84px]">
												{accCategory &&
													accCategory.value &&
													accCategory.value.map((val: any) => (
														<DscBadge isSelected={val === selected}>
															<button
																onClick={e => {
																	selectedFilter(val)
																}}
															>
																{val}
															</button>
														</DscBadge>
													))}
											</div>
										)}
									</div>
								)}
							</div>
							{!fetch ? (
								<div className="flex items-center gap-2 grow flex-wrap">
									{showTemp
										? temp?.map(
												(value: any, i: number) =>
													i < 6 && (
														<div key={i}>
															<Card data={value} currentBlockNumber={currentBlockNumber} />
														</div>
													),
										  )
										: data &&
										  data.length > 0 &&
										  data?.map(
												(value: any, i: number) =>
													i < 8 && (
														<div key={i} className="flex items-center grow">
															<Card data={value} currentBlockNumber={currentBlockNumber} />
														</div>
													),
										  )}
									{!data && <Loading />}
								</div>
							) : (
								<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
									{defaultVal?.map(
										(value: any, i: number) =>
											i < 6 && (
												<div key={i}>
													<Skeleton variant="rectangular" width={'100%'} height={200} />
												</div>
											),
									)}
								</div>
							)}
						</CardContainer>
					</CardInner>
				)}

				{selected !== 'Festival' && (
					<div className="mt-4 ">
						<CardInner className="bg-white">
							<CardContainer className="flex flex-col gap-2">
								<div className="flex items-center gap-2">
									<TextStyleBodyDefault>Rankings</TextStyleBodyDefault>
								</div>
								{ranking ? (
									<div className="w-full flex flex-col h-full gap-[8px]">
										<Accordion
											title="Crypto Tube"
											description="The best reports on market trends, new projects, general news, blockchain technology and its fundamentals."
										>
											<RankingCards
												data={ranking}
												group={group1}
												title="Crypto Tube"
												remove="Crypto Tube"
												description="The best reports on market trends, new projects, general news, blockchain technology and its fundamentals."
											/>
										</Accordion>

										{/**
											 * <Accordion
											title="Comedy Tube"
											description="Comedy is one of the most neglected genres and, at the same time, one of the most censored.">
											<RankingCards
												data={ranking}
												group={group3}
												title="Comedy Tube"
												remove="Comedy Tube"
												description="Comedy is one of the most neglected genres and, at the same time, one of the most censored."
											/>
										</Accordion>
											 */}

										<Accordion
											title="Artificial Intelligence"
											description="Here, we list AI videos from entertainment to experimental art and advanced simulations."
										>
											<RankingCards
												data={ranking}
												group={group2}
												title="Artificial Intelligence"
												description="Here, we list AI videos from entertainment to experimental art and advanced simulations."
											/>
										</Accordion>

										<Accordion title="Cinema" description="Cinema rankings. Curation open to all.">
											<RankingCards
												data={ranking}
												group={group4}
												title="Cinema"
												remove="Cinema"
												description="Cinema rankings. Curation open to all."
											/>
										</Accordion>
									</div>
								) : (
									<Loading />
								)}
							</CardContainer>
						</CardInner>
					</div>
				)}
			</div>

			<CardContainer>
				<div className="flex flex-col gap-2">
					<div className="w-full flex items-center ">
						<Tabs options={categories} value={isSelected} onChange={changeCurrentTab}></Tabs>
					</div>
					{fetchPosts ? (
						<div className="flex flex-col w-full  mb-10">
							<Feed posts={dataPosts} profile={profile} />
						</div>
					) : (
						<div className="flex flex-col w-full  mb-10">
							<Loading />
						</div>
					)}
				</div>
			</CardContainer>
		</div>
	)
}

export default Home
