import * as React from 'react'

import Dialog from '@mui/material/Dialog'

import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

import ActionButton from '../_button/Action'

interface Props {
	children: React.ReactNode
	open: boolean
	setOpen: (value: boolean) => void
	size?: string
	confirmButton?: boolean
	confirmAction?: () => void
	confirmText?: string
	type: string
}

const DscModal: React.FC<Props> = ({
	children,
	open,
	setOpen,
	size,
	confirmButton,
	confirmAction,
	confirmText,
	type,
}) => {
	const handleClose = () => {
		setOpen(false)
	}

	return (
		<React.Fragment>
			<Dialog
				onClose={handleClose}
				maxWidth={false}
				open={open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent sx={{ overflow: 'hidden' }}>
					<DialogContentText id="alert-dialog-description">{children}</DialogContentText>
				</DialogContent>
				<div className="flex items-center gap-2 justify-end mr-2" style={{ padding: '20px 24px' }}>
					<div className="flex items-center gap-4 justify-end">
						<ActionButton onSubmit={handleClose} text={'Close'} type="cancel"></ActionButton>
						{confirmButton && confirmAction && confirmText && (
							<ActionButton onSubmit={confirmAction} text={confirmText} type={type}></ActionButton>
						)}
					</div>
				</div>
			</Dialog>
		</React.Fragment>
	)
}

export default DscModal
