import React, { useContext, useState } from 'react'
import Tabs from 'src/components/_Dsc/Tabs'
import { TextStyleBodyDefaultLarge } from 'src/components/_Dsc/typography'
import { RegisterPrompt } from './prompts'
import { RegisterAgents } from './agents'
import { createNewDialog } from 'src/service/dialog'
import InputText from 'src/components/_Dsc/InputText'
import KineContext from 'src/context/KineContext'

interface Props {
	setIsCreate: (param: boolean) => void
}

const categories = [
	{
		label: 'Prompts',
		value: 'prompts',
	},
	{
		label: 'Agents',
		value: 'agents',
	},
]

export const DialogForm: React.FC<Props> = ({ setIsCreate }) => {
    const { user, handleGlobalMessage, setOnQueue } = useContext(KineContext)
	const [isSelected, setIsSelected] = useState('prompts')
	const [prompts, setPrompts] = useState([{ title: '', question: '' }])
	const [agents, setAgents] = useState<{ question: string; answer: string }>({
		question: '',
		answer: '',
	})
	const [selectedResponder, setSelectedResponder] = useState<string>('')
	const [selectedAsker, setSelectedAsker] = useState<string>('')
	const [title, setTitle] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const changeTab = (val: string) => {
		setIsSelected(val)
	}

	const confirmCreateForm = async () => {
        
		let payload = {
			name: title,
			description: description,
			respond:  agents.answer,
			ask: agents.question,
            prompts: prompts
		}
        // console.log('agents', agents)
        // console.log('prompts', prompts)
        setOnQueue(true)
        handleGlobalMessage('Action completed successfully, your Dialog will be listed soon')
        setIsCreate(false)
		await createNewDialog(payload)
       
	}

	return (
		<div className="w-full h-full bg-white p-2 rounded-[4px]">
			<TextStyleBodyDefaultLarge>
				Before completing the creation of the dialog, make sure you have registered all the necessary data for all tabs,
				'Prompts', 'Agents'
			</TextStyleBodyDefaultLarge>
			<div className="flex flex-col gap-2 w-full h-full">
				<Tabs onChange={changeTab} options={categories} value={isSelected}></Tabs>
				{isSelected === 'prompts' ? (
					<RegisterPrompt setIsSelected={setIsSelected} prompts={prompts} setPrompts={setPrompts} />
				) : (
					<div className="flex flex-col gap-2">
						<div className="flex flex-col gap-2">
							<InputText required label="Title" placeholder="Dialog Title" onChange={setTitle} value={title} />
							<InputText
								required
								label="Description"
								placeholder="Dialog Description"
								onChange={setDescription}
								value={description}
							/>
						</div>
						<RegisterAgents
							setIsSelected={setIsSelected}
							agents={agents}
							setAgents={setAgents}
							selectedResponder={selectedResponder}
							setSelectedResponder={setSelectedResponder}
							setSelectedAsker={setSelectedAsker}
							selectedAsker={selectedAsker}
							confirmCreateForm={confirmCreateForm}
							disabled={!title || !description || !prompts || !prompts[0].title || !prompts[0].question || !agents || !agents.question || !agents.answer}
						/>
					</div>
				)}
			</div>
		</div>
	)
}
