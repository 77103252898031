import React, { useEffect, useRef, useContext, useState } from 'react'
import { selectIsPeerAudioEnabled, selectIsPeerVideoEnabled, useHMSStore, useVideo } from '@100mslive/react-sdk'
import { MicOffIcon, PersonIcon } from '@100mslive/react-icons'
import KineContext from 'src/context/KineContext'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import { TextStyleCaption } from 'src/components/_Dsc/typography'

interface Props {
	peer: any
}

const Peers: React.FC<Props> = ({ peer }) => {
	const { videoRef } = useVideo({
		trackId: peer.videoTrack,
	})

	const { user } = useContext(KineContext) // Access the logged-in user from context
	const isPeerAudioEnabled = useHMSStore(selectIsPeerAudioEnabled(peer.id))
	const isPeerVideoEnabled = useHMSStore(selectIsPeerVideoEnabled(peer.id))
	const audioRef = useRef<HTMLAudioElement | null>(null)
	const [image, setImage] = useState('')

	useEffect(() => {
		selectIsPeerVideoEnabled(undefined)
	}, [])

	useEffect(() => {
		let parsedMetadata: any = {}

		try {
			parsedMetadata = JSON.parse(peer.metadata)

			setImage(parsedMetadata?.image)
		} catch (error) {
			console.error('Failed to parse metadata:', error)
		}
	}, [peer])

	return (
		<div className="peer-container relative">
			<audio ref={audioRef} autoPlay controls={false} />

			<div className="flex items-center gap-2">
				{!isPeerVideoEnabled ? <InitialSimbol profile={{ img: image, name: peer.name }} /> : ''}
				<TextStyleCaption>{peer.name}</TextStyleCaption>
				<div className="min-w-[10px] min-h-[10px] bg-green-500 rounded-full" />
			</div>
			{!isPeerVideoEnabled ? (
				''
			) : (
				<video ref={videoRef} className={`peer-video ${peer.isLocal ? 'local' : ''}`} autoPlay playsInline />
			)}
		</div>
	)
}

export default Peers
