import React, { useEffect, useState } from 'react'
import MovieCard from 'src/components/movieCard/MovieCard'
import KineContext from 'src/context/KineContext'
import CurrentMovie from '../currentMovie'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'

interface Props {
	list: any
	movies: any
	selectedMovie: string
	changeCurrentMovie: any
	totalLockMovie: any
	showModalMovie: any
	verifyUnstake: any
	byProfile: boolean
	getByProfile: any
	sendVote: any
	videoSrc: any
	loaderCurrentMovieVotes: any
	currentUserTotalLock: any
	isFetch: boolean
	rankingData?: any
}

export const MoviesinList: React.FC<Props> = ({
	sendVote,
	loaderCurrentMovieVotes,
	movies,
	selectedMovie,
	isFetch,
	videoSrc,
	changeCurrentMovie,
	totalLockMovie,
	showModalMovie,
	verifyUnstake,
	byProfile,
	getByProfile,
	currentUserTotalLock,
	rankingData,
}) => {
	const { color, user } = React.useContext(KineContext)
	const [sortedMovies, setSortedMovies] = useState([])

	// Função para ordenar os filmes por lock
	const sortMoviesByLock = (movies: any) => {
		return movies.slice().sort((a: any, b: any) => {
			const totalLockA = totalLockMovie(a)
			const totalLockB = totalLockMovie(b)
			return totalLockB - totalLockA
		})
	}
	useEffect(() => {
		setSortedMovies(sortMoviesByLock(movies))
	}, [movies])
	return (
		<div className="items-center flex flex-col gap-10 w-full flex-condicional  m-auto ">
			<div className="flex flex-col flex-condicional w-full">
				{sortedMovies &&
					sortedMovies.length &&
					sortedMovies.map((value: any, i: number) => (
						<div className="flex items-center grow w-full" key={i}>
							<p className={`text-[${color.text}] w-[3%] center flex items-center justify-center`}>{i + 1}</p>
							<div className="mt-2 w-[97%] bg-white rounded-[4px]" key={i}>
								<CardInner>
									<CardContainer>
										<MovieCard
											isRanking={true}
											currentSelectedMovie={selectedMovie}
											data={value}
											action={changeCurrentMovie}
											totalLockMovie={totalLockMovie}
											showModalMovie={showModalMovie}
											verifyUnstake={verifyUnstake}
											status=""
											byProfile={byProfile}
											profileData={getByProfile}
											currentUserTotalLock={currentUserTotalLock}
											rankingData={rankingData}
											owner={''}
										/>
										{selectedMovie === value && (
											<CurrentMovie
												selectedMovie={selectedMovie}
												user={user}
												sendVote={sendVote}
												videoSrc={videoSrc}
												isFetch={isFetch}
												loaderCurrentMovieVotes={loaderCurrentMovieVotes}
												totalLockMovie={totalLockMovie}
												currentUserTotalLock={currentUserTotalLock}
											/>
										)}
									</CardContainer>
								</CardInner>
							</div>
						</div>
					))}
			</div>
		</div>
	)
}
