import React, { useEffect, useState } from 'react'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { VotesCommunity } from './PropostalDetails'
import { Community } from 'src/pages/communities'
import { Loading } from 'src/components/loading'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import { TextStyleBodyDefault } from 'src/components/_Dsc/typography'

interface Props {
	community: Community
}

interface Votes {
	communityName: string
	socialName: string
	user: string
	voteResult: string
}

const ProposalVotes: React.FC<Props> = ({community}) => {
	const { user } = React.useContext(KineContext)
	const [inFetch, setInFetch] = useState(true)
	const [votes, setVotes] = useState<Votes[]>([] as Votes[])
	const getProposalVotes = async () => {
		setInFetch(true)
		let req = await communityService.verifyUserVoteCommunity(user, community.id)
		if (req && req.length) {
			setVotes(req)
			setInFetch(false)
			
		}else {
			setInFetch(false)
		}
	}

	useEffect(() => {
		getProposalVotes()
	},[])

	return (
		<div className='w-full flex flex-col'>
			{
				inFetch
				? <Loading />
				: <div className='flex flex-col gap-2'>
				 {
                    votes && votes.length
                    ? votes.map((vote: Votes, index: number)=> (
                        <div key={index} className="bg-white rounded-[4px] p-4 flex items-center gap-2">
                            <InitialSimbol profile={{img: undefined, name:vote.socialName}}/>
                            <TextStyleBodyDefault>{vote.socialName}</TextStyleBodyDefault>
							<div className={vote.voteResult === 'Approve' ? 'Voting' : 'Reject' }>
							<TextStyleBodyDefault>{vote.voteResult}</TextStyleBodyDefault>
							</div>
                        </div>
                    ))
                    : 'No data to display'
                }
				</div>
			}
		</div>
	)
}

export default ProposalVotes
