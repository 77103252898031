import React, { useContext, useEffect, useState } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefault, TextStyleCaption, TextStyleH1 } from 'src/components/_Dsc/typography'
import { DialogForm } from './components/dialogForm'
import { getAllDialogs } from 'src/service/dialog'
import { Loading } from 'src/components/loading'
import { DialogDetail } from './components/dialogDetail'
import KineContext from 'src/context/KineContext'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Dialog AI', link: '' },
]

const customPath = [
	{ label: 'Home', link: '/' },
	{ label: 'Dialog AI', link: '/dialog-ai' },
	{ label: 'Create Dialog AI', link: '/dialog-ai' },
]

export interface IDialog {
	id: number
	user_id: number
	name: string
	description: string
	respond: string
	ask: string
}

export const DialogAi: React.FC = () => {
	const { user } = useContext(KineContext)
	const [isCreate, setIsCreate] = useState(false)
	const [selectedDialog, setSelectedDialog] = useState({} as IDialog | null)
	const [inFetch, setInFetch] = useState(true)
	const [dialogs, setDialogs] = useState<IDialog[]>([])

	const enableEditForm = () => {
		setIsCreate(true)
	}

	const getDialogs = async () => {
		setInFetch(true)
		let req = await getAllDialogs()
		if (req && req.data) {
			setDialogs(req.data)
		}
		setInFetch(false)
	}

	const handleDialogChange = (dialog: IDialog) => {
		setSelectedDialog(dialog)
	}

	const returnAllDialogs = () => {
		setIsCreate(false)
		setSelectedDialog(null)
	}

	useEffect(() => {
		getDialogs()
		setSelectedDialog(null)
	}, [isCreate])

	return (
		<div className="page-padding">
			<Breadcrumb routes={isCreate ? customPath : path} />
			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="flex flex-col items-start justify-between">
						<div className="w-full flex flex-col">
							<div className="flex items-center justify-between">
								<TextStyleH1>{isCreate ? 'Create new Dialog' : 'Dialog AI'}</TextStyleH1>
								{!isCreate && (
									<Button
									disabled={!user || !user.address}
										onClick={() => {
											selectedDialog ? returnAllDialogs() :enableEditForm()
										}}
										value={selectedDialog ? 'Return all Dialogs' : "Create new Dialog"}
										icon={selectedDialog ? 'arrow_back' :"add"}
										size="small"
									/>
								)}
								{isCreate && (
									<Button
									disabled={!user || !user.address}
										onClick={() => {
											setIsCreate(false)
										}}
										value='Cancel'
										icon={'close'}
										size="small"
									/>
								)}
							</div>
						</div>
					</div>
					{inFetch ? (
						<Loading />
					) : (
						<div className="flex flex-col h-full w-full">
							{isCreate && <DialogForm setIsCreate={setIsCreate} />}
							{!isCreate ? (
								selectedDialog ? (
									<DialogDetail selectedDialog={selectedDialog} />
								) : (
									<div className="w-full h-full flex flex-wrap wrap gap-2">
										{dialogs && dialogs.length ? (
											dialogs.map((dialog: IDialog, key: number) => (
												<div
													onClick={() => {
														handleDialogChange(dialog)
													}}
													className="flex w-96  grow flex-col bg-white p-4 truncate hover:opacity-70 cursor-pointer"
													key={key}>
													<TextStyleBodyDefault>{dialog.name}</TextStyleBodyDefault>
													<TextStyleCaption className="truncate">{dialog.description}</TextStyleCaption>
													<div className="flex items-end justify-end w-full gap-2">
														<TextStyleCaption className="truncate">{dialog.ask}</TextStyleCaption>x
														<TextStyleCaption className="truncate">{dialog.respond}</TextStyleCaption>
													</div>
												</div>
											))
										) : (
											<TextStyleBodyDefault>You have not yet created dialogs between agents</TextStyleBodyDefault>
										)}
									</div>
								)
							) : (
								''
							)}
						</div>
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}
