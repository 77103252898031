import React from 'react'
import UserFestivals from '../profile/Components/myFestivals'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleH1 } from 'src/components/_Dsc/typography'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'

const MyConstelattions: React.FC = () => {
	const path = [
		{ label: 'Home', link: '/' },
		{ label: 'My Constellations', link: '' },
	]
	return (
		<div className="w-full h-full page-padding">
			<Breadcrumb routes={path} />

			<CardInner>
				<CardContainer>
					<TextStyleH1>My Constellations</TextStyleH1>
					<div className="bg-white flex flex-col p-4 rounded-[4px] mt-4">
						<UserFestivals />
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default MyConstelattions
