import React from 'react'
import DscIcons from 'src/components/_Dsc/icons'
import { TextStyleBodyDefaultLarge, TextStyleH2, TextStyleH3 } from 'src/components/_Dsc/typography'
import KineContext from 'src/context/KineContext'

interface Props {
	label: string
	icon: string
	path?: string
	title?: boolean
	haveOptions?: boolean
	isOpenDropdown?: boolean
}
const Option: React.FC<Props> = ({ label, icon, title, haveOptions, isOpenDropdown }) => {
	const { showFullMenu } = React.useContext(KineContext)
	return (
		<div
			className={
				title && showFullMenu
					? `flex items-center justify-between w-full`
					: showFullMenu && !title
					? 'flex items-center justify-between  option w-fill'
					: 'flex items-center justify-between '
			}
		>
			<div className="flex items-center gap-[8px] custom-icon">
				{icon && <DscIcons icon={icon} />}
				{showFullMenu ? (
					<>
						{title ? (
							<TextStyleH3>{label}</TextStyleH3>
						) : (
							<TextStyleBodyDefaultLarge>{label}</TextStyleBodyDefaultLarge>
						)}
					</>
				) : (
					''
				)}
			</div>
			{showFullMenu && (
				<>{haveOptions && <DscIcons icon={isOpenDropdown ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />}</>
			)}
		</div>
	)
}

export default Option
