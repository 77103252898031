import React, { useEffect, useState } from 'react'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { getUserFiles, removeUserFile } from 'src/service/storage/Ipfs'
import { DocumentCard } from './Components/DocumentCard'
import PublishDocument from './Components/PublishDocument'

import { CommunityFile } from 'src/pages/community/components/Actions/components/GPT/components/Rags'


interface File {
	id: number
	url: string
	size: string
	document_name: string
	created_at: string
	file_hash: string
}

export const MyDocumentsFiles: React.FC = () => {
	const { user, setOnQueue, handleGlobalMessage } = React.useContext(KineContext)
	const [files, setFiles] = React.useState([])
	const [inLoading, setInLoading] = React.useState(true)
    const [modal, setModal] = useState(false)
	
    const [selectedFile, setSelectedFile] = useState<File>({} as File)

	const getUseFiles = async () => {
		setInLoading(true)
		let req = await getUserFiles(user.address)
		if (req && req.data) {
			setFiles(req.data)
		}
		setInLoading(false)
	}

    const closeModal = () => {
		setModal(false)
	}

    const handleSelectFile = (file: File) => {
        setModal(true)
        setSelectedFile(file)
    }

	const onDeleteFile = async (file: File) => {
		setInLoading(true)
		setOnQueue(true)
		handleGlobalMessage('The file is being removed from your storage')
		await removeUserFile(user.address, file.file_hash)
		getUseFiles()

	}

	useEffect(() => {
		if (user && user.address) {
			getUseFiles()
		}
	}, [user])

	return (
		<div className="w-full h-full">
            {modal && <PublishDocument open={modal} file={selectedFile} onClose={closeModal}></PublishDocument>}
			{!inLoading ? (
				<div className="grid p-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
					{files && files.length
						? files.map(
								(
									file: { owner_name:string, id: number; url: string; size: string; document_name: string; created_at: string, file_hash:string },
									key: number,
								) => (
									<div key={key}>
										<DocumentCard file={file} onSelect={handleSelectFile} onDeleteFile={onDeleteFile}/>
									</div>
								),
						  )
						: ''}
				</div>
			) : (
				<Loading />
			)}
		</div>
	)
}
