import { createPortal } from 'react-dom'

import { IModal } from './interface'

import { DsyBody, DsyContainer, DsyContent, DsyFooter, DsyHeaderContainer, DsyHeaderTitle, DsyShadow } from './styles'

import Button from '../Button'
import DscIcons from '../icons'
import React, { useEffect } from 'react'

export const Modal = ({
	cancelLabel,
	children,
	confirmLabel,
	onCancel,
	onClose,
	onConfirm,
	open,
	title,
	type = 'primary',
	size = 'medium',
	confirmDisabled = false,
	onOverlayClick,
}: IModal) => {
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Enter' && !confirmDisabled && onConfirm) {
				onConfirm()
			}
		}

		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [confirmDisabled, onConfirm])
	if (!open) return null

	const handleOverlayClick = () => {
		if (onOverlayClick) {
			onOverlayClick()
		}
		if (onClose) {
			onClose()
		}
	}

	return createPortal(
		<DsyContainer>
			<DsyShadow onClick={handleOverlayClick}></DsyShadow>
			<DsyContent size={size}>
				<DsyHeaderContainer>
					<DsyHeaderTitle>
						{type === 'critical' && <DscIcons type={type} icon={''}></DscIcons>}
						{type === 'warning' && <DscIcons type={type} icon={''}></DscIcons>}
						{title}
					</DsyHeaderTitle>
					{onClose && (
						<button onClick={onClose}>
							<DscIcons icon="close"></DscIcons>
						</button>
					)}
				</DsyHeaderContainer>
				<DsyBody>{children}</DsyBody>
				{(cancelLabel || confirmLabel) && (
					<DsyFooter>
						{cancelLabel && onCancel && (
							<Button type="subtle" value={cancelLabel} onClick={onCancel} size="small"></Button>
						)}
						{confirmLabel && onConfirm && (
							<Button
								type={type}
								value={confirmLabel}
								onClick={onConfirm}
								size="small"
								disabled={confirmDisabled}
							></Button>
						)}
					</DsyFooter>
				)}
			</DsyContent>
		</DsyContainer>,
		document.body,
	)
}

export default Modal
