import React, { useEffect, useState } from 'react'
import Modal from 'src/components/_Dsc/Modal'
import { IKineraPost } from '../../../interface'
import KineContext from 'src/context/KineContext'
import { CreateMessengerChat } from 'src/service/messenger'

interface Props {
	setModalMessage: (param: boolean) => void
	modalMessage: boolean
	tweet: number
}

export const MessageModal: React.FC<Props> = ({ setModalMessage, modalMessage, tweet }) => {
	const { user, handleGlobalMessage, setOnQueue } = React.useContext(KineContext)
	const [title, setTitle] = useState('')
	const onClose = () => {
		setModalMessage(false)
	}
	const handleCreate = async () => {
		 await CreateMessengerChat(user.id, tweet, title)
        setModalMessage(false)
		setOnQueue(true)
		handleGlobalMessage('Message sent successfully, see all your chats in the "Messenger" tab')
	}
	useEffect(() => {}, [])
	return (
		<Modal open={modalMessage} title="Send message" onCancel={onClose} cancelLabel="Cancel" onClose={onClose} onConfirm={handleCreate} confirmLabel='Send' confirmDisabled={!title}>
			<div>
				<label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">
				Private message
				</label>
				<textarea
					onChange={e => {
						setTitle(e.target.value)
					}}
					maxLength={300}
					id="description"
					rows={4}
					className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-2 border-gray-300 focus:ring-blue-500 focus:border-blue-500"
					placeholder="Write your message..."></textarea>
				<div className="w-full flex justify-end">
					<p className="text-sm text-gray-500">{title.length}/300</p>
				</div>
			</div>
		</Modal>
	)
}
