import React from 'react'
import { Link } from 'react-router-dom'
import KineContext from 'src/context/KineContext'
import { commentOnPost, getAllPosts, getCommentsOnPost, getPost, removePost } from 'src/service/social/socialService'
import RichTextDisplay from '../social/components/createTweet/RichText/Display'
import ImagesDisplay from '../social/components/feed/components/ImagesDisplay'
import { Tooltip } from '@mui/material'

import { Loading } from 'src/components/loading'
import CommentBox from '../social/components/feed/components/CommentBox/CommentBox'

import './styles.scss'
import InputText from 'src/components/_Dsc/InputText'
import Button from 'src/components/_Dsc/Button'
import HorizontalDivider from 'src/components/_Dsc/divider'
const SocialPost: React.FC = () => {
	const { user, apiState, profile, setGlobalToast } = React.useContext(KineContext)
	const [isComment, setIsComment] = React.useState(false)
	const [isFetch, setIsFetch] = React.useState(true)
	const [comments, setComments] = React.useState<any>([])
	const [getComments, setGetComments] = React.useState(false)
	const [removeFetch, setRemoveFetch] = React.useState(false)
	const [isWriteComment, setIsWriteComment] = React.useState('')
	const [content, setContent] = React.useState('')

	const [tweet, setTweet] = React.useState<any>([])
	const [hiddenInput, setHiddenInput] = React.useState(false)

	const showCommentsOnPost = async (id: string) => {
		setIsWriteComment(id)
		setGetComments(true)
		const comments = await getCommentsOnPost(id, apiState)
		setGetComments(false)
		setComments(comments)
	}

	const commentOnPostById = async (id: string) => {
		setIsComment(true)
		let req = await commentOnPost(id, content, user, setGlobalToast, tweet.address)
		if (req) {
			if (req.data) comments.push(req.data)
		}
		//comments.push(req.data)
		setContent('')
		setIsComment(false)
	}
	const newFormatedDate = () => {
		const date = new Date(tweet.time)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const year = date.getFullYear()
		const hours = String(date.getHours()).padStart(2, '0')
		const minutes = String(date.getMinutes()).padStart(2, '0')

		return `${day}/${month}/${year} - ${hours}:${minutes}`
	}

	const removePostById = async (id: string) => {
		setRemoveFetch(true)
		setGlobalToast(true)
		const payload = {
			user: profile.id.toString(),
		}
		await removePost(id, payload)
		setRemoveFetch(false)
		await getAllPosts(false)
		setGlobalToast(false)
	}

	const getTweetData = async (id: string) => {
		if (id) {
			setIsFetch(true)
			let req = await getPost(id)

			if (req && req.data) {
				setTweet(req.data[0])
				setIsFetch(false)
				showCommentsOnPost(req.data[0].id)
				return true
			} else {
				setIsFetch(false)
			}
		} else {
			setIsFetch(false)
		}
	}

	/**
   * 
   *  const likePost = async (id: any) => {
		await commentLike(id)
		await showCommentsOnPost(tweet.id)
	}
   */

	const getCurrentComments = async (id: string) => {
		//setIsWriteComment(id)
		const comments = await getCommentsOnPost(id, apiState)

		setComments(comments)
	}

	const changeComment = (e: string) => {
		setContent(e)
	}

	React.useEffect(() => {
		getTweetData(window.location.pathname.split('post/')[1])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="social-post flex flex-col w-full h-full gap-1 home-social mt-[2px !important]">
			{!isFetch ? (
				<div className=" tweet flex flex-col mb-2 rounded-[4px] shadow-default gap-1">
					<div className="flex items-start bg-white border-[#efefef] gap-[10px] overflow-hidden  cursor-pointer p-2">
						<img
							src={
								tweet.avatarSrc
									? tweet.avatarSrc
									: 'https://media.istockphoto.com/id/522855255/vector/male-profile-flat-blue-simple-icon-with-long-shadow.jpg?s=612x612&w=0&k=20&c=EQa9pV1fZEGfGCW_aEK5X_Gyob8YuRcOYCYZeuBzztM='
							}
							alt="#"
							className="avatar"
						/>
						<div className="tweet-message truncate w-full">
							<div className="w-full justify-between items-center flex">
								<Link to={'/social/view/' + tweet.address} className="truncate">
									<b>{tweet.username}</b>
								</Link>
								<span className=" truncate min-w-[200px] items-end flex justify-end">{newFormatedDate()}</span>
							</div>
							<RichTextDisplay initialValue={tweet.message} readOnly={true} />

							{tweet.urls && tweet.urls.length >= 1 && tweet.urls[0] !== '' && <ImagesDisplay urls={tweet.urls} />}
						</div>
					</div>
					{/**
       * <span className=" truncate min-w-[200px] items-end flex justify-end mt-1 h-[20px]">
      {type && type.length && type.includes(',') ? (
        type
          .replace(/,/g, '#')
          .split('#')
          .filter(Boolean)
          .map((tag, index) => (
            <span key={index} className="mr-2 text-blue-800 hover:underline cursor-pointer">
              <span>#{tag}</span>
            </span>
          ))
      ) : (
        <span className="mr-2 text-blue-800 hover:underline cursor-pointer">#{type}</span>
      )}
    </span>
       */}

					<div className="flex items-center ml-2  text-sm  justify-end">
						{user && user.address ? (
							<>
								{profile && tweet.user_id === profile.id && !removeFetch ? (
									<Tooltip title="Delete post" placement="top-start">
										<button
											className="flex items-center hover:bg-pink-100 hover:font-bold p-2 rounded-lg cursor-pointer"
											onClick={() => {
												removePostById(tweet.id)
											}}>
											<svg
												className="w-6 h-6 text-gray-800 dark:text-white"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24">
												<path
													stroke="currentColor"
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
												/>
											</svg>
										</button>
									</Tooltip>
								) : (
									profile && tweet.user_id === profile.id && !removeFetch && <Loading />
								)}
							</>
							
						) : ''}
					</div>

					{isWriteComment === tweet.id && user.address ?(
						<div className="w-full flex flex-col  overflow-y-auto rounded-[8px] p-2 bg-white gap-2">
							<div className="h-[60px]">
								<InputText type="text" placeholder="write your comment" value={content} onChange={changeComment} />
							</div>

							<div className="flex w-full items-center">
								{user.address && !isComment && (
									<div className="flex w-full items-center  justify-end">
										{content.length > 1 ? (
											<Button
												size="small"
												value="Comment"
												onClick={() => {
													commentOnPostById(tweet.id)
												}}></Button>
										) : (
											<Button value="Post" size="small"></Button>
										)}
									</div>
								)}
							</div>
						</div>
					) : ''}
					{getComments ? (
						<div role="status">
							<Loading />
						</div>
					) : (
						<CommentBox
							comments={comments}
							id={window.location.pathname.split('post/')[1]}
							hiddenInput={hiddenInput}
							setOnAnswer={setHiddenInput}
							getCurrentComments={getCurrentComments}
						/>
					)}
				</div>
			) : (
				<Loading />
			)}
		</div>
	)
}

export default SocialPost
