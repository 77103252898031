import React from 'react'
import './style.scss'

interface Props {
	data: string[]
	sx: any
	currentBlockNumber: string
}

const BlockCounter: React.FC<Props> = ({ data, sx, currentBlockNumber }) => {
	const [counter, setCounter] = React.useState<number>(0)
	const [timeExpired, setTimeExpired] = React.useState<boolean>(false)
	const [isVerify, setIsVerify] = React.useState<boolean>(true)

	React.useEffect(() => {
		if (currentBlockNumber && data) {
			setIsVerify(true)

			let currentBlockNumberInt = parseInt(currentBlockNumber.replace(/,/g, ''))
			let parsedData1Int = parseInt(data[1].replace(/,/g, ''))

			// Calculating the difference
			let difference = Math.abs(currentBlockNumberInt - parsedData1Int) * 6

			setCounter(difference)
			const interval = setInterval(() => {
				setCounter((prevCounter: number) => {
					if (prevCounter > 0) {
						return prevCounter - 1
					} else {
						setTimeExpired(true)
						clearInterval(interval)
						return 0
					}
				})
				if (isVerify) {
					setIsVerify(false)
				}
			}, 1000)

			return () => clearInterval(interval)
		}
	}, [data, currentBlockNumber])

	return <div>{!isVerify && <p style={sx}>{timeExpired ? 'Ended' : formatTime(counter)}</p>}</div>
}

// Helper function to format time into JSX elements
const formatTime = (seconds: number): JSX.Element => {
	const days = Math.floor(seconds / (3600 * 24))
	seconds %= 3600 * 24 // remaining seconds after calculating days
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const remainingSeconds = seconds % 60

	// Format as "Xd:HH:MM" if more than one day
	if (days > 0) {
		return (
			<div className="calendar-body flex items-center gap-2">
				<div className="p-1">
					<p className="calendar-number">{days}</p>
					<p>Days</p>
				</div>
				<div className="p-1">
					<p className="calendar-number">{String(hours).padStart(2, '0')}</p>
					<p>Hours</p>
				</div>
				<div className="p-1">
					<p className="calendar-number">{String(minutes).padStart(2, '0')}</p>
					<p>Minutes</p>
				</div>
			</div>
		)
	} else {
		// Format as "HH:MM:SS" if less than one day
		return (
			<div className="calendar-body flex items-center gap-2">
				<div className="p-1">
					<p className="calendar-number">{String(hours).padStart(2, '0')}</p>
					<p>Hours</p>
				</div>
				<div className="p-1">
					<p className="calendar-number">{String(minutes).padStart(2, '0')}</p>
					<p>Minutes</p>
				</div>
				<div className="p-1">
					<p className="calendar-number">{String(remainingSeconds).padStart(2, '0')}</p>
					<p>Seconds</p>
				</div>
			</div>
		)
	}
}

export default BlockCounter
