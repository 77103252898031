import React from 'react'
import '../badge/styles.scss'

interface Props {
	children: React.ReactNode
	isSelected: boolean
}

const DscTab: React.FC<Props> = ({ isSelected, children }) => {
	return (
		<div className="dsc-tab">
			<div>{children}</div>
			{isSelected ? (
				<div className="tab-selected" style={{ minHeight: '3px', background: '#000' }}></div>
			) : (
				<div className="tab-selected" style={{ minHeight: '3px', background: 'transparent' }}></div>
			)}
		</div>
	)
}

export default DscTab
