import { web3FromAddress } from '@polkadot/extension-dapp'
import { Keyring } from '@polkadot/api'
import Swal from 'sweetalert2'
import { AccountId } from '@polkadot/types/interfaces'
import withReactContent from 'sweetalert2-react-content'
import { User } from '../../interface'
import { createPost } from '../social/socialService'
import { SetStateAction } from 'react'
import React from 'react'
import { NotificationService } from '../notification/notificationService'
import { planksToTokens, tokensToPlanks } from '../substrate/formated'
import API from '../api'
import Cookies from 'js-cookie'
const getFestivals = async (param = false, api: any) => {
	try {
		let request = await api.query.festivalModule.festivals.entries()
		let maped: any = []
		let notShow: any = []
		request?.forEach((value: any) => {
			if (!param) {
				if (value[1].toHuman()?.status === 'Active') {
					if (!notShow.includes(value[1].toHuman()?.id)) {
						maped.push(value[1].toHuman())
					}
				}
			} else {
				return maped.push(value[1].toHuman())
			}
		})

		return maped
	} catch (err) {
		return err
	}
}

const getFestivalsWinner = async (param = false, api: any) => {
	try {
		let request = await api.query.festivalModule.festivals.entries()
		let maped: any = []
		let notShow: any = []
		request?.forEach((value: any) => {
			if (!param) {
				let formated = value[1].toHuman()
				if (
					formated &&
					(formated.status === 'Finished' || formated.status === 'FinishedNotEnoughVotes') &&
					formated.voteMap &&
					Object.keys(formated.voteMap).length > 0
				) {
					if (!notShow.includes(value[1].toHuman()?.id)) {
						maped.push(value[1].toHuman())
					}
				}
			} else {
				return maped.push(value[1].toHuman())
			}
		})

		return maped
	} catch (err) {
		return err
	}
}

const getFestivalsById = async (id: any, api: any) => {
	try {
		let request: any = await api.query.festivalModule.festivals(id)
		return request?.toHuman()
	} catch (err) {
		return err
	}
}

const getMoviesInFestival = async (arr: Array<string>, api: any) => {
	try {
		const movies: any[] = await Promise.all(
			arr.map(async id => {
				const get: any = await api.query.movieModule.movies(id)
				if (get && get.toHuman()) {
					const movieData = get.toHuman()
					movieData['id'] = id
					return movieData
				}
				return null
			}),
		)
		return movies.filter(movie => movie !== null)
	} catch (err) {
		console.log(err)
	}
}

export const getCurrentAccBlock = async (api: any) => {
	if (api && api.query) {
		const get: any = await api.query.system.number()
		return get.toHuman()
	}
}

const createFestival = async (
	title: String,
	owner_name: string,
	description: String,
	maxEntry: any,
	_endTime: any,
	user: User,
	_totalNumber: any,
	accCategories: any,
	api: any,
	links?: any,
	setOpen?: any,
	setGlobalToast?: any,
) => {
	try {
		if (setGlobalToast) setGlobalToast(true)
		const MySwal = withReactContent(Swal)
		const connect = await api
		const injector = await web3FromAddress(user.address)

		let categoryTagList: any = []
		if (accCategories) {
			accCategories.forEach((val: any) => {
				categoryTagList.push([val.category, val.subCategory])
			})
		}

		const externalMovieIds = links ? links.split(',') : []
		const send = await connect.tx.festivalModule.createFestival(
			owner_name,
			title,
			description,
			tokensToPlanks(maxEntry),
			[],
			externalMovieIds,
			categoryTagList,
		)
		let formatedTags: string = ''
		if (categoryTagList && categoryTagList[0]) {
			categoryTagList[0].forEach((val: any) => {
				formatedTags += val + ','
			})
		}
		let lastId = await getLastedCreatedFestival(user, api)
		const payload = {
			title: title,
			description: description,
			owner: user.address,
			tags: formatedTags,
			status: 'pending',
			constellation_id: lastId,
		}

		if (setOpen) {
			await new Promise((resolve, reject) => {
				send
					.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
						if (result.status.isFinalized) {
							resolve(result)
							setOpen(true)
							setGlobalToast(false)
							//NotificationService.sendNotify(user.address, 'New constellation created, remember to activate it', 1, 0)
							//	API.post(`/api/constellations/`, payload)
						} else if (result.isError) {
							setGlobalToast(false)
							setOpen(false)
							return false
						} else {
							return false
						}
					})
					.catch((error: any) => {
						setGlobalToast(false)
						setOpen(false)
						if (error.message.includes('Cancelled')) {
							console.log('Transaction signing was cancelled by the user.')
							Swal.fire({
								icon: 'info',
								title: 'Oops...',
								text: 'Transaction signing was cancelled by the user.',
								footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
								willClose: () => {
									//window.location.reload();
								},
							})
							return false
						} else {
							Swal.fire({
								icon: 'info',
								title: 'Oops...',
								text: 'Unable to proceed with your transaction check your balance or chain status.',
								footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
								willClose: () => {
									//window.location.reload();
								},
							})
							return false
						}
					})
			})
		} else {
			await send
				.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
					if (result.status.isFinalized) {
						API.post(`/api/constellations/`, payload)
					} else if (result.isError) {
						console.log(result)
					} else {
						console.log(`Transação em andamento. Status: ${result.status}`)
					}
				})
				.catch((error: any) => {
					setGlobalToast(false)
					setOpen(false)
					if (error.message.includes('Cancelled')) {
						console.log('Transaction signing was cancelled by the user.')
						Swal.fire({
							icon: 'info',
							title: 'Oops...',
							text: 'Transaction signing was cancelled by the user.',
							footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
							willClose: () => {
								//window.location.reload();
							},
						})
						return false
					} else {
						console.error('Unexpected error:', error)
						return false
					}
				})

			MySwal.fire({
				title: <strong>Success!</strong>,
				html: <i> Your order has entered the queue and is already being processed.</i>,
				icon: 'success',
				willClose: () => {
					//	window.location.reload()
				},
			})
		}

		return true
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				//window.location.reload();
			},
		})
		console.log(err)
		return false
	}
}

const getFestivalsByOwner = async (user: string, apiState: any) => {
	try {
		const keyring = new Keyring({ type: 'sr25519' })
		const addressed = user
		const accountId = keyring.decodeAddress(addressed) as AccountId
		let request = await apiState.query.festivalModule.festivalOwners(accountId)
		return request?.toHuman()
	} catch (err) {
		return err
	}
}

const sendVoteInMovie = async (
	user: User,
	festivalId: string,
	movieId: string,
	amount: any,
	api: any,
	owner: string,
	showSnackBar: any,
	name: string,
) => {
	try {
		const connect = await api
		const injector = await web3FromAddress(user.address)
		let req = await api.query.system.account(user.address)
		let formated = req.toHuman()
		let currentBalance: any =
			formated && formated.data && formated.data.free && formated.data.free ? planksToTokens(formated.data.free) : 0

		if (currentBalance && parseInt(currentBalance) >= parseInt(amount)) {
			const send = await connect.tx.festivalModule.voteForMovieInFestival(
				festivalId,
				movieId,
				tokensToPlanks(parseInt(amount)),
				name,
			)

			await new Promise((resolve, reject) => {
				showSnackBar(true, 'loading')
				send.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
					if (result.status.isFinalized) {
						NotificationService.sendNotify(owner, `Your festival received a vote::${festivalId}`, 1, 0)
						const payload = {
							constellation_id: festivalId,
							user: user.address,
							amount: tokensToPlanks(amount),
							key: movieId,
						}
						API.post(`/api/constellations/vote`, payload)
						showSnackBar(true, 'done', true)
						return true
					} else if (result.isError) {
						showSnackBar(true, 'error', true)
						Swal.fire({
							icon: 'info',
							title: 'Ops...',
							text: result,
							footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
							willClose: () => {
								//window.location.reload()
							},
						})
						return false
					} else {
						console.log(`Transação em andamento. Status: ${result.status}`)
					}
				})
			})
		} else {
			Swal.fire({
				icon: 'info',
				title: 'Not enough tokens...',
				text: 'Check your amount of tokens to proceed with this transaction',
				footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
				willClose: () => {
					//window.location.reload()
				},
			})
			return false
		}
	} catch (err) {
		showSnackBar(false, 'error', true)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				//	window.location.reload()
			},
		})
		return false
	}
}

const insertMovie = async (festivalId: string, movieId: string, user: User, api: any) => {
	try {
		const MySwal = withReactContent(Swal)
		const connect = await api
		const injector = await web3FromAddress(user.address)

		const send = connect.tx.festivalModule.addMovieToFestival(festivalId, movieId)

		await send.signAndSend(user.address, { signer: injector.signer }, (_result: any) => {
			// window.location.reload()
		})
		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
		})
	} catch (err) {
		let userData = Cookies.get('kinera-cookie')
		if (userData) {
			//Cookies.remove('kinera-cookie')
		}

		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				window.location.reload()
			},
		})
	}
}

const insertExternalMovie = async (
	festivalId: string,
	_source: string = '',
	url: string,
	user: User,
	setUpload: any,
	api: any,
) => {
	try {
		const obj = [url]
		const MySwal = withReactContent(Swal)
		const connect = await api
		const injector = await web3FromAddress(user.address)

		const getCurrent = await getFestivalsById(festivalId, api)
		if (getCurrent) {
			const send = connect.tx.festivalModule.addMoviesToFest(festivalId, [], obj)

			await send.signAndSend(user.address, { signer: injector.signer }, (_result: any) => {
				// window.location.reload()
			})
			MySwal.fire({
				title: <strong>Success!</strong>,
				html: <i> Your order has entered the queue and is already being processed.</i>,
				icon: 'success',
			})

			setUpload()
		}
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				window.location.reload()
			},
		})
	}
}

const createTag = async (tagName: string, user: User, api: any) => {
	try {
		const MySwal = withReactContent(Swal)
		const connect = await api
		const injector = await web3FromAddress(user.address)
		const send = connect.tx.festivalModule.createCategory(tagName)

		await send.signAndSend(user.address, { signer: injector.signer }, (_result: any) => {
			// window.location.reload()
		})
		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
		})
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				window.location.reload()
			},
		})
	}
}

const activeFestival = async (
	id: string,
	endTime: any,
	totalNumber: any,
	user: User,
	api: any,
	description: string,
	setGlobalToast: any,
	categorie: string,
	setGlobalSnack: (value: SetStateAction<any>) => void,
	close?: any,
) => {
	try {
		const MySwal = withReactContent(Swal)
		const connect = await api
		const injector = await web3FromAddress(user.address)
		const blockNumber: any = await connect.query.system.number()
		const ccBlcok: any = parseInt(blockNumber) + 2

		let endTimeBlock
		if (endTime === 'm') {
			endTimeBlock = (totalNumber * 60) / 6
		} else if (endTime === 'h') {
			endTimeBlock = (totalNumber * 3600) / 6
		} else {
			endTimeBlock = (totalNumber * 86400) / 6
		}

		const send = connect.tx.festivalModule.activateFestivalAsap(parseInt(id), parseInt(ccBlcok + endTimeBlock))

		await new Promise((resolve, reject) => {
			send
				.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
					if (result.status.isFinalized) {
						resolve(result)
						createPost(user, description, 'Festival', [], setGlobalToast, true, categorie, parseInt(id), setGlobalSnack)
						NotificationService.sendNotify(user.address, `Your constellation was successfully activated::${id}`, 1, 0)
					} else if (result.isError) {
						reject(result)
					} else {
						console.log(`Transação em andamento. Status: ${result.status}`)
					}
				})
				.catch((error: any) => {
					if (error.message.includes('Cancelled')) {
						console.log('Transaction signing was cancelled by the user.')
						Swal.fire({
							icon: 'info',
							title: 'Oops...',
							text: 'Transaction signing was cancelled by the user.',
							footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
							willClose: () => {
								//window.location.reload();
							},
						})
						close ? close() : console.log('closed')
						return false
					} else {
						console.error('Unexpected error:', error)
						return false
					}
				})
		})

		MySwal.fire({
			title: '<strong>Success!</strong>',
			html: '<i>Your order has entered the queue and is already being processed.</i>',
			icon: 'success',
		}).then(result => {
			if (result.value) {
				// O usuário clicou em "OK", então atualize a página
				window.location.reload()
			}
		})
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				window.location.reload()
			},
		})
	}
}

const getAllTags = async (api: any) => {
	//console.log('entrei')
	try {
		//const keyring = new Keyring({ type: 'sr25519' });
		let request = await api.query.tagsModule.categories.entries()
		let arr: any = [{ key: 'All', param: ['All', 'All'] }]
		request.map(async (value: any) => {
			if (value[0]?.toHuman()[0].includes('Festival')) {
				arr.push({ value: value[1]?.toHuman()?.tagList, param: value[0]?.toHuman()[0][1] })
			}
		})
		if (arr && arr.length > 0) {
			arr.sort((a: any, b: any) => {
				if (typeof a.param === 'string' && typeof b.param === 'string') {
					return a.param.localeCompare(b.param)
				}
				return 0
			})
		}
		// Log the sorted array to the console (or return it from a function)
		//console.log(arr)
		return arr
	} catch (err) {
		return err
	}
}

const getUserWinAndOwnerFestivals = async (address: string, key: string, apiState: any) => {
	try {
		const keyring = new Keyring({ type: 'sr25519' })

		const accountId = keyring.decodeAddress(address) as AccountId
		let allFestivals: any = []
		let request = await apiState.query.festivalModule.walletFestivalData(accountId)
		let humanData: any = request.toHuman()

		if (key && humanData && humanData[key]) {
			const festivalPromises = humanData[key].map(async (value: any) => {
				return await getFestivalsById(value, apiState)
			})
			const festivalResults = await Promise.all(festivalPromises)
			allFestivals = festivalResults

			return allFestivals
		} else {
			return request?.toHuman()
		}
	} catch (err) {
		return err
	}
}

const getLastedCreatedFestival = async (user: User, apiState: any) => {
	try {
		const keyring = new Keyring({ type: 'sr25519' })

		const accountId = keyring.decodeAddress(user.address) as AccountId
		let lastFestival: any = null
		let request = await apiState.query.festivalModule.walletFestivalData(accountId)
		let humanData: any = request.toHuman()
		if (humanData && humanData.awaitingActivationFestivals) {
			const festivals = humanData.awaitingActivationFestivals
			lastFestival = festivals.length >= 2 ? festivals[festivals.length - 1] : festivals[0]
		}
		console.log('esa', lastFestival)
		return lastFestival
	} catch (err) {
		return err
	}
}

const getUserFestivalWalletData = async (user: User, apiState: any) => {
	try {
		const keyring = new Keyring({ type: 'sr25519' })

		const accountId = keyring.decodeAddress(user.address) as AccountId

		let request = await apiState.query.festivalModule.walletFestivalData(accountId)
		let humanData: any = request.toHuman()

		return humanData
	} catch (err) {
		return err
	}
}
const activeAsapFestival = async (
	user: User,
	connect: any,
	id: any,
	totalNumber: number,
	endTime: string,
	setGlobalToast: any,
	description: string,
	setGlobalSnack: (value: SetStateAction<any>) => void,
) => {
	try {
		const blockNumber: any = await connect.query.system.number()
		const ccBlcok: any = parseInt(blockNumber) + 2
		const injector = await web3FromAddress(user.address)
		let endTimeBlock
		if (endTime === 'm') {
			endTimeBlock = (totalNumber * 60) / 6
		} else if (endTime === 'h') {
			endTimeBlock = (totalNumber * 3600) / 6
		} else {
			endTimeBlock = (totalNumber * 86400) / 6
		}

		endTimeBlock = Math.ceil(endTimeBlock)

		const send = connect.tx.festivalModule.activateFestivalAsap(parseInt(id), parseInt(ccBlcok + endTimeBlock))

		await new Promise((resolve, reject) => {
			send.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
				if (result.status.isFinalized) {
					resolve(result)
					setGlobalToast(false)
					createPost(user, description, 'Festival', [], setGlobalToast, true, id, id, setGlobalSnack)
					NotificationService.sendNotify(user.address, `Your festival, has been successfully actived::${id}`, 1, 0)
				} else if (result.isError) {
					reject(result)
					setGlobalToast(false)
				} else {
					console.log(`Transação em andamento. Status: ${result.status}`)
				}
			})
		})
		return true
	} catch (err) {
		setGlobalToast(false)
		console.log('err', err)
	}
	setGlobalToast(false)
}

const removeMovie = async (id: string, _param: any, data: string, user: User, apiState: any) => {
	try {
		const MySwal = withReactContent(Swal)
		const connect = await apiState
		const injector = await web3FromAddress(user.address)
		const obj = [data]
		//	console.log('id', id)
		//console.log('_param', _param)
		//console.log('data', data)
		const send = connect.tx.festivalModule.removeMoviesFromFest(id, [], obj)

		await send.signAndSend(user.address, { signer: injector.signer }, (_result: any) => {
			// window.location.reload()
		})

		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
			willClose: () => {
				window.location.reload()
			},
		})
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				//	window.location.reload()
			},
		})
	}
}

const getVotesByConstellationId = async (id: string, key: string) => {
	try {
		let encoded = encodeURIComponent(key)
		let request = await API.get(`/api/constellations/vote/${id}/${encoded}`)
		return request.data
	} catch (e) {
		console.log('e')
	}
}

const getVotesByConstellationWinners = async (id: string) => {
	try {
		let request = await API.get(`/api/substrate/winners/${id}`)
		return request.data
	} catch (e) {
		console.log('e')
	}
}

const editFestivalData = async (id: string, title: String, description: string, user: User, api: any, action: any) => {
	try {
		const connect = await api
		const injector = await web3FromAddress(user.address)

		const send = await connect.tx.festivalModule.editFestivalDetails(id, title, description)
		action(true, 'loading')
		await send
			.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
				if (result.status.isFinalized) {
					action(true, 'done', true)
					return true
				} else if (result.isError) {
					action(true, 'error', true)
					return false
				} else {
					console.log(`Transação em andamento. Status: ${result.status}`)
				}
			})
			.catch((error: any) => {
				action(true, 'error', true)
				if (error.message.includes('Cancelled')) {
					console.log('Transaction signing was cancelled by the user.')
					Swal.fire({
						icon: 'info',
						title: 'Oops...',
						text: 'Transaction signing was cancelled by the user.',
						footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
						willClose: () => {
							//window.location.reload();
						},
					})
					return false
				} else {
					console.error('Unexpected error:', error)
					return false
				}
			})
	} catch (err) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				//window.location.reload();
			},
		})
		console.log(err)
		return false
	}
}

export const FestivalService = {
	getFestivals,
	getFestivalsById,
	getMoviesInFestival,
	createFestival,
	getFestivalsByOwner,
	insertMovie,
	sendVoteInMovie,
	insertExternalMovie,
	createTag,
	getAllTags,
	getUserWinAndOwnerFestivals,
	activeFestival,
	getLastedCreatedFestival,
	activeAsapFestival,
	getCurrentAccBlock,
	getUserFestivalWalletData,
	getFestivalsWinner,
	removeMovie,
	getVotesByConstellationId,
	getVotesByConstellationWinners,
	editFestivalData,
}
