import React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Header from '../layout/header/Header'
import Config from '../pages/config/Config'
import Details from '../pages/details/Details'
import Festivals from '../pages/festivals/Festivals'
import Home from '../pages/home/Home'
import Profile from '../pages/profile/Profile'
import CreateProfile from '../pages/social/createProfile/CreateProfile'
import SocialView from '../pages/socialView'
import Login from '../pages/login/Login'
import HomeSocial from '../pages/social/HomeSocial/HomeSocial'
import RankingDetails from '../pages/ranking'
import Rankings from '../pages/rankingLists'

import Historic from '../pages/historic'
import Moderation from '../pages/moderation'
import Veredicts from '../pages/moderation/components/veredicts'
import Earnings from '../pages/earnings'

import KineContext from '../context/KineContext'
import CreateConstelation from '../pages/createConstelations'
import EditProfile from '../pages/social/editProfile'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import MyConstelattions from '../pages/myConstellations'

import PostDetails from '../pages/profile'
import WinnersList from '../pages/winnersList'

import SocialPost from 'src/pages/socialPost'

import CustomTools from 'src/pages/customTools'
import MainLayout from 'src/layout'
import AnyToAny from 'src/pages/customTools/anytoany'
import MyDocuments from 'src/pages/myDocuments'
import VotedRankingsAndConstellations from 'src/pages/votedRankingsConstellations'
import UserFavorites from 'src/pages/favorites'
import Toast from 'src/components/_Dsc/toast'

import CreateCommunityProposal from 'src/pages/createCommunityProposal'
import CommunityProposal from 'src/pages/communityProposal'
import UserCommunities from 'src/pages/userCommunities'

import Communities from 'src/pages/communities'
import CommunityDetails from 'src/pages/community'
import { GPT } from 'src/components/GPT'
import { SocialMenseger } from 'src/pages/chat'
import { ChatRoom } from 'src/pages/chat/components/Chat'
import { DigitalTwin } from 'src/pages/digitalTwin'
import { CharacterInteract } from 'src/pages/characterInteract'
import { BazzarPreview } from 'src/pages/bazzarPreview'
import { DialogAi } from 'src/pages/dialogAi'
import { Chats } from 'src/components/_Dsc/Chat'

export default function Router() {
	const { globalToast, globalMessage, onQueue, setOnQueue } = React.useContext(KineContext)
	const [isLogin, setIsLogin] = React.useState(false)

	const closeToast = () => {
		setOnQueue(false)
	}

	const location = useLocation()
	const isModerationRoute = location.pathname.includes('/moderation')
	const isProdutionTools = location.pathname.includes('/custom-tools')
	return (
		<div className="w-full h-full">
			<Header isLogin={isLogin} setIsLogin={setIsLogin} />

			<Routes>
				<Route path="/moderation" element={<Moderation />} />
				<Route path="/moderation/veredict/:id/:extraParam" element={<Veredicts />} />;
			</Routes>

			{!isModerationRoute && (
				<div
					className={
						isProdutionTools
							? 'w-full  m-auto flex  h-full pt-[79px] min-h-[100vh]'
							: 'w-full max-w-[1400px] m-auto flex  h-full pt-[79px] min-h-[100vh]'
					}
				>
					<Login isLogin={isLogin} setIsLogin={setIsLogin} />
					<MainLayout isLogin={isLogin} setIsLogin={setIsLogin}>
						<main
							className={
								location.pathname.includes('social')
									? 'justify-center flex  h-full'
									: 'hidden-scroll overflow-y-auto h-auto w-full overflow-x-hidden w-full h-full'
							}
						>
							<Routes>
								<Route path="/custom-tools" element={<CustomTools />} />
								<Route path="/anytoany" element={<AnyToAny />} />
								<Route path="/" element={<Home />} />
								<Route path="/winners-list" element={<WinnersList />} />
								<Route path="communities" element={<Communities />} />
								<Route path="/config" element={<Config />} />
								<Route path="/social" element={<HomeSocial />} />
								<Route path="/register-profile" element={<CreateProfile />} />
								<Route path="/profile" element={<Profile />} />
								<Route path="/constellations" element={<Festivals />} />
								<Route path="/rankings" element={<Rankings />} />
								<Route path="/historic" element={<Historic />} />
								<Route path="/reward" element={<Earnings />} />
								<Route path="/my-voteds" element={<VotedRankingsAndConstellations />} />
								<Route path="/my-constellations" element={<MyConstelattions />} />
								<Route path="/my-documents" element={<MyDocuments />} />
								<Route path="/favorites" element={<UserFavorites />} />
								<Route path="/messenger" element={<SocialMenseger />} />
								<Route path="/chat/:id" element={<ChatRoom />} />
								<Route path="/community-proposal" element={<CreateCommunityProposal />} />
								<Route path="/my-communities" element={<UserCommunities />} />
								<Route path="/create-Constellations" element={<CreateConstelation />} />
								<Route path="/community/:id" element={<CommunityDetails />} />
								<Route path="social/view/:id" element={<SocialView />} />
								<Route path="community/proposal/:id" element={<CommunityProposal />} />
								<Route path="social/post/:id" element={<SocialPost />} />
								<Route path="ranking/details/:id" element={<RankingDetails />} />
								<Route path="/details/:id" element={<Details />} />
								<Route path="/post/:id" element={<PostDetails />} />
								<Route path="/edit-profile/:id" element={<EditProfile />} />
								<Route path="/digital-twin" element={<DigitalTwin />} />
								<Route path="/project/:id" element={<CharacterInteract />} />
								<Route path="/bazzar-preview" element={<BazzarPreview />} />
								<Route path="/dialog-ai" element={<DialogAi />} />
							</Routes>
						</main>
					</MainLayout>

					<Backdrop sx={{ color: '#fff', zIndex: '9999' }} open={globalToast}>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Chats />
				</div>
			)}

			{onQueue && (
				<div className="fixed z-[10]  bottom-5 left-5">
					<Toast text={globalMessage} onClose={closeToast}>
						<p className={`text-sm  `}>{globalMessage}</p>
					</Toast>
				</div>
			)}

			<GPT />
		</div>
	)
}
