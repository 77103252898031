import React from 'react'
import { TextBold } from 'src/components/_Dsc/typography/TextLg'
import './styles.scss'
import PopularProfile from 'src/pages/social/components/popularProfile'
import { popularProfiles } from 'src/service/social/socialService'
const PopularProfiles: React.FC = () => {
	const [fetch, setFetch] = React.useState(true)
	const [profiles, setProfiles] = React.useState([])
	const getData = async () => {
		setFetch(true)
		let profiles = await popularProfiles()
		setProfiles(profiles)
		setFetch(false)
	}
	React.useEffect(() => {
		getData()
	}, [])
	return (
		<div className="side-nav-profiles">
			<div className="container-profiles">
				<div className="side-nav-profiles__header flex items-center justify-center">
					<TextBold text="Popular profiles" size="16" />
				</div>
				{!fetch &&
					profiles &&
					profiles.length &&
					profiles.map((val: any, i: number) => <PopularProfile data={val} key={i} />)}
			</div>
		</div>
	)
}

export default PopularProfiles
