import React, { useEffect } from 'react'

import './styles.scss'

import { ModerationService } from '../../service/moderation/moderationService'
import { Loading } from '../../components/loading'
import { Link } from 'react-router-dom'
import KineContext from '../../context/KineContext'
const Moderation: React.FC = () => {
	const [fetch, setFetch] = React.useState(false)

	const [allReports, setAllReports] = React.useState([] as any)
	const { apiState } = React.useContext(KineContext)

	const getAllReports = async () => {
		setFetch(false)
		let req = await ModerationService.getReports(apiState)
		setAllReports(req)

		setFetch(true)
	}

	useEffect(() => {
		if (apiState) {
			getAllReports()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<div className="w-full flex flex-col h-full">
			{fetch ? (
				<>
					<div className="w-full flex flex-col h-full">
						<div className="space-y-2 pb-8 pt-6 md:space-y-5 ml-20 mt-10">
							<h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
								Reports
							</h1>
							<p className="text-lg leading-7 text-gray-500 dark:text-gray-400">
								Follow and participate in the platform’s content reports
							</p>
						</div>
						<ul className="divide-y divide-gray-200 dark:divide-gray-700">
							{allReports && allReports.length ? (
								allReports.map((val: any) => (
									<li className="py-12 border-top: 1px solid #e5e7eb p-10">
										<article>
											<div className="space-y-2 xl:grid xl:grid-cols-4 xl:items-baseline xl:space-y-0">
												<dl className="truncate overflow-hidden">
													<div className="text-base font-medium leading-6 text-gray-500 dark:text-gray-400 truncate overflow-hidden">
														<p className="truncate mr-4">{val.val.reporterId}</p>
													</div>
												</dl>
												<div className="space-y-5 xl:col-span-3">
													<div className="space-y-6">
														<div>
															<h2 className="text-2xl font-bold leading-8 tracking-tight">
																<p className="text-gray-900 dark:text-gray-100">{val.name[0][1]}</p>
															</h2>
															<div className="flex flex-wrap">
																<p className="mr-3 text-sm font-medium uppercase text-primary-500 hover:text-primary-600 dark:hover:text-primary-400">
																	In Resolution
																</p>
																<p className="mr-3 text-sm font-medium uppercase text-primary-500 hover:text-primary-600 dark:hover:text-primary-400">
																	{val.name[0][1]}
																</p>
															</div>
														</div>
														<div className="prose max-w-none text-gray-500 dark:text-gray-400">
															{val.val.justification}
														</div>
													</div>
													<div className="text-base font-medium leading-6">
														<button
															className="text-primary-500 hover:text-primary-600 dark:hover:text-primary-400"
															aria-label='Read more: "Release of Tailwind Nextjs Starter Blog v2.0"'
														>
															<Link to={`veredict/${val.name[0][0]}/${val.name[0][1]}`}>Read more →</Link>
														</button>
													</div>
												</div>
											</div>
										</article>
									</li>
								))
							) : (
								<p className="mt-2 ml-4">No data to display</p>
							)}
						</ul>
						<div className="w-full flex flex-wrap"></div>
					</div>
				</>
			) : (
				<Loading />
			)}
		</div>
	)
}

export default Moderation
