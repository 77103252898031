import React, { useEffect, useState } from 'react'
import '../style.scss'
import { TextStyleBodyDefault, TextStyleBodyDefaultLarge, TextStyleBodyDefaultMedium, TextStyleCaption } from 'src/components/_Dsc/typography'
import Button from 'src/components/_Dsc/Button'
import axios from 'axios'
import { Loading } from 'src/components/loading'
import { PublishModal } from './PublishModal'
import KineContext from 'src/context/KineContext'
import { inserFileByPrompt, insertUserFile } from 'src/service/storage/Ipfs'
import { Skeleton } from '@mui/material'
import { Prompt } from './ImageToMovie'
import { createUserPrompt, getAllUsersPrompt } from 'src/service/profile/profileService'
import { formatDate } from 'src/constants/functions'

interface SelectedFile {
	img_url: string
	prompt: string
}

interface Props {
	communityId?: string
	isCommunity?: boolean
	setResponse?: (response: string) => void
	setLoading?: (param: boolean) => void
}

export const ImageGenerate: React.FC<Props> = ({ communityId, isCommunity, setResponse, setLoading }) => {
	const { user, handleGlobalMessage, setOnQueue } = React.useContext(KineContext)
	const [inFetch, setInFetch] = useState(false)
	const [inFetchPrompts, setInFetchPrompts] = useState(false)
	const [inputText, setInputText] = useState('')
	const [prompt, setPrompt] = useState('')
	const [selectedFile, setSelectedFile] = useState({} as SelectedFile)
	const [openModal, setOpenModal] = useState(false)
	const [responseRequest, setResponseRequest] = useState('')
	const [custom_title, setCustomTitle] = useState('')
	const [prompts, setPrompts] = useState<Prompt[]>([])

	const sendRequest = async (param?: string) => {
		try {
			setPrompt(inputText)
			setInputText('')
			if (setLoading) {
				setLoading(true)
			}
			setInFetch(true)
			const response = await axios.post(
				'https://open-gpt.kinera.network/images/api/v1/generations',
				{
					prompt: inputText,
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)
			await onCreatePrompt()
			if (response && response.data && response.data[0] && response.data[0].url) {
				if (setResponse && !isCommunity) {
					setResponse(response.data[0].url)
				}
				setResponseRequest(response.data[0].url)
			}
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setInFetch(false)
			if (setLoading) {
				setLoading(false)
			}
		}
	}

	const handleOpenModal = (img_url: string) => {
		let selectedFile = {
			img_url: img_url,
			prompt: prompt,
		}
		setOpenModal(true)
		setSelectedFile(selectedFile)
	}

	const onPublish = async () => {
		if (communityId) {
			isPublishOnCommunity()
		}
	}

	const isPublishOnCommunity = async () => {
		if (selectedFile && communityId) {
			setOpenModal(false)
			handleGlobalMessage('Your file is being processed and will soon be available')
			setOnQueue(true)

			try {
				//console.log("res", response)
				const file = await transformUrlToPngFile(`https://open-gpt.kinera.network${responseRequest}`)

				//console.log("file", file)

				// Passa o arquivo para a função `insertUserFile`
				await inserFileByPrompt(file, user.address, 'community_file', communityId, prompt, custom_title)
				setOpenModal(false)
			} catch (error) {
				console.error('Error processing the file:', error)
				handleGlobalMessage('An error occurred while processing the file')
			} finally {
				setOpenModal(false)
				setOnQueue(false)
			}
		}
	}

	const transformUrlToPngFile = async (imageUrl: string): Promise<File> => {
		try {
			// Faz o download da imagem como um blob
			const response = await fetch(imageUrl)

			// Verifica se o download foi bem-sucedido
			if (!response.ok) {
				throw new Error('Failed to fetch the image')
			}

			const blob = await response.blob()

			// Cria o arquivo com o blob (mesmo sem verificar o tipo explicitamente)
			const file = new File([blob], `image_${Date.now()}.png`, {
				type: 'image/png',
			})

			return file
		} catch (error) {
			console.error('Error transforming URL to file:', error)
			throw error
		}
	}

	const onDownload = () => {
		const link = document.createElement('a')
		link.href = `https://open-gpt.kinera.network${responseRequest}` // URL da imagem
		link.download = `generated_image_${Date.now()}.png` // Nome do arquivo
		link.click()
	}

	const onCreatePrompt = async () => {
		if (!communityId) {
			return
		}
		try {
			let req = await createUserPrompt(inputText, communityId, '1')
			if (req) {
				getAllUserPrompts()
			}
		} catch (error) {
			console.error('Error:', error)
		}
	}

	const getAllUserPrompts = async () => {
		if (!communityId) {
			return
		}
		try {
			setInFetchPrompts(true)
			let req = await getAllUsersPrompt('1')
			setPrompts(req)
			setInFetchPrompts(false)
		} catch (error) {
			console.error('Error:', error)
		}
	}

	useEffect(() => {
		getAllUserPrompts()
	}, [user])

	return (
		<div className="w-full h-full flex flex-col gap-2">
			{openModal ? (
				<PublishModal
					onConfirm={onPublish}
					open={openModal}
					file={selectedFile}
					setOpen={setOpenModal}
					setFileTitle={setCustomTitle}
				/>
			) : (
				''
			)}
			<div className="flex items-start gap-2">
				<div className="min-w-[50%]">
					{!setResponse ? (
						<div className="response w-full  justify-center">
							{inFetch ? (
								<div className="flex items-center justify-center p-2 w-full">
									<Skeleton animation="wave" variant="rectangular" width={410} height={218} />
								</div>
							) : (
								<div>
									{responseRequest && responseRequest.length ? (
										<div className="flex flex-col items-end gap-2">
											<img src={`https://open-gpt.kinera.network${responseRequest}`} />
											<div className="flex items-center gap-2">
												<Button value="Download" size="small" onClick={onDownload} />
												<Button
													value="Post to the community "
													size="small"
													onClick={() => {
														handleOpenModal(`https://open-gpt.kinera.network${responseRequest}`)
													}}
												/>
											</div>
										</div>
									) : (
										<TextStyleBodyDefaultMedium>
											Describe your image idea: include subject, style, mood, colours, and details.
										</TextStyleBodyDefaultMedium>
									)}
								</div>
							)}
						</div>
					) : (
						''
					)}
					<div className="input-style flex items-end  flex-col p-2 gap-2 bg-white rounded-[4px]">
						<textarea
							maxLength={300}
							id="description"
							rows={4}
							value={inputText}
							onChange={e => setInputText(e.target.value)}
							className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
							placeholder="Start typing"></textarea>
						<Button value="Send" icon="send" size="small" onClick={sendRequest} disabled={inFetch || !inputText} />
					</div>
					<div className="mt-2 bg-[#ececec] pl-2">
						<TextStyleBodyDefaultLarge className="text-sm mt-2 w-full items-center gap-1  flex  font-bold">
							<span className="material-symbols-outlined text-lg">settings</span>
							Engine
						</TextStyleBodyDefaultLarge>
						<div className="engine flex flex-col">
							<p className="flex  gap-2 title text-2xl ">
								<img src="https://framerusercontent.com/images/7Nhoxwn9eWYrqKjEewfXutR90U.png?scale-down-to=1024" width={'30px'} alt="Comfy Logo" />
								Comfy
							</p>
							
							<div className="flex   gap-2">
								<p className=" text-sm">Dream shapper</p>
								<span className="material-symbols-outlined">image</span>
							</div>
						</div>
					</div>
				</div>
				<div className="min-w-[50%] max-h-[500px] overflow-y-auto flex flex-col gap-2 p-[5px]">
					{!inFetchPrompts ? (
						prompts && prompts.length > 0 ? (
							prompts.map((prompt: Prompt, key: number) => (
								<div key={key} className="p-2 bg-[#ececec] rounded-[4px]">
									<div className="w-full flex items-center justify-between">
										<TextStyleBodyDefault>@{prompt.owner_name}</TextStyleBodyDefault>
										<TextStyleCaption>{formatDate(prompt.created_at)}</TextStyleCaption>
									</div>
									<TextStyleCaption>{prompt.prompt_text}</TextStyleCaption>
								</div>
							))
						) : (
							''
							
						)
					) : (
						<Loading />
					)}
				</div>
			</div>
		</div>
	)
}
