import React, { useState } from 'react'
import UserFestivals from '../profile/Components/myFestivals'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleH1 } from 'src/components/_Dsc/typography'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import KineContext from 'src/context/KineContext'
import { useNavigate } from 'react-router-dom'
import DscTab from 'src/components/_Dsc/tab'
import MyVotedFromConstellations from './components/MyConstellationsVoted'
import MyVotedFromRankings from './components/MyRankingsVoted'

interface Categorie {
	label: string
	value: string
}

const VotedRankingsAndConstellations: React.FC = () => {
	const { user, apiState } = React.useContext(KineContext)

	const [currentTab, setCurrentTab] = useState('rankings')
	const navigate = useNavigate()
	const [isSelected, setIsSelected] = useState('Voted Rankings')
	const path = [
		{ label: 'Home', link: '/' },
		{ label: 'My holdings', link: '' },
	]
	const categories = [
		{
			label: 'Voted Rankings',
			value: 'rankings',
		},
		{
			label: 'Voted Constellations',
			value: 'constellations',
		},
	]

	const changeTab = (tab: Categorie) => {
		setCurrentTab(tab.value)
		setIsSelected(tab.label)
	}

	React.useEffect(() => {
		if (!user || !user.address) {
			navigate('/')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	return (
		<div className="w-full h-full page-padding">
			<Breadcrumb routes={path} />
			<TextStyleH1>My holdings</TextStyleH1>
			<CardInner className="bg-white">
				<CardContainer>
					<div className="w-full flex items-center ">
						{categories.map((val: Categorie, i: number) => (
							<div key={i}>
								<DscTab isSelected={val.label === isSelected}>
									<button onClick={() => changeTab(val)}>{val.label}</button>
								</DscTab>
							</div>
						))}
					</div>
					{currentTab === 'constellations' ? (
						<MyVotedFromConstellations />
					) : currentTab === 'rankings' ? (
						<MyVotedFromRankings />
					) : (
						''
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default VotedRankingsAndConstellations
