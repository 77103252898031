import React, { useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import Logo from '../../../assets/icons.svg'
import { TextStyleBodyDefault, TextStyleCaption } from 'src/components/_Dsc/typography'
import { deleteImageFromUserFolder, storage, uploadImageToUserFolder } from 'src/service/storage/firebase'
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import KineContext from 'src/context/KineContext'
import { Loading } from 'src/components/loading'
import {
	changeImageToPrivate,
	getUserFiles,
	insertFileInDB,
	publishImageToPublic,
} from 'src/service/storage/userImages'

interface Props {
	onClose: () => void
	selectedImage: any
	isProfile?: boolean
}
const serverAddress = 'https://ia.kinera.network'

const ViewFile: React.FC<Props> = ({ onClose, selectedImage, isProfile }) => {
	const { user, setCurrentUserStorage, verifyCurrentUserStorage, setGlobalToast } = React.useContext(KineContext)
	const [inFetch, setFetch] = useState<boolean>(false)
	const [isPublicFile, setIsPublicFile] = useState<boolean>(false)
	const [confirmHandle, setConfirmHandle] = useState<boolean>(false)
	const handleOverlayClick = (e: any) => {
		// Check if the click is on the overlay and not inside the modal content
		if (e.target.classList.contains('overlay')) {
			onClose()
		}
	}

	const downloadImage = () => {
		if (selectedImage && selectedImage.url) {
			let extension
			if (!selectedImage.format) {
				extension = 'png'
			} else {
				extension = selectedImage.format.split('/')[1]
			}
			const link = document.createElement('a')
			link.href = selectedImage.url
			link.download = `generated-image.${extension}`
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}
	const uploadImage = async () => {
		// Verifica se a imagem foi selecionada
		setConfirmHandle(false)
		if (!selectedImage) {
			alert('No image selected.')
			return
		}

		// Verifica se o usuário está logado
		if (!user || !user.address) {
			alert('User not logged in.')
			return
		}

		// Inicia o processo de fetch
		setFetch(true)

		try {
			let req = await publishImageToPublic(selectedImage, user.address)

			setCurrentUserStorage(!verifyCurrentUserStorage)

			if (req && req.image_path) {
				setIsPublicFile(true)
			}

			await verifyCurrentImageStatus()
		} catch (error) {
			console.error('Error uploading image:', error)
			alert('An error occurred while uploading the image.')
		}
	}

	const removeImage = async () => {
		setFetch(true)
		setIsPublicFile(false)
		await deleteImageFromUserFolder(selectedImage, user)
		await verifyCurrentImageStatus()
		setCurrentUserStorage(!verifyCurrentUserStorage)
		setFetch(false)
		onClose()
	}

	const userChangeImageToPrivate = async () => {
		setFetch(true)
		if (!selectedImage) {
			alert('No image selected.')
			return
		}

		if (!user || !user.address) {
			alert('User not logged in.')
			return
		}

		try {
			let req = await changeImageToPrivate(selectedImage, user.address)

			setCurrentUserStorage(!verifyCurrentUserStorage)

			if (req && req.privacity && req.privacity === 'private') {
				setIsPublicFile(false)
			}

			await verifyCurrentImageStatus()
		} catch (error) {
			console.error('Error changing image to private:', error)
			alert('An error occurred while changing the image to private.')
		}
	}

	const verifyCurrentImageStatus = async () => {
		setFetch(true)
		setIsPublicFile(false)
		let req = await getUserFiles(selectedImage.image_path, user.address)
		if (req && req.privacity && req.privacity === 'public') {
			setIsPublicFile(true)
		}
		setFetch(false)
	}

	React.useEffect(() => {
		verifyCurrentImageStatus()
	}, [])

	return (
		<div className="overlay-modal-image" onClick={handleOverlayClick}>
			<div className="modal modal-container">
				<div className="flex container-modal justify-center w-full h-full border-color side-color">
					<div className="w-[50%] right-modal container-modal relative flex flex-col items-center justify-center">
						<img
							alt="current image"
							className="w-full right-modal absolute opacity-60 z-[20] top-0 h-full object-cover blur-xl"
							src={selectedImage.url}
						/>
						<img alt="current image" className="relative z-[100] object-contain" src={selectedImage.url} />
					</div>
					<div className="w-[50%] left-modal bg-white justify-between flex flex-col p-2">
						<div className="flex items-center justify-between">
							<div className="flex items-center gap-2">
								<img src={Logo} width={'30px'} alt="Logo" />
							</div>
							<span
								onClick={onClose}
								className="material-symbols-outlined mr-4 text-black hover:opacity-70 hover:cursor-pointer">
								close
							</span>
						</div>
						{!isProfile && (
							<div>
								{!isPublicFile ? (
									<div className="publish-section gap-2 flex flex-col">
										<TextStyleCaption>
											So that everyone has access to your images, publish them on your social network
										</TextStyleCaption>
										{!confirmHandle ? (
											<div>
												{!inFetch ? (
													<div className="flex items-center gap-2">
														<Button
															onClick={() => {
																setConfirmHandle(true)
															}}
															value="Publish image"
															size="small"
														/>
														<Button value="Delete" type="critical" onClick={removeImage} size="small" />
													</div>
												) : (
													<div className="w-full flex items-center justify-center">
														<Loading />
													</div>
												)}
											</div>
										) : (
											<div>
												<TextStyleBodyDefault>
													Are you aware that this action will add this file to your public profile?
												</TextStyleBodyDefault>
												<div className="flex items-center gap-2">
													<Button value="Yes publish" onClick={uploadImage} size="small" />
													<Button
														value="Cancel"
														size="small"
														type="critical"
														onClick={() => {
															setConfirmHandle(false)
														}}
													/>
												</div>
											</div>
										)}
									</div>
								) : (
									<div>
										<TextStyleCaption>File already published on your profile</TextStyleCaption>
										<div className="flex items-center gap-2">
											{inFetch ? (
												<Loading />
											) : (
												<div className="flex items-center gap-2">
													<Button
														value="Change to private"
														type="warning"
														onClick={userChangeImageToPrivate}
														size="small"
													/>
													<Button value="Delete" type="critical" onClick={removeImage} size="small" />
												</div>
											)}
										</div>
									</div>
								)}
							</div>
						)}
						<div className="flex flex-col gap-4">
							<div className="">
								<div className="flex items-center gap-2">
									<span className="material-symbols-outlined text-sm">forum</span>
									<p className="label text-sm">Prompt</p>
								</div>
								<p className="text-2xl text">{selectedImage.prompt}</p>
							</div>
							<div className="w-full flex flex-wrap wrap gap-2">
								<div className="card-info text-container grow">
									<div className="flex items-center gap-2">
										<span className="material-symbols-outlined text-sm text-gray-400">draw</span>
										<p className="label text-sm">Model</p>
									</div>
									<div className="flex text items-center gap-1 text-center justify-center text-white">
										{selectedImage.model || 'N/A'}
									</div>
								</div>

								<div className="card-info text-container ">
									<div className="flex items-center gap-2">
										<span className="material-symbols-outlined text-sm text-gray-400">crop</span>
										<p className="label text-sm">Dimensions</p>
									</div>
									<div className="flex items-center justify-center gap-1">
										<p className="text-white text">{selectedImage.width || 'N/A'}</p>
										<span className="text-white text">x</span>
										<p className="text-white text">{selectedImage.height || 'N/A'}</p>
									</div>
								</div>
								<div className="card-info text-container grow">
									<div className="flex items-center gap-2">
										<span className="material-symbols-outlined text-sm text-gray-400">floor</span>
										<p className="label text-sm">Steps</p>
									</div>
									<div className="text flex items-center gap-1 text-center justify-center text-white">
										{selectedImage.steps || 'N/A'}
									</div>
								</div>
								<div className="card-info text-container ">
									<div className="flex items-center gap-2">
										<span className="material-symbols-outlined text-sm text-gray-400">schedule</span>
										<p className="label text-sm">Created</p>
									</div>
									<div className="text flex items-center gap-1 text-center justify-center text-white">N/A</div>
								</div>
							</div>
							<div className="flex items-center gap-2 justify-end">
								<Button value="Cancel" type="subtle" onClick={onClose} size="small" />
								<Button value="Download" type="primary" onClick={downloadImage} size="small" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ViewFile
