import React from 'react'
import './style.scss'
import KineContext from '../../../context/KineContext'

interface Props {
	children: React.ReactNode
	sx?: any
}

const TextH3: React.FC<Props> = ({ children, sx }) => {
	const { color } = React.useContext(KineContext)
	return (
		<h3 className={`dsc__text__h3 text-[${color.text}]`} style={sx}>
			{children}
		</h3>
	)
}

export default TextH3
