import React from 'react'
import { RankingList } from '../../interface'
import { useNavigate } from 'react-router-dom'

import './style.scss'
import { planksToTokens } from 'src/service/substrate/formated'
import { isByProfile } from 'src/constants/rankings'
import { Card, CardContainer, CardInner, CardRow } from '../_Dsc/Card'
import DscIcons from '../_Dsc/icons'
import { Tooltip } from '@mui/material'

const RankingCards: React.FC<{
	data: RankingList[]
	group?: any
	title: string
	remove?: string
	description: string
}> = ({ data, group, title, remove, description }) => {
	const history = useNavigate()

	React.useEffect(() => {
		//
	})

	const sumAllVotes = (list: any) => {
		let totalCount = 0

		for (const voterKey in list) {
			if (list.hasOwnProperty(voterKey)) {
				const votes = list[voterKey]

				for (const voteKey in votes) {
					if (votes.hasOwnProperty(voteKey)) {
						totalCount += 1
					}
				}
			}
		}
		return totalCount
	}

	const redirectTo = (id: string) => {
		history(`/ranking/details/${id}`)
	}

	const totalLockMovie = (ranking: any) => {
		let showNumber =
			ranking.totalLockup && ranking.totalLockup.length && ranking.totalLockup.includes(',')
				? planksToTokens(ranking.totalLockup)
				: ranking.totalLockup
		return showNumber
	}
	let sortedData: any = []
	if (group && data) {
		sortedData = data.filter((val: any) => group.includes(val?.value.name) && val?.value.name !== remove)
		sortedData = sortedData.slice().sort((a: any, b: any) => totalLockMovie(b.value) - totalLockMovie(a.value))
	} else if (!group && data) {
		sortedData = data.slice().sort((a: any, b: any) => totalLockMovie(b.value) - totalLockMovie(a.value))
	}

	return (
		<div className="flex flex-col gap-[8px]">
			{sortedData.map((value: any, index: number) => (
				<CardInner
					className="hover:bg-gray-200 cursor-pointer"
					onClick={() => {
						redirectTo(value.id)
					}}
				>
					<CardContainer>
						<div className="flex items-center justify-between mt-[2px]">
							<div className="flex items-center justify-center">
								<p className="truncate  font-normal flex items-center gap-2">
									{isByProfile.includes(value.value.name) ? (
										<span className="material-symbols-outlined">tv_signin</span>
									) : (
										<span className="material-symbols-outlined">play_circle</span>
									)}
									{value.value.name === 'Tiktok comedy'
										? 'Tik Tok Comedy'
										: value.value.name === 'Documentaries and Fiction, Online'
										? 'Documentaries Online'
										: value.value.name === 'Experimental Experimental and Art Films'
										? 'Experimental and Art Films'
										: value.value.name}
								</p>
							</div>
							<div className="flex items-center gap-4">
								<Tooltip title="Movies">
									<p className="flex items-center gap-2">
										<DscIcons icon="Movie" /> {value.value.moviesInList.length}
									</p>
								</Tooltip>
								<Tooltip title="Total lock">
									<p className="flex items-center gap-2">
										<DscIcons icon="local_atm" />
										{totalLockMovie(value.value)}
									</p>
								</Tooltip>
								<Tooltip title="Participants">
									<p className="flex items-center gap-2">
										<DscIcons icon="interactive_space" />
										{sumAllVotes(value.value.votesByUser) | 0}
									</p>
								</Tooltip>
							</div>
						</div>
					</CardContainer>
				</CardInner>
			))}
		</div>
	)
}

export default RankingCards
