import { Skeleton } from '@mui/material'
import React from 'react'
const defaultValue = [1, 2, 3, 4, 5]

const SkeletonDetail: React.FC = () => {
	return (
		<div className="w-full h-full flex gap-10 ">
			<div className="flex flex-col w-[30%] gap-5">
				<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
				{defaultValue.map((val: number, i: number) => (
					<div className="flex flex-col gap-5" key={i}>
						<Skeleton variant="rectangular" width={'100%'} height={'200px'} />
					</div>
				))}
			</div>
			<div className="flex flex-col w-[70%]">
				<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
				<div className="mt-10">
					<Skeleton variant="rectangular" width={'100%'} height={'500px'} />
				</div>
				<div className="mt-5 w-[100%]">
					<div className="flex justify-between items-center">
						<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
						<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
					</div>

					<div className="mt-5">
						<Skeleton variant="rectangular" width={'100%'} height={'200px'} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default SkeletonDetail
