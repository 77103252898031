import React from 'react'
import Box from '@mui/material/Box'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import './style.scss'
import KineContext from '../../context/KineContext'
interface Props {
	props: any
	updatedCategory?: any
	selected?: any
}

const Dropdown: React.FC<Props> = ({ props, updatedCategory, selected }) => {
	const { color } = React.useContext(KineContext)

	const handleChange = (event: SelectChangeEvent) => {
		updatedCategory(event.target.value)
	}

	const WhiteArrowDropDownIcon = (props: any) => <ArrowDropDownIcon {...props} style={{ color: '#000' }} />

	return (
		<div className=" rounded-xxl bg-white h-full shadow-md rounded-[4px] dropdown-body">
			<Box sx={{ minWidth: 320, borderRadius: '4px', color: color.text }}>
				<FormControl fullWidth>
					<Select
						IconComponent={WhiteArrowDropDownIcon}
						value={selected}
						onChange={handleChange}
						sx={{ color: '#000', fontWeight: 'bold' }}
					>
						{props &&
							props.length &&
							props?.map((val: any, i: number) => (
								<MenuItem value={val.key || val.param} key={i}>
									{val.key || val.param}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			</Box>
		</div>
	)
}

export default Dropdown
