import React, { useState, useEffect } from 'react'
import './style.scss'
import { globals } from '../../_helpers'
import KineContext from '../../context/KineContext'
import DscModal from '../_Dsc/modalImage'
import TextH3 from '../_Dsc/typography/TextH3'
import Close from '../../assets/close.svg'
import { RankingService } from 'src/service/ranking'
import { Link } from 'react-router-dom'
import { FestivalService } from 'src/service/festival/festivalService'
import DscInput from '../_Dsc/input'
import { Tooltip } from '@mui/material'
import Button from '../_Dsc/Button'
import { TextStyleBodyDefaultMedium, TextStyleBodyMedium } from '../_Dsc/typography'

interface MovieCardProps {
	data: any
	currentSelectedMovie: any
	action?: any
	isRanking: boolean
	totalLockMovie?: (number: string) => number
	showModalMovie?: any
	verifyUnstake?: (url: string) => number
	status?: string
	byProfile?: boolean
	profileData?: any
	currentUserTotalLock?: any
	showModalUsers?: (val: string) => void
	participants?: (url: string) => number
	rankingData?: any
	owner: string
}

const colorVariantTitle = {
	color: '#000',
	fontSize: '25px',
	fontWeight: 'bold',
}
const MovieCard: React.FC<MovieCardProps> = ({
	data,
	action,
	showModalMovie,
	currentSelectedMovie,
	isRanking,
	totalLockMovie,
	verifyUnstake,
	status,
	byProfile,
	profileData,
	currentUserTotalLock,
	showModalUsers,
	participants,
	rankingData,
	owner,
}) => {
	const { color, user, apiState } = React.useContext(KineContext)
	const [videoSrc, setVideoSrc] = useState<any>({})
	const [unstake, setUnstake] = useState<number>(0)
	const [profile, setProfile] = useState<any>([])
	const [open, setOpen] = useState(false)
	const [unstakeValue, setUnstakeValue] = useState(0)
	const [errorMessage, setErrorMessage] = useState('')
	const [enableUnstake, setEnableUnstake] = useState(false)
	const [openRemoveMovie, setOpenRemoveMovie] = useState(false)

	const getProfileData = async () => {
		try {
			let req = await profileData(data)
			setProfile(req)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getCurrentMovieData()
		if (byProfile) {
			getProfileData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (data && verifyUnstake) {
			let req = verifyUnstake(data)
			setUnstake(req)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	const getCurrentMovieData = async () => {
		if (data.includes('youtube.com')) {
			return await globals.getVideoSrc(data, setVideoSrc, 'youtube')
		} else if (data.includes('tiktok.com')) {
			return await globals.getVideoSrc(data, setVideoSrc, 'tiktok')
		}
	}

	const openModal = () => {
		setOpen(true)
	}

	const unstakeVote = async () => {
		setOpen(false)
		await RankingService.unstake(window.location.pathname.split('/details/')[1], data, unstakeValue, user, apiState)
		window.location.reload()
	}

	const onChangeUnstakeValue = (e: any) => {
		let totalValue = currentUserTotalLock(data)

		if (parseInt(e) > totalValue) {
			setErrorMessage(`
      Maximum value: ${totalValue}`)
			setUnstakeValue(totalValue)
			setEnableUnstake(true)
			return false
		} else {
			setEnableUnstake(true)
			setErrorMessage('')
			setUnstakeValue(e)
		}
	}

	const removeMovie = async () => {
		setOpenRemoveMovie(false)
		await FestivalService.removeMovie(window.location.pathname.split('/details/')[1], [], data, user, apiState)
		//window.location.reload()
	}

	const getTotalParticipants = () => {
		let count = 0
		if (rankingData && rankingData.votesByUser) {
			// Iterar sobre cada chave de usuário em votesByUser
			Object.keys(rankingData.votesByUser).forEach(userKey => {
				// Verificar se o movieId específico existe dentro das chaves de filmes deste usuário
				if (rankingData.votesByUser[userKey].hasOwnProperty(data)) {
					count += 1 // Incrementa o contador se o filme existe sob esse usuário
				}
			})
		}
		return count
	}

	return (
		<>
			{open && (
				<DscModal
					open={open}
					setOpen={setOpen}
					confirmButton={enableUnstake}
					confirmText={'Proceed'}
					confirmAction={unstakeVote}
					type="normal"
				>
					<div className="flex flex-col gap-4  overflow-hidden relative min-w-[700px]">
						<div className="flex p-2 items-center justify-between">
							<div className="flex items-center gap-4">
								<span className="material-symbols-outlined text-[40px]">account_balance_wallet</span>
								<TextH3 sx={colorVariantTitle}>Unstake</TextH3>
							</div>
							<button
								className="cursor-pointer"
								onClick={() => {
									setOpen(false)
								}}
							>
								<img src={Close} alt="img" />
							</button>
						</div>
						<div className="p-2">
							<DscInput
								type="number"
								value={unstakeValue}
								label="Value to unstake"
								placeholder={`Max: ${unstake}`}
								onChange={onChangeUnstakeValue}
							/>
							<p className="mt-2 font-bold">You can withdraw up to {unstake}</p>
							<p className="mt-2 text-red-600">{errorMessage}</p>
						</div>
					</div>
				</DscModal>
			)}
			{openRemoveMovie && (
				<DscModal
					open={openRemoveMovie}
					setOpen={setOpenRemoveMovie}
					confirmButton={true}
					confirmText={'Proceed'}
					confirmAction={removeMovie}
					type="alert"
				>
					<div className="flex flex-col gap-4  overflow-hidden relative min-w-[700px]">
						<div className="flex p-2 items-center justify-between">
							<div className="flex items-center gap-4">
								<span className="material-symbols-outlined text-red-300" style={{ fontSize: '40px' }}>
									error
								</span>
								<TextH3 sx={colorVariantTitle}>Are you sure?</TextH3>
							</div>
							<button
								className="cursor-pointer"
								onClick={() => {
									setOpenRemoveMovie(false)
								}}
							>
								<img src={Close} alt="img" />
							</button>
						</div>
						<div className="max-h-[400px] w-[500px] text-[#848B94] m-auto overflow-y-auto">
							Are you sure you want to remove the constellation video?
						</div>
					</div>
				</DscModal>
			)}
			{!isRanking ? (
				<article className={`card-movie-bg default-shadow rounded-[4px]`}>
					<div className="p-2 festival items-center flex flex-col  w-full ">
						{data.includes('youtube') ? (
							<div className="flex gap-2 w-full">
								<h3 className={` w-[90%] truncate text-[${color.text}]`}>{videoSrc.title}</h3>
							</div>
						) : (
							<div className="flex items-center justify-center m-auto w-full">
								<div
									className="flex items-center justify-center m-auto"
									style={{
										backgroundPosition: 'center',
										height: '100px',
										width: '80px',
										backgroundImage: `url(${videoSrc.thumbnail_url})`,
										backgroundSize: 'contain',
										backgroundRepeat: 'no-repeat',
									}}
								/>
							</div>
						)}
						<img src={videoSrc.thumbnail_url} width="200px" height="140px" alt="icon" />
					</div>

					{status === 'AwaitingActivation' ? (
						<div className="card-footer bg-white rounded-[4px]">
							{data === currentSelectedMovie ? (
								<div className="flex items-center gap-4">
									<p className={`text-black`}>Currently Selected </p>
									{user && user.address === owner && (
										<Button
											size="small"
											type="critical"
											value="Remove"
											onClick={() => {
												setOpenRemoveMovie(true)
											}}
										></Button>
									)}
								</div>
							) : (
								<div className="flex items-center gap-4">
									<Button
										size="small"
										value="View/Vote"
										onClick={() => {
											action(data)
										}}
									></Button>
									<>
										{user && user.address === owner && (
											<Button
												size="small"
												type="critical"
												value="Remove"
												onClick={() => {
													setOpenRemoveMovie(true)
												}}
											></Button>
										)}
									</>
								</div>
							)}
						</div>
					) : (
						<div className="card-footer gap-2 ">
							<p
								className="flex items-center cursor-pointer hover:bg-gray-500 p-1 truncate "
								role="button"
								onClick={() => {
									showModalUsers && showModalUsers(data)
								}}
							>
								<Tooltip title="Participants" placement="top">
									<span className="material-symbols-outlined">groups</span>
								</Tooltip>
								<Tooltip title="Participants" placement="top">
									<span className={` text-black font-medium p-1 flex items-center justify-center`}>
										{(participants && participants(data)) || 0}
									</span>
								</Tooltip>
							</p>
							<p className="flex items-center 1 cursor-pointer ">
								<Tooltip title="Votes" placement="top">
									<span className="material-symbols-outlined">paid</span>
								</Tooltip>
								<Tooltip title="Votes" placement="top">
									<span className={`text-black font-medium p-1 flex items-center justify-center`}>
										{currentUserTotalLock(data) || 0}
									</span>
								</Tooltip>
							</p>
							{data === currentSelectedMovie ? (
								<p className={`text-black font-medium`}>Currently Selected </p>
							) : (
								<div className="flex items-center gap-4">
									<Button
										size="small"
										value="View/Vote"
										onClick={() => {
											action(data)
										}}
									></Button>
								</div>
							)}
						</div>
					)}
				</article>
			) : (
				<div className="flex  justify-between  items-center mt-1 w-full">
					{byProfile ? (
						<div className="flex items-center w-full justify-between">
							<div className="flex gap-[8px] max-[75%] overflow-hidden items-center">
								<div className="current-movie flex items-center p-2 mt-[-3px] min-w-[150px] min-w-[10%]">
									{profile && (
										<Link to={data} target="_blank">
											<img
												className="hover:opacity-70 cursor-pointer rounded-[1000%] border-2"
												src={profile.img}
												alt="icon"
												onClick={() => {
													//showModalMovie(data)
												}}
											/>
										</Link>
									)}
								</div>
								<div className="flex truncate  flex-col ">
									<p className="truncate  ml-2">{profile?.name} </p>

									<p className="truncate ml-2 text-small">{profile?.desc || ''}</p>
									<TextStyleBodyDefaultMedium className="ml-2 min-w-[190px]  flex items-center ">
										Voters: {getTotalParticipants()}
									</TextStyleBodyDefaultMedium>
								</div>
							</div>
							<div className="flex items-center gap-[8px]  justify-end mr-4">
								<TextStyleBodyMedium
									className={`bg-[#003eab] text-white p-[3px] rounded-[4px] default-shadow whitespace-nowrap`}
								>
									Total KinexPlay lock <b>{totalLockMovie && totalLockMovie(data)}</b>
								</TextStyleBodyMedium>

								{user && user.address && (
									<div className="flex flex-col items-end gap-1">
										{data === currentSelectedMovie ? (
											<p
												className="mb-1 p-2 rounded-lg font-bold min-w-[70px]"
												style={{ background: '#fff', color: '#000' }}
											>
												Voting
											</p>
										) : (
											<Button
												size="small"
												value="Vote"
												onClick={() => {
													action(data)
												}}
											></Button>
										)}
										{unstake && Number(unstake) > 1 ? (
											<Button value="Unstake" size="small" onClick={openModal}></Button>
										) : (
											''
										)}
									</div>
								)}
							</div>
						</div>
					) : (
						<div className="flex items-center w-full justify-between">
							<div className="flex gap-[8px] max-[75%] overflow-hidden items-center">
								<div className="current-movie flex items-center p-2 mt-[-3px] w-[150px] min-w-[10%]">
									{data.includes('youtube') ? (
										<img
											className="hover:opacity-70 cursor-pointer"
											src={videoSrc.thumbnail_url}
											alt="icon"
											onClick={() => {
												showModalMovie(data)
											}}
										/>
									) : (
										<div className="flex items-center justify-center m-auto w-full">
											<div
												onClick={() => {
													showModalMovie(data)
												}}
												className="flex items-center justify-center m-auto"
												style={{
													backgroundPosition: 'center',
													height: '60px',
													width: '60px',
													backgroundImage: `url(${videoSrc.thumbnail_url})`,
													backgroundSize: 'cover',
													backgroundRepeat: 'no-repeat',
												}}
											/>
										</div>
									)}
								</div>
								<div className="flex truncate flex-col ">
									<div className="flex items-center gap-3">
										<p className="truncate  ml-2">{videoSrc.title}</p>
										<p className="min-w-[190px] font-bold text-black flex items-center">
											Participants: {getTotalParticipants()}
										</p>
									</div>
									<p className="truncate ml-2 text-small">{videoSrc.author}</p>
								</div>
							</div>
							<div className="flex items-center gap-[8px]  justify-end mr-4">
								<TextStyleBodyMedium
									className={`bg-[#003eab] text-white p-[3px] rounded-[4px] default-shadow whitespace-nowrap`}
								>
									Total KinexPlay lock <b>{totalLockMovie && totalLockMovie(data)}</b>
								</TextStyleBodyMedium>

								{user && user.address && (
									<div className="flex flex-col items-end gap-1">
										{data === currentSelectedMovie ? (
											<p
												className="mb-1 p-2 rounded-lg font-bold min-w-[70px]"
												style={{ background: '#fff', color: '#000' }}
											>
												Voting
											</p>
										) : (
											<Button
												size="small"
												value="Vote"
												onClick={() => {
													action(data)
												}}
											></Button>
										)}
										{unstake && Number(unstake) > 1 ? (
											<Button value="Unstake" size="small" onClick={openModal}></Button>
										) : (
											''
										)}
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	)
}

export default MovieCard
