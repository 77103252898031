import React from 'react'
import KineContext from '../../../context/KineContext'
import './style.scss'

interface Props {
	text: string
	onSubmit: () => void
	type?: string
	children?: React.ReactNode
	size?: string
	disabled?: boolean
}

const ActionButton: React.FC<Props> = ({ disabled, text, onSubmit, type, children, size = 'lg' }) => {
	const { color } = React.useContext(KineContext)
	return (
		<button
			disabled={disabled}
			className={`button__action flex items-center rounded-[4px]   font-medium ${
				type === 'alert' ? 'bg-red-500 text-white' : type !== 'cancel' ? `bg-[${color.action}] text-white` : ''
			}`}
			style={{ padding: size === 'sm' ? '2px 10px' : '8px 12px 8px 12px' }}
			onClick={onSubmit}
		>
			{text}
			{children && <span className="ml-2">{children}</span>}
		</button>
	)
}

export default ActionButton
