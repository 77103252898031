import React, { useEffect, useRef, useState, useMemo } from 'react'

import { IDropdown } from './interface'

import {
	DsyButton,
	DsyButtonIcon,
	DsyButtonLabel,
	DsyContainer,
	DsyDropdownSelect,
	DsyDropdownItem,
	DsyDropdownList,
	DsyDropdownItemLabel,
	DsyDropdownItemCheckbox,
	DsySearchContainer,
	DsySearchInput,
	DsySearchClear,
} from './styles'
import { useCloseOnClickOutsideComponent } from '../Helpers'
import DscIcons from '../icons'
import { TextStyleCaption } from '../typography'

export const Dropdown: React.FC<IDropdown> = ({
	color = 'subtle',
	disabled = false,
	setPriority,
	label = '',
	maxSelect,
	multiple = false,
	onChange,
	options,
	placeholder = '',
	position = 'left',
	icon,
	search = false,
	size = 'small',
	type = 'select',
	value,
}) => {
	const [visible, setVisible] = useState<boolean>(false)
	const [filter, setFilter] = useState<string>('')

	const containerRef = useRef<HTMLDivElement>(null)

	const toggleVisible = () => {
		setFilter('')
		setVisible(!visible)
		if (setPriority) {
			setPriority(false)
		}
	}

	useCloseOnClickOutsideComponent(containerRef, visible, toggleVisible)

	const itemOnClickHandler = (selectedValue: string) => {
		if (multiple) {
			const newValue = [...value]
			const selectedValueIndex = newValue.findIndex(v => v === selectedValue)

			if (selectedValueIndex >= 0) {
				newValue.splice(selectedValueIndex, 1)
			} else {
				if (maxSelect && newValue.length >= maxSelect) return

				newValue.push(selectedValue)
			}

			onChange(newValue)
		} else {
			onChange([selectedValue])
			toggleVisible()
		}
	}

	let displayedLabel: string = label
	let showBadge: boolean = false

	if (multiple) {
		showBadge = value.length > 0
	} else {
		const currentItem = options.find(o => o.value === value[0])
		displayedLabel = currentItem ? currentItem.label : placeholder
	}

	const filteredOptions = useMemo(
		() =>
			search && filter.length > 0
				? options.filter(o => o.label.toLocaleLowerCase('en-US').indexOf(filter.toLocaleLowerCase('en-US')) >= 0)
				: options,
		[filter, options],
	)

	return (
		<DsyContainer ref={containerRef} disabled={disabled}>
			{((search && !visible) || !search) && !icon && (
				<DsyButton
					size={size}
					color={color}
					disabled={disabled}
					onClick={toggleVisible}
					className="w-full flex justify-between"
				>
					<DsyButtonLabel>{displayedLabel}</DsyButtonLabel>

					<DscIcons icon={visible ? 'arrow_drop_up' : 'arrow_drop_down'}></DscIcons>
				</DsyButton>
			)}
			{icon && (
				<span
					className={' material-symbols-outlined text-black cursor-pointer hover:opacity-70'}
					onClick={toggleVisible}
				>
					{icon}
				</span>
			)}
			{search && visible && (
				<DsySearchContainer size={size}>
					<DsySearchInput autoFocus value={filter} onChange={e => setFilter(e.target.value)}></DsySearchInput>
					{filter.length > 0 && (
						<DsySearchClear className="material-icons" onClick={() => setFilter('')}>
							cancel
						</DsySearchClear>
					)}
				</DsySearchContainer>
			)}
			{visible && (
				<DsyDropdownSelect position={position}>
					<DsyDropdownList>
						{filteredOptions.map((option, key) => (
							<DsyDropdownItem key={key} selected={value.includes(option.value)}>
								<DsyDropdownItemLabel onClick={() => itemOnClickHandler(option.value)}>
									{option.label}
									{option.description ? <TextStyleCaption>{option.description}</TextStyleCaption> : ''}
								</DsyDropdownItemLabel>
							</DsyDropdownItem>
						))}
					</DsyDropdownList>
				</DsyDropdownSelect>
			)}
		</DsyContainer>
	)
}

export default Dropdown
