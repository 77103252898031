import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import KineContext from '../../../context/KineContext'
import { Link } from 'react-router-dom'
import './style.scss'
import { Loading } from '../../../components/loading'

import Input from '@mui/material/Input'

import { web3Accounts, web3Enable } from '@polkadot/extension-dapp'
import { createUserData } from '../../../service/profile/profileService'
import Alert from '@mui/material/Alert'

import { storage } from 'src/service/storage/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'

import { Accounts } from 'src/pages/login/components/accounts'
import { getSocialProfileByAddres } from 'src/service/social/socialService'
import { User } from 'src/interface'
import { TextStyleBodyDefault, TextStyleH1 } from 'src/components/_Dsc/typography'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import InputText from 'src/components/_Dsc/InputText'
import Button from 'src/components/_Dsc/Button'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'

const CreateProfile: React.FC = () => {
	const path = [
		{ label: 'Home', link: '/' },
		{ label: 'Create Profile', link: '' },
	]
	const navigate = useNavigate()
	const { user, setGlobalToast, color, setGlobalProfile, profile, setUser } = React.useContext(KineContext)
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [fetch, setFetch] = useState(false)
	const [isError, setIsError] = useState<String | null>(null)
	const [image, setImage] = useState<any>(null)
	const [coverImage, setCoverImage] = useState<any>(null)
	const [coverImagePreview, setCoverImagePreview] = useState<any>(null)
	const [accounts, setAccounts] = React.useState([] as any)
	const [previewImage, setPreviewImage] = useState<string | null>(null)

	const [currentAccount, setCurrentAccount] = React.useState([])
	const [selectedAccount, setSelectedAccount] = useState<any>([])
	const [verified, setVerified] = useState(false)
	const [fetchAccount, setFetchAccount] = useState(false)
	const [message, setMessage] = useState('')
  const [password, setPassword] = useState('')
	const [tag, setTag] = useState('')

	const handleSubmit = async () => {
		if (!name || !password) {
			alert('Name and password is required')
			return
		}

		setGlobalToast(true)

		const formData = new FormData()
		formData.append('img', image ? image : null)
		formData.append('tag', tag)
		formData.append('user', selectedAccount.address)
		formData.append('name', name)
		formData.append('cover', coverImage ? coverImage : null)
		formData.append('password', password)
		description && formData.append('description', description)
		try {
			let req: any = await createUserData(formData)

			if (req.error) {
				setIsError('A profile with this name already exists')
				setName('')
				setDescription('')
				setGlobalToast(false)
			} else {
				setIsError('')
				setFetch(false)
				navigate('/social')
				setGlobalToast(false)
				setGlobalProfile(true)
				setUser(selectedAccount)
			}
		} catch (error) {
			setGlobalToast(false)
		}
	}

	const changePassword = (e: string) => {
		setPassword(e)
	}

	const getImageWithNewName = (originalFile: any, newName: any) => {
		const blob = new Blob([originalFile], { type: originalFile.type })
		const newFile = new File([blob], newName, { type: originalFile.type })
		return newFile
	}

	const getImageUrl = async (event: any) => {
		if (event.target.files && event.target.files.length > 0) {
			const originalFile = event.target.files[0]
			const renamedFile = getImageWithNewName(originalFile, 'profile')
			const storageRef = ref(storage, `files/${originalFile.name}`)
			const uploadTask = uploadBytesResumable(storageRef, originalFile)
			const snapshot = await uploadTask

			let urlProfile = await getDownloadURL(snapshot.ref)

			setImage(urlProfile)

			const reader = new FileReader()
			reader.onload = e => {
				if (e.target && typeof e.target.result === 'string') {
					setPreviewImage(e.target.result)
				}
			}
			reader.readAsDataURL(renamedFile)
		} else {
			setImage(null)
			setPreviewImage(null)
		}
	}

	const getImageCoverUrl = async (event: any) => {
		if (event.target.files && event.target.files.length > 0) {
			const originalFile = event.target.files[0]

			const renamedFile = getImageWithNewName(originalFile, 'profile')

			//setCoverImage([renamedFile])

			const storageRef = ref(storage, `files/${originalFile.name}`)
			const uploadTask = uploadBytesResumable(storageRef, originalFile)
			const snapshot = await uploadTask
			let urlProfile = await getDownloadURL(snapshot.ref)
			setCoverImage(urlProfile)

			const reader = new FileReader()
			reader.onload = e => {
				if (e.target && typeof e.target.result === 'string') {
					setCoverImagePreview(e.target.result)
				}
			}
			reader.readAsDataURL(renamedFile)
		} else {
			setCoverImage(null)
		}
	}
	const extensionSetup = async () => {
		await web3Enable('kinera')
		const accounts: any[] = await web3Accounts()
		setAccounts(accounts)
	}

	const chageAccount = async (account: User) => {
		setFetchAccount(true)
		setSelectedAccount(account)
		setVerified(false)
		let req = await getSocialProfileByAddres(account.address)

		if (req && req.id) {
			setMessage('This account already has a profile created, select a different wallet')
			setVerified(false)
			setFetchAccount(false)
			return false
		} else {
			setSelectedAccount(account)
			setFetchAccount(false)
			setMessage('')
			setVerified(true)
		}
	}

	const changeDescription = (e: string) => {
		setDescription(e)
	}

	const changeName = (e: string) => {
		if (e.length > 25) {
			e = e.substring(0, 25);
		}
		setName(e);
		return name
	};

	const changeTag = (e: string) => {
		// Remove todos os caracteres especiais da string, deixando apenas letras e números
		let sanitizedString = e.replace(/[^a-zA-Z0-9 ]/g, '');
		if (sanitizedString.length > 15) {
			sanitizedString = sanitizedString.substring(0, 15);
		}
		setTag(sanitizedString);
	  };

	React.useEffect(() => {
		extensionSetup()
		if (profile && profile.user) {
			navigate('/')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, profile])

	return (
		<div className="page-padding flex flex-col gap-[8px]">
			<Breadcrumb routes={path} />
			<CardInner className="bg-white">
				<CardContainer>
					<div className="flex flex-col w-full">
						<TextStyleH1>Social profile</TextStyleH1>

						{isError && (
							<div className="mb-4">
								<Alert severity="error">A profile with this name already exists.</Alert>
							</div>
						)}
						<div>
							{previewImage && (
								<div className="mb-4 relative flex ">
									<div className="p-4">
										<img
											src={previewImage}
											alt="preview"
											style={{
												width: '200px',
												height: '200px',
												objectFit: 'cover',
												zIndex: '10',
												overflow: 'hidden',
												borderRadius: '100%',
												position: 'relative',
											}}
										/>
									</div>
									<div
										className="w-full flex items-center cover-image-bg"
										style={{
											backgroundImage: `url(${coverImagePreview})`,
											backgroundSize: 'cover',

											backgroundPosition: 'center',
											position: 'absolute',
										}}></div>
								</div>
							)}
						</div>
						{selectedAccount && verified && (
							<div className="flex gap-10 items-center">
								{
									<div className="mb-4">
										<label htmlFor="image" className={`block font-medium mb-2 text-[${color.text}]`}>
											Profile Image
										</label>
										<Input
											type="file"
											id="image"
											inputProps={{ accept: '.jpg, .jpeg, .png' }}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
												getImageUrl(event)
											}}
											style={{ display: 'none' }}
										/>
										<label htmlFor="image">
											<Button value="Upload Image" size="small"></Button>
										</label>
									</div>
								}

								{
									<div className="mb-4">
										<label htmlFor="cover" className={`block font-medium mb-2 text-[${color.text}]`}>
											Cover Image
										</label>
										<Input
											type="file"
											id="cover"
											inputProps={{ accept: '.jpg, .jpeg, .png' }}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
												getImageCoverUrl(event)
											}}
											style={{ display: 'none' }}
										/>
										<label htmlFor="cover">
											<Button value="Cover Image" size="small"></Button>
										</label>
									</div>
								}
							</div>
						)}
						<div className=" w-full mb-4">
							{accounts && accounts.length && !fetchAccount ? (
								<Accounts
									selected={selectedAccount}
									extensionSetup={extensionSetup}
									accounts={accounts}
									setIsLogin={() => {}}
									notShowDefaultValue={true}
									calback={chageAccount}
									currentAccount={currentAccount}
									setCurrentAccount={setCurrentAccount}
									password={''}
									setPassword={() => {}}
									errorMessage={''}
								/>
							) : (
								<Loading />
							)}
						</div>
						{message && <div className="text-center  font-bold m-2 text-red-600">{message}</div>}
						<div className="mb-4 w-full">
							<div className='flex items-end gap-1 w-full'>
							<TextStyleBodyDefault className='pb-[33px] text-[20px !important]'>@</TextStyleBodyDefault>
							<div className='w-full flex flex-col'>
							<InputText
								label="Username"
								type="text"
								disabled={!verified || !selectedAccount}
								placeholder="Unique identifier ex: cross141..."
								value={tag}
								onChange={changeTag}
							/>
							<p className={`text-[${color.text}] text-sm mt-1 justify-end flex`}>{tag.length}/15</p>
							</div>
							</div>
						</div>
						<div className="mb-4">
							<InputText
								label="Profile Name"
								disabled={!verified || !selectedAccount}
								placeholder="Your Name.."
								value={name}
								onChange={changeName}
							/>
							<p className={`text-[${color.text}] text-sm mt-1 justify-end flex`}>{name.length}/25</p>
						</div>
						<div className="">
							<InputText
							
								label="Description"
								disabled={!verified || !selectedAccount}
								value={description}
								placeholder="Profile Description.."
								onChange={changeDescription}
							/>
							<p className={`text-[${color.text}] text-sm mt-1 justify-end flex`}>{description.length}/350</p>
						</div>
						<div className="mb-4">
							<InputText
								type="password"
								disabled={!verified || !selectedAccount || !name}
								value={password}
								placeholder="password.."
								label="Password"
								onChange={changePassword}
							/>
						</div>

						{!fetch ? (
							<div className="w-full flex item-end justify-end gap-5">
								<button className="text-bold p-1">
									<Link to="/social">Cancel</Link>
								</button>
								<Button
									value="Create"
									onClick={handleSubmit}
									disabled={!name.length || !password || !password.length}
									size="small"></Button>
							</div>
						) : (
							<Loading />
						)}
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}
export default CreateProfile
