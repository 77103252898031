import API from '../api'

export const CreateMessengerChat = async (owner_id: number, peer_id: number | string, title?: string) => {
	try {
		let payload = {
			owner_id: owner_id,
			peer_id: peer_id,
			content: title,
		}
		const req = await API.post(`/api/messenger/create`, payload)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

export const getAllUserChats = async (owner_id: number) => {
	try {
		const req = await API.get(`/api/messenger/${owner_id}`)
		return req.data
	} catch (err) {
		console.error(err)
	}
}
