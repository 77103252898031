import React, { useEffect, useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface RichTextEditorProps {
	initialValue?: string
	onchange?: any
	value?: any
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ initialValue, onchange, value }) => {
	const quillRef = useRef<ReactQuill>(null)
	const [isFocused, setIsFocused] = useState(false)

	const modules = {
		toolbar: [
			[{ header: '1' }, { header: '2' }, { font: [] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[
				{
					color: [
						'#e0557a',
						'#e60000',
						'#ff9900',
						'#ffff00',
						'#008a00',
						'#0066cc',
						'#9933ff',
						'#ffffff',
						'#facccc',
						'#ffebcc',
						'#ffffcc',
						'#cce8cc',
						'#cce0f5',
						'#ebd6ff',
						'#bbbbbb',
						'#f06666',
						'#ffc266',
						'#ffff66',
						'#66b966',
						'#66a3e0',
						'#c285ff',
						'#888888',
						'#a10000',
						'#b26b00',
						'#b2b200',
						'#006100',
						'#0047b2',
						'#6b24b2',
						'#444444',
						'#5c0000',
						'#663d00',
						'#666600',
						'#003700',
						'#002966',
						'#3d1466',
					],
				},
			],
			['background'],
			['link'],
			['clean'],
		],
	}

	const formats = [
		'header',
		'font',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'link',
		'color',
		'background',
	]

	useEffect(() => {
		if (quillRef.current) {
			const quillInstance = quillRef.current.getEditor()
			quillInstance.focus()

			// Move cursor to the end of the text
			const length = quillInstance.getLength()
			quillInstance.setSelection(length, length)
		}
	}, [value])

	const handleFocus = () => {
		setIsFocused(true)
	}

	const handleBlur = () => {
		setIsFocused(false)
	}

	return (
		<div
			className={` box-quill h-full relative max-h-[150px] ${
				isFocused ? 'border-solid border-[3px] border-[#0053e4]' : ''
			}`}
			style={{ overflowX: 'auto', whiteSpace: 'pre-line' }}
		>
			<ReactQuill
				ref={quillRef}
				placeholder="What's happening?"
				value={value}
				onChange={onchange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				modules={modules}
				formats={formats}
			/>
		</div>
	)
}

export default RichTextEditor
