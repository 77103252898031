import API from "../api"

export const createNewDialog = async (payload: {name: string, description: string, respond: string, ask: string, prompts: any}) => {
	try {
		let req = await API.post(`/api/dialog/create`, payload)
		return req.data
	} catch (e) {
		return false
	}
}

export const getAllDialogs = async () => {
	try {
		let req = await API.get(`/api/dialog/`)
		return req.data
	} catch (e) {
		return false
	}
}

export const getDialogPrompts = async (id: number) => {
	try {
		let req = await API.get(`/api/dialog/prompts/${id}`)
		return req.data
	} catch (e) {
		return false
	}
}