import React from 'react'
import { ITabs } from './interface'

import { DsyTab, DsyTabSelected, DsyTabs } from './styles'

export const Tabs = ({ options, value, onChange }: ITabs) => {
	const onChangeHandler = (value: string): void => {
		if (onChange !== undefined) onChange(value)
	}

	return (
		<DsyTabs>
			{options.map((option, key) =>
				option.value === value ? (
					<DsyTabSelected key={key} onClick={() => (!option?.disabled ? onChangeHandler(option.value) : '')}>
						{option.label}
					</DsyTabSelected>
				) : (
					<DsyTab key={key} onClick={() => (!option?.disabled ? onChangeHandler(option.value) : '')}>
						{option.label}
					</DsyTab>
				),
			)}
		</DsyTabs>
	)
}

export default Tabs
