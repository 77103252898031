import React from 'react'
import styled from 'styled-components'

interface IDsyButton {
	onClick?: (e: React.MouseEvent) => void
}

interface IDsyButtonContent {
	size?: string
	appearance?: string
	iconPosition?: string
	iconOnly?: boolean
	disabled?: boolean
}

export const DsyButton = styled.div<IDsyButton>`
	display: inline-block;
	width: fit-content;
`
export const DsyButtonContent = styled.div<IDsyButtonContent>`
	border: none;
	background-color: transparent;
	background-size: 100% 100%;
	font-family: var(--font-circular-std);
	border-radius: var(--border-radius);
	border: 2px solid transparent;
	white-space: nowrap;
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	${({ disabled }) =>
		disabled &&
		`
		pointer-events: none;
		cursor: not-allowed;
	`}

	${({ size, iconOnly }) => {
		switch (size) {
			case 'small':
				return `
					font-size: 14px;
					line-height: 16px;
					font-weight: 500;
					padding: ${iconOnly ? '6px' : '6px 10px'};

					i {
						font-size: 16px;
					}
				`
			case 'medium':
				return `
					font-size: 16px;
					line-height: 16px;
					font-weight: 500;
					padding: 10px 14px;
					padding: ${iconOnly ? '8px' : '10px 14px'};

					i {
						font-size: 20px;
					}
				`
			case 'large':
				return `
					font-size: 16px;
					line-height: 16px;
					font-weight: 500;
					padding: 14px 22px;
					padding: ${iconOnly ? '10px' : '14px 22px'};

					i {
						font-size: 24px;
					}
				`
		}
	}}

	${({ appearance, iconOnly, disabled }) => {
		switch (appearance) {
			case 'primary':
				return `
					background-color: ${disabled ? 'var(--action-primary-light)' : 'var(--action-primary)'};
					color: var(--text-on);
					&:hover {
						background-color: var(--action-primary-dark);
					}
					&:active {
						&::after {
							background-color: var(--action-primary-dark);
						}
					}
				`
			case 'secondary':
				return `
					background-color: ${disabled ? 'var(--action-secondary-light)' : 'var(--action-secondary)'};
					color: var(--text-on);
					&:hover {
						background-color: var(--action-secondary-dark);
					}
					&:active {
						&::after {
							background-color: var(--action-secondary-dark);
						}
					}
				`
			case 'critical':
				return `
					background-color: ${disabled ? 'var(--action-critical-light)' : 'var(--action-critical)'};
					color: var(--text-on);
					&:hover {
						background-color: var(--action-critical-dark);
					}
					&:active {
						&::after {
							background-color: var(--action-critical-dark);
						}
					}
				`
			case 'warning':
				return `
					background-color:var(--action-warning);
					${disabled && 'opacity: 0.5;'}
					color: var(--text-default);
					&:hover {
						background-color: var(--action-warning-dark);
					}
					&:active {
						&::after {
							background-color: var(--action-warning-dark);
						}
					}
				`
			case 'outlined':
				return `
					border-color: ${disabled ? '--border-primary-light' : 'var(--border-primary)'};
					color: ${disabled ? '--text-primary-light' : 'var(--text-primary)'};
					&:hover {
						border-color: var(--border-primary-dark);
						color: var(--text-primary-dark);
					}
				`
			case 'link':
				return `
					color: ${disabled ? '--text-disable' : 'var(--text-primary)'};
					// ${iconOnly && 'text-decoration: none;'}

					&:hover {
						color: var(--text-primary-dark);
					}
				`
			case 'subtle':
				return `
					color: ${disabled ? '' : 'var(--text-default)'};
					&:hover {
						background-color: var(--background-default);
					}
					&:active {
						&::after {
							background-color: var(--background-default);
						}
					}
				`
		}
	}}

	${({ iconPosition }) =>
		iconPosition === 'right' &&
		`
			flex-direction: row-reverse;
	`}
`
export const DsyLabel = styled.span`
	z-index: 2;
	position: relative;
	user-select: none;
	//line-height: 1;
`

export const DsyUnderline = styled.span`
	display: block;
	position: absolute;
	width: 0;
	height: 2px;
	left: 1px;
	bottom: -2px;
	background-color: var(--text-primary);

	${DsyButtonContent}:hover & {
		width: calc(100% - 2px);
		background-color: var(--text-primary-dark);
	}
`
