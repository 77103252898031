import React from 'react'
import KineContext from '../../../context/KineContext'
import PaletteIcon from '@mui/icons-material/Palette'
import { SketchPicker } from 'react-color'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import TitleH1 from '../../../components/_Dsc/typography/TitleH1'

const initialColorState = {
	action: '#d090dc',
	info: '#d090dc',
	text: '#292929',
	alert: '#f1f664ed',
	alertText: '#000',
	blackColor: '#000',
	sidenav: '#8f559a',
	bg: '#e6b8f0',
	asideText: '#fff',
	title: '#292929',
	logo: '#fff',
}

export const ColorConfig: React.FC = () => {
	const { color, setColor } = React.useContext(KineContext)
	const [currentOpenPanel, setCurrentOpenPanle] = React.useState('')
	const setChangeColorOpenPanel = (panel: string) => {
		setCurrentOpenPanle(panel)
	}
	const handleChangeColor = (color: { hex: string }) => {
		setColor((prevColors: any) => {
			const newColors = {
				...prevColors,
				[currentOpenPanel]: color.hex,
			}

			localStorage.setItem('kinera__currentColor', JSON.stringify(newColors))

			return newColors
		})

		// setCurrentOpenPanel('')
	}

	const resetColor = () => {
		setColor(initialColorState)
		localStorage.removeItem('kinera__currentColor')
	}

	return (
		<div className="w-full flex flex-col items-center justify-center">
			<div className="space-y-2 pb-8 pt-6 md:space-y-5 ml-20 mt-10">
				<TitleH1 text={'	Custom Colors'} />
				<p className={`text-lg leading-7  bg-[${color.alert}] p-1 text-[${color.alertText}]`}>
					Remember that the colors modified here will reflect throughout the application
				</p>
			</div>
			<div className="flex items-center gap-5 flex-wrap wrap justify-center">
				<div className="h-[50px] flex items-center gap-2 relative border p-1">
					<p className={`text-[${color.text}]`}>Actions</p>
					<button
						onClick={() => {
							setChangeColorOpenPanel('action')
						}}>
						<p style={{ background: color.action, width: '20px', height: '20px' }}></p>
					</button>
					{currentOpenPanel === 'action' && (
						<div style={{ position: 'absolute', zIndex: 2 }}>
							<div
								style={{
									position: 'fixed',
									top: '0px',
									right: '0px',
									bottom: '0px',
									left: '0px',
								}}
							/>
							<SketchPicker color={color} onChange={handleChangeColor} />
							<div className="w-full flex items-end justify-end">
								<button
									onClick={() => {
										setCurrentOpenPanle('')
									}}
									className={`bg-[${color.action}] text-[${color.text}] p-1 mt-2 flex end justify-end items-end`}
									style={{ order: '1', zIndex: '9999999999999999999999999' }}>
									Save
								</button>
							</div>
						</div>
					)}
				</div>
				<div className="h-[50px] flex items-center gap-2 relative border p-1">
					<p className={`text-[${color.text}]`}>Informations</p>
					<button
						onClick={() => {
							setChangeColorOpenPanel('info')
						}}>
						<p style={{ background: color.info, width: '20px', height: '20px' }}></p>
					</button>
					{currentOpenPanel === 'info' && (
						<div style={{ position: 'absolute', zIndex: 2 }}>
							<div
								style={{
									position: 'fixed',
									top: '0px',
									right: '0px',
									bottom: '0px',
									left: '0px',
								}}
							/>
							<SketchPicker color={color} onChange={handleChangeColor} />
							<div className="w-full flex items-end justify-end">
								<button
									onClick={() => {
										setCurrentOpenPanle('')
									}}
									className={`bg-[${color.action}] text-[${color.text}] p-1 mt-2 flex end justify-end items-end gap-2`}
									style={{ order: '1', zIndex: '9999999999999999999999999' }}>
									Save
									<SaveAsIcon />
								</button>
							</div>
						</div>
					)}
				</div>
				<div className="h-[50px] flex items-center gap-2 relative border p-1">
					<p className={`text-[${color.text}]`}>Default text color</p>
					<button
						onClick={() => {
							setChangeColorOpenPanel('text')
						}}>
						<p style={{ background: color.text, width: '20px', height: '20px' }}></p>
					</button>
					{currentOpenPanel === 'text' && (
						<div style={{ position: 'absolute', zIndex: 2 }}>
							<div
								style={{
									position: 'fixed',
									top: '0px',
									right: '0px',
									bottom: '0px',
									left: '0px',
								}}
							/>
							<SketchPicker color={color} onChange={handleChangeColor} />
							<div className="w-full flex items-end justify-end">
								<button
									onClick={() => {
										setCurrentOpenPanle('')
									}}
									className={`bg-[${color.action}] text-[${color.text}] p-1 mt-2 flex end justify-end items-end gap-2`}
									style={{ order: '1', zIndex: '9999999999999999999999999' }}>
									Save
									<SaveAsIcon />
								</button>
							</div>
						</div>
					)}
				</div>

				<div className="h-[50px] flex items-center gap-2 relative border p-1">
					<p className={`text-[${color.title}]`}>Title text color</p>
					<button
						onClick={() => {
							setChangeColorOpenPanel('title')
						}}>
						<p style={{ background: color.text, width: '20px', height: '20px' }}></p>
					</button>
					{currentOpenPanel === 'title' && (
						<div style={{ position: 'absolute', zIndex: 2 }}>
							<div
								style={{
									position: 'fixed',
									top: '0px',
									right: '0px',
									bottom: '0px',
									left: '0px',
								}}
							/>
							<SketchPicker color={color} onChange={handleChangeColor} />
							<div className="w-full flex items-end justify-end">
								<button
									onClick={() => {
										setCurrentOpenPanle('')
									}}
									className={`bg-[${color.action}] text-[${color.text}] p-1 mt-2 flex end justify-end items-end gap-2`}
									style={{ order: '1', zIndex: '9999999999999999999999999' }}>
									Save
									<SaveAsIcon />
								</button>
							</div>
						</div>
					)}
				</div>
				<div className="h-[50px] flex items-center gap-2 relative border p-1">
					<p className={`text-[${color.text}]`}>Alert color</p>
					<button
						onClick={() => {
							setChangeColorOpenPanel('alertText')
						}}>
						<p style={{ background: color.alertText, width: '20px', height: '20px' }}></p>
					</button>
					{currentOpenPanel === 'alertText' && (
						<div style={{ position: 'absolute', zIndex: 2 }}>
							<div
								style={{
									position: 'fixed',
									top: '0px',
									right: '0px',
									bottom: '0px',
									left: '0px',
								}}
							/>
							<SketchPicker color={color} onChange={handleChangeColor} />
							<div className="w-full flex items-end justify-end">
								<button
									onClick={() => {
										setCurrentOpenPanle('')
									}}
									className={`bg-[${color.action}] text-[${color.text}] p-1 mt-2 flex end justify-end items-end gap-2`}
									style={{ order: '1', zIndex: '9999999999999999999999999' }}>
									Save
									<SaveAsIcon />
								</button>
							</div>
						</div>
					)}
				</div>
				<div className="h-[50px] flex items-center gap-2 relative border p-1">
					<p className={`text-[${color.text}]`}>Aside menu</p>
					<button
						onClick={() => {
							setChangeColorOpenPanel('sidenav')
						}}>
						<p style={{ background: color.sidenav, width: '20px', height: '20px' }}></p>
					</button>
					{currentOpenPanel === 'sidenav' && (
						<div style={{ position: 'absolute', zIndex: 2 }}>
							<div
								style={{
									position: 'fixed',
									top: '0px',
									right: '0px',
									bottom: '0px',
									left: '0px',
								}}
							/>
							<SketchPicker color={color} onChange={handleChangeColor} />
							<div className="w-full flex items-end justify-end">
								<button
									onClick={() => {
										setCurrentOpenPanle('')
									}}
									className={`bg-[${color.action}] text-[${color.text}] p-1 mt-2 flex end justify-end items-end gap-2`}
									style={{ order: '1', zIndex: '9999999999999999999999999' }}>
									Save
									<SaveAsIcon />
								</button>
							</div>
						</div>
					)}
				</div>
				<div className="h-[50px] flex items-center gap-2 relative border p-1">
					<p className={`text-[${color.text}]`}>Aside menu text</p>
					<button
						onClick={() => {
							setChangeColorOpenPanel('asideText')
						}}>
						<p style={{ background: color.text, width: '20px', height: '20px' }}></p>
					</button>
					{currentOpenPanel === 'asideText' && (
						<div style={{ position: 'absolute', zIndex: 2 }}>
							<div
								style={{
									position: 'fixed',
									top: '0px',
									right: '0px',
									bottom: '0px',
									left: '0px',
								}}
							/>
							<SketchPicker color={color} onChange={handleChangeColor} />
							<div className="w-full flex items-end justify-end">
								<button
									onClick={() => {
										setCurrentOpenPanle('')
									}}
									className={`bg-[${color.action}] text-[${color.text}] p-1 mt-2 flex end justify-end items-end gap-2`}
									style={{ order: '1', zIndex: '9999999999999999999999999' }}>
									Save
									<SaveAsIcon />
								</button>
							</div>
						</div>
					)}
				</div>
				<div className="h-[50px] flex items-center gap-2 relative border p-1">
					<p className={`text-[${color.text}]`}>Content</p>
					<button
						onClick={() => {
							setChangeColorOpenPanel('bg')
						}}>
						<PaletteIcon />
					</button>
					{currentOpenPanel === 'bg' && (
						<div style={{ position: 'absolute', zIndex: 2 }}>
							<div
								style={{
									position: 'fixed',
									top: '0px',
									right: '0px',
									bottom: '0px',
									left: '0px',
								}}
							/>
							<SketchPicker color={color} onChange={handleChangeColor} />
							<div className="w-full flex items-end justify-end">
								<button
									onClick={() => {
										setCurrentOpenPanle('')
									}}
									className={`bg-[${color.action}] text-[${color.text}] p-1 mt-2 flex end justify-end items-end gap-2`}
									style={{ order: '1', zIndex: '9999999999999999999999999' }}>
									Save
									<SaveAsIcon />
								</button>
							</div>
						</div>
					)}
				</div>
				<div className="h-[50px] flex items-center gap-2 relative border p-1">
					<p className={`text-[${color.text}]`}>Logo</p>
					<button
						onClick={() => {
							setChangeColorOpenPanel('logo')
						}}>
						<PaletteIcon />
					</button>
					{currentOpenPanel === 'logo' && (
						<div style={{ position: 'absolute', zIndex: 2 }}>
							<div
								style={{
									position: 'fixed',
									top: '0px',
									right: '0px',
									bottom: '0px',
									left: '0px',
								}}
							/>
							<SketchPicker color={color} onChange={handleChangeColor} />
							<div className="w-full flex items-end justify-end">
								<button
									onClick={() => {
										setCurrentOpenPanle('')
									}}
									className={`bg-[${color.action}] text-[${color.text}] p-1 mt-2 flex end justify-end items-end gap-2`}
									style={{ order: '1', zIndex: '9999999999999999999999999' }}>
									Save
									<SaveAsIcon />
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="flex gap-10 items-center mt-4">
				<button
					className={`bg-[${color.action}] text-[${color.text}] p-1 flex gap-3 items-center`}
					onClick={() => {
						resetColor()
					}}>
					Reset colors
					<RestartAltIcon />
				</button>
			</div>

			<div className="mt-5 gap-10 flex items-center flex-wrap wrap justify-center">
				<button className={`bg-[${color.action}] text-[${color.text}] p-1`}>Action example</button>
				<p className={`text-[${color.text}] p-1`}>Default text example</p>
				<p className={`bg-[${color.info}] text-[${color.text}] p-1`}>Information example</p>
				<p className={`text-[${color.text}] p-1`}>Title text example</p>
				<p className={`text-[${color.text}] p-1`}>Aside text example</p>
				<p className={`bg-[${color.alert}] text-[${color.alertText}] p-1`}>Information example</p>
			</div>
		</div>
	)
}
