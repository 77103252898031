import React from 'react'
import { IKineraPost } from '../../../interface'
import { Link, useNavigate } from 'react-router-dom'
import ImagesDisplay from '../../ImagesDisplay'

interface Props {
	tweet: IKineraPost
}

const ConstellationPost: React.FC<Props> = ({ tweet }) => {
	const navigate = useNavigate()
	const newFormatedDate = () => {
		const date = new Date(tweet.time)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const year = date.getFullYear()
		const hours = String(date.getHours()).padStart(2, '0')
		const minutes = String(date.getMinutes()).padStart(2, '0')

		return `${day}/${month}/${year} - ${hours}:${minutes}`
	}
	return (
		<div className="w-full flex gap-5 p-2 items-start">
			<img
				onClick={() => {
					navigate('/social/view/' + tweet.address)
				}}
				src={
					tweet.avatarSrc
						? tweet.avatarSrc
						: 'https://media.istockphoto.com/id/522855255/vector/male-profile-flat-blue-simple-icon-with-long-shadow.jpg?s=612x612&w=0&k=20&c=EQa9pV1fZEGfGCW_aEK5X_Gyob8YuRcOYCYZeuBzztM='
				}
				alt="#"
				className="avatar cursor-pointer hover:opacity-70"
			/>

			<div className={'w-full drop-shadow-md '}>
				<div className="bg-[#f9fafb] p-2 flex flex-col rounded-[4px] shadow-default w-full">
					<div className="flex items-start border-[#efefef] gap-[10px] overflow-hidden cursor-pointer">
						<div className="tweet-message truncate w-full overflow-visible">
							<div
								className="w-full justify-between items-center flex hover:opacity-80"
								onClick={() => {
									navigate('/social/view/' + tweet.address)
								}}
							>
								<Link to={'/social/view/' + tweet.address} className="truncate">
									<b>{tweet.username}</b>
								</Link>
								<span className="truncate min-w-[200px] items-end flex justify-end">{newFormatedDate()}</span>
							</div>
							<div className="hover:opacity-80">
								<Link to={`/details/${tweet.event_id}`} className="cursor-pointer whitespace-pre-line">
									Join my new constellation <b className={`ml-2 underline hover:text-blue-800`}>{tweet.message}</b>
								</Link>

								{tweet.urls && tweet.urls.length >= 1 && tweet.urls[0] !== '' && <ImagesDisplay urls={tweet.urls} />}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConstellationPost
